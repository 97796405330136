import React, { useMemo, useRef, useCallback } from "react";
import { x } from "@xstyled/styled-components";
import { Button, Icon, IconButton, Popover, Skeleton, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useAttachmentsButtonStyles, useMessengerHeaderStyles, useSeparatorStyles } from "./MessengerHeader.styles";
import { AddCircleOutline, Attachment, ChevronDown, Download, Settings } from "@alphasights/alphadesign-icons";
import { useIsOverflow, useNotifications } from "@alphasights/client-portal-shared";
import { ThreadResponse, AttachmentResponse, RequestType, ResponseStatusType } from "types";
import { StatusPillVariant } from "../MessengerStatusPill/MessengerStatusPill.styles";
import { getRequestTypeName } from "pages/MessengerPage/utils";
import { useMessengerContext } from "providers/MessengerProvider";
import { messageAttachmentService } from "services/messageAttachment";
import { withAccessControl } from "components/AccessControl/AccessControl";

export const MessengerHeader = () => {
  const { isSettingsOpened, setIsSettingsOpened, onOpenSettings, selectedInbox, projectToken } = useMessengerContext();
  const { container, headerInfo, headerActions } = useMessengerHeaderStyles();

  if (!selectedInbox) return null;

  const showAttachments = selectedInbox.requestType !== RequestType.Clarification;
  const showSettings = selectedInbox.requestType === RequestType.WorkRequest;
  const firstMessage = selectedInbox.threads[0]?.messages[0];

  const toggleSettings = () => {
    if (isSettingsOpened) {
      setIsSettingsOpened(!isSettingsOpened);
    } else {
      const awaitingExperts = selectedInbox.threads
        .filter(
          (thread) =>
            thread.status === ResponseStatusType.AwaitingApproval ||
            thread.status === ResponseStatusType.AwaitingResponse ||
            thread.status === ResponseStatusType.ExpiredAwaitingApproval
        )
        .map((thread) => thread.advisor.id);

      onOpenSettings(awaitingExperts);
    }
  };

  return (
    <x.div {...container}>
      <x.div {...headerInfo}>
        <x.div>
          <Typography variant="body-em" color="strong">
            {getRequestTypeName(selectedInbox.requestType)}
          </Typography>
          <Typography variant="body-small" color="secondary">
            From: {firstMessage.sender.name} {" • "}
            <FormattedDateTime prefix={undefined} date={firstMessage.createdAt} format="E d MMM yyyy, h:mmaaa" />
          </Typography>
        </x.div>
      </x.div>
      <x.div {...headerActions}>
        {showAttachments && <AttachmentsButton threads={selectedInbox.threads} projectToken={projectToken} />}
        {showSettings && (
          <IconButton onClick={toggleSettings} variant="outline" size="small">
            <Settings />
          </IconButton>
        )}
        <Button
          onClick={() => onOpenSettings([], true)}
          variant="outline"
          size="small"
          startIcon={<AddCircleOutline />}
        >
          Add Experts
        </Button>
      </x.div>
    </x.div>
  );
};

export const statusVariantMap = {
  [ResponseStatusType.AwaitingResponse]: "awaiting",
  [ResponseStatusType.AwaitingApproval]: "awaiting",
  [ResponseStatusType.Sent]: "sent",
  [ResponseStatusType.Replied]: "replied",
  [ResponseStatusType.Closed]: "nolongeravailable",
  [ResponseStatusType.Declined]: "declined",
  [ResponseStatusType.Cancelled]: "cancelled",
  [ResponseStatusType.ExpiredAwaitingApproval]: "expired",
  [ResponseStatusType.Expired]: "expired",
  [ResponseStatusType.PendingUpdate]: "awaiting",
} as { [key in ResponseStatusType]: StatusPillVariant };

const attachmentPriority = ["client", "expert"];

const AttachmentsButton = ({ threads, projectToken }: { threads: ThreadResponse[]; projectToken: string }) => {
  const { popoverContainer } = useAttachmentsButtonStyles();

  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
  const ref = React.useRef(null);
  const handlePopoverClick = (event: React.MouseEvent) => {
    if (anchorEl) {
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };
  const open = Boolean(anchorEl);

  const attachmentGroup = useMemo(
    () =>
      threads
        .flatMap((thread) => thread.messages.flatMap((message) => message))
        .filter((thread) => thread.attachments.length > 0)
        .sort(
          (a, b) =>
            attachmentPriority.indexOf(b.sender.role) - attachmentPriority.indexOf(a.sender.role) ||
            new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()
        )
        .reduce(
          (acc, message) => ({
            ...acc,
            [message.sender.name!]: [...(acc[message.sender.name!] || []), ...message.attachments].filter(
              (attachment, index, self) => index === self.findIndex((att) => att.id === attachment.id)
            ),
          }),
          {} as { [id: string]: AttachmentResponse[] }
        ),
    [threads]
  );

  const hasAttachment = Object.keys(attachmentGroup).length > 0;

  return (
    <x.div>
      {hasAttachment && (
        <>
          <Button
            variant="outline"
            size="small"
            startIcon={<Attachment />}
            endIcon={<ChevronDown />}
            onClick={handlePopoverClick}
          >
            Attachments
          </Button>
          <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handlePopoverClose} placement="bottom-end">
            {Object.keys(attachmentGroup).map((sender, index) => (
              <x.div key={index} {...popoverContainer}>
                <AttachmentSenderGroup
                  sender={sender}
                  attachments={attachmentGroup[sender]}
                  projectToken={projectToken}
                  isOpen={open}
                />
                {index < Object.keys(attachmentGroup).length - 1 && <Separator usePadding />}
              </x.div>
            ))}
          </Popover>
        </>
      )}
    </x.div>
  );
};

const AttachmentSenderGroup = ({
  sender,
  attachments,
  projectToken,
  isOpen,
}: {
  sender: string;
  attachments: AttachmentResponse[];
  projectToken: string;
  isOpen: boolean;
}) => {
  const { senderGroup, senderName } = useAttachmentsButtonStyles();

  return (
    <x.div {...senderGroup}>
      <Typography {...senderName}>{sender}</Typography>
      {attachments.map((attachment) => (
        <AttachmentFile key={attachment.id} attachment={attachment} projectToken={projectToken} isOpen={isOpen} />
      ))}
    </x.div>
  );
};

const AttachmentFile = ({
  attachment,
  projectToken,
  isOpen,
}: {
  attachment: AttachmentResponse;
  projectToken: string;
  isOpen: boolean;
}) => {
  const { fileContainer, fileName } = useAttachmentsButtonStyles();
  const { showSuccessBanner, showErrorBanner } = useNotifications();

  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref, [isOpen]);

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => {
        showSuccessBanner("Attachment download successful");
      })
      .catch(() => {
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead");
      });
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <x.div data-testid="attachment-file" {...fileContainer}>
      <Icon>
        <Attachment />
      </Icon>
      <Tooltip title={<Typography>{attachment.filename}</Typography>} disabled={!isOverflow} position="top">
        <Typography ref={ref} {...fileName}>
          {attachment.filename}
        </Typography>
      </Tooltip>
      <Typography variant="body-small" color="assistive">
        {attachment.type} • {attachment.fileSize}
      </Typography>
      <x.div display="flex" marginLeft="auto">
        <IconButtonWithAccess
          accessControl={{ allowedPermissions: ["follow-up-messages"] }}
          variant="basic"
          onClick={onClickDownload}
        >
          <Download />
        </IconButtonWithAccess>
      </x.div>
    </x.div>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);

const Separator = ({ usePadding = false }: { usePadding?: boolean }) => {
  const { separator, padding } = useSeparatorStyles({ usePadding });

  return (
    <x.div {...padding}>
      <x.div {...separator} />
    </x.div>
  );
};

export const MessengerHeaderSkeleton = () => {
  const { container } = useMessengerHeaderStyles();

  return (
    <x.div {...container} h="85px">
      <x.div flexGrow={2} display="flex">
        <Skeleton />
        <Skeleton />
      </x.div>
      <x.div flexGrow={1} />
      <x.div flexGrow={1}>
        <Skeleton />
      </x.div>
    </x.div>
  );
};
