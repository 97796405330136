import React, { useState, useRef, useContext, useEffect } from "react";
import { DispatchContext } from "../../../components/InteractionsPage/DispatchContext";
import { createPortal } from "react-dom";
import useOnClickOutside from "../../../hooks/useOnClickHooks";
import {
  submitScheduling,
  requestChangeInteraction,
  requestAdvisor,
} from "../../../components/InteractionsPage/reducer";
import {
  addMinutes,
  addSeconds,
  differenceInMinutes,
  differenceInSeconds,
  min,
  max,
  parseISO,
  isWithinInterval,
  startOfMinute,
} from "date-fns";
import { FormattedTimeRange, useTimezone } from "../../../providers/TimezoneProvider";
import { zonedTimeToUtc } from "date-fns-tz";
import ReactTooltip from "react-tooltip";
import { AvailabilityPopup } from "../AvailabilityPopup";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { eventOnElement } from "utils";
import { CalendarPopover } from "./CalendarPopover";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";
import * as S from "./ExpertAvailability.styles";

const adjustStartEndEventSlot = ({ event, continuesEarlier, continuesLater, tz }) => {
  const start = parseISO(event.availability.startsAt);
  const end = parseISO(event.availability.endsAt);

  const midnightBetween = zonedTimeToUtc(tz.format(end, "yyyy-MM-dd") + " 00:00:00.000", tz.currentTimezone);

  if (continuesLater) {
    return {
      start,
      end: midnightBetween,
    };
  }

  if (continuesEarlier) {
    return {
      start: midnightBetween,
      end,
    };
  }

  return {
    start,
    end,
  };
};

export const ExpertAvailabilityWrapper = ({
  event,
  children,
  isEventPopupOpen,
  popupState,
  onUpdatePopupState,
  onPopupForwardTo,
  onPopupOpen,
  onPopupClose,
  referenceElement,
  continuesPrior: continuesEarlier,
  continuesAfter: continuesLater,
  isFlyout,
  ...others
}) => {
  const isLoggedIn = useIsAuthenticated();
  const tz = useTimezone();
  const [timeHovered, setTimeHovered] = useState(null);
  // popup & popper settings
  const popover = useRef();
  const dispatch = useContext(DispatchContext);

  const wrapperRef = useRef();
  const availabilityStartTime = startOfMinute(parseISO(event.availability.startsAt)).getTime();
  const availabilityEnd = parseISO(event.availability.endsAt);
  const availabilityEndTime = availabilityEnd.getTime();
  const adjustedSlot = adjustStartEndEventSlot({
    event,
    continuesEarlier,
    continuesLater,
    tz,
  });

  const availabilityDuration = differenceInSeconds(adjustedSlot.end, adjustedSlot.start);

  let suggestedDuration = 0;
  const expectedDuration = event.interaction.allowNonHourAutoBook
    ? Math.max(event.interaction.expectedDuration || 3600, 1800)
    : 3600;
  if (availabilityDuration >= expectedDuration) {
    suggestedDuration = expectedDuration;
  } else {
    if (availabilityDuration >= 1800 && event.interaction.allowNonHourAutoBook) suggestedDuration = 1800;
  }

  const onPopupCloseWrapper = () => {
    onPopupClose();
    setTimeHovered(null);
  };

  useOnClickOutside(popover, function keepOpenIfClickingInSameSlot(e) {
    let el = e.target;

    while (el != null) {
      if (
        el.dataset &&
        el.dataset["advisorId"] === event.interaction.id &&
        el.dataset["startTime"] === availabilityStartTime.toString()
      )
        return;

      el = el.parentNode;
    }

    if (wrapperRef.current.contains(e.target)) return;

    onPopupCloseWrapper();
  });

  const onSchedule = ({ interaction, start, end, interactionId, timezone }, callback, errorCallback) => {
    const matchedAvailability = event.overlaps.some((slot) => {
      return isWithinInterval(start, slot) && isWithinInterval(end, slot);
    });

    return dispatch(
      submitScheduling(
        {
          interactionIds: [interactionId],
          mode: "slots",
          slots: [{ startsAt: start, endsAt: end }],
          timezone,
        },
        {
          origin: HitOrigin.calendarView,
          skipPopup: true,
          matchedAvailability,
          isLoggedIn,
        }
      )
    )
      .then(onPopupForwardTo(start, interactionId))
      .then(callback)
      .catch(errorCallback);
  };

  const onScheduleFollowUp = ({ interaction, start, end, interactionId, timezone }, callback, errorCallback) => {
    return event.deps
      .onRequestFollowUp({
        id: interactionId,
        selectCard: false,
        origin: HitOrigin.calendarViewPopup,
      })
      .then((followUp) => {
        event.deps.selectInteraction(followUp);
        return onSchedule({ interaction, start, end, interactionId: followUp.id, timezone }, callback, errorCallback);
      });
  };

  const onRequestSchedule = ({ interactionId = event.interaction.id, requestedTime, duration }) => {
    return dispatch(
      requestAdvisor({
        id: interactionId,
        origin: HitOrigin.calendarView,
        skipPopup: true,
      })
    )
      .then(() =>
        dispatch(
          requestChangeInteraction({
            id: interactionId,
            type: "SCHEDULE_REQUEST",
            projectToken: event.interaction.projectToken,
            payload: {
              requestedTime,
              duration,
            },
          })
        )
      )
      .then(() => {
        onPopupForwardTo(requestedTime, interactionId);
      });
  };

  const onRequestScheduleFollowUp = ({ requestedTime, duration }) => {
    return event.deps
      .onRequestFollowUp({
        id: event.interaction.id,
        selectCard: false,
        origin: HitOrigin.calendarViewPopup,
      })
      .then((followUp) => {
        event.deps.selectInteraction(followUp);
        onRequestSchedule({
          interactionId: followUp.id,
          requestedTime,
          duration,
        });
        return followUp;
      });
  };

  const availabilityDivRef = useRef();
  const calculateSelectedTime = ({ clientY }, callback) => {
    if (suggestedDuration === 0) return;

    // this rect is managed by the lib, hence the ugly children[0]
    const rect = availabilityDivRef.current.children[0].getBoundingClientRect();
    const rectActualTime = continuesEarlier
      ? { start: adjustedSlot.start, end: event.end }
      : continuesLater
      ? { start: event.start, end: adjustedSlot.end }
      : { start: event.start, end: event.end };

    const pixelsPerMinute = rect.height / differenceInMinutes(rectActualTime.end, rectActualTime.start);

    const offsetY = clientY - rect.top;
    const timeOffset15min = Math.floor(offsetY / pixelsPerMinute / 15) * 15;
    const timeSelected = startOfMinute(addMinutes(rectActualTime.start, timeOffset15min));

    const overlap = event.overlaps.find((o) => isWithinInterval(timeSelected, o));
    const overlapDuration = overlap && differenceInSeconds(overlap.end, timeSelected);

    const duration =
      overlapDuration > 0 &&
      differenceInSeconds(availabilityEndTime, addSeconds(timeSelected, overlapDuration)) > suggestedDuration
        ? Math.min(overlapDuration, suggestedDuration)
        : popupState?.duration || suggestedDuration;

    const maxValidValue = startOfMinute(addSeconds(adjustedSlot.end, -duration));

    const selectedStart = max([min([timeSelected, maxValidValue]), availabilityStartTime]);
    const minDuration = Math.max(duration, popupState?.duration || suggestedDuration);
    const isValidStart = isWithinInterval(addSeconds(selectedStart, minDuration), {
      start: availabilityStartTime,
      end: availabilityEndTime,
    });
    if (isValidStart) {
      callback({ selectedStart, duration });
    }
  };

  const isThisSlotPopped =
    isEventPopupOpen &&
    popupState.selectedStart.getTime() >= adjustedSlot.start.getTime() &&
    popupState.selectedStart.getTime() < adjustedSlot.end.getTime();

  let isFirstEventOfAvailability = false;

  if (continuesLater) {
    isFirstEventOfAvailability = event.start.getTime() === availabilityStartTime;
  } else if (continuesEarlier) {
    isFirstEventOfAvailability =
      popupState.selectedStart && popupState.selectedStart.getTime() >= adjustedSlot.start.getTime();
  } else {
    isFirstEventOfAvailability = event.start.getTime() === adjustedSlot.start.getTime();
  }

  const onAvailabilityHover = ({ selectedStart, duration }) => {
    setTimeHovered({ start: selectedStart, duration });
  };

  const notOnPopup = (e) => !popover.current || !eventOnElement(e, popover.current);

  useEffect(ReactTooltip.rebuild); // eslint-disable-line react-hooks/exhaustive-deps

  const elemId = `expert-availability-wrapper-${event.interaction.id}-${event.start.toISOString()}`;

  const overlapSlots = event.overlaps.map((overlap) => (
    <OverlappingSlot
      key={`overlap-${event.id}-${overlap.start.getTime()}`}
      start={overlap.start}
      end={overlap.end}
      style={others.style}
      event={event}
    />
  ));

  return (
    <div
      id={elemId}
      data-testid={elemId}
      className="aui-cursor-pointer"
      ref={wrapperRef}
      data-advisor-id={event.interaction.id}
      data-start-time={availabilityStartTime}
      onMouseMove={(e) =>
        !event.isProvideAvailability && notOnPopup(e) && calculateSelectedTime(e, onAvailabilityHover)
      }
      onMouseLeave={(e) => !event.isProvideAvailability && notOnPopup(e) && setTimeHovered(null)}
      onClick={(e) => !event.isProvideAvailability && notOnPopup(e) && calculateSelectedTime(e, onPopupOpen)}
      data-tip={
        suggestedDuration === 0
          ? "Please reach out to your AlphaSights project lead as this availability timeframe is too short to schedule a call in the Platform"
          : null
      }
      data-class="custom-react-tooltip-light"
      data-place="bottom"
    >
      <div ref={availabilityDivRef}>
        {children}
        {availabilityDivRef.current?.children &&
          overlapSlots.length > 0 &&
          createPortal(overlapSlots, availabilityDivRef.current.children[0])}
      </div>
      {isThisSlotPopped && isFirstEventOfAvailability && (
        <>
          <CalendarPopover ref={popover} referenceElement={referenceElement}>
            <AvailabilityPopup
              event={event}
              onClose={onPopupCloseWrapper}
              selectedStart={popupState.selectedStart}
              setSelectedStart={(val) => {
                const newDuration = Math.min(differenceInSeconds(availabilityEnd, val), popupState.duration);
                return onUpdatePopupState({
                  selectedStart: val,
                  duration: newDuration,
                });
              }}
              duration={popupState.duration}
              setDuration={(val) => onUpdatePopupState({ duration: val })}
              onSchedule={onSchedule}
              onScheduleFollowUp={onScheduleFollowUp}
              onRequestSchedule={onRequestSchedule}
              onRequestScheduleFollowUp={onRequestScheduleFollowUp}
              isFlyout={isFlyout}
            />
          </CalendarPopover>
          {availabilityDivRef.current &&
            createPortal(
              <TemporaryScheduledSlot
                start={popupState.selectedStart}
                duration={popupState.duration}
                style={others.style}
                event={event}
              />,
              availabilityDivRef.current.children[0]
            )}
        </>
      )}
      {!isThisSlotPopped &&
        timeHovered &&
        availabilityDivRef.current &&
        createPortal(
          <TemporaryScheduledSlot
            start={timeHovered?.start}
            duration={timeHovered?.duration}
            style={others.style}
            event={event}
          />,
          availabilityDivRef.current.children[0]
        )}
    </div>
  );
};

const calculateSubEvent = ({ parentStart, parentEnd, start, end, style }) => {
  const event15Length = differenceInMinutes(parentEnd, parentStart) / 15;
  const slot15Length = differenceInMinutes(end, start) / 15;
  const height = (100 * slot15Length) / event15Length + "%";
  const slot15Offset = differenceInMinutes(start, parentStart) / 15;

  const top = (100 * slot15Offset) / event15Length + "%";

  return { top, height };
};

const TemporaryScheduledSlot = ({ start, duration, style, event }) => {
  const end = addSeconds(start, duration);

  const { top, height } = calculateSubEvent({
    parentStart: event.start,
    parentEnd: event.end,
    start,
    end,
    style,
  });
  const isMutual = event.overlaps.find((o) => isWithinInterval(start, o) && isWithinInterval(end, o));
  return (
    <S.TemporaryScheduledSlot
      id="temporary-scheduled-slot"
      top={top}
      height={height}
      data-testid="temporary-scheduled-slot"
      isMutual={isMutual}
    >
      <ExpertAvailabilityEvent event={{ ...event, start, end, isMutual }} />
    </S.TemporaryScheduledSlot>
  );
};

const OverlappingSlot = ({ start, end, event }) => {
  const pctPerMinute = 100 / differenceInMinutes(event.end, event.start);
  const topOffset = differenceInMinutes(start, event.start) * pctPerMinute;
  const height = differenceInMinutes(end, start) * pctPerMinute;
  const id = `mutual-availability-slot-${start.toISOString()}-${end.toISOString()}`;

  return (
    <S.OverlappingSlotStyled
      id={id}
      top={`calc(${topOffset}% - 1px)`}
      height={`calc(${height}% + 2px)`}
      data-testid={id}
    ></S.OverlappingSlotStyled>
  );
};

export const useExpertAvailabilityEventProps = ({ isProvideAvailability }) => {
  const {
    color: { border, text, base },
  } = useThemeTokens();

  return {
    style: {
      color: isProvideAvailability ? text.disabled : text.info,
      borderColor: isProvideAvailability ? border.disabled : border.info,
      backgroundColor: base.white,
    },
  };
};

const EventDescription = ({ angle, advisorName, companyName, role }) => (
  <>
    {angle}
    {advisorName}
    <br />
    {companyName} - {role}
  </>
);

export const ExpertAvailabilityEvent = ({
  event: {
    interaction: { id, advisorName, companyName, role, angles },
    start,
    end,
    source,
    isMutual,
    detailedDescription = true,
  },
}) => {
  const angle = angles[0];
  const angleTitle = angle && angle.title + " | ";
  const args = { detailedDescription, angle: angleTitle, advisorName, companyName, role };

  return (
    <S.ExpertAvailabilityEventStyled data-testid={`${source}-slot-${id}-${start.toISOString()}`}>
      <S.EventDescriptionStyled>
        <EventDescription {...args} />
        <br />
      </S.EventDescriptionStyled>
      <S.EventPeriodStyled data-testid={`event-period-slot-${id}`}>
        {isMutual && "Mutual: "}
        <FormattedTimeRange start={start} end={end} />
      </S.EventPeriodStyled>
    </S.ExpertAvailabilityEventStyled>
  );
};
