import React from "react";
import { NPSCategory, NPSCompany } from "@alphasights/portal-api-client";
import tokens from "@alphasights/alphadesign-tokens";
import { ChartData, SynthesisBarChart } from "../../components";

export const NPSChart = ({ companies }: { companies: NPSCompany[] }) => {
  const chartData = getChartData(companies);
  const categories = companies.map((item) => item.companyName);

  return (
    <SynthesisBarChart
      chartData={chartData}
      categories={categories}
      extraChartProps={{
        id: "nps-stacked-bar-chart",
        horizontal: true,
        popOverPlacement: "bottom",
      }}
    />
  );
};

const getChartData = (companies: NPSCompany[]): ChartData[] => {
  const validSentiments = [NPSCategory.DETRACTOR, NPSCategory.PASSIVE, NPSCategory.PROMOTER];
  return validSentiments.map((sentiment) => {
    return {
      name: sentimentLabels[sentiment],
      color: sentimentColors[sentiment],
      data: companies.map((company, idx) => {
        const sentimentData = company.categorySummaries.find((s) => s.category === sentiment);
        return {
          y: sentimentData?.count ?? 0,
          x: idx.toString(),
          color: sentimentColors[sentiment],
          sentiment: sentimentLabels[sentiment],
          quotes: sentimentData?.quotes ?? [],
        };
      }),
    };
  });
};

const sentimentColors = {
  [NPSCategory.DETRACTOR]: tokens.color.chart.categorical.base04,
  [NPSCategory.PROMOTER]: tokens.color.chart.categorical.base03,
  [NPSCategory.PASSIVE]: tokens.color.chart.categorical.base09,
};

const sentimentLabels = {
  [NPSCategory.DETRACTOR]: "Detractor",
  [NPSCategory.PROMOTER]: "Promoter",
  [NPSCategory.PASSIVE]: "Passive",
};
