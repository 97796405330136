import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import React, { ChangeEvent, useCallback } from "react";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./NPSModuleContent.styles";
import { nps } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { Icon, IconButton, Tile, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { NPSCategory, NPSCompany, NPSQuote, SynthesisQuote } from "@alphasights/portal-api-client";
import { Company as CompanyIcon, ArrowDown, ArrowUp, Delete, Remove } from "@alphasights/alphadesign-icons";
import { EditableInput, ExpertQuote, RevisionToggler } from "../components";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _, { noop } from "lodash";
import { NPSChart } from "./NPSChart";
import { ScheduleCustomersBanner } from "../components/ScheduleCustomersBanner";

export const NPSModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const styles = useStyles();
  const { spacing } = useThemeTokens();

  const { revision, mode } = useProjectSynthesisContext();

  const content = nps(revision?.contents);

  return (
    <x.div mt={spacing.inner.base06}>
      <ScheduleCustomersBanner />
      <x.div {...styles.container}>
        {mode === Mode.VIEW && (
          <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="32px" zIndex="1" />
        )}
        <NPSChart companies={content.companies} />
        {content.companies.map((c) => {
          return <CompanyNPSRender key={c.id} company={c} />;
        })}
      </x.div>
    </x.div>
  );
};

const CompanyNPSRender = ({ company }: { company: NPSCompany }) => {
  const styles = useStyles();

  return (
    <x.div {...styles.card} data-testid={`company-${company.id}`}>
      <x.div {...styles.companyRow}>
        <CompanyName company={company} />
      </x.div>
      <CompanySummary company={company} />

      {_.sortBy(
        company.transcriptAnalyses.flatMap((ta) => ta.quotes),
        (q) => [NPSCategory.PROMOTER, NPSCategory.PASSIVE, NPSCategory.DETRACTOR].indexOf(q.category)
      ).map((q) => {
        return <CompanyNPSQuote key={q.id} company={company} quote={q} />;
      })}
    </x.div>
  );
};

const CompanyNPSQuote = ({ company, quote }: { company: NPSCompany; quote: NPSQuote }) => {
  const styles = useStyles();
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onEditQuote = useCallback(
    (updatedQuote: SynthesisQuote) => {
      editOperations.npsUpdateQuote(company.id, quote.id, updatedQuote.text, selectedRevisionIdx);
    },
    [editOperations, company, quote, selectedRevisionIdx]
  );

  const onDeleteQuote = useCallback(() => {
    editOperations.npsDeleteQuote(company.id, quote.id, selectedRevisionIdx);
  }, [editOperations, company, quote, selectedRevisionIdx]);

  return (
    <x.div {...styles.quoteRow}>
      <NPSCategoryIcon category={quote.category} />
      <x.div display="flex" w="100%" flexDirection="column">
        <ExpertQuote
          quote={quote}
          mode={mode}
          onEditQuote={onEditQuote}
          onDeleteQuote={onDeleteQuote}
          onNavigateToTranscript={noop}
          saveInProgress={false}
          showCompanyInfo
        />
      </x.div>
    </x.div>
  );
};

const CompanyName = ({ company }: { company: NPSCompany }) => {
  const styles = useStyles();

  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const tile = company.companyLogo ? (
    <Tile variant="image" size="x-small" image={company.companyLogo} />
  ) : (
    <Tile variant="icon" size="x-small" icon={<CompanyIcon />} />
  );

  const onDeleteCompany = useCallback(() => {
    editOperations.npsDeleteCompany(company.id, selectedRevisionIdx);
  }, [editOperations, company, selectedRevisionIdx]);

  return (
    <>
      {tile}
      <Typography variant="body-em" data-testid="company-name">
        {company.companyName}
      </Typography>
      {mode === Mode.EDIT && (
        <Tooltip title="Delete NPS">
          <IconButton
            size="small"
            variant="ghost"
            onClick={onDeleteCompany}
            testId={`delete-company-${company.id}`}
            xStyledProps={styles.deleteCompanyIcon}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

const CompanySummary = ({ company }: { company: NPSCompany }) => {
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      editOperations.npsUpdateCompanySummary(company.id, e.target.value, selectedRevisionIdx);
    },
    [editOperations, company, selectedRevisionIdx]
  );

  if (mode === Mode.VIEW) {
    return (
      <Typography variant="body" data-testid="company-summary">
        {company.summary}
      </Typography>
    );
  }

  return <EditableInput input={company.summary} onChange={onChange} />;
};

const NPSCategoryIcon = ({ category }: { category: NPSCategory }) => {
  const { color, icon, text } = {
    PROMOTER: {
      text: "Promoter",
      color: "success",
      icon: <ArrowUp />,
    },
    PASSIVE: {
      text: "Passive",
      color: "secondary",
      icon: <Remove />,
    },
    DETRACTOR: {
      text: "Detractor",
      color: "danger",
      icon: <ArrowDown />,
    },
  }[category];

  return (
    <Tooltip title={text} position="bottom">
      <Icon color={color} dataAttributes={{ "data-testid": `category-icon-${category}` }}>
        {icon}
      </Icon>
    </Tooltip>
  );
};
