import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { x, th } from "@xstyled/styled-components";
import { Help, LeftArrow, Send } from "@alphasights/alphadesign-icons";
import {
  Button,
  Icon,
  IconButton,
  Loading,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { NoCommentsYet } from "./NoCommentsYet";
import { CommentRow } from "./CommentRow.js";
import CommentService from "./commentService";
import { useCommentContext } from "./CommentContext";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { clamp } from "lodash";
import { useTrackUserAction, ButtonWithMargin } from "@alphasights/client-portal-shared";
import { ProjectMembersPopover } from "./ProjectMembersPopover";
import { useProjectMembersContext } from "providers/ProjectMembersProvider";
import useCreatorColors from "./useCreatorColors";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { useCurrentUser, useIsInternalUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { HitOrigin } from "@alphasights/portal-api-client";
import { SwipeableItem } from "components/SwipeableItem/SwipeableItem";

export const calculateRows = (event, maxRows = 5) => {
  const style = getComputedStyle(event.target);
  const lineHeight = Math.floor(parseFloat(style.lineHeight.replace("px", ""))) || 18;
  const paddingTop = parseFloat(style.paddingTop.replace("px", ""));
  const paddingBottom = parseFloat(style.paddingBottom.replace("px", ""));

  const contentHeight = event.target.scrollHeight - paddingTop - paddingBottom;
  const rows = Math.floor(clamp(contentHeight / lineHeight, 1, maxRows)) || 1;

  return [rows, lineHeight];
};

const trackingDebounce = (func) => {
  let timeoutId;
  let callsParams = [];

  return function (...params) {
    callsParams.push(params);
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(callsParams);
      callsParams = [];
    }, 1000);
  };
};

const keepScrollAtBottom = (scrollAreaRef, previousRows, newRows, lineHeight) => {
  if (previousRows !== newRows) {
    scrollAreaRef.current.scrollTop += (newRows - previousRows) * lineHeight;
  }
};

const TextAreaWrapper = styled.div`
  padding-top: ${(props) => (props.showMobileView ? th.space("inner-base02") : th.space("inner-base03"))};
  padding-bottom: ${(props) => (props.showMobileView ? th.space("inner-base02") : th.space("inner-base03"))};
  padding-left: ${(props) => (props.showMobileView ? th.space("inner-base03") : th.space("inner-base04"))};
  padding-right: ${(props) => (props.showMobileView ? th.space("inner-base03") : th.space("inner-base04"))};
  border-style: solid;
  border-width: ${(props) => (props.showMobileView ? th.borderWidth("sm") : th.borderWidth("lg"))};
  border-radius: ${th.radius("small")};
  border-color: ${th.color("border-neutral-default")};
  line-height: 0px;
  &.focused {
    border-color: ${th.color("border-selected")};
  }
`;

const CommentThreadComponent = ({
  onClose,
  service = CommentService,
  projectId,
  angleId,
  expertId,
  newFlyout = false,
  showMobileView,
}) => {
  const {
    commentButtonStyles,
    scrollAreaStyles,
    containerStyles,
    commentFormFieldsetStyles,
    commentFormQueryStyles,
    formStyles,
    commentList,
  } = useCommentThreadStyles(newFlyout, showMobileView);
  const {
    color: { border, background },
    spacing: { inner },
  } = useThemeTokens();

  const isInternalUser = useIsInternalUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingContent, setEditingContent] = useState("");
  const creatorColors = useCreatorColors(comments);

  const contentInputRef = useRef();

  const triggerTextareaOnChange = (textarea, newValue) => {
    // Hack to trigger the onChange hook when updating the textarevalue
    let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
    nativeInputValueSetter.call(textarea, newValue);
    textarea.dispatchEvent(new Event("input", { bubbles: true }));
    textarea.focus();
  };

  const { getFilteredClients, getFilteredCsts } = useProjectMembersContext();

  const tagUser = useCallback(
    (user, token) => {
      const textarea = contentInputRef.current;
      const before = textarea.value.substring(0, textarea.selectionStart);
      const after = textarea.value.substring(textarea.selectionStart);
      const cutPoint = before.lastIndexOf("@" + token);
      const newValue = before.substring(0, cutPoint) + `@${user.displayName} ` + after;

      triggerTextareaOnChange(textarea, newValue);

      setShowUsers(null);
    },
    [contentInputRef]
  );

  const { markThreadRead } = useCommentContext();

  const formRef = useRef();

  const scrollAreaRef = useRef();
  const trackUserAction = useTrackUserAction();

  useEffect(() => {
    let observer;
    let allThreadRead;
    stopEditingComment();

    trackUserAction.logHit({
      origin: HitOrigin.flyout,
      action: "COMMENTS_OPENED",
      projectToken: projectId,
      references: { angleId, expertId },
    });

    const markAsRead = trackingDebounce((allCallParams) => {
      const comments = allCallParams.flatMap((params) => params);
      service.markAsRead({ projectId, comments });
    });

    setIsLoading(true);
    service
      .getComments({
        projectId,
        angleId,
        expertId,
      })
      .then((response) => {
        setIsLoading(false);
        setComments(response);

        if (isInternalUser) {
          return;
        }

        observer = new IntersectionObserver(
          (entries) => {
            const commentsRead = entries.filter((e) => e.isIntersecting);

            commentsRead.forEach(async (el) => {
              const elId = el.target.dataset.commentId;

              observer.unobserve(el.target);

              const comment = response.find(({ id }) => elId === id.toString());

              markAsRead(comment);

              let updatedComments;

              setComments((comments) => {
                updatedComments = comments.map((c) => {
                  return c.id === comment.id
                    ? {
                        ...comment,
                        recentlyRead: true,
                      }
                    : c;
                });

                return updatedComments;
              });

              allThreadRead = updatedComments.every(({ readAt, recentlyRead }) => readAt || recentlyRead);
            });
          },
          {
            threshold: 0.95,
          }
        );

        const notRead = response.filter((c) => !c.readAt);
        if (notRead.length > 0) {
          allThreadRead = false;

          document.querySelector(`[data-comment-id='${notRead[0].id}']`).scrollIntoView(false);

          notRead.map((c) => document.querySelector(`[data-comment-id='${c.id}']`)).map((el) => observer.observe(el));
        } else {
          allThreadRead = true;
          if (response.length > 0)
            document.querySelector(`[data-comment-id='${response[response.length - 1].id}']`).scrollIntoView();
        }
      });

    return () => {
      allThreadRead && markThreadRead({ expertId, angleId });
      observer && observer.disconnect();
    };
  }, [service, projectId, angleId, expertId]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = useCallback(
    (event) => {
      if (isInternalUser) {
        return;
      }

      event && event.preventDefault();

      const content = new FormData(formRef.current).get("content") || "";

      if (content.trim() !== "") {
        setIsSubmitting(true);

        const saveCommentCall = () => {
          return editingCommentId
            ? service.editComment({
                projectId,
                editingCommentId,
                editingContent,
              })
            : service.submitComment({ projectId, angleId, expertId, content });
        };

        saveCommentCall().then((response) => {
          setIsSubmitting(false);
          stopEditingComment();
          setComments(
            [...comments.filter((i) => i.id !== response.id), response].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            )
          );

          setTimeout(() => {
            document.querySelector(`[data-comment-id='${response.id}']`).scrollIntoView({ behavior: "smooth" });
          }, 50);

          formRef.current.elements.content.value = "";
          formRef.current.elements.content.rows = "1";
          !showMobileView && formRef.current.elements.content.focus();

          trackUserAction.logHit({
            origin: HitOrigin.flyout,
            action: "COMMENTS_SUBMITTED",
            projectToken: projectId,
            references: {
              angleId,
              expertId,
            },
          });
        });
      }
    },
    [
      formRef,
      comments,
      editingContent,
      editingCommentId,
      service,
      angleId,
      expertId,
      projectId,
      trackUserAction,
      isInternalUser,
      showMobileView,
    ]
  );

  const edit = (commentId) => {
    const comment = comments.filter((comment) => comment.id === commentId)[0];
    setEditingCommentId(commentId);
    setEditingContent(comment.content);
    const textarea = contentInputRef.current;
    triggerTextareaOnChange(textarea, comment.content);
  };

  const onClickEditCancel = useCallback(
    (shouldTriggerOnChange = true) => {
      stopEditingComment();
      const textarea = contentInputRef.current;
      shouldTriggerOnChange && triggerTextareaOnChange(textarea, "");
    },
    [contentInputRef]
  );

  const stopEditingComment = () => {
    setEditingCommentId(null);
    setEditingContent("");
  };

  const remove = useCallback(
    (commentId, shouldTriggerOnChange = true) => {
      setIsSubmitting(true);

      service.deleteComment({ projectId, commentId }).then(() => {
        setIsSubmitting(false);
        onClickEditCancel(shouldTriggerOnChange);

        setComments((prev) => prev.filter((i) => i.id !== commentId));

        trackUserAction.logHit({
          origin: HitOrigin.flyout,
          action: "COMMENTS_DELETED",
          projectToken: projectId,
          details: { commentId },
        });
      });
    },
    [onClickEditCancel, service, projectId, trackUserAction]
  );

  useEffect(() => {
    let listener;
    let input = contentInputRef.current;

    if (input) {
      listener = (event) => {
        if (event.code === "Enter" && !event.shiftKey) {
          event.preventDefault();
          submit();
        }
        if (event.code === "ArrowLeft" || event.code === "ArrowRight") {
          event.stopPropagation();
        }
        if (event.code === "Escape") {
          event.stopPropagation();
          onClickEditCancel(event);
        }
      };

      input.addEventListener("keydown", listener);
    }

    return () => {
      input && listener && input.removeEventListener("keydown", listener);
    };
  }, [contentInputRef, formRef, submit, onClickEditCancel]);

  const isInputDisabled = isLoading || isSubmitting;

  const styleForCommentRow = (ix, id, length) => {
    const bordered = {
      borderBottom: "solid 1px",
      borderBottomColor: editingCommentId === id ? border.selected : border.divider,
    };

    if (showMobileView) {
      return {
        p: inner.base04,
        ...bordered,
      };
    } else {
      if (ix === 0) {
        return {
          pb: inner.base04,
          ...bordered,
        };
      }

      if (ix === length - 1) {
        return {
          py: inner.base04,
          mt: inner.base02,
          ...bordered,
        };
      }

      return {
        py: inner.base04,
        mt: inner.base02,
        ...bordered,
      };
    }
  };

  return (
    <x.div {...containerStyles} data-testid="comments">
      {!showMobileView && <CommentHeader newFlyout={newFlyout} onClose={onClose} />}
      <x.div ref={scrollAreaRef} {...scrollAreaStyles}>
        {isLoading && <Loading size="sm" margin="auto" />}
        {!isLoading && (
          <>
            {comments.length === 0 && <NoCommentsYet showMobileView={showMobileView} />}
            <x.div data-testid="comment-list" {...commentList}>
              {comments.map((comment, ix) => (
                <SwipeableItem
                  key={comment.id}
                  {...styleForCommentRow(ix, comment.id, comments.length)}
                  onItemSwiped={() => remove(comment.id, false)}
                  previewSwipe={showMobileView && ix === 0}
                >
                  <CommentRow
                    key={comment.id}
                    comment={comment}
                    onCommentEdit={edit}
                    editingCommentId={editingCommentId}
                    editingValue={editingContent}
                    onCommentRemove={remove}
                    avatarBgColor={
                      creatorColors.find((creatorColor) => creatorColor.creatorName === comment.creatorName)?.color
                    }
                    showMobileView={showMobileView}
                  />
                </SwipeableItem>
              ))}
            </x.div>
          </>
        )}
      </x.div>
      <x.form ref={formRef} onSubmit={submit} data-testid="message-form" {...formStyles}>
        <x.fieldset disabled={isInputDisabled} {...commentFormFieldsetStyles}>
          <x.div {...commentFormQueryStyles}>
            <ProjectMembersPopover
              open={showUsers}
              filter={userQuery}
              onClose={() => setShowUsers(null)}
              onUserSelect={(u) => tagUser(u, userQuery)}
              anchorRef={contentInputRef}
              newFlyout={newFlyout}
            >
              <TextAreaWrapper
                showMobileView={showMobileView}
                className={inputFocused ? "focused" : ""}
                style={{ backgroundColor: isSubmitting ? background.neutral.default : background.surface.page.default }}
              >
                <textarea
                  ref={contentInputRef}
                  name="content"
                  placeholder="Start typing here..."
                  data-testid="message-input"
                  rows="1"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    outline: "none",
                    resize: "none",
                    backgroundColor: isSubmitting ? background.neutral.default : background.surface.page.default,
                    padding: 0,
                  }}
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  onKeyUp={(event) => {
                    const input = event.target;
                    const before = input.value.substring(0, input.selectionStart);
                    const hasSpaceAfterAt = input.value.substring(before.lastIndexOf("@") + 1).startsWith(" ");
                    const hasAnchor =
                      before.lastIndexOf("@") === 0 ||
                      (before.lastIndexOf("@") > 0 && before[before.lastIndexOf("@") - 1] === " ");
                    const newQuery = before.substring(before.lastIndexOf("@") + 1);
                    const hasMatches = getFilteredClients(newQuery).length > 0 || getFilteredCsts(newQuery).length > 0;
                    const isSameWord = newQuery.split(" ").length === 1;
                    const lastWord = newQuery.split(" ").pop();
                    const newQueryWhenLastWordStarted =
                      newQuery.split(" ").slice(0, -1).join(" ") + " " + (lastWord[0] ?? "");
                    const startedWordWithMatch =
                      getFilteredClients(newQueryWhenLastWordStarted).length > 0 ||
                      getFilteredCsts(newQueryWhenLastWordStarted).length > 0;
                    setShowUsers(hasAnchor && (hasMatches || isSameWord || startedWordWithMatch) && !hasSpaceAfterAt);
                    setUserQuery(newQuery);
                  }}
                  onChange={(event) => {
                    if (editingCommentId) {
                      setEditingContent(event.target.value);
                    }
                    const previousRows = parseInt(event.target.rows);

                    event.target.rows = "1";

                    const [newRows, lineHeight] = calculateRows(event);

                    event.target.rows = newRows.toString();

                    keepScrollAtBottom(scrollAreaRef, previousRows, newRows, lineHeight);
                  }}
                />
              </TextAreaWrapper>
            </ProjectMembersPopover>
          </x.div>
          <x.div {...commentButtonStyles}>
            {showMobileView ? (
              <IconButton size="small" variant="outline" key="rounded-button" onClick={submit}>
                <Send />
              </IconButton>
            ) : (
              <>
                {editingCommentId && (
                  <ButtonWithMargin onClick={onClickEditCancel} variant="ghost" size="small" loading={isInputDisabled}>
                    Cancel
                  </ButtonWithMargin>
                )}
                <ButtonWithAccessControl
                  variant="secondary"
                  size="small"
                  loading={isInputDisabled}
                  accessControl={{
                    allowedPermissions: [],
                  }}
                >
                  {editingCommentId ? "Save Edits" : "Comment"}
                </ButtonWithAccessControl>
              </>
            )}
          </x.div>
        </x.fieldset>
        <div id="comment-popover" />
      </x.form>
    </x.div>
  );
};

const CommentHeader = ({ newFlyout, onClose }) => {
  const { isMobile } = useCheckScreen();
  const { titleStyles, headerStyles } = useCommentThreadStyles(newFlyout);
  return (
    <x.div {...headerStyles}>
      {(isMobile || !newFlyout) && (
        <Button variant="icon" data-testid="back-comments-button" onClick={onClose}>
          <LeftArrow />
        </Button>
      )}
      <x.div {...titleStyles}>
        <Typography variant="body-large-em">Comments</Typography>
        <Tooltip
          title="These comments are viewable by colleagues on the project and your AlphaSights team."
          dark={true}
        >
          <x.div>
            <Icon color="secondary">
              <Help />
            </Icon>
          </x.div>
        </Tooltip>
      </x.div>
    </x.div>
  );
};

export const CommentThread = (props) => {
  const currentUser = useCurrentUser();
  const { openLoginModal } = useClientPortalOperations();

  useEffect(() => {
    if (!currentUser) {
      openLoginModal({
        allowAnonymousContinue: false,
      });
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return currentUser ? <CommentThreadComponent {...props} /> : <SignedOfCommentThread {...props} />;
};

const SignedOfCommentThread = ({ newFlyout, onClose }) => {
  const { containerStyles } = useCommentThreadStyles(newFlyout);
  const { openLoginModal } = useClientPortalOperations();
  return (
    <x.div {...containerStyles} data-testid="comments">
      <CommentHeader newFlyout={newFlyout} onClose={onClose} />
      <NoCommentsYet>
        <Typography
          component="div"
          variant="body-small"
          mx="auto"
          color="link"
          pt="11px"
          onClick={() => openLoginModal({ allowAnonymousContinue: false })}
        >
          Sign in to see messages
        </Typography>
      </NoCommentsYet>
    </x.div>
  );
};

export const useCommentThreadStyles = (newFlyout, showMobileView) => {
  const {
    spacing: { inner, layout },
    color: { border, background },
  } = useThemeTokens();

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    bg: background.inverse,
    ...(!showMobileView && {
      h: "100%",
    }),
    ...(showMobileView && {
      minH: "100%",
    }),
  };

  const commentButtonStyles = {
    display: "flex",
    justifyContent: "end",
    pt: showMobileView ? 0 : inner.base04,
  };

  const titleStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    ...(!newFlyout
      ? {
          pr: inner.base08,
        }
      : {}),
  };

  const headerStyles = {
    display: "flex",
    alignItems: "center",
    gap: layout.base03,
    ...(!newFlyout
      ? {
          pt: "1px",
        }
      : {}),
  };

  const scrollAreaStyles = {
    display: "flex",
    flexDirection: "column",
    overflowY: showMobileView ? undefined : "auto",
    flexGrow: "1",
    mt: showMobileView ? 0 : inner.base06,
  };

  const commentFormFieldsetStyles = {
    display: "flex",
    p: 0,
    m: 0,
    borderTop: "solid 1px",
    borderTopColor: border.divider,
    flexDirection: "column",
    ...(showMobileView && {
      flexDirection: "row",
      alignItems: "center",
      gap: inner.base03,
      p: inner.base04,
    }),
  };

  const commentFormQueryStyles = {
    pt: inner.base08,
    ...(showMobileView && {
      pt: 0,
      flexGrow: 1,
    }),
  };

  const formStyles = {
    ...(showMobileView && {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      background: background.inverse,
      zIndex: 2,
    }),
  };

  const commentList = {
    ...(showMobileView && {
      mb: "66px",
    }),
  };

  return {
    containerStyles,
    commentButtonStyles,
    titleStyles,
    headerStyles,
    scrollAreaStyles,
    commentFormFieldsetStyles,
    commentFormQueryStyles,
    formStyles,
    commentList,
  };
};

const ButtonWithAccessControl = withAccessControl(Button);
