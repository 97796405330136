import React from "react";
import { Chat } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useCommentContext } from "./CommentContext";
import { Icon, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const CommentIcon = ({ angleId, expertId, tooltip, interaction }) => {
  const { getCounts } = useCommentContext();
  const { locked } = useLockedExpert(interaction);
  const { onOpenComments } = useProjectInteractionsContext();
  const { pendingCount } = getCounts({ angleId, expertId });
  const newMessage = pendingCount > 0;
  const {
    color: { background },
  } = useThemeTokens();

  const onClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      onOpenComments(interaction);
    },
    [interaction, onOpenComments]
  );

  return (
    <Tooltip title={tooltip} disabled={locked} dark>
      <x.div
        display="flex"
        alignItems="center"
        maxWidth="max-content"
        cursor={locked ? "not-allowed" : "pointer"}
        data-testid="comment-icon"
        background={locked ? background.disabled : undefined}
      >
        <Icon size="small" color="secondary" badged={newMessage} onClick={locked ? null : onClick}>
          <Chat />
        </Icon>
      </x.div>
    </Tooltip>
  );
};
