import React, { useState, useCallback, Fragment } from "react";
import { useQuery } from "query-utils";
import { projectService } from "services/content";
import { format } from "date-fns";
import {
  Tabs,
  TabItem,
  Typography,
  Button,
  Loading,
  useThemeTokens,
  Modal,
  Switch,
} from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { CONTRIBUTOR_PROGRAM, PRIVATE_TRANSCRIPT_LIBRARY, noteHeader, optedIn, optedOut } from "./text";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const ShareSection = ({ isToggleOn, selectedTab, handleOnToggleClick, availableTabs }) => {
  const {
    color: { text },
    spacing: { inner, layout },
  } = useThemeTokens();

  const {
    shareSection: { title, description, note },
  } = availableTabs[selectedTab];

  return (
    <x.div
      data-testid="modal-share-options"
      display="flex"
      paddingTop={inner.base08}
      paddingBottom={inner.base06}
      alignItems="center"
    >
      <x.div display="flex" flexDirection="column" flex={1}>
        <Typography component="span" color={text.strong._}>
          {title}
        </Typography>
        <Typography variant="body-small" component="span" color={text.secondary}>
          {description}
        </Typography>
        {note && (
          <Typography color={text.secondary} marginTop={inner.base02}>
            <Typography variant="body-small-em" component="span">
              {noteHeader}
            </Typography>
            <Typography variant="body-small" component="span" paddingLeft={inner.base}>
              {note}
            </Typography>
          </Typography>
        )}
      </x.div>
      <x.div marginLeft={layout.base03} display="flex" alignItems="center">
        <Switch
          data-testid="modal-share-options-switch"
          isSelected={isToggleOn}
          size="medium"
          onClick={handleOnToggleClick}
          leftText={isToggleOn ? optedIn : optedOut}
          key={`${selectedTab}-${isToggleOn}`}
        />
      </x.div>
    </x.div>
  );
};

export const SummaryDetails = ({
  isSummaryDetailsVisible,
  isDetailsOpen,
  selectedTab,
  handleOnDetailsClick,
  isLoading,
  impactedInteractionsInfo,
  availableTabs,
}) => {
  const {
    spacing: { inner },
    color: { text, border: borderColor, primary },
    shape: { border },
  } = useThemeTokens();

  const renderDetailsContent = () => {
    if (isLoading) {
      return (
        <x.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="100%"
          data-testid="modal-summary-details-loading"
        >
          <Loading size="sm" />
        </x.div>
      );
    }

    if (isSummaryDetailsVisible) {
      const { impactedInteractions, impactedInteractionsCount, totalInteractionsCount } = impactedInteractionsInfo;

      const interactionsLength = impactedInteractions.length;

      return (
        <>
          <x.div display="flex" data-testid="modal-summary-details-content">
            <Typography component="span" variant="body-em" marginRight={inner.base02} color={text.strong._}>
              Summary
            </Typography>
            <Typography component="span" color={text.secondary}>
              Total project interactions: {totalInteractionsCount}
            </Typography>
          </x.div>

          <x.div display="flex" marginBottom={inner.base06}>
            <Typography component="span" marginRight={inner.base02} color={text.strong._}>
              {Boolean(interactionsLength) ? (
                <>
                  <Typography component="span" variant="body-em" color={primary} marginRight={inner.base}>
                    {impactedInteractionsCount}
                  </Typography>
                  {availableTabs[selectedTab].summaryDetails.description}
                </>
              ) : (
                availableTabs[selectedTab].summaryDetails.emptyDescription
              )}
            </Typography>
            {Boolean(interactionsLength) && (
              <Button
                data-testid="modal-summary-details-button"
                color={text.info}
                h={inner.base05}
                onClick={handleOnDetailsClick}
                variant="link"
                endIcon={isDetailsOpen ? <ChevronUp /> : <ChevronDown />}
              >
                <Typography component="span" variant="body-em">
                  {isDetailsOpen ? "Hide Details" : "Show Details"}
                </Typography>
              </Button>
            )}
          </x.div>
          {isDetailsOpen &&
            impactedInteractions.map((interaction, index) => {
              const { id, advisorCompany, role, scheduledCallTime } = interaction;

              const showInteractionLine = Boolean(advisorCompany || role || scheduledCallTime);

              if (!showInteractionLine) {
                return null;
              }

              return (
                <x.div
                  key={id}
                  display="flex"
                  alignItems="center"
                  marginBottom={inner.base04}
                  paddingBottom={inner.base04}
                  borderBottomWidth={interactionsLength - 1 === index ? border.width.none : border.width.small}
                  borderBottomColor={borderColor.neutral.default}
                >
                  <Typography component="span">
                    <Typography component="span" color={text.strong._}>
                      {advisorCompany}
                    </Typography>
                    {Boolean(advisorCompany && role) && (
                      <Typography component="span" color={text.strong._}>
                        &nbsp;- &nbsp;
                      </Typography>
                    )}
                    <Typography component="span" color={text.strong._}>
                      {role}
                    </Typography>
                    {Boolean(advisorCompany || role) && Boolean(scheduledCallTime) && (
                      <Typography component="span" color={text.strong._}>
                        &nbsp;|&nbsp;
                      </Typography>
                    )}
                    {Boolean(scheduledCallTime) && (
                      <Typography component="span" marginLeft={inner.base} color={text.secondary}>
                        {format(new Date(scheduledCallTime), "dd MMM yyyy")}
                      </Typography>
                    )}
                  </Typography>
                </x.div>
              );
            })}
        </>
      );
    }
  };

  return (
    <x.div
      data-testid="modal-summary-details"
      h="208px"
      display="flex"
      flexDirection="column"
      paddingTop={inner.base06}
      borderTopWidth={border.width.small}
      borderTopColor={borderColor.neutral.default}
      overflowY="auto"
    >
      {renderDetailsContent()}
    </x.div>
  );
};

export const projectSettingsTabs = ({
  isPCCEnabledClient,
  isPeerContentContributor,
  isPTLEnabledClient,
  isPrivateTranscriptContributor,
}) => {
  return [
    {
      id: "peerContentContributor",
      label: CONTRIBUTOR_PROGRAM.label,
      enabled: isPCCEnabledClient,
      shareSection: {
        title: CONTRIBUTOR_PROGRAM.title,
        description: CONTRIBUTOR_PROGRAM.description,
      },
      summaryDetails: {
        description: `interactions will be opted ${
          isPeerContentContributor ? "out of" : "into"
        } the contributor program`,
        emptyDescription: CONTRIBUTOR_PROGRAM.summaryDetails.emptyDescription,
      },
      fetchImpactedInteractions(projectToken, peerContentContributor) {
        return projectService.fetchPeerContentContributorImpactedInteractions(projectToken, peerContentContributor);
      },
    },
    {
      id: "privateTranscriptContributor",
      label: PRIVATE_TRANSCRIPT_LIBRARY.label,
      enabled: isPTLEnabledClient,
      shareSection: {
        title: PRIVATE_TRANSCRIPT_LIBRARY.title,
        description: PRIVATE_TRANSCRIPT_LIBRARY.description,
        note: PRIVATE_TRANSCRIPT_LIBRARY.note,
      },
      summaryDetails: {
        description: `interactions will ${
          isPrivateTranscriptContributor ? "not be" : "be"
        } shared with your enterprise`,
        emptyDescription: `All applicable interactions will ${
          isPrivateTranscriptContributor ? "not be" : "be"
        } shared with your enterprise.`,
      },
      fetchImpactedInteractions(projectToken, privateTranscriptContributor) {
        return projectService.fetchPrivateTranscriptContributorImpactedInteractions(
          projectToken,
          privateTranscriptContributor
        );
      },
    },
  ].filter((tab) => tab.enabled);
};

export const ProjectSettingsModal = ({
  token: projectToken,
  isPTLEnabledClient = false,
  isPCCEnabledClient = false,
  isUpdatingProjectLevelSettings = false,
  isPeerContentContributor,
  isPrivateTranscriptContributor,
  setIsPeerContentContributor,
  setIsPrivateTranscriptContributor,
  onClose,
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { onUpdateProjectPCCFlag, onUpdateProjectPTLFlag } = useProjectInteractionsContext();
  const [selectedTab, setSelectedTab] = useState(0);

  const PROJECT_SETTINGS_TABS = projectSettingsTabs({
    isPCCEnabledClient,
    isPeerContentContributor,
    isPTLEnabledClient,
    isPrivateTranscriptContributor,
  });

  const isPeerContentContributorTab = PROJECT_SETTINGS_TABS[selectedTab].id === "peerContentContributor";

  const intialToggleValue = isPeerContentContributorTab ? isPeerContentContributor : isPrivateTranscriptContributor;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(intialToggleValue);

  const isSummaryDetailsVisible = isToggleOn !== intialToggleValue;

  const { data: impactedInteractionsInfo, isFetching } = useQuery(
    ["impactedInteractions", selectedTab],
    () => PROJECT_SETTINGS_TABS[selectedTab].fetchImpactedInteractions(projectToken, isToggleOn),
    {
      enabled: isSummaryDetailsVisible,
    }
  );

  const impactedInteractions = impactedInteractionsInfo?.impactedInteractions || [];

  const handleSetSelectedTab = (index) => {
    if (index !== selectedTab) {
      const newToggleValue = isPeerContentContributorTab ? isPrivateTranscriptContributor : isPeerContentContributor;

      setSelectedTab(index);
      setIsDetailsOpen(false);
      setIsToggleOn(newToggleValue);
    }
  };

  const handleOnToggleClick = (currentStatus) => {
    setIsToggleOn(!currentStatus);
    setIsDetailsOpen(false);
  };

  const handleOnDetailsClick = () => {
    setIsDetailsOpen((prev) => !prev);
  };

  const updatePCCProjectState = useCallback(() => onUpdateProjectPCCFlag(isToggleOn, impactedInteractions), [
    isToggleOn,
    onUpdateProjectPCCFlag,
    impactedInteractions,
  ]);

  const updatePTLProjectState = useCallback(() => onUpdateProjectPTLFlag(isToggleOn, impactedInteractions), [
    isToggleOn,
    onUpdateProjectPTLFlag,
    impactedInteractions,
  ]);

  const handleOnConfirm = () => {
    if (isSummaryDetailsVisible) {
      if (isPeerContentContributorTab) {
        updatePCCProjectState();
        setIsPeerContentContributor(isToggleOn);
      } else {
        updatePTLProjectState();
        setIsPrivateTranscriptContributor(isToggleOn);
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open
      title="Project Settings"
      slotWidth="676px"
      onClose={onClose}
      shouldShowFooter={true}
      zIndex={100}
      primaryButton={
        <Button
          loading={isUpdatingProjectLevelSettings}
          disabled={isUpdatingProjectLevelSettings}
          variant="secondary"
          onClick={handleOnConfirm}
          ml={inner.base02}
        >
          <Typography variant="body-em" component="span">
            Confirm
          </Typography>
        </Button>
      }
      secondaryButton={
        <Button variant="ghost" onClick={onClose} px={inner.base06} py={inner.base03}>
          <Typography variant="body-em" component="span" color={text.strong._}>
            Cancel
          </Typography>
        </Button>
      }
    >
      <Tabs
        index={selectedTab}
        onChange={handleSetSelectedTab}
        data-testid="project-settings-tabs"
        variant="default"
        position="relative"
      >
        {PROJECT_SETTINGS_TABS.map(({ label }) => {
          return (
            <Fragment key={`project-settings-${label}`}>
              <TabItem data-testid={`project-settings-${label}`} label={label} />
            </Fragment>
          );
        })}
      </Tabs>
      <ShareSection
        isToggleOn={isToggleOn}
        selectedTab={selectedTab}
        handleOnToggleClick={handleOnToggleClick}
        availableTabs={PROJECT_SETTINGS_TABS}
      />
      <SummaryDetails
        isSummaryDetailsVisible={isSummaryDetailsVisible}
        isDetailsOpen={isDetailsOpen}
        selectedTab={selectedTab}
        handleOnDetailsClick={handleOnDetailsClick}
        isLoading={isFetching}
        impactedInteractionsInfo={impactedInteractionsInfo}
        availableTabs={PROJECT_SETTINGS_TABS}
      />
    </Modal>
  );
};
