import React from "react";
import { x } from "@xstyled/styled-components";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { DataVisualizationContainer, Header, Sidebar } from "./components";

const SurveysResultsView = React.memo(() => {
  // TODO [REDIS2-502]: Remove Surveys Results Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasSurveysResultsPageBadge = hasUserBadge(Badge.surveysResults);

  // TODO: Get the survey id from the url
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { isLoading } = useSurveyMetadata(surveyId);

  if (!hasSurveysResultsPageBadge) {
    return null;
  }

  if (isLoading) {
    return <div>Loading Metadata...</div>;
  }

  return (
    <x.div display="flex" data-testid="surveys-results-view-page" h="100%">
      <x.div display="flex" flexDirection="column" flex="1 1 auto" data-testid="surveys-results-view-container">
        <Header />
        <DataVisualizationContainer />
      </x.div>
      <Sidebar />
    </x.div>
  );
});

export default SurveysResultsView;
