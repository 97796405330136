import React, { useCallback, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { HitAction, SynthesisModuleRevision, SynthesisModule } from "@alphasights/portal-api-client";
import { useStyles } from "./SynthesisSidebarContent.styles";
import { Add, CloseFilled, DragIndicator, Stats } from "@alphasights/alphadesign-icons";
import { Icon, IconButton, Loading, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _ from "lodash";
import { isKpcContent, isNPSContent, isQuestionContent, isVendorContent } from "../synthesisTypeGuards";

export const SynthesisSidebarCard = ({
  synthesisModule,
  isDragging,
}: {
  synthesisModule: SynthesisModule;
  isDragging: boolean;
}) => {
  const styles = useStyles();
  const { selectedModule, promoteModule, mode, synthesisLogHit } = useProjectSynthesisContext();
  const [isHovered, setIsHovered] = useState(false);

  const isSelected = mode !== Mode.NEW && synthesisModule.id === selectedModule?.id;

  const lastRevision = synthesisModule.revisions[synthesisModule.revisions.length - 1];

  const getRevisionTitle = useCallback((revision: SynthesisModuleRevision) => {
    if (isQuestionContent(revision.contents)) return revision.contents.topic;
    if (isKpcContent(revision.contents)) return "Key Purchasing Criteria";
    if (isVendorContent(revision.contents)) return "Vendor List";
    if (isNPSContent(revision.contents)) return "Net Promotor Score";

    return "";
  }, []);

  const lastTopic = useMemo(
    () =>
      _.findLast(
        synthesisModule.revisions.map((rev) => getRevisionTitle(rev)),
        (t) => t
      ),
    [getRevisionTitle, synthesisModule]
  );

  const onPromoteModule = () => {
    promoteModule(synthesisModule);
    synthesisLogHit({
      action: HitAction.projectSynthesisModulePromoted,
      references: { moduleIds: [synthesisModule.id] },
      details: {
        cta: "card",
        moduleType: synthesisModule.contentType,
      },
    });
  };

  if (!lastRevision) {
    return null;
  }

  return (
    <x.div
      {...styles.cardWrapper}
      {...(synthesisModule.visibility === "ADDED" ? styles.isCustom : styles.isExtracted)}
      {...(isSelected ? styles.isActive : styles.isInactive)}
      aria-selected={isSelected}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`synthesis-sidebar-card-${lastTopic ?? "processing"}`}
    >
      <Typography variant="body" color={"black"} lineHeight="32px">
        {lastTopic ? <>{lastTopic}</> : <Skeleton variant="noMargin" width="250px" height="20px" />}
      </Typography>
      <x.div display={"flex"} alignItems={"center"}>
        {synthesisModule.contentType === "KPC" && (
          <Icon color="secondary">
            <Stats />
          </Icon>
        )}
        {synthesisModule.visibility === "SUGGESTED" && (
          <IconButton variant={"ghost"} size={"small"} onClick={onPromoteModule}>
            <Add data-testid="promote-button" />
          </IconButton>
        )}
        {lastRevision?.status === "FAILED" && (
          <Icon color="red" dataAttributes={{ "data-testid": `card-failed-${synthesisModule.id}` }}>
            <CloseFilled />
          </Icon>
        )}
        {lastRevision?.status === "PROCESSING" && (
          <Icon dataAttributes={{ "data-testid": `card-processing-${synthesisModule.id}` }}>
            <Loading />
          </Icon>
        )}
        {(isDragging || (isHovered && synthesisModule.visibility === "ADDED")) && <DragIndicator />}
      </x.div>
    </x.div>
  );
};
