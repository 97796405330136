import { useQuery } from "query-utils";
import surveysService from "services/surveysService";
import {
  PaginatedAggregatedResults,
  SurveyInsight,
  SurveyMetadata,
  TransformedSurveyMetadata,
  TransformedTab,
} from "./types";

const GET_SURVEY_METADATA_KEY = "survey-results-metadata";
const GET_SURVEY_RESULTS_KEY = "survey-results";
const GET_SURVEY_INSIGHTS_KEY = "survey-insights";

const defaultMetadata = {
  companyDetails: [],
  id: "",
  decipherId: "",
  projectId: "",
  status: "draft",
  startDate: null,
  endDate: null,
  type: "",
  name: "",
  tabs: [],
  questions: [],
};

// Method to transform the data from the API to the frontend
const transformSurveyData = (data: SurveyMetadata): TransformedTab[] => {
  const tabMap: { [key: string]: TransformedTab } = {};

  data.questions.forEach((question, index) => {
    const { tab, subTab, ...rest } = question;
    const { name: tabName, display: tabDisplay } = tab;
    const { name: subTabName, display: subTabDisplay } = subTab;

    if (!tabMap[tabName]) {
      tabMap[tabName] = {
        name: tabName,
        display: tabDisplay,
        subTabs: [],
      };
    }

    const currentTab = tabMap[tabName];
    let currentSubTab = currentTab.subTabs.find((st) => st.name === subTabName);

    if (!currentSubTab) {
      currentSubTab = {
        name: subTabName,
        display: subTabDisplay,
        questions: [],
      };
      currentTab.subTabs.push(currentSubTab);
    }

    currentSubTab.questions.push({
      questionIndex: index,
      ...rest,
    });
  });

  return Object.values(tabMap);
};

const fetchSurveyMetadata = async (surveyId: string) => {
  try {
    const rawData: SurveyMetadata = await surveysService.readSurveyMetadata(surveyId);
    const { tabs, ...rest } = rawData;
    const transformedData = transformSurveyData(rawData);

    return { ...rest, tabs: transformedData };
  } catch {
    console.log("Error fetching survey metadata");
    return defaultMetadata as TransformedSurveyMetadata;
  }
};

const fetchSurveyResults = async ({
  surveyId,
  questionIds,
  pageSize,
  page,
}: {
  surveyId: string;
  questionIds: string[];
  pageSize: number;
  page: number;
}) => {
  try {
    const results: PaginatedAggregatedResults = await surveysService.readSurveyResults(
      surveyId,
      questionIds,
      pageSize,
      page
    );
    return results;
  } catch {
    console.log("Error fetching survey results");
  }
};

const fetchSurveyInsights = async ({
  surveyId,
  page,
  pageSize,
}: {
  surveyId: string;
  page: number;
  pageSize: number;
}) => {
  try {
    const results: SurveyInsight[] = await surveysService.readSurveyInsights(surveyId, page, pageSize);
    return results;
  } catch {
    console.log("Error fetching survey insights");
    return [];
  }
};

// GET THE METADATA OF THE SURVEY
export const useSurveyMetadata = (surveyId: string) => {
  return useQuery<TransformedSurveyMetadata>([GET_SURVEY_METADATA_KEY, surveyId], () => fetchSurveyMetadata(surveyId), {
    enabled: !!surveyId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};

// GET THE RESULTS OF THE SURVEY GIVEN THE QUESTION IDS
export const useSurveyResults = (surveyId: string, questionIds: string[]) => {
  return useQuery<PaginatedAggregatedResults | undefined>(
    [GET_SURVEY_RESULTS_KEY, surveyId, questionIds],
    () => fetchSurveyResults({ surveyId, questionIds, pageSize: questionIds.length, page: 0 }),
    {
      enabled: !!questionIds.length,
    }
  );
};

// GET THE INFORMATION TO USE IN THE EXPERTS SIDEBAR
export const useSurveyInsights = (surveyId: string) => {
  return useQuery<SurveyInsight[]>(
    [GET_SURVEY_INSIGHTS_KEY, surveyId],
    () => fetchSurveyInsights({ surveyId, page: 0, pageSize: 0 }),
    {
      enabled: !!surveyId,
    }
  );
};
