import React from "react";
import { KpcItem } from "@alphasights/portal-api-client";
import tokens from "@alphasights/alphadesign-tokens";
import { ChartData, SynthesisBarChart } from "../../components";

export const KpcChart = ({ kpcItems }: { kpcItems: KpcItem[] }) => {
  const chartData = getChartData(kpcItems);
  const categories = kpcItems.map((item) => item.name);

  return (
    <SynthesisBarChart
      chartData={chartData}
      categories={categories}
      extraChartProps={{
        id: "kpc-stacked-bar-chart",
        horizontal: false,
        popOverPlacement: "right",
      }}
    />
  );
};

const getChartData = (kpcItems: KpcItem[]): ChartData[] => {
  const validSentiments = ["VERY_IMPORTANT", "IMPORTANT", "SOMEWHAT_IMPORTANT"];
  return validSentiments.map((sentiment) => ({
    name: sentimentLabels[sentiment as keyof typeof sentimentColors],
    color: sentimentColors[sentiment as keyof typeof sentimentColors],
    data: kpcItems.map((item, idx) => {
      const sentimentData = item.sentiments.find((s) => s.sentiment === sentiment);
      return {
        y: sentimentData?.count ?? 0,
        x: idx.toString(),
        color: sentimentColors[sentiment as keyof typeof sentimentColors],
        sentiment: sentimentLabels[sentiment as keyof typeof sentimentColors],
        quotes: sentimentData?.quotes ?? [],
      };
    }),
  }));
};

const sentimentLabels = {
  VERY_IMPORTANT: "Critical",
  IMPORTANT: "Important",
  SOMEWHAT_IMPORTANT: "Discussed",
};

const sentimentColors = {
  VERY_IMPORTANT: tokens.color.chart.sequential.base,
  IMPORTANT: tokens.color.chart.sequential.base03,
  SOMEWHAT_IMPORTANT: tokens.color.chart.sequential.base05,
};
