import React from "react";
import { CustomerExpertView } from "./CustomerExpertView";
import { useLocation, useNavigate } from "router-utils";
import { CustomerKnowledgeProvider } from "providers/CustomerKnowledgeProvider";
import { hasSufficientCustomerKnowledge } from "./helpers";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

export const CustomerExpertPage = (props) => {
  const { loading, experts } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const { customerKnowledge } = useCurrentProjectContext();

  if (!loading && !hasSufficientCustomerKnowledge(customerKnowledge, experts)) {
    navigate(location.pathname.replace("/customer-view", "/table-view"));
  }

  return (
    <CustomerKnowledgeProvider customerKnowledge={customerKnowledge} {...props}>
      <CustomerExpertView {...props} />
    </CustomerKnowledgeProvider>
  );
};
