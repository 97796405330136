import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useAdvisorsPageStyles } from "views/AdvisorsPage/AdvisorsPage.styles";
import { PastProjectsBanner } from "components/PastProjectsBanner";
import { AdvisorsHeaderNewUx, AdvisorsHeaderNewUxProps } from "components/AdvisorsHeaderNewUx";
import { HitOrigin } from "@alphasights/portal-api-client";
import { x } from "@xstyled/styled-components";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";

type AdvisorsPageHeaderProps = {
  filterComponent: (isCompact: boolean) => React.ReactNode;
  loading: boolean;
  onSearch: (searchQuery?: string) => void;
  onSearchQueryChange: (value: string) => void;
  pastProjectsEnabled: boolean;
  project: Project;
  searchQuery: string;
  showing: number;
  currentView: string;
} & Omit<AdvisorsHeaderNewUxProps, "searchComponent" | "filterComponent" | "counter">;

export const AdvisorsPageHeader = ({
  filterComponent,
  loading,
  onSearch,
  onSearchQueryChange,
  pastProjectsEnabled = false,
  project,
  searchQuery,
  showing,
  currentView,
  ...props
}: AdvisorsPageHeaderProps) => {
  const styles = useAdvisorsPageStyles();
  const {
    color: { text },
  } = useThemeTokens();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(new FormData(event.target as HTMLFormElement).get("query") as string);
  };
  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onSearchQueryChange("");
    onSearch("");
  };
  const origin = currentView === HitOrigin.listView ? HitOrigin.listView : HitOrigin.tableView;

  const searchForm = (
    <CollapsibleSearch
      onSubmit={onSubmit}
      onValueChange={onSearchQueryChange}
      onClear={onClear}
      value={searchQuery}
      id="advisor-page-search-input"
      name="query"
      placeholder="Search in experts"
    />
  );

  const counter = (
    <Typography color={text.secondary} whiteSpace={"nowrap"} ml="8px" data-testid="summary-bar">
      {showing} {showing === 1 ? "result" : "results"}
    </Typography>
  );

  return (
    <>
      {pastProjectsEnabled && project?.inactive && (
        <x.div {...styles.header}>
          <PastProjectsBanner project={project} origin={origin} />
        </x.div>
      )}
      <AdvisorsHeaderNewUx
        {...props}
        searchComponent={searchForm}
        filterComponent={filterComponent}
        counter={counter}
        currentView={currentView}
      />
    </>
  );
};
