import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const useProjectLaunchPageStyles = () => {
  const currentUser = useCurrentUser();
  const contentSearchEnabled = !currentUser?.consultancyClient && currentUser?.alphaNowEnabled;

  const {
    spacing,
    color: { background },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      pb: spacing.layout.base03,
      overflowX: "auto",
      width: "100%",
    },
    header: {
      position: "sticky",
      top: 0,
      pt: spacing.layout.base03,
      zIndex: 1,
      background: background.surface.recessed,
      minWidth: contentSearchEnabled ? "729px" : "350px",
    },
    mainContent: {
      w: "100%",
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
      px: spacing.layout.base03,
      maxWidth: contentSearchEnabled ? "1272px" : "1100px",
    },
    projectDetails: {
      gap: spacing.inner.base04,
    },
    detailsContent: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
      flex: 4,
    },
    searchContent: {
      h: "calc(100vh - 190px)",
      minWidth: "350px",
      flex: 2,
      position: "sticky",
      top: "106px",
    },
    sideBySideContainer: {
      display: "flex",
      alignItems: "stretch",
      gap: spacing.inner.base,
    },
  };
};
