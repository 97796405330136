import React from "react";
import { Alert, Link, useThemeTokens } from "@alphasights/alphadesign-components";
import { CallAvailabilityStatus } from "./constants";
import { x } from "@xstyled/styled-components";

export const CallUnavailableAlert = ({ availability, projectLead }) => {
  const { spacing } = useThemeTokens();
  const reachOut = (projectLead) => (
    <span>
      Reach out to your {projectLead ? <Link href={`mailto:${projectLead.email}`}>project lead</Link> : "project lead"}{" "}
      for more details.
    </span>
  );

  const messageByStatus = (availability, projectLead) => {
    if (availability === CallAvailabilityStatus.HAS_ALREADY_TRANSPIRED) {
      return <>Call has already transpired. {reachOut(projectLead)}</>;
    } else if (availability === CallAvailabilityStatus.OUT_OF_TIME) {
      return "You can only access the call 10 minutes before the scheduled time.";
    } else if (availability === CallAvailabilityStatus.OVERDUE) {
      return <>Call is overdue. {reachOut(projectLead)}</>;
    } else {
      return <>Call is not available. {reachOut(projectLead)}</>;
    }
  };

  return (
    <x.div marginTop={spacing.layout.base03}>
      <Alert variant="danger">{messageByStatus(availability, projectLead)}</Alert>
    </x.div>
  );
};
