import React, { useEffect } from "react";
import { Alert, ContentCard, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import * as S from "./AngleCards.styled";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import useBrowserStorage, { StorageType } from "hooks/useBrowserStorage";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

export const AngleCards = ({ loading }: { loading: boolean }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  const { project } = useCurrentProjectContext();

  const [dismissedQuestionViewAlerts, setDismissedQuestionViewAlerts] = useBrowserStorage<string[]>(
    "dismissedQuestionViewAlerts",
    [],
    StorageType.LOCAL
  );

  useEffect(
    function hideAlertIfDismissed() {
      dismissedQuestionViewAlerts.includes(project?.token) && setShowAlert(false);
    },
    [project, dismissedQuestionViewAlerts]
  );

  const onCloseAlert = () => {
    setDismissedQuestionViewAlerts([...dismissedQuestionViewAlerts, project?.token]);
    setShowAlert(false);
  };

  return (
    <S.Wrapper>
      {loading ? (
        <>
          <SidebarCardLoading />
          <SidebarCardLoading />
          <SidebarCardLoading />
        </>
      ) : (
        <>
          {showAlert && (
            <Alert size="small" onClose={() => onCloseAlert()}>
              Questions have been created and tailored to support your diligence, using AI trained on our internal
              question pool.
            </Alert>
          )}
          <QuestionAngles />
        </>
      )}
    </S.Wrapper>
  );
};

const QuestionAngles = () => {
  const { angleQuestionsCount } = useAngleQuestionsContext();

  const angles = useAnglesFromFilters();

  return (
    <>
      {angles.map((angle) => (
        <AngleCard
          key={angle.id}
          angle={angle}
          questionCount={angleQuestionsCount.find((c) => c.angleId === angle.id)?.count}
        />
      ))}
    </>
  );
};

const AngleCard = ({ angle, questionCount }: { angle: FilterOption; questionCount?: number | undefined }) => {
  const { setSelectedAngleId, angleQuestionsCountLoading } = useAngleQuestionsContext();

  return (
    <ContentCard onClick={() => setSelectedAngleId(angle.id!!)} cursor="pointer">
      <x.div display="flex" justifyContent="space-between">
        <Typography color="strong">{angle.label}</Typography>
        {angleQuestionsCountLoading ? (
          <S.AngleQuestionCountLoading />
        ) : (
          questionCount && (
            <Typography color="assistive">
              {`${questionCount} question${questionCount !== 1 ? "s" : ""} added`}
            </Typography>
          )
        )}
      </x.div>
    </ContentCard>
  );
};

const SidebarCardLoading = () => (
  <S.SidebarCardLoadingWrapper>
    <x.div w="70%" maxW="200px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
    <x.div w="30%" maxW="130px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
  </S.SidebarCardLoadingWrapper>
);

const useAnglesFromFilters = () => {
  const {
    state: { filterOptions },
  } = useProjectInteractionsContext();

  return (filterOptions?.groups ?? []) as FilterOption[];
};
