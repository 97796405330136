import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useRecommendedResearchPanelStyles = () => {
  const {
    spacing,
    color: { background },
    shape,
  } = useThemeTokens();

  return {
    researchList: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
      h: "100%",
      overflow: "scroll",
    },
    noRecommendedContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      w: "160px",
      gap: spacing.inner.base03,
      textAlign: "center",
      margin: "auto",
    },
    noRecommendedContentIcon: {
      h: spacing.layout.base08,
      w: spacing.layout.base08,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: background.neutral.default,
      borderRadius: shape.border.radius.small,
    },
  };
};
