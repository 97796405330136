import React, { useMemo } from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import useCustomerExpertStyles from "../styles";
import { x } from "@xstyled/styled-components";
import { Experience } from "../../../models/Interaction";
import { ExperienceIcon } from "../ExperienceIcon";
import { buildExperienceTooltipCopy, getUniqCustomerKnowledges, IN_ORDER_EXPERIENCES } from "../helpers";
import _ from "lodash";
import { Pill, Tooltip, Typography } from "@alphasights/alphadesign-components";

const otherVendorsColumnPrefix = "other-vendors-column-";

export const OtherVendorsColumn = ({ row }: AlphaTableTypes.Cell<any, any>) => {
  const styles = useCustomerExpertStyles();

  const interaction: Interaction = row.original;
  const knowledges = useMemo(() => getUniqCustomerKnowledges(interaction.customerKnowledges), [interaction]);

  const otherKnowledgesCount = useMemo(() => {
    const knowledgesNotInUse = knowledges.filter((knowledge) => knowledge.experience !== Experience.Uses);
    const groupedKnowledges = _.groupBy(knowledgesNotInUse, "experience");
    const mainExp = IN_ORDER_EXPERIENCES.map((exp) => groupedKnowledges[exp]).find((a) => a);
    if (!mainExp) return null;

    const mainCk = mainExp[0];
    const othersTooltip = IN_ORDER_EXPERIENCES.map((exp) =>
      buildExperienceTooltipCopy(
        exp,
        (groupedKnowledges[exp] || []).filter((ck) => ck !== mainCk)
      )
    )
      .filter((a) => a)
      .join(", ");
    return {
      mainCk,
      othersTooltip,
      othersCount: knowledgesNotInUse.length - 1,
    };
  }, [knowledges]);

  const mainName = useMemo(() => {
    if (!otherKnowledgesCount) return "";
    const name = otherKnowledgesCount.mainCk.vendor.name;
    const limit = otherKnowledgesCount.othersCount ? 10 : 20;
    return name.length > limit ? `${name.slice(0, limit - 3)}...` : name;
  }, [otherKnowledgesCount]);

  if (!otherKnowledgesCount?.mainCk) {
    return null;
  }

  return (
    <x.div display="flex" alignItems="center" data-testid={`${otherVendorsColumnPrefix}${interaction.id}`}>
      <x.div {...styles.otherVendors} overflow="hidden">
        <Tooltip
          title={buildExperienceTooltipCopy(otherKnowledgesCount.mainCk.experience, [otherKnowledgesCount.mainCk])}
        >
          <x.div data-testid={`main-others-ck-${interaction.id}`}>
            <ExperienceIcon experience={otherKnowledgesCount.mainCk.experience} title={mainName} />
          </x.div>
        </Tooltip>

        {otherKnowledgesCount.othersCount > 0 && (
          <Tooltip title={otherKnowledgesCount.othersTooltip}>
            <Pill size={"small"} isInteractive={false} whiteSpace="nowrap">
              <Typography variant="body-small">+{otherKnowledgesCount.othersCount} more</Typography>
            </Pill>
          </Tooltip>
        )}
      </x.div>
    </x.div>
  );
};
