import { fetch } from "hooks/useApi";
import { PaginatedAggregatedResults, SurveyInsight, SurveyMetadata } from "views/SurveysResultsView/api/types";

export default {
  async readSurveyMetadata(surveyId: string): Promise<SurveyMetadata> {
    return fetch({
      url: `/api/survey/${surveyId}/metadata`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async readSurveyResults(
    surveyId: string,
    questionIds: string[],
    pageSize: number,
    page: number
  ): Promise<PaginatedAggregatedResults> {
    return fetch({
      url: `/api/survey/${surveyId}/results/aggregated?pageSize=${pageSize}&page=${page}&questionIds=${questionIds.join(
        ","
      )}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async readSurveyInsights(surveyId: string, page: number, pageSize: number): Promise<SurveyInsight[]> {
    return fetch({
      url: `/api/survey/${surveyId}/results/insights?page=${page}&pageSize=${pageSize}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};
