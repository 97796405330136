import React, { useEffect, useState } from "react";
import { CallUnavailableAlert } from "./CallUnavailableAlert.js";
import { COUNTRY_CODES } from "constants/Country/index.js";
import { SelectCountry } from "components/SelectCountry/index.js";
import { Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CallAvailabilityStatus } from "./constants";
import { FormattedDateTime, useTimezone, hasTimeZoneAbbreviation } from "../../providers/TimezoneProvider";

export const DialIn = ({
  isOpen,
  mainNumber,
  otherNumbers,
  interactionId,
  accessCode,
  callAvailabilityStatus,
  projectLead,
}) => {
  const timezone = useTimezone();
  const DATE_FORMAT = `E d MMM, h:mm aaa (zzz${hasTimeZoneAbbreviation(timezone.currentTimezone) ? ", O" : ""})`;

  const [selected, setSelected] = useState();
  const [dialNumbers, setDialNumbers] = useState([]);
  const [scheduledFor, setScheduledFor] = useState();

  useEffect(() => {
    if (callAvailabilityStatus) {
      setScheduledFor(callAvailabilityStatus.startDate);
    }
  }, [callAvailabilityStatus]);

  useEffect(() => {
    if (otherNumbers && otherNumbers.length > 0) {
      setDialNumbers(
        otherNumbers.map((number) => {
          const code = COUNTRY_CODES.find((country) => number.country.includes(country.name))?.isoCode;
          return {
            value: number.number,
            children: number.country,
            code: code,
          };
        })
      );

      const mainDialNumber = otherNumbers.find((dialNumber) => dialNumber.number === mainNumber);

      if (mainDialNumber) {
        setSelected(mainNumber);
      } else {
        setSelected(otherNumbers[0].number);
      }
    } else {
      setDialNumbers([]);
      setSelected(null);
    }
  }, [mainNumber, otherNumbers, interactionId]);

  const { color, spacing } = useThemeTokens();

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {callAvailabilityStatus && callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE && (
        <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
      )}

      <Typography variant="body-small-spaced" marginTop={spacing.layout.base03} color={color.text.assistive}>
        Please dial this phone number followed by the code to join the call. Ensure the country matches your current
        location.
      </Typography>

      <x.div marginTop={spacing.layout.base03}>
        <SelectCountry
          value={selected}
          onChange={(value) => setSelected(value)}
          children={dialNumbers}
          allowMultiple={false}
          input={true}
          placeholder={"Dial-in Numbers"}
        />
      </x.div>
      <x.div
        gap={spacing.inner.base}
        marginTop={spacing.layout.base02}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="body-small">Scheduled for:</Typography>
        <Typography variant="body-small" marginLeft={spacing.layout.base}>
          {scheduledFor && <FormattedDateTime date={new Date(scheduledFor)} format={DATE_FORMAT} />}
        </Typography>
      </x.div>
      <x.div marginTop={spacing.layout.base} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body-small">Number:</Typography>
        <Link marginLeft={spacing.layout.base} href={`tel:${selected}`}>
          {selected}
        </Link>
      </x.div>
      <x.div marginTop={spacing.layout.base} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body-small">Code:</Typography>
        <Typography variant="body-small" marginLeft={spacing.layout.base}>
          {accessCode}
        </Typography>
      </x.div>
    </div>
  );
};
