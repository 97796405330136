import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { x } from "@xstyled/styled-components";
import {
  Button,
  Divider,
  Icon,
  Link,
  Option,
  Skeleton,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import {
  ArrowLeft,
  CalendarAvailable,
  Call,
  ChevronDown,
  ChevronUp,
  Close,
  Mail,
  Request,
  SummarizedTranscript,
} from "@alphasights/alphadesign-icons";

import MoreOptions from "./MoreOptions";
import { InteractionChainList } from "./InteractionChainList";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useTopbarFlyoutStyles } from "pages/InteractionPage/AdvisorFlyOut.styles";
import { CallMeButton } from "components/CallMe";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { selectExperts, setFlyoutMode } from "components/InteractionsPage/reducer";
import { useNavigate } from "router-utils";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AdvisorResearchRequestButton } from "components/PastProjectsBanner";
import { DropdownButton, OptionWithLoginWall } from "components/DropdownButton";
import { InteractionActionIcons } from "./InteractionActionIcons";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { filterOtherInteractions, isCallAvailable } from "components/InteractionsPage/helpers/Interaction";
import { TopbarContainer } from "./Topbar.styled";
import {
  PORTAL_WORKSTREAM_BADGE,
  useProjectBadgeContext,
  ENABLE_AI_SUMMARIZATION,
  ENABLE_PORTAL_MESSAGES,
  ENABLE_LEGACY_BCG_CLARIFICATIONS,
} from "providers/BadgeProvider";
import { noop } from "lodash";
import { FlyoutConfiguration, FlyoutMode } from "pages/InteractionPage/enums";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { MessageThreadList } from "./MessageThreadList";
import { WorkRequestMaximumCharge } from "./WorkRequestMaximumCharge";
import { ExpertChargesList } from "./ExpertChargesList";
import { MessageType } from "types";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { CurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { deliverablesInteractions, getAvailableTranscripts, getDefaultRecording } from "views/DeliverablesView/helpers";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import { ViewInSystem } from "components/DebugSupport";

export const Topbar = ({
  backgroundSyncRunning,
  chainInteractions: chainInteractionsInput,
  clientRequests,
  currentView,
  hasClientPortalMessages,
  interaction,
  isActiveProject,
  onCancelRequest,
  onClose,
  onUpdateChainIdSelected,
  onRequest,
  onRequestFollowUp,
  onRequestUpgradeInteraction,
  onSendNewMessage,
  onSchedule,
  onToggleComments,
  hideStarOrigin,
  pccOptOutWindow,
  researchRequest,
  isLoadingResearchRequest,
  showCalendarView,
  token,
  updateResearchRequest,
  listOpened,
  setListOpened,
  locked,
  sendMessageButtonRef,
  maximumTaskDuration,
  selectedExperts,
}) => {
  const { flyoutConfiguration, flyoutMode, newMessageType } = useFlyoutContext();
  const {
    containerStyles,
    headerStyles,
    advisorInfoStyles,
    advisorNameStyles,
    advisorInteractionsStyles,
    callToActionsStyles,
    interactionListStyles,
    maximumChargeStyles,
  } = useTopbarFlyoutStyles({ flyoutConfiguration });

  const advisorNameRef = useRef(null);
  const ctaWrapperRef = useRef();
  const { isMobile } = useCheckScreen();
  const isAdvisorNameOverflow = useIsOverflow(advisorNameRef, interaction?.id);
  const isCallAboutToStartOrHappening = useMemo(() => interaction && isCallAvailable(interaction), [interaction]);
  const { selectedWorkstream } = useContext(CurrentWorkstreamContext) ?? {};

  const chainInteractions = filterOtherInteractions(chainInteractionsInput);

  const isDeliverablesView = currentView === HitOrigin.deliverablesView;
  const showMoreOptions = !isDeliverablesView && flyoutMode !== FlyoutMode.NewMessage;
  const showFollowUp =
    ![FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode) &&
    (!isDeliverablesView || interaction.showFollowUp);
  const isCalendarView = ["calendar-view", "schedule-view"].includes(currentView);

  const isFullScreenConfiguration = flyoutConfiguration === FlyoutConfiguration.Fullscreen;

  const { onToggleStar } = useStarExpertContext();
  const { hasProjectBadge } = useProjectBadgeContext();

  const portalMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES) && !interaction?.isSurvey;
  const showInteractionCtas =
    ![FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode) &&
    (!hasProjectBadge(PORTAL_WORKSTREAM_BADGE) || !interaction.isSurvey);
  const showMessageCtas = flyoutMode === FlyoutMode.Messages;
  const showSendMessageCta = flyoutMode === FlyoutMode.NewMessage;

  const [chainListOpened, setChainListOpened] = useState(listOpened);
  const [threadListOpened, setThreadListOpened] = useState(false);
  const [isCallMeModalOpen, setIsCallMeModalOpen] = useState(false);

  const [surveyListOpened, setSurveyListOpened] = useState(false);
  const surveyChain = chainInteractions.filter((it) => it.interactionType === "Industry Survey");

  const [callListOpened, setCallListOpened] = useState(false);
  const callChain = chainInteractions.filter((it) => it.interactionType === "Call");

  const topbarRef = useRef(null);

  const openSurveyList = (state) => {
    setSurveyListOpened(state);
    state && setCallListOpened(!state);
  };

  const openCallList = (state) => {
    setCallListOpened(state);
    state && setSurveyListOpened(!state);
  };

  const surveyInteractionListVariant = useMemo(() => {
    return chainInteractionsInput.find(({ surveyResponse }) => Boolean(surveyResponse));
  }, [chainInteractionsInput]);

  useEffect(
    () => setListOpened && setListOpened(chainListOpened || callListOpened || surveyListOpened || threadListOpened),
    [setListOpened, chainListOpened, callListOpened, surveyListOpened, threadListOpened]
  );

  useEffect(
    function closeListsOnChangeFlyoutMode() {
      if (flyoutMode !== FlyoutMode.Messages) setThreadListOpened(false);
      if ([FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode)) {
        setChainListOpened(false);
        setSurveyListOpened(false);
        setCallListOpened(false);
      }
    },
    [flyoutMode]
  );

  useEffect(() => {
    if (!threadListOpened || !topbarRef.current) return;

    const handleClick = (event) => {
      if (!topbarRef.current.contains(event.target)) {
        setThreadListOpened(false);
      }
    };

    window.addEventListener("click", handleClick, { capture: true });

    return () => window.removeEventListener("click", handleClick, { capture: true });
  }, [threadListOpened]);

  const [isExpertsListOpen, setIsExpertsListOpen] = useState(false);
  const [expertsCharge, setExpertsCharge] = useState([]);

  const {
    state: { runningAction },
  } = useProjectInteractionsContext();

  return (
    <TopbarContainer ref={topbarRef}>
      <x.div {...containerStyles} flexWrap={isMobile ? "wrap" : undefined}>
        <x.div {...headerStyles}>
          <x.div {...advisorInfoStyles}>
            <CloseButton onClose={onClose} />
            {flyoutMode === FlyoutMode.NewMessage ? (
              <Typography component="div" variant="body-em" {...advisorNameStyles}>
                New {newMessageType}
              </Typography>
            ) : (
              <>
                <Tooltip
                  data-testid="advisor-name-tooltip"
                  title={interaction.advisorName}
                  disabled={!isAdvisorNameOverflow}
                  position="top"
                >
                  <Typography ref={advisorNameRef} variant="body-em" component="div" {...advisorNameStyles}>
                    {interaction.advisorName}
                  </Typography>
                </Tooltip>
                <ViewInSystem interactionId={interaction.id} />
                <InteractionActionIcons
                  locationContext="flyout"
                  hideStarOrigin={hideStarOrigin}
                  backgroundSyncRunning={backgroundSyncRunning}
                  currentView={currentView}
                  interaction={interaction}
                  isActiveProject={isActiveProject}
                  onClickComments={() => onToggleComments(interaction)}
                  isExpertHidden={interaction.hidden}
                  onClickStar={() => onToggleStar(interaction, hideStarOrigin || HitOrigin.flyout)}
                  isExpertStarred={interaction.starred}
                  showCommentsButton={!isCalendarView}
                  token={token}
                  origin={HitOrigin.flyout}
                />
              </>
            )}
          </x.div>
          {flyoutMode === FlyoutMode.NewMessage ? (
            <x.div {...maximumChargeStyles}>
              <WorkRequestMaximumCharge
                selectedExperts={selectedExperts}
                maximumTaskDuration={maximumTaskDuration}
                messageType={newMessageType}
                setExpertsCharge={setExpertsCharge}
                isExpertsListOpen={isExpertsListOpen}
                setIsExpertsListOpen={setIsExpertsListOpen}
              />
            </x.div>
          ) : (
            <x.div {...advisorInteractionsStyles}>
              {runningAction === "loadingAdvisorInteractions" || !chainInteractions.length ? (
                <Skeleton variant="noMargin" width="200px" />
              ) : (
                <>
                  {surveyInteractionListVariant ? (
                    <>
                      {surveyChain.length > 0 && (
                        <InteractionsLink
                          label={"Surveys"}
                          interactions={surveyChain}
                          opened={surveyListOpened}
                          setOpened={openSurveyList}
                        />
                      )}
                      {callChain.length > 0 && (
                        <InteractionsLink
                          label={"Follow-up Calls"}
                          interactions={callChain}
                          opened={callListOpened}
                          setOpened={openCallList}
                        />
                      )}
                    </>
                  ) : (
                    <InteractionsLink
                      label={"Interactions"}
                      interactions={chainInteractions}
                      opened={chainListOpened}
                      setOpened={setChainListOpened}
                    />
                  )}
                  {hasClientPortalMessages &&
                    interaction.showMessagesLink &&
                    selectedWorkstream?.workstreamType !== "survey" && (
                      <>
                        <Typography>|</Typography>
                        <MessagesLink
                          interaction={interaction}
                          token={token}
                          opened={threadListOpened}
                          setOpened={setThreadListOpened}
                          onSendNewMessage={onSendNewMessage}
                        />
                      </>
                    )}
                </>
              )}
            </x.div>
          )}
        </x.div>
        {!isFullScreenConfiguration && !locked && (
          <x.div ref={ctaWrapperRef} {...callToActionsStyles}>
            {showMoreOptions && (
              <MoreOptions
                onCancelRequest={() => onCancelRequest(interaction.id, HitOrigin.flyout)}
                pccOptOutWindow={pccOptOutWindow}
                interaction={interaction}
                order={isMobile ? 9 : undefined}
                token={token}
                isCentered={ctaWrapperRef.current?.childElementCount > 1}
              />
            )}
            {showMessageCtas && (
              <MessageButton
                label="New"
                variant="primary"
                interactions={[interaction]}
                onSendNewMessage={onSendNewMessage}
              />
            )}
            {showSendMessageCta && <SendMessageButton buttonRef={sendMessageButtonRef} />}
            {isCallAboutToStartOrHappening ? (
              showInteractionCtas && !interaction.isNonCallInteraction ? (
                <CallMeButton
                  callMeUrl={interaction.callMeUrl}
                  provider={interaction.newspeakProvider}
                  isModalOpen={isCallMeModalOpen}
                  setIsModalOpen={setIsCallMeModalOpen}
                  projectToken={token}
                  size="small"
                  variant={isCallAboutToStartOrHappening ? "primary" : "outline"}
                  interaction={interaction}
                />
              ) : null
            ) : (
              <>
                {showFollowUp && (
                  <FollowUpButton
                    interaction={interaction}
                    hasClientPortalMessages={hasClientPortalMessages}
                    token={token}
                    onRequestFollowUp={() =>
                      onRequestFollowUp({
                        id: interaction.id,
                        origin: HitOrigin.flyout,
                      })
                    }
                    backgroundSyncRunning={backgroundSyncRunning}
                    singleButton={interaction.isSurvey}
                    variant={interaction.showFollowUp ? "primary" : "outline"}
                    showMessagingOptions={portalMessagesEnabled}
                    onSendNewMessage={onSendNewMessage}
                  />
                )}
                {showInteractionCtas && (
                  <>
                    {interaction.showRequest && (
                      <RequestButton
                        disabled={backgroundSyncRunning}
                        onClick={() => onRequest(interaction.id, `${currentView}-regular`)}
                        interaction={interaction}
                        loading={interaction.runningAction === "request"}
                      />
                    )}
                    {interaction.showInstantSchedule && (
                      <InstantScheduleButton
                        disabled={backgroundSyncRunning}
                        onClick={() => onRequest(interaction.id, `${currentView}-instant`)}
                        interaction={interaction}
                        loading={interaction.runningAction === "request"}
                      />
                    )}
                    {interaction.showSchedule && (
                      <ScheduleButton
                        disabled={backgroundSyncRunning}
                        onClick={() => onSchedule(interaction)}
                        interaction={interaction}
                        loading={interaction.runningAction === "schedule"}
                      />
                    )}
                    {interaction.showRequestToReEngage && (
                      <RequestToReEngageButton
                        interaction={interaction}
                        researchRequest={researchRequest}
                        updateResearchRequest={updateResearchRequest}
                        isLoadingResearchRequest={isLoadingResearchRequest}
                        token={token}
                      />
                    )}
                    {interaction.showUpgradeInteraction && (
                      <UpgradeInteractionButton onClick={onRequestUpgradeInteraction} />
                    )}
                  </>
                )}
              </>
            )}
          </x.div>
        )}
      </x.div>
      {threadListOpened && <MessageThreadList {...interactionListStyles} />}

      {!surveyInteractionListVariant && chainListOpened && (
        <InteractionChainList
          loading={runningAction === "loadingAdvisorInteractions"}
          chainInteractions={chainInteractions}
          chainIdSelected={interaction.id}
          onChangeChainIdSelected={onUpdateChainIdSelected}
          clientRequests={clientRequests}
          showCalendarView={showCalendarView}
          isExpertHidden={interaction.hidden}
          flyoutConfiguration={flyoutConfiguration}
          {...interactionListStyles}
        />
      )}

      {surveyInteractionListVariant && (
        <>
          {surveyListOpened && (
            <InteractionChainList
              loading={runningAction === "loadingAdvisorInteractions"}
              chainInteractions={surveyChain}
              chainIdSelected={interaction.id}
              onChangeChainIdSelected={onUpdateChainIdSelected}
              clientRequests={clientRequests}
              showCalendarView={showCalendarView}
              isExpertHidden={interaction.hidden}
              flyoutConfiguration={flyoutConfiguration}
              {...interactionListStyles}
            />
          )}
          {callListOpened && (
            <InteractionChainList
              loading={runningAction === "loadingAdvisorInteractions"}
              chainInteractions={callChain}
              chainIdSelected={interaction.id}
              onChangeChainIdSelected={onUpdateChainIdSelected}
              clientRequests={clientRequests}
              showCalendarView={showCalendarView}
              isExpertHidden={interaction.hidden}
              flyoutConfiguration={flyoutConfiguration}
              {...interactionListStyles}
            />
          )}
        </>
      )}
      {isExpertsListOpen && selectedExperts?.length > 1 && (
        <x.div pt="8px">
          <ExpertChargesList experts={expertsCharge} />
        </x.div>
      )}
    </TopbarContainer>
  );
};

const CloseButton = ({ onClose }) => {
  const { isMobile } = useCheckScreen();
  const dispatch = useContext(DispatchContext);
  const { flyoutMode, messageThreads } = useFlyoutContext();
  const [initialFlyoutMode, setInitialFlyoutMode] = useState();

  useEffect(() => {
    setInitialFlyoutMode(flyoutMode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldGoBackToMessages = useMemo(() => {
    return (
      initialFlyoutMode !== FlyoutMode.NewMessage && flyoutMode === FlyoutMode.NewMessage && messageThreads?.length
    );
  }, [flyoutMode, messageThreads, initialFlyoutMode]);

  const shouldGoBackToInteraction = useMemo(() => {
    return (
      initialFlyoutMode !== FlyoutMode.NewMessage &&
      (flyoutMode === FlyoutMode.Messages || flyoutMode === FlyoutMode.NewMessage)
    );
  }, [flyoutMode, initialFlyoutMode]);

  const onClick = useCallback(() => {
    if (shouldGoBackToMessages) {
      dispatch(setFlyoutMode(FlyoutMode.Messages));
    } else if (shouldGoBackToInteraction) {
      dispatch(setFlyoutMode(FlyoutMode.Interaction));
    } else {
      onClose();
    }
    dispatch(selectExperts([]));
  }, [dispatch, onClose, shouldGoBackToMessages, shouldGoBackToInteraction]);

  return (
    <x.div data-testid="close-flyout" onClick={onClick}>
      <Icon color="secondary" data-testid="" onClick={noop} size={isMobile ? "large" : "medium"}>
        {shouldGoBackToInteraction || shouldGoBackToMessages ? <ArrowLeft /> : <Close />}
      </Icon>
    </x.div>
  );
};

const InteractionsLink = ({ interactions, opened, setOpened, label }) => {
  const { flyoutMode } = useFlyoutContext();
  const dispatch = useContext(DispatchContext);
  const enableInteractionNavigation = useMemo(
    () => interactions?.length > 1 || interactions?.some((i) => i.state !== "proposed"),
    [interactions]
  );

  const onClick = () => {
    if (flyoutMode === FlyoutMode.Messages) {
      dispatch(setFlyoutMode(FlyoutMode.Interaction));
    } else {
      setOpened(!opened);
    }
  };

  const WrapperComponent = ({ ...props }) =>
    enableInteractionNavigation ? (
      <Link
        component="button"
        endIcon={
          flyoutMode !== FlyoutMode.Messages && (
            <x.div w="20px" h="20px" fontSize="20px" transition="transform 0.2s ease-in-out">
              {opened ? <ChevronUp /> : <ChevronDown />}
            </x.div>
          )
        }
        onClick={onClick}
        style={{ outline: "none" }}
        {...props}
      />
    ) : (
      <x.div {...props} />
    );

  return (
    <WrapperComponent data-testid="interactions-link" whiteSpace="nowrap">
      <Typography
        variant="body-em"
        data-testid={`interactions-link-${label}`}
        color={enableInteractionNavigation ? undefined : "secondary"}
      >
        {label} ({enableInteractionNavigation ? interactions?.length ?? 0 : 0})
      </Typography>
    </WrapperComponent>
  );
};

const MessagesLink = ({ interaction, token, opened, setOpened, onSendNewMessage }) => {
  const dispatch = useContext(DispatchContext);
  const { flyoutMode, messageThreads } = useFlyoutContext();
  const { disableAction, tooltipText } = useLockedExpert(interaction);
  const isLoggedIn = useIsAuthenticated();
  const followUpMessagesHref = `/${token}/experts/messages-view/${interaction.id}`;

  const onClick = (event) => {
    if (disableAction) {
      event.preventDefault();
      return;
    }
    if (!messageThreads.length) {
      return onSendNewMessage([interaction], MessageType.WorkRequest);
    }

    flyoutMode !== FlyoutMode.Messages ? dispatch(setFlyoutMode(FlyoutMode.Messages)) : setOpened(!opened);
  };

  const WrapperComponent = ({ to, style, ...props }) => {
    return <React.Fragment {...props} />;
  };

  return (
    <WrapperComponent to={followUpMessagesHref} style={{ textDecoration: "none" }}>
      <LinkWithLoginWall
        component={isLoggedIn ? "button" : "div"}
        data-tip={tooltipText}
        onClick={onClick}
        cursor="pointer"
        whiteSpace="nowrap"
        style={{ outline: "none" }}
        endIcon={
          flyoutMode === FlyoutMode.Messages && (
            <x.div w="20px" h="20px" fontSize="20px" transition="transform 0.2s ease-in-out">
              {opened ? <ChevronUp /> : <ChevronDown />}
            </x.div>
          )
        }
      >
        <Typography variant="body-em" data-testid="messages-link">
          Messages
          {` (${messageThreads?.length ?? 0})`}
        </Typography>
      </LinkWithLoginWall>
    </WrapperComponent>
  );
};

const getMessagingOptions = (interactions, onSendNewMessage, enableLegacyBCGClarifications = false) => {
  const hasAnyCompletedInteraction = interactions.some(
    (interaction) =>
      interaction.state === "completed" || (interaction.othersCounts && interaction.othersCounts["completed"])
  );
  const hasAnyNonCompletedInteraction = interactions.some(
    (interaction) =>
      interaction.state !== "completed" ||
      (interaction.othersCounts &&
        Object.keys(interaction.othersCounts).some((state) => state !== "completed" && interaction.othersCounts[state]))
  );
  const noWrittenWork = interactions.every((interaction) => interaction.noWrittenWork);

  const options = [];

  if (hasAnyNonCompletedInteraction && !enableLegacyBCGClarifications) {
    options.push({
      label: "Send Call Guide",
      listOptionAccessories: { leftIcon: <Mail /> },
      requireLogin: true,
      action: (event) => {
        event.stopPropagation();
        onSendNewMessage(interactions, MessageType.CallGuide);
      },
    });
  }

  if (!noWrittenWork) {
    if (!enableLegacyBCGClarifications) {
      options.push({
        label: "Send Work Request",
        listOptionAccessories: { leftIcon: <Mail /> },
        requireLogin: true,
        action: (event) => {
          event.stopPropagation();
          onSendNewMessage(interactions, MessageType.WorkRequest);
        },
      });
    }

    if (hasAnyCompletedInteraction) {
      options.push({
        label: "Send Clarification",
        listOptionAccessories: { leftIcon: <Mail /> },
        requireLogin: true,
        action: (event) => {
          event.stopPropagation();
          onSendNewMessage(interactions, MessageType.Clarification);
        },
      });
    }
  }

  return options;
};

const getTranscriptOptions = (projectToken, interactions, navigate) => {
  if (interactions.length > 1) return [];

  const options = [];
  const interaction = deliverablesInteractions(interactions)[0];
  const availableTranscripts = interaction ? getAvailableTranscripts(getDefaultRecording(interaction)) : [];
  const showViewSummary = availableTranscripts.some((tr) => ["regular", "ai"].includes(tr.transcriptType));

  if (showViewSummary) {
    options.push({
      label: "View Summary",
      listOptionAccessories: { leftIcon: <SummarizedTranscript /> },
      requireLogin: true,
      action: (event) => {
        event.stopPropagation();
        navigate(
          `/${projectToken}/experts/deliverables-view/?selectedInteraction=${interactions[0].id}&aiSummaryType=Comprehensive`
        );
      },
    });
  }
  return options;
};

const withSplitOptions = (WrappedButton) => ({
  onSendNewMessage,
  primaryOptionLabel,
  primaryOptionClick,
  ...props
}) => {
  const options = [];
  const { interactions, onClick } = props;
  const { project } = useCurrentProjectContext();
  const { hasProjectBadge } = useProjectBadgeContext();
  const navigate = useNavigate();

  if (primaryOptionLabel) {
    options.push({
      label: primaryOptionLabel,
      listOptionAccessories: { leftIcon: <Call /> },
      onClick: primaryOptionClick ?? onClick,
    });
  }

  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);

  options.push(...getMessagingOptions(interactions, onSendNewMessage, enableLegacyBCGClarifications));

  if (hasProjectBadge(ENABLE_AI_SUMMARIZATION)) {
    options.push(...getTranscriptOptions(project.token, interactions, navigate));
  }

  const popoverContent = interactions.every((interaction) => interaction.noWrittenWork) && (
    <>
      {options.length > 0 && <Divider spacing="md" />}
      <x.div p="8px" cursor="default">
        <Typography variant="body-small-em" color="secondary">
          This Expert has opted out of{" "}
        </Typography>
        <Typography variant="body-small-em" color="secondary">
          written work.
        </Typography>
      </x.div>
    </>
  );

  return options.length > 0 && <WrappedButton options={options} popoverContent={popoverContent} {...props} />;
};

export const RequestButton = ({ disabled, onClick, ...props }) => {
  const { interaction } = props;
  const { canRequest, tooltipText } = useLockedExpert(interaction);
  const [isRequesting, setIsRequesting] = useState(false);

  return (
    <CtaButton
      data-testid="request-button"
      data-tip={tooltipText}
      startIcon={<Request />}
      disabled={disabled || !canRequest}
      onClick={(...props) => {
        if (!isRequesting) {
          setIsRequesting(true);
          onClick(...props).finally(() => setIsRequesting(false));
        }
      }}
      {...props}
    >
      Request
    </CtaButton>
  );
};

export const InstantScheduleButton = ({ disabled, onClick, ...props }) => {
  const { interaction } = props;
  const { canSchedule, tooltipText } = useLockedExpert(interaction);
  const [isRequesting, setIsRequesting] = useState(false);

  return (
    <CtaButtonWithLoginWall
      data-testid="instant-schedule-button"
      data-tip={tooltipText}
      startIcon={<CalendarAvailable />}
      disabled={disabled || !canSchedule}
      onClick={(...props) => {
        if (!isRequesting) {
          setIsRequesting(true);
          onClick(...props).finally(() => setIsRequesting(false));
        }
      }}
      {...props}
    >
      Schedule
    </CtaButtonWithLoginWall>
  );
};

export const ScheduleButton = ({ disabled, onClick, ...props }) => {
  const { interaction } = props;
  const { canSchedule, tooltipText } = useLockedExpert(interaction);

  return (
    <CtaButtonWithLoginWall
      data-testid="schedule-button"
      data-tip={tooltipText}
      startIcon={<CalendarAvailable />}
      disabled={disabled || !canSchedule}
      onClick={onClick}
      {...props}
    >
      Schedule
    </CtaButtonWithLoginWall>
  );
};

export const UpgradeInteractionButton = ({ ...props }) => {
  return (
    <CtaButton variant="outline" data-testid="upgrade-interaction-button" {...props}>
      Upgrade Interaction
    </CtaButton>
  );
};

export const ClientAvailabilityButton = ({ disabled, onClick, ...props }) => {
  const { interaction } = props;
  const { canSchedule, tooltipText } = useLockedExpert(interaction);

  return (
    <CtaButtonWithLoginWall
      variant="outline"
      data-testid="leave-availability-button"
      data-tip={tooltipText}
      disabled={disabled || !canSchedule}
      onClick={onClick}
      {...props}
    >
      Provide Availability
    </CtaButtonWithLoginWall>
  );
};

const FollowUpButtonWithMessagingOptions = ({
  onRequestFollowUp,
  backgroundSyncRunning,
  onSendNewMessage,
  ...props
}) => {
  const { interaction } = props;
  const { isMobile } = useCheckScreen();
  const { canFollowUp } = useLockedExpert(interaction);
  const {
    state: { runningAction },
  } = useProjectInteractionsContext();

  if (!canFollowUp) return null;

  return (
    <DropdownButtonWithSplitOptions
      interactions={[interaction]}
      data-testid="grouped-follow-up-button"
      whiteSpace="nowrap"
      size={isMobile ? "medium" : "small"}
      optionsSize="medium"
      loading={interaction.runningAction === "followUp"}
      label="Follow-up"
      disabled={runningAction === "loadingAdvisorInteractions"}
      primaryOptionClick={onRequestFollowUp}
      primaryOptionLabel={
        interaction.showFollowUp ? (interaction.hasAdvisorAvailability ? "Schedule Call" : "Request Call") : undefined
      }
      popoverPlacement="bottom-end"
      onSendNewMessage={onSendNewMessage}
      {...props}
    />
  );
};

export const FollowUpButton = ({
  hasClientPortalMessages,
  token,
  onRequestFollowUp,
  backgroundSyncRunning,
  label,
  showMessagingOptions = false,
  singleButton,
  size,
  onSendNewMessage,
  ...props
}) => {
  const { interaction } = props;
  const navigate = useNavigate();
  const { isMobile } = useCheckScreen();
  const { disableAction, canFollowUp } = useLockedExpert(interaction);
  const {
    state: { runningAction },
  } = useProjectInteractionsContext();

  if (showMessagingOptions && interaction.showFollowUpMessageButton) {
    return (
      <FollowUpButtonWithMessagingOptions
        onRequestFollowUp={onRequestFollowUp}
        backgroundSyncRunning={backgroundSyncRunning}
        onSendNewMessage={onSendNewMessage}
        {...props}
      />
    );
  }

  const followUpMessagesHref = `/${token}/experts/messages-view/${interaction.id}`;
  const onClickMessagesButton = () => {
    navigate(followUpMessagesHref);
  };

  const options = [
    interaction.showFollowUp && {
      "data-testid": "follow-up-call-button",
      label: "Call",
      action: onRequestFollowUp,
      disabled: backgroundSyncRunning || !canFollowUp,
    },
    interaction.showFollowUpMessageButton &&
      hasClientPortalMessages && {
        "data-testid": "send-message-button",
        label: "Message",
        action: onClickMessagesButton,
        disabled: disableAction,
        requireLogin: true,
      },
  ].filter(Boolean);

  if (options.length === 0) return null;
  return (
    <>
      {singleButton ? (
        <CtaButton
          data-testid="single-follow-up-button"
          onClick={onRequestFollowUp}
          loading={interaction.runningAction === "followUp"}
          disabled={backgroundSyncRunning || !canFollowUp}
          {...props}
        >
          {label ?? "Follow-up"}
        </CtaButton>
      ) : (
        <DropdownButton
          options={options}
          data-testid="grouped-follow-up-button"
          whiteSpace="nowrap"
          size={isMobile ? "medium" : size ?? "small"}
          loading={interaction.runningAction === "followUp"}
          label="Follow-up"
          disabled={runningAction === "loadingAdvisorInteractions"}
          {...props}
        />
      )}
    </>
  );
};

const RequestToReEngageButton = ({
  interaction,
  researchRequest,
  updateResearchRequest,
  isLoadingResearchRequest,
  token,
}) => {
  return (
    <AdvisorResearchRequestButton
      interactionId={interaction.id}
      projectToken={token}
      researchRequest={researchRequest}
      updateResearchRequest={updateResearchRequest}
      isLoadingResearchRequest={isLoadingResearchRequest}
      {...interaction}
    />
  );
};

export const MessageButton = ({ size = "small", ...props }) => {
  const { isMobile } = useCheckScreen();

  return (
    <DropdownButtonWithSplitOptions
      data-testid="message-button"
      whiteSpace="nowrap"
      size={isMobile ? "medium" : size}
      optionsSize="medium"
      label="Message"
      popoverPlacement="bottom-end"
      {...props}
    />
  );
};

export const SendMessageButton = ({ ...props }) => {
  const { newMessageType } = useFlyoutContext();
  return <CtaButton {...props}>Send {newMessageType}</CtaButton>;
};

export const CtaButton = ({
  children,
  showMessagingOptions = false,
  startIcon = undefined,
  buttonRef = undefined,
  onSendNewMessage = undefined,
  ...props
}) => {
  const { isMobile } = useCheckScreen();

  const WrapperComponent = useMemo(
    () => ({ ...props }) =>
      showMessagingOptions ? (
        <ButtonWithSplitOptions
          ref={buttonRef}
          onSendNewMessage={onSendNewMessage}
          interactions={[props.interaction]}
          {...props}
        />
      ) : (
        <Button ref={buttonRef} {...props} />
      ),
    [buttonRef, onSendNewMessage, showMessagingOptions]
  );

  return (
    <WrapperComponent
      size={isMobile ? "medium" : "small"}
      whiteSpace="nowrap"
      startIcon={showMessagingOptions ? undefined : startIcon}
      {...props}
    >
      {children}
    </WrapperComponent>
  );
};

const SplitButton = ({ options, popoverContent, children, ...props }) => {
  const splitPopoverContent = (
    <>
      {options.map((option, index) => {
        const { requireLogin } = option || {};
        const OptionComponent = requireLogin ? OptionWithLoginWall : Option;
        return <OptionComponent onClick={option.action} key={index} {...option} />;
      })}
      {popoverContent}
    </>
  );
  return (
    <Button splitPopoverContent={splitPopoverContent} splitPopoverProps={{ zIndex: 9 }} {...props}>
      {children}
    </Button>
  );
};

const CtaButtonWithLoginWall = withLoginWall(CtaButton);
const LinkWithLoginWall = withLoginWall(Link);
const DropdownButtonWithLoginWall = withLoginWall(DropdownButton);
const DropdownButtonWithSplitOptions = withSplitOptions(DropdownButtonWithLoginWall);
const ButtonWithSplitOptions = withSplitOptions(SplitButton);
