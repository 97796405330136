import React, { lazy, Suspense } from "react";
import { x } from "@xstyled/styled-components";
import ReactHtmlParser from "react-html-parser";
import { useInView } from "react-intersection-observer";
import { Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TransformedQuestion } from "views/SurveysResultsView/api/types";

type QuestionProps = { question: TransformedQuestion };

const LazyVisualisationTypeFactory = lazy(() => import("./VisualisationTypeFactory"));

const Question = React.memo(({ question }: QuestionProps) => {
  const { color, spacing } = useThemeTokens();
  const { id, questionIndex, text } = question;
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <x.div ref={ref} display="flex" flexDirection="column" data-testid="question-section">
      <x.div
        display="flex"
        flexDirection="column"
        padding={`${spacing.layout.base03} ${spacing.layout.base05}`}
        borderTop={`1px solid ${color.border.neutral.default}`}
        gap={spacing.inner.base04}
      >
        <Typography variant="body-em" color={color.text.strong._}>
          {questionIndex + 1}. {ReactHtmlParser(text)}
        </Typography>
        {inView ? (
          <Suspense fallback={<Skeleton count={7} />}>
            <LazyVisualisationTypeFactory questionId={id} />
          </Suspense>
        ) : (
          <Skeleton count={7} />
        )}
      </x.div>
    </x.div>
  );
});

export default Question;
