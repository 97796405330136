import { SynthesisAnswer } from "@alphasights/portal-api-client";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { FileStatus, SelectedThirdPartyFileToUpload } from "views/DeliverablesView/ThirdPartyDocUploadModal";
import { Angle } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";

export const getCompanyName = (answer: SynthesisAnswer, document?: ThirdPartyInteraction) => {
  if (answer.expert.thirdPartyDocumentId && document) {
    return document.attribute.attributes.expertCompanyName || "[Expert Company]";
  }
  return answer.expert.companyName || "N/A";
};

export const getExpertRole = (answer: SynthesisAnswer, document?: ThirdPartyInteraction) => {
  if (answer.expert.thirdPartyDocumentId && document) {
    return document.attribute.attributes.expertPosition || "[Expert Position]";
  }
  return answer.expert.role || "N/A";
};

export const isFromThirdPartyDocument = (answer: SynthesisAnswer) => {
  return !!answer.expert.thirdPartyDocumentId;
};

export const mapThirdPartyInteractionToSelectedThirdPartyFileToUpload = (
  thirdPartyDocument: ThirdPartyInteraction,
  id: number
): SelectedThirdPartyFileToUpload => ({
  id,
  documentId: thirdPartyDocument.id,
  documentTitle: thirdPartyDocument.originalFilename,
  name: thirdPartyDocument.originalFilename,
  status: FileStatus.informationRequired,
  date: new Date(thirdPartyDocument.createdAt),
  isTranscript: thirdPartyDocument.documentType === ThirdPartyDocumentType.transcript,
  expertCompanyName: thirdPartyDocument.attribute.attributes.expertCompanyName,
  expertCompanyCdsAlphaCompanyId: thirdPartyDocument.attribute.attributes.expertCompanyCdsAlphaCompanyId,
  expertPosition: thirdPartyDocument.attribute.attributes.expertPosition,
  angle: thirdPartyDocument.attribute.attributes.angle as Angle | undefined,
});
