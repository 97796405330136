import React, { useEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import Tab from "views/SurveysResultsView/components/DataVisualization/components/Tab";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api/useSurveysAPI";
import { TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";
import { useLinkableSection } from "pages/AlphaNowPage/primers/hooks";

type LinkableSectionResult = {
  setRefs: (node: HTMLDivElement) => void;
  inView: boolean;
};

const DataVisualizationContainer = React.memo(() => {
  const { spacing } = useThemeTokens();

  // TODO: Get the survey id from the url
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { tabs } = surveyMetadata as TransformedSurveyMetadata;
  let sectionsRefs: React.MutableRefObject<HTMLDivElement | null>[] = tabs.map(() =>
    useRef<HTMLDivElement | null>(null)
  );
  let linkableSectionsRefs: LinkableSectionResult[] = sectionsRefs.map((ref) =>
    useLinkableSection({ sectionRef: ref })
  );
  let inViewSections = linkableSectionsRefs.map((entry) => entry.inView);

  const navigateToSectionIndex = useSurveysResultsStore(({ navigateToSectionIndex }) => navigateToSectionIndex);
  const setSelectedSectionIndex = useSurveysResultsStore(({ setSelectedSectionIndex }) => setSelectedSectionIndex);
  const setNavigateToSectionIndex = useSurveysResultsStore(
    ({ setNavigateToSectionIndex }) => setNavigateToSectionIndex
  );

  useEffect(() => {
    if (navigateToSectionIndex !== null && sectionsRefs[navigateToSectionIndex].current) {
      sectionsRefs[navigateToSectionIndex].current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToSectionIndex]);

  useEffect(() => {
    linkableSectionsRefs.forEach(({ inView }, index) => {
      if (inView) {
        if (navigateToSectionIndex === null || navigateToSectionIndex === index) {
          setSelectedSectionIndex(index);
          if (navigateToSectionIndex) setNavigateToSectionIndex(null);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...inViewSections]);

  if (sectionsRefs.length === 0 || sectionsRefs.length !== tabs.length) return null;

  return (
    <x.div
      data-testid="data-visualization-container"
      padding={spacing.layout.base03}
      display="flex"
      flexDirection="column"
      gap={spacing.inner.base06}
      h="100%"
      overflowY="auto"
    >
      <x.div display="flex" flexDirection="column" gap={spacing.inner.base06}>
        {tabs.map((tab, index) => (
          <Tab key={tab.display} tab={tab} ref={linkableSectionsRefs[index].setRefs} />
        ))}
      </x.div>
    </x.div>
  );
});

export default DataVisualizationContainer;
