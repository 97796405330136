import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, shape, color } = useThemeTokens();

  return {
    expertCardWrapper: {
      border: "solid 1px " + color.border.divider,
      borderRadius: shape.border.radius.medium,
      p: spacing.inner.base03,
      display: "flex",
      gap: spacing.inner.base,
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: {
        hover: color.background.surface.page.hover,
      },
    },
    interactionContent: {
      w: 276,
    },
    vendorWrapper: {
      display: "flex",
      gap: "12px",
      maxW: "100%",
      w: "100%",
    },
    vendorDataColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      minWidth: 0,
      flex: 1,
    },
    vendorMentionsTotal: {
      display: "flex",
      gap: "12px",
    },
    vendorAddTitle: {
      color: color.text.secondary,
    },
    vendorAddInfo: {
      color: color.text.assistive,
    },
    vendorLogo: {
      w: spacing.inner.base05,
      h: spacing.inner.base05,
    },
    carousel: {
      display: "flex",
      gap: "8px",
      mt: "4px",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base06,
      mt: spacing.inner.base06,
      position: "relative",
    },
  };
};
