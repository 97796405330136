import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { DatePicker as AdsDatePicker, Icon, Popover, useThemeTokens } from "@alphasights/alphadesign-components";
import { CalendarToday } from "@alphasights/alphadesign-icons";
import { format } from "date-fns";

const RightAccessory = () => {
  const { spacing } = useThemeTokens();
  return (
    <x.div mr={spacing.inner.base02}>
      <Icon style={{ backgroundColor: "none" }} color="secondary">
        <CalendarToday />
      </Icon>
    </x.div>
  );
};

const Content = ({ value }) => {
  const { spacing } = useThemeTokens();
  return <x.div pl={spacing.inner.base02}>{value}</x.div>;
};

export const Datepicker = ({
  date,
  setDate,
  dateFormat = "MMM dd, yyyy",
  minDate,
  placeholder = "Select date",
  styles,
  disabled = false,
}) => {
  const { color, spacing } = useThemeTokens();
  const ref = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [formattedDate, setFormattedDate] = useState(date ? format(date || new Date(), dateFormat) : "");
  const [textColor, setTextColor] = useState(color.text.assistive);

  const handleSelectDate = (date) => {
    setDate(date);
    setFormattedDate(format(date, dateFormat));
    handleClose();
  };

  useEffect(() => {
    setFormattedDate(date ? format(date || new Date(), dateFormat) : "");
  }, [date, dateFormat]);

  useEffect(() => {
    setTextColor(formattedDate ? color.text.primary : color.text.assistive);
  }, [formattedDate, color.text]);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setShowCalendar(true);
    } else {
      setAnchorEl(null);
      setShowCalendar(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowCalendar(false);
  };

  return (
    <x.div
      style={styles}
      position="relative"
      borderWidth="2px"
      borderStyle="solid"
      borderColor={color.border.default}
      borderRadius="medium"
    >
      <x.div
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="full"
        padding={spacing.inner.base03}
        borderRadius="medium"
        cursor={disabled ? "not-allowed" : "pointer"}
        backgroundColor={disabled ? color.background.disabled : "transparent"}
        color={disabled ? color.text.disabled : textColor}
        onClick={handleClick}
        data-testid="date-picker"
      >
        <Content value={formattedDate ? formattedDate : placeholder} />
        <RightAccessory />
      </x.div>
      {showCalendar && !disabled && (
        <Popover ref={ref} anchorEl={anchorEl} open onClose={handleClose}>
          <AdsDatePicker firstDate={date} onChange={handleSelectDate} minDate={minDate} />
        </Popover>
      )}
    </x.div>
  );
};
