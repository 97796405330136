import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, color } = useThemeTokens();

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base06,
      mt: spacing.inner.base06,
      position: "relative",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      border: `solid 1px ${color.border.divider}`,
      gap: spacing.inner.base03,
      borderRadius: spacing.inner.base02,
      p: spacing.inner.base06,
    },
    companyRow: {
      display: "flex",
      gap: spacing.inner.base02,
      alignItems: "center",
    },
    quoteRow: {
      display: "flex",
      gap: spacing.inner.base,
      alignItems: "center",
    },
    deleteCompanyIcon: {
      ml: "auto",
    },
  };
};
