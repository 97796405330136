import styled from "styled-components";
import { useThemeTokens, Typography } from "@alphasights/alphadesign-components";

const SpeakerTypography = styled(Typography).attrs({ variant: "body-em" })(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    align-items: baseline;
  `;
});

const FragmentTypography = styled(Typography).attrs({ component: "span" })(() => {
  const { font, spacing } = useThemeTokens();
  return `
    padding-left: ${spacing.inner.base12};
    line-height: ${font.lineHeight["03"]};
  `;
});

const FragmentWrapper = styled.div(() => {
  const { color, spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base};
    color: ${color.text.strong._};
  `;
});

const SpeakerWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base06};
    align-items: center;
  `;
});

const TranscriptWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    width: 644px;
    max-width: 644px;
    gap: ${spacing.inner.base04};
    margin: ${spacing.inner.base06} auto;
    padding: ${spacing.inner.base06};
  `;
});

export { SpeakerTypography, FragmentTypography, FragmentWrapper, SpeakerWrapper, TranscriptWrapper };
