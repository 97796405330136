import { fetch } from "../hooks/useApi";
import { AngleQuestionCount, AngleQuestionTheme } from "models/AngleQuestions";

export const angleQuestionsService = {
  async findAngleQuestionThemes(projectToken: string, angleId: string): Promise<AngleQuestionTheme[]> {
    return fetch({
      url: `/api/projects/${projectToken}/angle-question-themes/${angleId}`,
      handleForbidden: true,
    }).then((res) => res.json());
  },
  async getAngleQuestionsCount(projectToken: string): Promise<AngleQuestionCount[]> {
    return fetch({
      url: `/api/projects/${projectToken}/angle-question-themes/count-by-angle`,
      handleForbidden: true,
    }).then((res) => res.json());
  },
};
