import _ from "lodash";
import { Experience } from "models/Interaction";

export const EXPERIENCE_DESCRIPTION = {
  [Experience.Uses]: "Uses",
  [Experience.Churned]: "Churned",
  [Experience.Evaluated]: "Evaluated, did not select",
  [Experience.Aware]: "Aware of",
  [Experience.Unaware]: "Unaware of",
};

export const IN_ORDER_EXPERIENCES = [
  Experience.Uses,
  Experience.Churned,
  Experience.Evaluated,
  Experience.Aware,
  Experience.Unaware,
];

export type CustomerKnowledgeResponse = {
  data: CustomerKnowledgeRecord[];
};

export type CustomerKnowledgeRecord = {
  id: string;
  advisorId: string;
  angleId: string;
  angleTypeName: string;
  customerKnowledges: CustomerKnowledge[];
};

export const getUniqCustomerKnowledges = (customerKnowledges: CustomerKnowledge[]) => {
  return _.uniqBy(customerKnowledges, (value) => value.vendor.id + value.experience);
};

export const buildExperienceTooltipCopy = (experience: Experience, customerKnowledges: CustomerKnowledge[]) => {
  if (!customerKnowledges?.length) return null;
  const vendorNames = customerKnowledges?.map((ck) => ck.vendor.name);
  return `${EXPERIENCE_DESCRIPTION[experience]} ${
    vendorNames.length > 1
      ? `${vendorNames.slice(0, -1).join(", ")} and ${vendorNames[vendorNames.length - 1]}`
      : vendorNames[0]
  }`;
};

export const hasCustomerExperts = (allInteractions: Interaction[]) => {
  return allInteractions?.some((i) => i.angles.some((angle) => angle?.type?.name === "End Customers"));
};

export const hasSufficientCustomerKnowledge = (
  cks: CustomerKnowledgeRecord[],
  displayedExperts: { advisorId: string; angleId: string }[] | undefined
) => {
  if (!displayedExperts || displayedExperts.length === 0) return false;

  const grouped = Object.values(_.groupBy(cks, (r) => `${r.advisorId}-${r.angleId}`));
  const experts = grouped
    .map((a) => {
      return {
        angleId: a[0].angleId,
        angleTypeName: a[0].angleTypeName,
        advisorId: a[0].advisorId,
        records: a,
      };
    })
    .filter(({ angleId, advisorId }) =>
      displayedExperts.some((e) => e.advisorId === advisorId && e.angleId === angleId)
    );

  const endCustomers = experts?.filter((expert) => expert.angleTypeName === "End Customers") ?? [];

  if (!endCustomers.length) return false;

  const endCustomersWithEnoughCks = endCustomers.filter(
    (ckGrouped) => Math.max(...ckGrouped.records.map((ck) => ck.customerKnowledges.length)) >= 3
  );

  const validPercentil = endCustomersWithEnoughCks.length / endCustomers.length;
  return validPercentil >= 0.65;
};

export const isEndCustomerInteraction = (interaction: Interaction) => {
  return interaction.angles?.some((angle) => angle?.type?.name === "End Customers");
};
