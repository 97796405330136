import React from "react";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { ExternalLink } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { CallUnavailableAlert } from "./CallUnavailableAlert";
import { CallAvailabilityStatus } from "./constants";

export const OpenExternalLink = ({ isOpen, callAvailabilityStatus, projectLead, joinUrl, textToShow }) => {
  const openLink = () => {
    window.open(joinUrl, "_blank");
  };

  function isUnavailable() {
    return callAvailabilityStatus && callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE;
  }

  const { spacing } = useThemeTokens();

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {isUnavailable() && (
        <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
      )}
      <x.div marginTop={spacing.layout.base03}>{textToShow}</x.div>
      <x.div marginTop={spacing.layout.base03}>
        <Button onClick={openLink} startIcon={<ExternalLink />} size="large" disabled={isUnavailable()}>
          Join Call
        </Button>
      </x.div>
    </div>
  );
};
