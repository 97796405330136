import { useRef } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { SearchSizeVariant, SearchStyleVariant, STYLE_CONFIG } from "./consts";
import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import { DataTestIds } from ".";

type VariantsProps = {
  styleVariant: SearchStyleVariant;
  sizeVariant: SearchSizeVariant;
};

type InnerWrapperProps = {
  isCollapsible: boolean;
  isSearchEmpty: boolean;
  isSearchBarActive: boolean;
  isMultiLine: boolean;
  isOptionsOpen: boolean;
} & VariantsProps;

const OuterWrapper = styled(x.div)(
  ({ styleVariant, sizeVariant, isCollapsible, isSearchEmpty, isSearchBarActive }: InnerWrapperProps) => {
    const { color, shape, spacing } = useThemeTokens();
    const { current: stylesFromConfig } = useRef(STYLE_CONFIG[sizeVariant]);
    const {
      innerWrapper: {
        [styleVariant]: { boxShadow: activeBoxShadow, borderRadius, position, zIndex },
      },
      height,
    } = stylesFromConfig;
    const maxHeight = toPx(fromPx(height) * 2);
    const boxShadow = isSearchBarActive ? activeBoxShadow : "none";
    const hasEmbeddedSearchResults = isSearchBarActive && styleVariant === SearchStyleVariant.V2;

    const commonStyles = `
    ${
      hasEmbeddedSearchResults
        ? `
       min-height:fit-content;
       max-height:fit-content;
       border: ${shape.border.width.small} solid ${color.border.neutral.default};
      `
        : `
      min-height: ${height};
      max-height: ${maxHeight};
      `
    };
      position: ${position};
      z-index: ${zIndex};
      background: ${color.background.surface.overlay};
      box-shadow: ${boxShadow};
    `;
    if (isCollapsible) {
      return `
    ${commonStyles}
    transition: width 0.3s ease-in-out;
    width: 200px;
    height: ${spacing.inner.base08};
    flex: none;
    border-radius: ${shape.border.radius.xlarge};
    &:has(input:not(:focus)) {
      &:hover, &:hover input {
        background-color: ${color.background.action.outline.hover};
      }
    }
    #${DataTestIds.search.SearchItemsContainer} {
      flex-wrap: nowrap;
    }
    ${
      isSearchEmpty &&
      `
      &:has(input:not(:focus)) {
        width: 40px;
        cursor: pointer !important;
        #${DataTestIds.search.SearchItemsContainer} {
          pointer-events: none;
          width: 0;
        }
      }
    `
    }
  `;
    }

    return `
      ${commonStyles}
      border-radius: ${borderRadius};
    `;
  }
);

type SearchBarWrapperProps = {
  styleVariant: SearchStyleVariant;
  hasErrorMessage: boolean;
  isSearchBarActive: boolean;
};

const SearchBarWrapper = styled(x.div)(
  ({ styleVariant, hasErrorMessage, isSearchBarActive }: SearchBarWrapperProps) => {
    const { color, shape, spacing } = useThemeTokens();

    const isV1 = styleVariant === SearchStyleVariant.V1;
    const isV2 = styleVariant === SearchStyleVariant.V2;

    const applyErrorMessageStyles = hasErrorMessage && (isV1 || (isV2 && !isSearchBarActive));
    const applyOutlineStyles = isV1 || applyErrorMessageStyles;

    return `
    display: flex;
    align-items: baseline;
    gap: ${spacing.inner.base02};
    border-radius: inherit;
    overflow-x: hidden;
    overflow-y: clip;
    ${
      applyOutlineStyles &&
      `
      outline: ${shape.border.width.small} solid ${
        hasErrorMessage ? color.border.danger : color.border.neutral.default
      };
      &:focus-within {
        outline: ${shape.border.width.small} solid ${hasErrorMessage ? color.border.danger : color.border.selected};
      }
    
      `
    }
  `;
  }
);

type ItemsContainerProps = {
  isSearchBarActive: boolean;
  isMultiLine: boolean;
} & VariantsProps;

const ItemsContainer = styled(x.div)(
  ({ sizeVariant, styleVariant, isSearchBarActive, isMultiLine }: ItemsContainerProps) => {
    const { spacing } = useThemeTokens();
    const { current: stylesFromConfig } = useRef(STYLE_CONFIG[sizeVariant]);
    const {
      itemsContainer: {
        [styleVariant]: { pt, pb },
      },
      height,
      searchItem: {
        xStyledProps: { gap },
      },
    } = stylesFromConfig;

    const paddingTop = isSearchBarActive ? pt : spacing.inner.base;
    const paddingBottom = isSearchBarActive ? pb : spacing.inner.base;
    const maxHeight = toPx(fromPx(height) * 2);
    const overflowX = isMultiLine ? "hidden" : "auto";

    return `
    width: -webkit-fill-available;
    max-height: ${maxHeight};
    display: flex;
    align-items: center;
    ${
      isMultiLine &&
      `
      flex-wrap: wrap;
      overflow-y: auto;
    `
    };
    overflow-x: ${overflowX};
    gap: ${gap};
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
    cursor: pointer;
    border-radius: inherit;
  `;
  }
);

const ErrorMessageTypography = styled(Typography).attrs({ variant: "body-small" })(() => {
  const { color, spacing } = useThemeTokens();
  return `
    background-color: ${color.background.neutral.subtle};
    padding-top: ${spacing.inner.base02}
    color: ${color.border.danger}
  `;
});

export { OuterWrapper, SearchBarWrapper, ItemsContainer, ErrorMessageTypography };
