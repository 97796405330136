import React, { useState } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import ExpertCard from "./ExpertCard";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyInsights } from "views/SurveysResultsView/api/useSurveysAPI";

const Experts = () => {
  const { color, spacing, shape } = useThemeTokens();
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>();

  // TODO: Get the survey id from the url
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyInsights } = useSurveyInsights(surveyId);

  const handleOnCardClick = (cardIndex: number) => {
    if (selectedCardIndex === cardIndex) {
      setSelectedCardIndex(undefined);
    } else {
      setSelectedCardIndex(cardIndex);
    }
  };

  if (!surveyInsights) {
    return null;
  }

  return (
    <x.div
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      p={spacing.inner.base06}
      paddingRight={spacing.inner.base04}
      overflowY="scroll"
      gap={spacing.inner.base03}
      borderTop={`${shape.border.width.sm} solid ${color.border.neutral.default}`}
    >
      {surveyInsights.map((element, index) => (
        <ExpertCard
          key={element.resultSetId}
          index={index}
          element={element}
          isCardOpen={selectedCardIndex === index}
          handleOnCardClick={handleOnCardClick}
        />
      ))}
    </x.div>
  );
};

export default Experts;
