import { x } from "@xstyled/styled-components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PortalMobileTopBar } from "../../../components/PortalMobileTopBar";
import { IconButton } from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { MobileTextBox } from "../../../components/MobileTextBox";
import { useMessengerContext } from "../../../providers/MessengerProvider";
import { ParticipantRole, ThreadResponse } from "../../../types";

import { isSameDay } from "date-fns";
import { MobileMessengerAdvisorCard, QuestionCard } from "../MobileQuestionContext/MobileQuestionContext.component";
import { useMobileMessageCardStyles } from "../MobileMessageList/MobileMessageList.styles";
import { useMessengerContentStyles } from "./MobileMessengerContent.styles";
import { getRequestTypeName } from "pages/MessengerPage/utils";

const REPLY_SIZE_LIMIT = 300;

export const ReplyThreadView = ({
  selectedThread,
  onClose,
  setSelectedThreadId,
  setReplyingTo,
  replyingTo,
  title,
}: {
  selectedThread: ThreadResponse;
  onClose: () => void;
  setReplyingTo: (replyingTo: boolean) => void;
  replyingTo: boolean;
  setSelectedThreadId: (threadId: string | undefined) => void;
  title?: string;
}) => {
  const { messageQuestionContextWrapper } = useMobileMessageCardStyles();
  const { wrapper } = useMessengerContentStyles();
  const { onSubmitReply, onReplyCreated } = useMessengerContext();

  const question = selectedThread.messages[0];
  const questionSentAt = question?.sentAt;
  const isQuestionFromToday = useMemo(
    () => (questionSentAt ? isSameDay(new Date(), new Date(questionSentAt)) : false),
    [questionSentAt]
  );

  const isLastMessageFromAdvisor = useMemo(() => {
    const lastMessage = selectedThread.messages[selectedThread.messages.length - 1];
    return lastMessage.sender.role === ParticipantRole.Advisor;
  }, [selectedThread.messages, selectedThread.messages.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const [messageText, setMessageText] = useState<string>();
  const [messageErrors, setMessageErrors] = useState<string>();
  const sendMessageButtonRef = useRef<HTMLElement | null>(null);

  const mainButton = (
    <IconButton size="large" variant="ghost" onClick={onClose} testId="mobile-top-bar-menu-button">
      <ArrowLeft />
    </IconButton>
  );

  const checkMessageError = useCallback(
    (isOk: boolean) => {
      if (!messageText || messageText.length === 0) {
        setMessageErrors("The message is empty");
        return false;
      } else {
        if (messageText.length > REPLY_SIZE_LIMIT) {
          setMessageErrors("You have exceeded the character limit thread reply.");
          return false;
        }
        setMessageErrors(undefined);
      }
      return isOk;
    },
    [messageText]
  );

  const onClickSend = useCallback(
    (messageText: string) => {
      onSubmitReply(selectedThread.id, {
        content: messageText,
      }).then((message) => {
        onReplyCreated(selectedThread.id, message);
      });
    },
    [onSubmitReply, onReplyCreated, selectedThread.id]
  );

  useEffect(() => {
    if (messageErrors) {
      checkMessageError(false);
    }
  }, [messageText, messageErrors, checkMessageError]);

  return (
    <x.div data-testid="mobile-reply-thread-view" {...wrapper}>
      <PortalMobileTopBar
        title={
          replyingTo ? `Reply to ${selectedThread?.advisor.name}` : `${title} with ${selectedThread?.advisor.name}`
        }
        mainButton={mainButton}
      />

      <x.div {...messageQuestionContextWrapper} mb={isLastMessageFromAdvisor ? "113px" : undefined}>
        {question && (
          <QuestionCard
            message={question}
            isMessageFromToday={isQuestionFromToday}
            messageTypeName={getRequestTypeName(selectedThread.requestType)}
          />
        )}
        <MobileMessengerAdvisorCard
          thread={selectedThread}
          isFirstThread={true}
          shouldShowReplies={true}
          setSelectedThreadId={setSelectedThreadId}
          setReplyingTo={setReplyingTo}
        />
      </x.div>

      {isLastMessageFromAdvisor && replyingTo && (
        <MobileTextBox
          name="message-content"
          error={messageErrors}
          placeholder="Begin typing your message here..."
          maxLength={REPLY_SIZE_LIMIT}
          onChange={setMessageText}
          defaultValue={messageText}
          resizable={false}
          onSendClick={() => messageText && onClickSend(messageText)}
          sendMessageButtonRef={sendMessageButtonRef}
          checkMessageError={checkMessageError}
        />
      )}
    </x.div>
  );
};
