import React, { useMemo, forwardRef } from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { Pill, PillProps, Tooltip, Typography } from "@alphasights/alphadesign-components";
import useFollowUpStyles from "./followUpStyles";
import { SurveyCellProps } from "../customTypes";

export const FollowUpCallStatusCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;
  const styles = useFollowUpStyles();

  const followUps = useMemo(() => {
    // In theory there should never be a follow up in "proposed" state, as we delete it in PTO if it goes back from "requested"
    // But seems like Judah saw this somewhere and asked to remove the pill so here it is
    return props.row.original.followUps.filter(({ state }: Interaction) => state !== "proposed");
  }, [props.row.original.followUps]);

  const mainFollowUp = useMemo(() => {
    if (interaction.surveyResponse?.state !== "completed") {
      return null;
    }

    return (
      followUps.find(({ state }: Interaction) => state !== "completed") ?? {
        state: "available",
      }
    );
  }, [followUps, interaction]);

  const otherFollowUps = useMemo(() => {
    return followUps.filter((f: Interaction) => f !== mainFollowUp);
  }, [mainFollowUp, followUps]);

  const secondaryFollowUpState =
    otherFollowUps.length > 0 &&
    otherFollowUps
      .map(({ state }: Interaction) => state)
      .reduce((state: String, cur: String) => (cur === state ? state : undefined));

  return (
    mainFollowUp && (
      <div style={styles.followUpDiv} data-testid={`follow-up-call-status-column-${interaction.id}`}>
        <StatusChip followUpState={mainFollowUp.state} chipContent={mainFollowUp.state} />

        {otherFollowUps.length > 0 && (
          <StatusChip
            data-testid={`other-follow-ups-${interaction.id}`}
            followUpState={secondaryFollowUpState || "available"}
            chipContent={`${otherFollowUps.length}`}
            tooltipText={`View ${otherFollowUps.length} ${
              otherFollowUps.length === 1 ? "interaction" : "interactions"
            }`}
          />
        )}
      </div>
    )
  );
};

interface StatusChipProps extends PillProps {
  followUpState?: string;
  tooltipText?: React.ReactNode;
  chipContent: string;
  newChipCount?: boolean;
}

export const StatusChip = forwardRef<HTMLDivElement, StatusChipProps>(
  ({ followUpState = "proposed", tooltipText, chipContent, newChipCount = false, ...props }, ref) => {
    const { pillVariantMap, chipIcons } = useFollowUpStyles({ newChipCount });
    const adjustedState = followUpState.replace(/proposed/, "available");

    return (
      <Tooltip title={tooltipText} position="bottom" disabled={!tooltipText}>
        <Pill
          ref={ref as React.Ref<HTMLDivElement>}
          variant={pillVariantMap[adjustedState]}
          size="small"
          leftAccessories={chipIcons[adjustedState as keyof typeof chipIcons]}
          isInteractive={false}
          {...props}
        >
          <Typography variant="body-small">{chipContent}</Typography>
        </Pill>
      </Tooltip>
    );
  }
);

export const FollowUpCallStatusColumn = {
  id: "FollowUpCallStatusColumn",
  accessorKey: "",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <FollowUpCallStatusCell props={props} />,
  header: "Follow Up Call Status",
  minSize: 200,
};
