import { Alert } from "@alphasights/alphadesign-components";
import { HitAction } from "@alphasights/portal-api-client";
import { buildUrlWithFilters } from "components/InteractionsFilter/serializer";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import React from "react";
import { useNavigate } from "router-utils";

export const ScheduleCustomersBanner = () => {
  const navigate = useNavigate();
  const { synthesisLogHit, selectedModule, revision } = useProjectSynthesisContext();
  const { project } = useCurrentProjectContext();

  const {
    state: { filterOptions },
  } = useProjectInteractionsContext();

  if ((selectedModule?.transcriptsAvailable.length ?? 0) >= 3) return null;

  const redirectToTableView = () => {
    const angles: FilterOption[] = filterOptions?.groups ?? [];
    const customerAnglesIds = angles.filter((angle) => angle.label.match(/customer/i)).map((angle) => angle.value);
    synthesisLogHit({
      action: HitAction.projectSynthesisScheduleCustomers,
      details: {
        revision: revision?.revision,
        moduleType: selectedModule?.contentType,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    navigate(buildUrlWithFilters(`/${project?.token}/experts/table-view`, { groups: customerAnglesIds }));
  };

  return (
    <Alert
      size="small"
      buttonsPosition="right"
      buttonPrimaryProps={{
        children: "Schedule Customers",
        variant: "outline",
        size: "small",
        onClick: redirectToTableView,
        whiteSpace: "nowrap",
      }}
      w="100%"
      data-testid="schedule-customers-banner"
    >
      <span data-testid="schedule-customers-banner-text">
        Schedule more Calls with customer experts to prompt further insights and analysis.
      </span>
    </Alert>
  );
};
