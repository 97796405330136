import React, { useState } from "react";
import { fetch } from "../../hooks/useApi";
import { FormattedDateTime } from "../../providers/TimezoneProvider";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useTrackUserAction, withMargin } from "@alphasights/client-portal-shared";
import { Alert, Button, Loading, Typography } from "@alphasights/alphadesign-components";
import { usePastProjectsBannerStyle } from "./PastProjectsBanner.styles";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { TextArea } from "components/TextArea";
import { Variant, withAccessControl } from "components/AccessControl/AccessControl";
import { Request } from "@alphasights/alphadesign-icons";
import { CtaButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { HitOrigin } from "@alphasights/portal-api-client";

const ButtonWithAccess = withAccessControl(Button);

export const canCreateResearchRequest = (researchRequest) => researchRequest == null || researchRequest.canOverride;

export const PastProjectsBanner = ({ className = "", project, origin }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [researchRequest, updateResearchRequest] = useState(project.researchRequest);

  return (
    <>
      <div data-testid={`past-projects-banner-${project.token}`} className={` ${className}`}>
        <Alert variant="info" justifyContent="flex-start">
          <Typography>
            {canCreateResearchRequest(researchRequest) ? (
              <>
                This project has been closed due to inactivity.{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Typography
                  component="a"
                  color="link"
                  variant="body-em"
                  cursor="pointer"
                  data-testid={`request-continue-research-link-${project.token}`}
                  onClick={() => setModalOpen(true)}
                >
                  Request to continue research
                </Typography>{" "}
                or contact your project lead.
              </>
            ) : (
              <>
                <span>
                  {researchRequest.requester} requested to continue research on this project on{" "}
                  <FormattedDateTime date={researchRequest.createdAt} format="dd MMM yyyy" />.
                </span>
              </>
            )}
          </Typography>
        </Alert>
      </div>
      <ResearchRequestModal
        title="Continue Research"
        projectToken={project.token}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        updateResearchRequest={updateResearchRequest}
        header={
          <Typography>
            You are requesting to continue research on the project:{" "}
            <Typography variant="body-em" component="span">
              {project.title}.
            </Typography>{" "}
            Please provide details so we can best support your research.
          </Typography>
        }
        origin={origin}
      />
    </>
  );
};

export const AdvisorResearchRequestButton = ({
  projectToken,
  interactionId,
  runningAction,
  researchRequest,
  updateResearchRequest,
  isLoadingResearchRequest,
  className = "",
  advisorLockState,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { canFollowUp, tooltipText } = useLockedExpert({ advisorLockState });

  if (isLoadingResearchRequest || runningAction === "loadingAdvisorResearchRequest") {
    return <Loading size="sm" />;
  }

  const CtaComponent = CtaButton;

  return (
    <div className={className}>
      {canCreateResearchRequest(researchRequest) && (
        <>
          <Typography
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            data-tip={!canFollowUp ? tooltipText : null}
            component="div"
          >
            <CtaComponent
              data-testid="advisor-research-request-button"
              onClick={() => setModalOpen(true)}
              disabled={!canFollowUp}
              startIcon={<Request />}
            >
              Request To Re-engage
            </CtaComponent>
          </Typography>
          <ResearchRequestModal
            title="Request to Re-engage"
            detailsPlaceholder="Please provide availability..."
            projectToken={projectToken}
            advisorshipId={interactionId}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            updateResearchRequest={updateResearchRequest}
            header={<Typography>Please outline your availability below.</Typography>}
            origin={HitOrigin.flyout}
          />
        </>
      )}
    </div>
  );
};

const ResearchRequestModal = ({
  title,
  detailsPlaceholder = "Please provide details...",
  projectToken,
  advisorshipId,
  header,
  isOpen,
  onClose,
  updateResearchRequest,
  origin,
}) => {
  const [details, setDetails] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { logHit } = useTrackUserAction();
  const { isMobile } = useCheckScreen();

  const onCreateResearchRequest = async (details) => {
    try {
      setLoading(true);
      const res = await fetch({
        url: `/api/projects/${projectToken}/research-requests`,
        method: "POST",
        body: JSON.stringify({
          details,
          advisorshipId,
          type: advisorshipId == null ? "PROJECT" : "ADVISOR",
        }),
      }).then((data) => {
        logHit({
          origin: origin,
          action: "CREATE_RESEARCH_REQUEST",
          advisorshipId: advisorshipId,
          projectToken: projectToken,
        });

        return data;
      });
      const data = await res.json();
      updateResearchRequest(data);
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const { modalContainer, buttonMargin } = usePastProjectsBannerStyle({
    isMobile,
  });

  const ButtonWithAccessWithMargin = withMargin(ButtonWithAccess);

  const primaryButton = (
    <ButtonWithAccess
      accessControl={{
        variant: Variant.NotAllowed,
      }}
      variant="secondary"
      data-testid="send-research-request-button"
      type="submit"
      loading={isLoading}
      onClick={() => onCreateResearchRequest(details)}
    >
      Send Request
    </ButtonWithAccess>
  );

  const secondaryButton = (
    <ButtonWithAccessWithMargin
      accessControl={{
        variant: Variant.NotAllowed,
      }}
      variant="ghost"
      onClick={onClose}
      {...buttonMargin}
    >
      Cancel
    </ButtonWithAccessWithMargin>
  );

  return (
    <ResponsiveModal
      variant="fullscreen"
      onClose={onClose}
      open={isOpen}
      title={title}
      slotWidth={isMobile ? "100%" : "496px"}
      slotHeight={isMobile ? null : "auto"}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    >
      <Typography {...modalContainer}>
        {header}
        <TextArea
          value={details}
          onChange={({ target: { value } }) => setDetails(value)}
          placeholder={detailsPlaceholder}
          rows={isMobile ? "8" : "4"}
        />
      </Typography>
    </ResponsiveModal>
  );
};
