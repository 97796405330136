import React, { useMemo, useRef } from "react";
import { useQuery } from "query-utils";
import _ from "lodash";
import { Alert, Divider, Icon, Loading } from "@alphasights/alphadesign-components";
import { GenericMessagePage } from "views/DeliverablesView/DeliverablesPage/GenericMessagePage";
import { ThirdPartyInteraction } from "types";
import {
  DEFAULT_SUMMARY,
  DOCUMENT_SUMMARY_KEY,
  NO_SUMMARY_MESSAGE,
  SUMMARY_FAILED_MESSAGE,
  SUMMARY_LOADING_MESSAGE,
  SUMMARY_LOADING_TITLE,
  SummaryType,
} from "./consts";
import { ErrorContainer, SummaryContainer, SummaryViewContainer } from "./DocumentSummaryTab.styled";
import OverviewDisplay from "./OverviewDisplay";
import ThemeDisplay from "./ThemeDisplay";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { DEFAULT_PROCESSING_STATUS, ProcessingStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { getSequentialFetchStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/utils";
import { DocumentSummary, Summary, SummaryTheme } from "./types";
const DocumentSummaryTab = ({
  setSearchTargetEl,
  interaction,
}: {
  setSearchTargetEl: any;
  interaction: ThirdPartyInteraction;
}) => {
  const { contentStyle } = useHideDeliverablesContent();
  const { fetchDocumentSummary, fetchDocumentSummaryStatus } = useDeliverableContext();
  const summaryRef = useRef<HTMLDivElement>(null);
  const documentId = interaction.id;

  const { data: processingStatus = DEFAULT_PROCESSING_STATUS, status: fetchProccessingStatus } = useQuery<{
    status: ProcessingStatus;
  }>(["summary-status"], () => fetchDocumentSummaryStatus(documentId), {
    refetchInterval: (documentSummaryStatus) => {
      const hasPendingSummary = documentSummaryStatus?.status === ProcessingStatus.pending;
      return hasPendingSummary ? 5000 : false;
    },
  });

  const { data: summary = DEFAULT_SUMMARY, status: fetchSummaryStatus } = useQuery<DocumentSummary>(
    [DOCUMENT_SUMMARY_KEY, documentId],
    () => fetchDocumentSummary(documentId),
    {
      enabled: processingStatus.status === ProcessingStatus.completed,
    }
  );

  const summaryStatus = getSequentialFetchStatus(fetchProccessingStatus, processingStatus.status, fetchSummaryStatus);

  const hasSummary = summary.parts.length > 0;

  const { overview, themes } = useMemo(() => {
    const parts = summary.parts;

    const overview = parts.find(({ type }) => type === SummaryType.overview)?.text || null;

    const themes = parts
      .filter(({ type }) => type !== SummaryType.overview)
      .reduce((acc: SummaryTheme[], part: Summary) => {
        if (part.type === SummaryType.theme) {
          return [...acc, { title: part.text, quotes: [], points: [] }];
        } else {
          const theme = _.last(acc) as SummaryTheme;
          const quotes = part.type === SummaryType.quote ? [part] : [];
          const points = part.type === SummaryType.point ? [part] : [];

          const updated = {
            ...theme,
            quotes: [...theme.quotes, ...quotes],
            points: [...theme.points, ...points],
          };

          return [..._.initial(acc), updated];
        }
      }, []);

    return { overview, themes };
  }, [summary]);

  if (summaryStatus === ProcessingStatus.pending) {
    return (
      <GenericMessagePage
        title={SUMMARY_LOADING_TITLE}
        message={SUMMARY_LOADING_MESSAGE}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  if (summaryStatus === ProcessingStatus.failed) {
    return (
      <ErrorContainer>
        <Alert variant="danger" w="auto">
          {SUMMARY_FAILED_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  if (!hasSummary) {
    return (
      <ErrorContainer>
        <Alert variant="info" w="auto">
          {NO_SUMMARY_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  return (
    <SummaryViewContainer ref={setSearchTargetEl}>
      {themes.length > 0 && (
        <SummaryContainer ref={summaryRef} {...contentStyle}>
          <OverviewDisplay overview={overview} />
          {themes.map((t, idx) => {
            return (
              <React.Fragment key={t.title}>
                <ThemeDisplay theme={t} ix={idx} interaction={interaction} />
                {idx !== themes.length - 1 && <Divider w="100%" />}
              </React.Fragment>
            );
          })}
        </SummaryContainer>
      )}
    </SummaryViewContainer>
  );
};

export { DocumentSummaryTab as default };
