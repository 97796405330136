import React, { useMemo, useRef } from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { Experience } from "../../../models/Interaction";
import { ExperienceIcon } from "../ExperienceIcon";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import _ from "lodash";
import { Pill, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { buildExperienceTooltipCopy, getUniqCustomerKnowledges } from "../helpers";
import useCustomerExpertStyles from "../styles";
import { CustomerKnowledgeFilters } from "../CustomerKnowledgeFilters/ckFilterService";
import { useIsOverflow } from "@alphasights/client-portal-shared";

const currentVendorColumnPrefix = "current-vendor-column-";

export const calculateMainKnowledge = (
  knowledges: CustomerKnowledge[],
  appliedFilters: CustomerKnowledgeFilters,
  project: Project
) => {
  const vendorIdsInUsesFilter = Object.keys(appliedFilters.vendors ?? {}).filter((vendorId) =>
    appliedFilters.vendors![vendorId].includes(Experience.Uses)
  );
  const knowledgesInUse = _.orderBy(
    knowledges.filter((it) => it.experience === Experience.Uses),
    "vendor.name"
  );
  const cksInFilters = knowledgesInUse.filter((ck) => vendorIdsInUsesFilter.includes(ck.vendor.id));

  const vendorIdsInTargetCompanies = project.investmentTargetCompanies.map((target) => target.id);
  const cksInTargetCompanies = knowledgesInUse.filter((ck) => vendorIdsInTargetCompanies.includes(ck.vendor.id));

  let mainKnowledge: CustomerKnowledge;
  if (cksInFilters.length > 0) {
    mainKnowledge = cksInFilters[0];
  } else if (cksInTargetCompanies.length > 0) {
    mainKnowledge = cksInTargetCompanies[0];
  } else {
    mainKnowledge = knowledgesInUse[0];
  }
  return mainKnowledge;
};

export const CurrentVendorColumn = ({ row }: AlphaTableTypes.Cell<any, any>) => {
  const styles = useCustomerExpertStyles();
  // @ts-ignore
  const { project }: { project: Project } = useCurrentProjectContext();
  const { appliedFilters } = useCustomerKnowledgeContext();

  const interaction: Interaction = row.original;
  const knowledges = useMemo(() => getUniqCustomerKnowledges(interaction.customerKnowledges), [interaction]);

  const knowledgesInUse = useMemo(
    () =>
      _.orderBy(
        knowledges.filter((it) => it.experience === Experience.Uses),
        "vendor.name"
      ),
    [knowledges]
  );

  const [mainKnowledge, otherKnowledges] = useMemo(() => {
    let mainKnowledge: CustomerKnowledge;
    mainKnowledge = calculateMainKnowledge(knowledgesInUse, appliedFilters, project);

    const otherKnowledges = knowledgesInUse.filter((ck) => ck.id !== mainKnowledge.id);

    return [mainKnowledge, otherKnowledges];
  }, [appliedFilters, knowledgesInUse, project]);

  const plusMoreTooltipCopy = useMemo(() => buildExperienceTooltipCopy(Experience.Uses, knowledgesInUse), [
    knowledgesInUse,
  ]);

  const columnRef = useRef(null);
  const isOverflow = useIsOverflow(columnRef);
  const vendorNameMaxChars = useMemo(() => {
    if (isOverflow && otherKnowledges.length > 0) {
      return 10;
    } else {
      return 20;
    }
  }, [isOverflow, otherKnowledges.length]);

  return (
    <x.div
      {...styles.currentlyUsesVendors}
      data-testid={`${currentVendorColumnPrefix}${interaction.id}`}
      ref={columnRef}
    >
      {mainKnowledge ? (
        <>
          <ExperienceIcon
            experience={mainKnowledge.experience}
            title={mainKnowledge.vendor?.name}
            maxChars={vendorNameMaxChars}
          />
          {otherKnowledges.length > 0 && (
            <Tooltip title={plusMoreTooltipCopy} position="bottom">
              <Pill size={"small"} isInteractive={false} whiteSpace="nowrap">
                <Typography variant="body-small">+{otherKnowledges.length} more</Typography>
              </Pill>
            </Tooltip>
          )}
        </>
      ) : null}
    </x.div>
  );
};
