import React, { FC, useState, useCallback, useEffect } from "react";
import { Loading } from "@alphasights/alphadesign-components";

import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { useQuery, useQueryClient } from "query-utils";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { TranscriptResponse } from "services/thirdPartyDocumentsService";
import { FileProcessingStatus } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";

import PdfViewer from "./PdfViewer";
import TranscriptViewer from "./TranscriptViewer";
import { LOAD_ERROR_MESSAGE, FETCH_DOC_JSON_KEY, FETCH_DOC_KEY } from "./constants";

import * as S from "./DocumentViewer.styled";

export const DataTestIds = {
  LOADING_SPINNER: "loading-spinner",
  ERROR_MESSAGE: "error-message",
};

type DocumentViewerProps = {
  interaction: ThirdPartyInteraction;
};

const DocumentViewer: FC<DocumentViewerProps> = ({ interaction }) => {
  const { id: documentId, documentType, processingStatus } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const { expertCompanyName, expertPosition } = interaction.attribute?.attributes || {};
  const isDocumentStatusPollingEnabled = isTranscript && processingStatus === FileProcessingStatus.pending;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [transcript, setTranscript] = useState<TranscriptResponse>();
  const [isFetchTranscriptEnabled, setIsFetchTranscriptEnabled] = useState<boolean>(
    isTranscript && processingStatus === FileProcessingStatus.completed
  );

  const { fetchDocument, fetchFileAsPdf, fetchDocumentJson } = useDeliverableContext();
  const queryClient = useQueryClient();

  const handlePdfViewerError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const handleTranscriptViewerError = () => {
    setIsLoading(true);
    setIsFetchTranscriptEnabled(false);
  };

  const readDocumentFile = useCallback(async () => {
    try {
      const response = await fetchFileAsPdf(documentId);

      const fileStream = response.body;
      const reader = fileStream.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
      }

      const blob = new Blob(chunks, { type: response.headers.get("Content-Type") });
      setFile(blob);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching document", error);
      handlePdfViewerError();
    }
  }, [fetchFileAsPdf, setFile, setIsLoading, documentId]);

  const { data: documentData } = useQuery([FETCH_DOC_KEY, documentId], async () => fetchDocument(documentId), {
    enabled: isDocumentStatusPollingEnabled,
    refetchInterval: (res) => {
      if (res?.processingStatus === FileProcessingStatus.completed) {
        setIsFetchTranscriptEnabled(true);
        return false;
      }
      return 30000;
    },
  });

  useQuery(
    [FETCH_DOC_JSON_KEY, documentId],
    async () => {
      let response = null;
      try {
        response = await fetchDocumentJson(documentId);
      } catch (error) {
        console.error("Error fetching document json", error);
        setIsFetchTranscriptEnabled(false);
        return null;
      }
      setTranscript(response);
      setIsError(false);
      setIsLoading(false);
      return response;
    },
    { enabled: isFetchTranscriptEnabled }
  );

  useEffect(() => {
    if (!isFetchTranscriptEnabled) {
      readDocumentFile();
    }
  }, [isFetchTranscriptEnabled, readDocumentFile]);

  useEffect(() => {
    if (documentData?.processingStatus === FileProcessingStatus.completed) {
      queryClient.invalidateQueries(["fetchThirdPartyDocuments"]);
    }
  }, [documentData, queryClient]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (transcript) {
    return (
      <TranscriptViewer
        fragments={transcript.parts}
        onError={handleTranscriptViewerError}
        expertCompany={expertCompanyName}
        expertPosition={expertPosition}
      />
    );
  }

  return <PdfViewer data={file} isLoading={isLoading} onError={handlePdfViewerError} loading={<LoadingSpinner />} />;
};

const LoadingSpinner = () => (
  <S.StateContainer data-testid={DataTestIds.LOADING_SPINNER}>
    <Loading />
  </S.StateContainer>
);

const ErrorMessage = () => (
  <S.StateContainer data-testid={DataTestIds.ERROR_MESSAGE}>
    <S.ErrorContainer>{LOAD_ERROR_MESSAGE}</S.ErrorContainer>
  </S.StateContainer>
);

export default DocumentViewer;
