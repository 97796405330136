import React, { ChangeEvent, useCallback } from "react";
import { IconButton, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { SynthesisQuote } from "@alphasights/portal-api-client";
import { useNavigate } from "router-utils";
import { useStyles } from "../SynthesisModuleContent.styles";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ArrowRight, Delete } from "@alphasights/alphadesign-icons";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { x } from "@xstyled/styled-components";
import { FloatingActions } from "components/FloatingActions";
import { removeSurroundQuotes } from "../../../../utils/quotes";
import _ from "lodash";
import { TopicPill } from "./TopicPill";
import { EditableInput } from "./EditableInput";

export const ExpertQuote = ({
  quote,
  mode,
  onEditQuote,
  onDeleteQuote,
  onNavigateToTranscript,
  saveInProgress,
  showCompanyInfo = false,
}: {
  quote: SynthesisQuote;
  mode: Mode;
  onEditQuote: (updatedQuote: SynthesisQuote) => void;
  onDeleteQuote: () => void;
  onNavigateToTranscript: () => void;
  saveInProgress: boolean;
  showCompanyInfo?: boolean;
}) => {
  const navigate = useNavigate();
  const { spacing } = useThemeTokens();
  const { quoteWrapper, borderedBox, editHeader } = useStyles();
  const { project } = useCurrentProjectContext();

  const navTo = useCallback(
    ({
      interactionId,
      transcriptIndex,
      tooltip,
    }: {
      interactionId: string;
      transcriptIndex: number;
      tooltip: string;
    }) => ({
      icon: <ArrowRight />,
      tooltip: tooltip,
      testid: "nav",
      onClick: () => {
        onNavigateToTranscript();
        navigate(
          `/${project?.token}/experts/deliverables-view/?part=${transcriptIndex}&selectedInteraction=${interactionId}`
        );
      },
    }),
    [navigate, onNavigateToTranscript, project]
  );

  const navToDoc = useCallback(
    ({ docId, transcriptIndex, tooltip }: { docId: string; transcriptIndex: number; tooltip: string }) => ({
      icon: <ArrowRight />,
      tooltip: tooltip,
      testid: "nav",
      onClick: () => {
        onNavigateToTranscript();
        navigate(
          `/${project?.token}/experts/deliverables-view/?part=${transcriptIndex}&selectedUploadedDocument=${docId}`
        );
      },
    }),
    [navigate, onNavigateToTranscript, project]
  );

  const onEdit = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedQuote = { ...quote, text: e.target.value };
    onEditQuote(updatedQuote);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div
        {...borderedBox}
        py={spacing.inner.base03}
        px={spacing.inner.base04}
        data-testid={`quote-edit-${quote.id}`}
      >
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base02}>
            {quote.topic ? (
              <TopicPill title={quote.topic} />
            ) : (
              <Typography component="span" variant="body-small-spaced" fontStyle="italic" color="secondary">
                {quote.companyName} - {quote.role}
              </Typography>
            )}
          </x.div>
          <Tooltip variant="dark" title="Delete quote">
            <IconButton
              size="small"
              variant="ghost"
              onClick={() => onDeleteQuote()}
              testId={`delete-quote-${quote.id}`}
              disabled={saveInProgress}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </x.div>
        <EditableInput input={quote.text} onChange={onEdit} quoted={true} />
      </x.div>
    );
  }

  return (
    <x.div {...quoteWrapper} key={quote.id}>
      <FloatingActions
        actions={_.compact([
          quote.transcriptIndex &&
            quote.interactionId &&
            navTo({
              interactionId: quote.interactionId,
              transcriptIndex: quote.transcriptIndex,
              tooltip: quote.edited ? "View original quote in transcript" : "View in transcript",
            }),
          quote.transcriptIndex &&
            quote.thirdPartyDocumentId &&
            navToDoc({
              docId: quote.thirdPartyDocumentId,
              transcriptIndex: quote.transcriptIndex,
              tooltip: quote.edited ? "View original quote in document" : "View in document",
            }),
        ])}
      >
        <x.span data-testid={`quote-${quote.id}`}>
          {quote.topic && <TopicPill title={quote.topic} />}
          <Typography data-testid="quoted-text" component="span" variant="body-small-spaced" fontStyle="italic">
            "{removeSurroundQuotes(quote.text)}"
          </Typography>
        </x.span>
        {showCompanyInfo && (
          <Typography component="span" variant="body-small-spaced" fontStyle="italic" color="secondary">
            {" "}
            {quote.companyName} - {quote.role}
          </Typography>
        )}
      </FloatingActions>
      {quote.edited && (
        <Typography component="span" variant="body-small-spaced" color="assistive">
          Edited
        </Typography>
      )}
    </x.div>
  );
};
