import React from "react";
import { useQuery } from "query-utils";
import { x } from "@xstyled/styled-components";
import { Alert, Icon, Loading } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { GenericMessagePage } from "views/DeliverablesView/DeliverablesPage/GenericMessagePage";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { ThirdPartyInteraction } from "types";
import {
  DOCUMENT_QUESTIONS_KEY,
  ERROR_GENERATING_QUESTION_VIEW_MESSAGE,
  ERROR_OCCURRED_MESSAGE,
  GENERATING_QUESTION_MESSAGE,
  GENERATING_QUESTION_TITLE,
  NO_QUESTIONS_MESSAGE,
} from "./consts";
import NewQuestion from "./NewQuestion";
import CompleteQuestion from "./CompleteQuestion";
import { ErrorContainer } from "./CompleteQuestion/CompleteQuestion.styled";
import { QuestionContainer } from "./DocumentQuestionTab.styled";
import ProcessingQuestion from "./ProcessingQuestion";
import { DEFAULT_PROCESSING_STATUS, ProcessingStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { getSequentialFetchStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/utils";
import { DocumentQuestion, ProcessingStatus as ProcessingStatusType } from "./types";

const DataTestIds = {
  questionContent: "questions-content",
  questionSection: "question-section",
};

const DocumentQuestionTab = ({
  setSearchTargetEl,
  interaction,
}: {
  setSearchTargetEl: any;
  interaction: ThirdPartyInteraction;
}) => {
  const { contentStyle, showContent } = useHideDeliverablesContent();
  const user = useCurrentUser();
  const { fetchDocumentQuestions, fetchDocumentQuestionStatus } = useDeliverableContext();
  const documentId = interaction.id;

  const { data: processingStatus = DEFAULT_PROCESSING_STATUS, status: fetchProccessingStatus } = useQuery<
    ProcessingStatusType
  >(["questions-status"], () => fetchDocumentQuestionStatus(documentId), {
    refetchInterval: (documentQuestionsStatus) => {
      const hasPendingQuestions = documentQuestionsStatus?.status === ProcessingStatus.pending;
      return hasPendingQuestions ? 5000 : false;
    },
  });

  const { data: questions = [], status: fetchQuestionsStatus } = useQuery<DocumentQuestion[]>(
    [DOCUMENT_QUESTIONS_KEY, documentId],
    () => fetchDocumentQuestions(documentId),
    {
      enabled: processingStatus.status === ProcessingStatus.completed,
      refetchInterval: (documentQuestions = []) => {
        const hasPendingQuestions = documentQuestions.some((q) => q.status === ProcessingStatus.pending);
        return hasPendingQuestions ? 10000 : false;
      },
    }
  );

  const questionsStatus = getSequentialFetchStatus(
    fetchProccessingStatus,
    processingStatus.status,
    fetchQuestionsStatus
  );

  if (questionsStatus === ProcessingStatus.pending) {
    return (
      <GenericMessagePage
        title={GENERATING_QUESTION_TITLE}
        message={GENERATING_QUESTION_MESSAGE}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  if (questionsStatus === ProcessingStatus.failed) {
    return (
      <ErrorContainer>
        <Alert variant="danger" w="100%">
          <b>{ERROR_OCCURRED_MESSAGE}</b> {ERROR_GENERATING_QUESTION_VIEW_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  if (!questions.length) {
    return (
      <ErrorContainer>
        <Alert variant="info" w="100%">
          {NO_QUESTIONS_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  return (
    <x.div {...contentStyle} data-testid={DataTestIds.questionContent}>
      {user?.enableAiInteractivity && showContent && questions.length > 1 && (
        <QuestionContainer>
          <NewQuestion documentId={interaction.id} />
        </QuestionContainer>
      )}
      <div ref={setSearchTargetEl} data-testid={DataTestIds.questionSection}>
        {questions.map((q) => (
          <QuestionContainer key={q.id}>
            {q.status === ProcessingStatus.pending ? (
              <ProcessingQuestion question={q} />
            ) : (
              <CompleteQuestion question={q} interaction={interaction} />
            )}
          </QuestionContainer>
        ))}
      </div>
    </x.div>
  );
};

export { DocumentQuestionTab as default };
