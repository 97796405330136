import React, { FC } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

import { Cell } from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/types";

const DataTestIds = {
  DELETE_BUTTON: "delete-cell-button",
};

type DeleteCellProps = Cell & {
  onClick: (id: number) => void;
};

const DeleteCell: FC<DeleteCellProps> = ({ row: { original: selectedFile, index }, onClick }) => {
  const handleClick = () => {
    onClick(selectedFile.id);
  };
  return (
    <IconButton
      variant="outline"
      size="small"
      onClick={handleClick}
      dataAttributes={{ "data-testid": `${DataTestIds.DELETE_BUTTON}-${index}` }}
    >
      <Delete />
    </IconButton>
  );
};

export { DeleteCell as default, DataTestIds };
export type { DeleteCellProps };
