import React, { FC, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Tooltip } from "@alphasights/alphadesign-components";
import { Upload } from "@alphasights/alphadesign-icons";

import useModal from "hooks/useModal";
import ThirdPartyDocUploadModal, {
  FileObject,
  FileStatus,
  SelectedThirdPartyFileToUpload,
} from "views/DeliverablesView/ThirdPartyDocUploadModal";
import { ADD_DOCUMENTS, TOOLTIP_TEXT, FILE_TYPES } from "./constants";
import { createFileName, readFile } from "./utils";

import * as S from "./AddDocumentsCard.styled";

const DataTestIds = {
  INPUT: "add-documents-input",
};

const AddDocumentsCard: FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedThirdPartyFileToUpload[]>([]);

  const { isVisible: isUploadModalVisible, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useModal();

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const lastFileId = useRef(-1);

  const openFileExplorer = () => {
    uploadInputRef.current?.click();
  };

  const handleFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? [];
    const filesArray = Array.from(files);

    const newFiles = await Promise.all(
      filesArray.map(async (file) => {
        const { name } = file;
        let data = null;
        let status = FileStatus.processing;
        try {
          data = await readFile(file);
        } catch (error) {
          status = FileStatus.failed;
        }
        const res = { name, data, status, date: new Date() };
        return res;
      })
    ).then((results: FileObject[]) => {
      const selectedFileNames = selectedFiles.map((file) => file.name);
      return results.map((res) => {
        const { name, data, status, date } = res;
        const uniqueName = createFileName(selectedFileNames, name);
        selectedFileNames.push(uniqueName);
        return {
          name: uniqueName,
          documentTitle: uniqueName,
          data,
          status,
          date,
          id: ++lastFileId.current,
        };
      });
    });
    setSelectedFiles((prevFiles) => [...newFiles, ...prevFiles]);
    onOpenUploadModal();
    uploadInputRef.current!.value = "";
  };

  const handleCloseUploadModal = () => {
    setSelectedFiles([]);
    onCloseUploadModal();
    lastFileId.current = 0;
  };

  return (
    <>
      <Tooltip title={TOOLTIP_TEXT} size="small">
        <S.Wrapper onClick={openFileExplorer}>
          <S.StyledTypography variant="body-small-em">
            <Icon size="small">
              <Upload />
            </Icon>
            <span>{ADD_DOCUMENTS}</span>
          </S.StyledTypography>
          <x.input
            data-testid={DataTestIds.INPUT}
            ref={uploadInputRef}
            type="file"
            multiple
            accept={FILE_TYPES.toString()}
            onChange={handleFilesSelected}
            hidden
          />
        </S.Wrapper>
      </Tooltip>
      {isUploadModalVisible && (
        <ThirdPartyDocUploadModal
          files={selectedFiles}
          setFiles={setSelectedFiles}
          selectFiles={openFileExplorer}
          onClose={handleCloseUploadModal}
        />
      )}
    </>
  );
};

export { AddDocumentsCard as default, DataTestIds };
