import React from "react";
import { Portal, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const HeaderPortal = ({ children }: { children: JSX.Element }) => {
  const [containerEl, setContainerEl] = React.useState<HTMLElement | undefined>(undefined);
  React.useLayoutEffect(() => {
    setContainerEl(document.querySelector("#interaction-page-header-container") as HTMLElement);
  }, []);

  if (containerEl) {
    return (
      <Portal containerEl={containerEl}>
        {children}
        <Separator />
      </Portal>
    );
  }

  return (
    <>
      {children}
      <Separator />
    </>
  );
};

export const Separator = styled.div(() => {
  const { shape, color } = useThemeTokens();
  return `
    border-bottom: ${shape.border.width.small} solid ${color.border.divider};
    background: ${color.background.surface.recessed};
  `;
});
