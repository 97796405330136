import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  Alert,
  Avatar,
  Button,
  Collapsible,
  Icon,
  IconButton,
  Link,
  Typography,
} from "@alphasights/alphadesign-components";
import {
  Attachment as AttachmentIcon,
  ChevronDown,
  ChevronUp,
  Download,
  Expert,
  Reply,
} from "@alphasights/alphadesign-icons";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { UnreadDot } from "../MessengerThreadCard/MessengerThreadCard";
import {
  useMessageHeaderStyles,
  useMessengerAdvisorThreadCardStyles,
  useMessengerAttachmentStyles,
  useMessengerMessageStyles,
  useMessengerQuestionCardStyles,
} from "./MessengerAdvisorThreadCard.styles";
import {
  AttachmentResponse,
  ComplianceReviewStatus,
  MessageResponse,
  ParticipantRole,
  ResponseStatusType,
  ThreadResponse,
} from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { messageAttachmentService } from "services/messageAttachment";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { sanitizeMessage } from "pages/MessengerPage/utils";
import { MessengerSuggestion } from "components/MessengerSuggestion/MessengerSuggestion";
import { MessengerReplyField } from "components/MessengerReplyField/MessengerReplyField";
import { useComplianceReviewContent } from "hooks/useComplianceReviewContent";
import { MessengerComplianceReviewApprovalStatus } from "components/MessengerComplianceReviewApprovalStatus/MessengerComplianceReviewApprovalStatus";

export const MessengerQuestionCard = ({
  question,
  messageTypeName,
}: {
  question: MessageResponse;
  messageTypeName: string;
}) => {
  const { card } = useMessengerQuestionCardStyles();
  const {
    onAcceptSuggestion: originalOnAcceptSuggestion,
    onDeclineSuggestion: originalOnDeclineSuggestion,
  } = useMessengerContext();

  const { originalMessage, suggestionMessage, onAcceptSuggestion, onDeclineSuggestion } = useComplianceReviewContent({
    message: question,
    onAcceptSuggestion: originalOnAcceptSuggestion,
    onDeclineSuggestion: originalOnDeclineSuggestion,
  });
  const { sanitizedContent } = useMemo(() => sanitizeMessage(originalMessage), [originalMessage]);

  return (
    <x.div data-testid="question-card" {...card}>
      <Typography variant="body-em">
        Question <MessengerComplianceReviewApprovalStatus status={question.complianceReviewStatus} />
      </Typography>
      <Typography
        data-testid={`question-content-${question.id}`}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />

      {question.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_DECLINED && (
        <Alert size="small" width="100%">
          Suggested edits were declined. The {messageTypeName} has not been sent to expert(s)
        </Alert>
      )}

      {question.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING && (
        <MessengerSuggestion
          content={suggestionMessage?.sanitizedContent}
          messageTypeName={messageTypeName}
          onAcceptSuggestion={onAcceptSuggestion}
          onDeclineSuggestion={onDeclineSuggestion}
        />
      )}
    </x.div>
  );
};

export const MessengerAdvisorThreadCard = ({
  thread,
  isFirstThread,
  hideStatus,
}: {
  thread: ThreadResponse;
  isFirstThread: boolean;
  hideStatus?: boolean;
}) => {
  const { card, repliesContainer, repliesButton } = useMessengerAdvisorThreadCardStyles();

  const [isExpanded, setIsExpanded] = useState(isFirstThread);

  const replies = thread.messages.slice(2);

  return (
    <>
      {thread.messages.length > 1 ? (
        <x.div data-testid="advisor-thread-card" data-messenger-card-thread-id={thread.id} {...card}>
          <MessengerMessage message={thread.messages[1]} isLastMessage={replies.length === 0} threadId={thread.id} />
          {replies.length > 0 && !isExpanded && (
            <Button
              marginRight="auto"
              variant="ghost"
              size="small"
              onClick={() => setIsExpanded(true)}
              startIcon={<ChevronDown />}
              {...repliesButton}
            >
              View {replies.length} {replies.length > 1 ? "replies" : "reply"}
            </Button>
          )}
          {replies.length > 0 && isExpanded && (
            <Collapsible open>
              <x.div {...repliesContainer}>
                {replies.map((message, index) => (
                  <MessengerMessage
                    key={message.id}
                    message={message}
                    isLastMessage={index === replies.length - 1}
                    threadId={thread.id}
                  />
                ))}
              </x.div>
            </Collapsible>
          )}

          {replies.length > 0 && isExpanded && (
            <Button
              marginRight="auto"
              variant="ghost"
              size="small"
              onClick={() => setIsExpanded(false)}
              startIcon={<ChevronUp />}
              {...repliesButton}
            >
              Hide {replies.length > 1 ? "replies" : "reply"}
            </Button>
          )}
        </x.div>
      ) : (
        <MessagedExperts thread={thread} hideStatus={hideStatus} />
      )}
    </>
  );
};

const MessagedExperts = ({ thread, hideStatus }: { thread: ThreadResponse; hideStatus?: boolean }) => {
  const { card } = useMessengerAdvisorThreadCardStyles();
  const { container } = useMessengerMessageStyles();
  const { experts } = useMessengerContext();

  const expert = useMemo(() => experts.find((e) => e.id === thread.advisor.id), [experts, thread.advisor.id]);

  return (
    <x.div {...card}>
      <x.div {...container}>
        <MessageHeader
          id={thread.advisor.id}
          name={thread.advisor.name}
          relevantCompany={expert?.relevantCompany}
          relevantPosition={expert?.relevantPosition}
          status={hideStatus ? undefined : thread.status}
          avatarColor={thread.advisor.avatarColor ?? "base02"}
          doubledBlinded={expert?.interaction.doubleBlinded ?? false}
        />
      </x.div>
    </x.div>
  );
};

const MessengerMessage = ({
  message,
  isLastMessage,
  threadId,
}: {
  message: MessageResponse;
  isLastMessage: boolean;
  threadId: string;
}) => {
  const { experts, onSubmitReply, onReplyCreated } = useMessengerContext();
  const { container, rightInfo } = useMessengerMessageStyles();
  const isAdvisor = message.sender.role === ParticipantRole.Advisor;

  const replyRef = useRef<HTMLTextAreaElement>(null);
  const [showReply, setShowReply] = useState(false);

  const [isExpanded, setExpanded] = useState(false);
  const { isMobile } = useCheckScreen();

  const { sanitizedContent, content, isContentSanitized } = useMemo(() => sanitizeMessage(message), [message]);

  const messageContent = isExpanded ? content : sanitizedContent;

  const focusReply = useCallback(() => {
    if (!replyRef.current) return;
    replyRef.current.focus();
  }, []);

  useEffect(() => focusReply(), [focusReply, showReply]);

  const onToggleExpandedMode = () => setExpanded(!isExpanded);
  const clickReply = () => {
    setShowReply(true);
    focusReply();
  };

  const cancelReply = () => {
    setShowReply(false);
  };

  const expert = useMemo(() => experts.find((e) => e.id === message.sender.id), [experts, message.sender.id]);

  const isClient = message.sender.role === "client";

  return (
    <>
      <x.div data-testid="thread-message" {...container}>
        <MessageHeader
          id={message.sender.id}
          name={message.sender.name}
          relevantCompany={expert?.relevantCompany}
          relevantPosition={expert?.relevantPosition}
          isClient={isClient}
          avatarColor={message.sender.avatarColor ?? "base02"}
          doubledBlinded={expert?.interaction.doubleBlinded ?? false}
          complianceReviewStatus={message.complianceReviewStatus}
          showUnreadDot={
            (!message.isRead && !isClient) ||
            message.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING
          }
        />
        <x.div {...rightInfo}>
          {!message.obfuscated && isLastMessage && isAdvisor && (
            <Button
              marginRight="auto"
              variant="ghost"
              size="small"
              startIcon={<Reply />}
              onClick={clickReply}
              data-testid="reply-message-button"
            >
              Reply
            </Button>
          )}
          <Typography variant="body-small" color="secondary">
            <FormattedDateTime prefix={undefined} date={message.createdAt} format="E d, h:mmaaa" />
          </Typography>
        </x.div>
      </x.div>
      <Typography
        data-testid={`message-content-${message.id}`}
        style={{
          filter: message.obfuscated ? "blur(2.5px)" : "none",
          userSelect: message.obfuscated ? "none" : "auto",
        }}
        dangerouslySetInnerHTML={{ __html: messageContent }}
      />
      {message.attachments.length > 0 && <AttachmentList attachments={message.attachments} />}
      {isContentSanitized && (
        <Link onClick={() => onToggleExpandedMode()}>
          <Typography variant={isMobile ? "body-large-em" : "body-em"}>See {isExpanded ? "Less" : "More"}</Typography>
        </Link>
      )}

      {showReply && (
        <MessengerReplyField
          ref={replyRef}
          closeReply={cancelReply}
          threadId={threadId}
          onSubmitReply={onSubmitReply}
          onReplyCreated={onReplyCreated}
        />
      )}
    </>
  );
};

const MessageHeader = ({
  id,
  name,
  relevantPosition,
  relevantCompany,
  isClient,
  status,
  avatarColor,
  doubledBlinded,
  complianceReviewStatus,
  showUnreadDot,
}: {
  id: string;
  name?: string;
  relevantPosition: string;
  relevantCompany: string;
  isClient?: boolean;
  status?: ResponseStatusType;
  avatarColor?: any;
  doubledBlinded: boolean;
  complianceReviewStatus?: ComplianceReviewStatus;
  showUnreadDot?: boolean;
}) => {
  const { onSelectExpert } = useMessengerContext();
  const { senderName, statusLabel } = useMessageHeaderStyles({ status });

  return (
    <>
      {doubledBlinded ? (
        <Avatar color={avatarColor}>
          <Expert />
        </Avatar>
      ) : (
        <Avatar text={name ?? "Expert"} color={avatarColor} />
      )}

      <x.div>
        <x.div>
          <Typography variant="body-em" component="span" onClick={() => onSelectExpert(id)} {...senderName}>
            {name}
            {status && ` • `} {showUnreadDot && <UnreadDot marginRight={0} />}
          </Typography>
          {status && (
            <Typography component="span" {...statusLabel}>
              {status}
            </Typography>
          )}
        </x.div>
        {!isClient && (
          <Typography variant="body-small" color="secondary">
            {relevantCompany} - {relevantPosition}
          </Typography>
        )}
      </x.div>

      <MessengerComplianceReviewApprovalStatus status={complianceReviewStatus} />
    </>
  );
};

const AttachmentList = ({ attachments }: { attachments: AttachmentResponse[] }) => {
  const { attachmentsListContainer } = useMessengerAttachmentStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const showCollapseButton = attachments.length > 2;

  return (
    <>
      {(isExpanded || !showCollapseButton) && (
        <x.div {...attachmentsListContainer}>
          {attachments.map((attachment: AttachmentResponse) => (
            <Attachment attachment={attachment} key={`attachment-${attachment.id}`} />
          ))}
        </x.div>
      )}
      {showCollapseButton && (
        <div>
          <Button
            variant="ghost"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
            startIcon={<AttachmentIcon />}
          >
            {isExpanded ? `Hide ${attachments.length} attachments` : `Show ${attachments.length} attachments`}
          </Button>
        </div>
      )}
    </>
  );
};

const Attachment = ({ attachment }: { attachment: AttachmentResponse }) => {
  const { projectToken } = useMessengerContext();
  const { attachmentContainer } = useMessengerAttachmentStyles();
  const { showErrorBanner, showSuccessBanner } = useNotifications();

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => showSuccessBanner("Attachment download successful"))
      .catch(() =>
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead")
      );
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <x.div {...attachmentContainer} data-testid="attachment-card">
      <Icon>
        <AttachmentIcon />
      </Icon>
      <Typography color="strong">{attachment.filename}</Typography>
      <Typography variant="body-small" color="assistive">
        {attachment.type} • {attachment.fileSize}
      </Typography>
      <IconButtonWithAccess
        accessControl={{ allowedPermissions: ["follow-up-messages"] }}
        variant="basic"
        onClick={onClickDownload}
        color="secondary"
      >
        <Download />
      </IconButtonWithAccess>
    </x.div>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);
