import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "query-utils";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { contentService } from "services/content";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import {
  CreateWatchlistPayload,
  DeleteWatchlistTriggersPayload,
  UpdateWatchlistSettingsPayload,
  FetchWatchlistPayload,
  UpdateWatchlistTriggersPayload,
} from "./types";
import { getInitialWatchlistData, getOrderedWatchlists } from "hooks/useWatchlist/utils";

const useWatchlist = () => {
  const [selectedWatchlistId, setSelectedWatchlistId] = useState<string>();
  const queryClient = useQueryClient();
  const user = useCurrentUser();
  const displayWatchlist = !!user?.alphaNowEnabled && !user?.consultancyClient;

  const { data: watchlistData = [] } = useQuery(
    ["fetchWatchlistData", "fetchWatchlists"],
    () => contentService.fetchWatchlists(),
    {
      refetchOnMount: false,
      enabled: displayWatchlist,
    }
  );

  // TODO [RD1-92]: Remove ordering when handled
  const availableWatchlists = getOrderedWatchlists(watchlistData);

  useDeepCompareEffect(() => {
    availableWatchlists.length > 0 && setSelectedWatchlistId(availableWatchlists[0].id);
  }, [watchlistData]);

  const createWatchlist = async (createWatchlistData: CreateWatchlistPayload | {} = {}) => {
    const newWatchlist = await contentService.createWatchlist({
      ...getInitialWatchlistData(availableWatchlists),
      ...createWatchlistData,
    } as any);
    queryClient.invalidateQueries(["fetchWatchlistData", "fetchWatchlists"]);
    return newWatchlist;
  };

  const updateWatchlist = useMutation(
    ({ watchlistId, watchlistSettings }: { watchlistId: string; watchlistSettings: UpdateWatchlistSettingsPayload }) =>
      contentService.updateWatchlist(watchlistId, watchlistSettings),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetchWatchlistData"]);
      },
    }
  );

  const useFetchWatchlist = ({ watchlistId, sort, filter }: FetchWatchlistPayload) => {
    return useQuery(
      ["fetchWatchlistData", "getWatchlist", watchlistId],
      async () => contentService.getWatchlist({ watchlistId, sort, filter }),
      {
        enabled: !!watchlistId,
      }
    );
  };

  const updateWatchlistTriggers = useMutation(
    ({ watchlistId, watchlistTriggers }: UpdateWatchlistTriggersPayload) =>
      contentService.updateWatchlistTriggers(watchlistId, watchlistTriggers),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetchWatchlistData"]);
      },
    }
  );

  const deleteWatchlist = useMutation((watchlistId: string) => contentService.deleteWatchlist(watchlistId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchWatchlistData", "fetchWatchlists"]);
    },
  });

  const deleteWatchlistTriggers = useMutation(
    ({ watchlistId, triggerIds }: DeleteWatchlistTriggersPayload) =>
      contentService.deleteWatchlistTriggers(watchlistId, triggerIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetchWatchlistData"]);
      },
    }
  );

  return {
    availableWatchlists: availableWatchlists ?? [],
    selectedWatchlistId,
    setSelectedWatchlistId,
    createWatchlist,
    updateWatchlistTriggers: updateWatchlistTriggers.mutateAsync,
    deleteWatchlistTriggers: deleteWatchlistTriggers.mutateAsync,
    updateWatchlist: updateWatchlist.mutateAsync,
    deleteWatchlist: deleteWatchlist.mutateAsync,
    useFetchWatchlist,
  };
};

export default useWatchlist;
