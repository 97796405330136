import React, { FC, useState, useEffect, useRef, ReactElement } from "react";
import { Document, Page, pdfjs, PDFPageProxy } from "react-pdf";

import { LANDSCAPE_SCALING_FACTOR, PORTRAIT_SCALING_FACTOR } from "./constants";

import * as S from "./PdfViewer.styled";

pdfjs.GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

export const DataTestIds = {
  PDF_VIEWER: "pdf-viewer",
};

type PdfViewerProps = {
  isLoading: boolean;
  loading: ReactElement;
  onError: () => void;
  data?: Blob;
};

const PdfViewer: FC<PdfViewerProps> = ({ data, isLoading, onError, loading }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [hasWhiteBackground, setHasWhiteBackground] = useState<boolean>(true);
  const [pageScales, setPageScales] = useState<number[]>([]);

  const pagesContainerRef = useRef<HTMLDivElement>(null);

  const handleDocumentLoadSuccess = ({ numPages: numberOfPages }: { numPages: number }) => {
    setHasWhiteBackground(false);
    setNumPages(numberOfPages);
    setPageScales(new Array(numPages).fill(1));
  };

  const handleDocumentLoadError = (error: Error) => {
    console.error("Error loading document:", error);
    setHasWhiteBackground(true);
    onError();
  };

  const updatePageScale = (page: PDFPageProxy) => {
    const { width: pageWidth, height: pageHeight } = page;
    const isLandscape = pageWidth > pageHeight;
    const newScales = [...pageScales];
    newScales[page.pageNumber - 1] = Math.min(
      (pagesContainerRef.current?.getBoundingClientRect().width ?? pageWidth) / pageWidth,
      isLandscape ? LANDSCAPE_SCALING_FACTOR : PORTRAIT_SCALING_FACTOR
    );
    setPageScales(newScales);
  };

  useEffect(() => {
    isLoading && setHasWhiteBackground(true);
  }, [isLoading]);

  return (
    <S.DocumentWrapper hasWhiteBackground={hasWhiteBackground} data-testid={DataTestIds.PDF_VIEWER}>
      <Document
        file={data}
        onLoadSuccess={handleDocumentLoadSuccess}
        onLoadError={handleDocumentLoadError}
        loading={loading}
      >
        <S.PagesContainer ref={pagesContainerRef}>
          {Array.from({ length: numPages }, (_, index) => (
            <S.PageWrapper key={`page_${index + 1}`}>
              <Page
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer
                scale={pageScales[index]}
                onLoadSuccess={updatePageScale}
              />
            </S.PageWrapper>
          ))}
        </S.PagesContainer>
      </Document>
    </S.DocumentWrapper>
  );
};

export default PdfViewer;
