import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Alert,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Company, Copy, Delete, Expert } from "@alphasights/alphadesign-icons";
import { useStyles } from "../SynthesisModuleContent.styles";
import {
  HitAction,
  SynthesisAnswer,
  SynthesisModuleRevision,
  SynthesisModule,
  SynthesisOverview,
  KpcItem,
  NPSCompany,
} from "@alphasights/portal-api-client";
import { copyTextToClipboard } from "components/FloatingActions";
import _ from "lodash";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { ViewInSystem } from "components/DebugSupport";
import { EditableInput, ExpertQuote, RevisionToggler, TopicPill } from "../components";
import { question } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { useModal } from "@alphasights/ads-community-hooks";
import EditDocumentModal from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal";
import { DocumentAttributes } from "services/thirdPartyDocumentsService";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import ThirdPartyDocUploadModal, {
  SelectedThirdPartyFileToUpload,
} from "views/DeliverablesView/ThirdPartyDocUploadModal";
import { useQueryClient } from "query-utils";
import { useUpdateDocumentAttributes } from "views/DeliverablesView/ThirdPartyDocumentPage/PageHeader/useUpdateDocumentAttributes";
import { createFormPropsFromDocument } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/utils";
import {
  getCompanyName,
  getExpertRole,
  isFromThirdPartyDocument,
  mapThirdPartyInteractionToSelectedThirdPartyFileToUpload,
} from "./helpers";

export const QuestionModuleContent = () => {
  const { selectedModule, revision, mode, setMode } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return (
    <QuestionModuleContentSwitch revision={revision} mode={mode} setMode={setMode} selectedModule={selectedModule} />
  );
};

const QuestionModuleContentSwitch = ({
  revision,
  mode,
  setMode,
  selectedModule,
}: {
  revision: SynthesisModuleRevision;
  mode: Mode;
  setMode: (mode: Mode) => void;
  selectedModule: SynthesisModule;
}) => {
  if (revision.status === "FAILED")
    return <FailedModule revision={revision} setMode={setMode} module={selectedModule} />;

  if (revision.status === "COMPLETED")
    return (
      <CompleteModule
        revision={revision}
        mode={mode}
        setMode={setMode}
        visibility={selectedModule.visibility}
        module={selectedModule}
      />
    );

  return <ProcessingModuleContent revision={revision} />;
};

const FailedModule = ({
  revision,
  setMode,
  module,
}: {
  revision: SynthesisModuleRevision;
  setMode: (mode: Mode) => void;
  module: SynthesisModule;
}) => {
  const { saveModuleChanges, editOperations, newSynthesisModule } = useProjectSynthesisContext();
  const hasError = useMemo(() => newSynthesisModule.questionHasError(question(revision?.contents).question), [
    newSynthesisModule,
    revision,
  ]);

  const submitQuestion = useCallback(
    (question: string) => {
      if (hasError) return;
      saveModuleChanges(
        {
          ...module,
          aiRegenerationEnabled: true,
          revisions: module.revisions.map((rev) =>
            rev.id !== revision.id ? rev : { ...rev, contents: { ...rev.contents, question } }
          ),
        },
        { originator: "input-enter-pressed", promptShown: false }
      );
    },
    [hasError, saveModuleChanges, module, revision.id]
  );

  useEffect(() => setMode(Mode.EDIT), [revision, setMode]);

  return (
    <x.div px={"24px"} py={"16px"} display={"flex"} flexDirection={"column"} gap={"24px"}>
      <x.div display={"flex"} alignItems={"center"} gap="15px">
        <x.div flexGrow={1}>
          <EditableInputWrapper data-testid="question-failed-edit">
            <EditableInput
              input={question(revision?.contents).question}
              onChange={(e) =>
                editOperations.updateQuestion(e.currentTarget?.value, module.revisions.indexOf(revision))
              }
              onEnterPressed={(e) => submitQuestion(e.currentTarget.value)}
              errorMessage={hasError ? "Input too brief. Please rephrase the question and try again" : undefined}
            />
          </EditableInputWrapper>
        </x.div>
        <RevisionToggler />
      </x.div>
      <Alert w="100%" variant="danger" data-testid="failed-module-banner">
        We were unable to generate insights for this question. Please try rephrasing your question.
      </Alert>
    </x.div>
  );
};

const CompleteModule = ({
  revision,
  mode,
  setMode,
  visibility,
  module,
}: {
  revision: SynthesisModuleRevision;
  mode: Mode;
  setMode: (mode: Mode) => void;
  visibility: string;
  module: SynthesisModule;
}) => {
  const { overviewWrapper, overviewTitle, overviewContent, answersWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });

  const failed = useMemo(() => revision.status === "FAILED", [revision]);

  useEffect(() => {
    if (failed) {
      setMode(Mode.EDIT);
    }
  }, [failed, setMode]);

  return (
    <x.div data-testid={`revision-${revision.revision}-content`}>
      <AssignAngleTypeBanner />
      <x.div {...overviewWrapper}>
        <x.div {...overviewTitle}>
          <Question question={question(revision?.contents).question} />
          {mode === Mode.VIEW && <RevisionToggler />}
        </x.div>

        <x.div data-testid="overview-list" {...overviewContent}>
          {question(revision?.contents)?.overviews?.map((overviewTopic) => (
            <OverviewItem
              key={overviewTopic.id}
              overviewTopic={overviewTopic}
              expertCount={revision.expertCount ?? 0}
            />
          ))}
        </x.div>
      </x.div>

      <x.div {...answersWrapper}>
        {mode === Mode.VIEW && question(revision?.contents).answers?.length === 0 ? (
          <EmptyAnswers />
        ) : (
          <>
            {question(revision?.contents).answers?.map((answer) => (
              <ExpertAnswer key={answer.id} answer={answer} visibility={visibility} revision={revision} />
            ))}
          </>
        )}
      </x.div>
    </x.div>
  );
};

const Question = ({ question }: { question: string }) => {
  const { mode, selectedRevisionIdx, editOperations, newSynthesisModule } = useProjectSynthesisContext();
  const user = useCurrentUser();

  const onEditQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
    editOperations.updateQuestion(e.target.value, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT && user?.enableAiInteractivity) {
    return (
      <EditableInputWrapper data-testid="question-edit">
        <EditableInput
          input={question}
          onChange={onEditQuestion}
          errorMessage={
            newSynthesisModule.questionHasError(question)
              ? "Input too brief. Please rephrase the question and try again"
              : undefined
          }
        />
      </EditableInputWrapper>
    );
  }

  return (
    <Typography variant="h3" shouldCapitalizeHeadline={false}>
      {question}
    </Typography>
  );
};

const EditableInputWrapper = styled.div`
  width: 100%;
  textarea {
    font-size: 1.125rem;
    font-weight: 600;
  }
  display: flex;
`;

const OverviewItem = ({ overviewTopic, expertCount }: { overviewTopic: SynthesisOverview; expertCount: number }) => {
  const { spacing } = useThemeTokens();
  const { overviewItem, borderedBox, editHeader } = useStyles();
  const { mode, editOperations, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();

  const onEditOverview = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedOverviewTopic = { ...overviewTopic, summary: e.target.value };
    editOperations.updateOverview(updatedOverviewTopic, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div {...borderedBox} py={spacing.inner.base04} data-testid={`overview-edit-${overviewTopic.id}`}>
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base04}>
            <TopicPill title={overviewTopic.title} />
            <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
          </x.div>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => editOperations.deleteOverview(overviewTopic, selectedRevisionIdx)}
            testId={`delete-overview-${overviewTopic.id}`}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </x.div>
        <EditableInput input={overviewTopic.summary} onChange={onEditOverview} />
      </x.div>
    );
  }

  return (
    <x.div {...overviewItem} data-testid={"overview-item"} key={overviewTopic.id}>
      <x.div>
        {overviewTopic.title && <TopicPill title={overviewTopic.title} />}
        <Typography component="span">{overviewTopic.summary}</Typography>
      </x.div>
      <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
    </x.div>
  );
};

const ExpertCounter = ({ relevantCount, totalExpertCount }: { relevantCount: number; totalExpertCount: number }) => (
  <Typography data-testid="expert-counter" color="assistive" component="span" flexShrink="0">
    {(totalExpertCount ?? 0) > 0 ? `${relevantCount}/${totalExpertCount} experts` : "0 experts"}
  </Typography>
);

const ExpertAnswer = ({
  revision,
  answer,
  visibility,
}: {
  revision: SynthesisModuleRevision;
  answer: SynthesisAnswer;
  visibility: string;
}) => {
  const { spacing } = useThemeTokens();
  const { borderedBox, companyLogoWrapper, answerTitle, answerTitleWrapper } = useStyles();
  const {
    mode,
    editOperations,
    selectedRevisionIdx,
    saveInProgress,
    synthesisLogHit,
    selectedModule,
    thirdPartyDocuments,
  } = useProjectSynthesisContext();
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();
  const { project } = useCurrentProjectContext();
  const projectToken = project!.token;
  const document = thirdPartyDocuments.find((doc) => doc.id === answer.expert.thirdPartyDocumentId);
  const companyName = getCompanyName(answer, document);
  const expertRole = getExpertRole(answer, document);
  const isThirdParty = isFromThirdPartyDocument(answer);
  const { onSaveEdits } = useUpdateDocumentAttributes({
    interaction: document,
    projectToken,
  });

  const handleSaveEdits = (attributes: DocumentAttributes) => {
    try {
      document && onSaveEdits({ documentId: document.id, attributes });
    } catch (error) {
      console.error("Unable to save edits", error);
    }
    onCloseEditModal();
  };

  const handleCopy = () => {
    answer && copyTextToClipboard(formatSynthesisAnswer(answer));
    synthesisLogHit({
      action: HitAction.projectSynthesisCopy,
      details: {
        revision: revision?.revision,
        question: question(revision?.contents)?.question,
        contentCopiedType: "answer",
        copiedAnswer: answer.text,
        moduleType: selectedModule?.contentType,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  const ActionIcon = () =>
    mode === Mode.EDIT ? (
      <IconButton
        size="small"
        variant="ghost"
        onClick={() => editOperations.deleteAnswer(answer, selectedRevisionIdx)}
        testId={`delete-answer-${answer.id}`}
        disabled={saveInProgress}
      >
        <Delete />
      </IconButton>
    ) : visibility === "ADDED" ? (
      <Tooltip title="Copy insight">
        <HideableIconButton size="small" variant="ghost" onClick={() => handleCopy()}>
          <Copy data-testid={`copy-answer-${answer.id}`} />
        </HideableIconButton>
      </Tooltip>
    ) : (
      <></>
    );

  return (
    <x.div id="answer-block" {...borderedBox}>
      <x.div {...answerTitleWrapper}>
        <x.div {...answerTitle} data-testid="company-name">
          <x.div data-testid="company-logo" {...companyLogoWrapper}>
            {answer.expert.companyLogo ? (
              <img src={answer.expert.companyLogo} alt={companyName} height={spacing.inner.base03} />
            ) : (
              <Icon>
                <Company />
              </Icon>
            )}
          </x.div>
          <Typography color="assistive" component="span" variant="body-small">
            {companyName} - {expertRole}
          </Typography>
          {isThirdParty && document && (
            <>
              <Typography color="assistive" component="span" variant="body-small">
                |
              </Typography>
              <Link component="button" size="small" onClick={onOpenEditModal}>
                Add expert information
              </Link>
              {isEditModalVisible && (
                <EditDocumentModal
                  onClickCancel={onCloseEditModal}
                  onClickSave={handleSaveEdits}
                  {...createFormPropsFromDocument(_.omit(document, ["scheduledCallTime", "origin"]))}
                />
              )}
            </>
          )}
          {_.uniq(answer.quotes.flatMap((q) => q.interactionId)).map((interactionId) => (
            <ViewInSystem interactionId={interactionId} />
          ))}
        </x.div>
        <ActionIcon />
      </x.div>

      <ExpertAnswerText answer={answer} />

      {answer.quotes.map((quote) => (
        <ExpertQuote
          key={quote.id}
          quote={quote}
          mode={mode}
          onEditQuote={(updatedQuote) => {
            editOperations.updateQuote(updatedQuote, selectedRevisionIdx);
          }}
          onDeleteQuote={() => {
            editOperations.deleteQuote(quote, selectedRevisionIdx);
          }}
          onNavigateToTranscript={() => {
            synthesisLogHit({
              action: HitAction.projectSynthesisViewInTranscript,
              details: {
                revision: revision?.revision,
                question: question(revision?.contents).question,
                quote: quote.text,
                moduleType: selectedModule?.contentType,
              },
              references: {
                moduleId: selectedModule?.id,
              },
            });
          }}
          saveInProgress={saveInProgress}
        />
      ))}
    </x.div>
  );
};

const EmptyAnswers = () => {
  const { emptyAnswersWrapper, emptyIconWrapper } = useStyles();

  return (
    <x.div {...emptyAnswersWrapper} data-testid="empty-answers">
      <x.div {...emptyIconWrapper}>
        <Icon size="small" color="secondary">
          <Expert />
        </Icon>
      </x.div>
      <Typography variant="body-large-em" color="secondary">
        No Experts Insights
      </Typography>
      <Typography color="secondary">Refresh to generate expert insights for this module</Typography>
    </x.div>
  );
};

const ExpertAnswerText = ({ answer }: { answer: SynthesisAnswer }) => {
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onEditAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedAnswer = { ...answer, text: e.target.value };
    editOperations.updateAnswer(updatedAnswer, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div data-testid={`answer-edit-${answer.id}`}>
        <EditableInput input={answer.text} onChange={onEditAnswer} />
      </x.div>
    );
  }

  return (
    <Typography data-testid="expert-answer" component="div" variant="body-small-spaced">
      {answer.text}
    </Typography>
  );
};

const ProcessingModuleContent = ({ revision }: { revision?: SynthesisModuleRevision }) => {
  const { borderedBox } = useStyles();

  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        {question(revision?.contents)?.question ? (
          <Question question={question(revision?.contents).question} />
        ) : (
          <Skeleton variant="noMargin" width="300px" />
        )}
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>

      <x.div {...borderedBox}>
        <Skeleton variant="noMargin" width="300px" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};

const AssignAngleTypeBanner = () => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedThirdPartyFileToUpload[]>([]);
  const [bannerVisible, setBannerVisible] = useState(true);
  const { thirdPartyDocuments } = useProjectSynthesisContext();
  const { isVisible: isUploadModalVisible, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useModal();
  const queryClient = useQueryClient();

  const documentsWithoutAngle = useMemo(() => thirdPartyDocuments.filter((doc) => !doc.attribute.attributes.angle), [
    thirdPartyDocuments,
  ]);

  const files = useMemo(() => documentsWithoutAngle.map(mapThirdPartyInteractionToSelectedThirdPartyFileToUpload), [
    documentsWithoutAngle,
  ]);

  const handleCloseUploadModal = () => {
    setSelectedFiles([]);
    queryClient.invalidateQueries(["fetchThirdPartyDocuments"]);
    onCloseUploadModal();
  };

  const handleOpenUploadModal = () => {
    setSelectedFiles(files);
    onOpenUploadModal();
  };

  return (
    <>
      {bannerVisible && documentsWithoutAngle.length > 0 && (
        <StyledAlert size="small" buttonsPosition="right" onClose={() => setBannerVisible(false)} w="100%">
          <span data-testid="assign-angle-type-banner">
            Please assign expert angle type to synthesise uploaded documents.{" "}
            <Link component="button" size="small" onClick={handleOpenUploadModal}>
              View Uploaded Documents
            </Link>
          </span>
        </StyledAlert>
      )}
      {isUploadModalVisible && (
        <ThirdPartyDocUploadModal
          files={selectedFiles}
          setFiles={setSelectedFiles}
          onClose={handleCloseUploadModal}
          editMode={true}
        />
      )}
    </>
  );
};

const StyledAlert = styled(Alert)`
  span:has(> [data-testid="assign-angle-type-banner"]) {
    width: auto;
    flex-grow: 1;
  }
`;

const HideableIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  #answer-block:hover & {
    opacity: 1;
  }
`;

export const formatSynthesisAnswer = (answer: SynthesisAnswer): string => {
  let formattedString = `
  <p style="font-size: 13px;">
    <span style="color: #666B7A;">${answer.expert.companyName}</span> -
    <span style="color: #666B7A;">${answer.expert.role}</span>
  </p>
  <br>
  <p style="font-size: 13px;">${answer.text}</p>
  <br>`;

  answer.quotes.forEach((quotes) => {
    formattedString += `
    `;
    if (quotes.topic) {
      formattedString += `<span style="font-weight: bold; font-size: 13px; ">${quotes.topic}</span>:`;
    }
    formattedString += `<i>"${quotes.text}"</i><br>`;
  });

  return formattedString + `<br>`;
};

export const formatSynthesisKpc = (item: KpcItem): string => {
  return `
  <div>
    <p style="margin-bottom: 12px; font-weight: bold;">${item.name}</p>
    <p style="margin-bottom: 12px;">${item.summary}</p>
    ${item.quotes
      .map((quote) => {
        const q = "<span style='font-style: italic'>\"" + quote.text + '"</span>';
        const author =
          "<span style='font-style: italic; color: #666b7a'>" + quote.companyName + " - " + quote.role + "</span>";
        return "<p>• " + q + " " + author + "</p>";
      })
      .join("\n")}
  </div>
  <br>
  `;
};

export const formatSynthesisNps = (company: NPSCompany): string => {
  const quotes = company.transcriptAnalyses.flatMap((ta) => ta.quotes);

  return `
    <div style="margin-bottom: 12px; font-weight: bold;">${company.companyName}</div>
    <div style="margin-bottom: 12px;">${company.summary}</div>
    <br>
    ${quotes
      .map((quote) => {
        const q = "<span style='font-style: italic'>\"" + quote.text + '"</span>';
        const author =
          "<span style='font-style: italic; color: #666b7a'>" + quote.companyName + " - " + quote.role + "</span>";
        return "<p>• " + q + " " + author + "</p>";
      })
      .join("\n")}
    <br>
    <br>
  `;
};
