import React, { useRef } from "react";
import { GenerativeAi } from "@alphasights/alphadesign-icons";
import { FloatingActions, useHelperActions } from "components/FloatingActions";
import { getAuthor } from "views/DeliverablesView/ThirdPartyDocumentCard/utils";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import {
  COPY_QUOTE_MESSAGE,
  COPY_SUMMARY_MESSAGE,
  ERROR_OCCURRED_MESSAGE,
  ERROR_PROCESSING_QUESTION_MESSAGE,
} from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/consts";
import { ProcessingStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";
import _ from "lodash";
import { ThirdPartyInteraction } from "types";
import { DocumentQuestion } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/types";
import {
  AnswerContainer,
  CompleteQuestionContainer,
  StyledErrorAlert,
  StyledIcon,
  StyledQuestion,
  StyledQuote,
} from "./CompleteQuestion.styled";

const DataTestIds = {
  copyQuestionSection: "floating-actions-question",
  copyAnswerSection: "floating-actions-answer",
  copyQuoteSection: "floating-actions-quote",
};

const CompleteQuestion = ({
  question,
  interaction,
}: {
  question: DocumentQuestion;
  interaction: ThirdPartyInteraction;
}) => {
  const wrapperRef = useRef(null);
  const { copyNoFormatTextAction, copyQuoteAction, copyRefAction } = useHelperActions();
  const author = getAuthor(interaction);

  return (
    <CompleteQuestionContainer data-testid={`question-${question.id}`} ref={wrapperRef}>
      <FloatingActions
        data-testid={DataTestIds.copyQuestionSection}
        highlightOnHover={false}
        actions={[copyRefAction(wrapperRef)]}
      >
        <StyledQuestion data-testid={`question-text-${question.id}`} variant="body-em">
          {question.question}
        </StyledQuestion>
        {question.answer && (
          <FloatingActions
            data-testid={DataTestIds.copyAnswerSection}
            highlightOnHover={true}
            actions={_.compact([copyNoFormatTextAction(question.answer!!, COPY_SUMMARY_MESSAGE)])}
          >
            <AnswerContainer>
              <StyledIcon size="large">
                <GenerativeAi />
              </StyledIcon>
              <EmphasisTypography
                component={"span"}
                data-testid={`question-answer-${question.id}`}
                id={`question-answer-${question.id}`}
              >
                {question.answer}
              </EmphasisTypography>
            </AnswerContainer>
          </FloatingActions>
        )}
        {question.quotes?.map((q) => (
          <div key={q.transcriptIndex}>
            <FloatingActions
              data-testid={DataTestIds.copyQuoteSection}
              highlightOnHover={true}
              actions={_.compact([copyQuoteAction(q.value, author, COPY_QUOTE_MESSAGE)])}
            >
              <StyledQuote component="div" data-testid={`question-quote-${question.id}`}>
                "{q.value}"
              </StyledQuote>
            </FloatingActions>
          </div>
        ))}
      </FloatingActions>
      {question.status === ProcessingStatus.failed && (
        <StyledErrorAlert variant="danger" data-testid={`failed-question-${question.id}`}>
          <b>{ERROR_OCCURRED_MESSAGE}</b> {ERROR_PROCESSING_QUESTION_MESSAGE}
        </StyledErrorAlert>
      )}
    </CompleteQuestionContainer>
  );
};

export { CompleteQuestion as default, DataTestIds };
