import { LinkProps } from "@alphasights/alphadesign-components";
import { Project } from "@alphasights/alphadesign-icons";
import { ProjectsMenu, useAccessControl, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser, useMyOrderedProjects } from "@alphasights/portal-auth-react";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { noop, partition } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "router-utils";
import { SidebarOption } from "../SidebarOption";
import * as S from "components/SidebarNav/SidebarNav.styled";
import { HitAction, HitOrigin, LeanProject } from "@alphasights/portal-api-client";

interface ProjectsOptionProps {
  projectsPath: string;
}
interface CustomLinkProps extends LinkProps {
  projectToken?: string;
}

export const ProjectsOption = ({ projectsPath }: ProjectsOptionProps) => {
  const { logHit } = useTrackUserAction();
  const projects = useMyOrderedProjects();
  const currentUser = useCurrentUser();
  const ref = useRef<HTMLDivElement>(null);

  const isProjectLaunchEnabled = useMemo(() => currentUser?.projectLaunchEnabled ?? false, [currentUser]);
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const [pastProjects, activeProjects] = useMemo(() => partition(projects, (project) => project.inactive), [projects]);
  const hasAnyProject = useMemo(() => (projects ?? []).length > 0, [projects]);
  const currentPathname = useLocation().pathname;
  const isMyAlphasights = new RegExp(myAlphaSightsBaseUrl).test(currentPathname);
  const selectedProjectToken = isMyAlphasights ? currentPathname.split("/").pop() : currentPathname.split("/").at(1);

  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);

  const getPastProjectRedirectPath = useCallback((project: LeanProject) => {
    return `/${project.token}/experts`;
  }, []);

  const CustomLink = ({ href, children, onClick: onClickInput, ...props }: CustomLinkProps) => {
    const navigate = useNavigate();
    const projectToken = href?.replace("/projects/", "");

    const isProjectInactive = pastProjects.some((p) => p.token === projectToken);

    const to = isProjectInactive
      ? getPastProjectRedirectPath(pastProjects.find((p) => p.token === projectToken)!)
      : `/${myAlphaSightsBaseUrl}${href}`;

    const url = href === "/project-launch" ? "/project-launch" : to;
    const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      onClickInput?.(e);
      navigate(url);
      closeMenu();
    };

    return (
      <a href={url} onClick={handleNavigation} {...props}>
        {children}
      </a>
    );
  };

  const onLaunchProjectClick = () => {
    logHit({
      origin: HitOrigin.projectsMenu,
      action: HitAction.projectLaunchMenuClicked,
    });
  };

  return (
    <div ref={ref} onMouseEnter={!isMyAlphasights ? openMenu : noop} onMouseLeave={closeMenu}>
      <SidebarOption id="projects" to={projectsPath} permission="view_projects" label="Projects" icon={<Project />} />
      {hasAnyProject && hasProjectsPermission && open && (
        <S.Popover open anchorEl={ref?.current ?? undefined} placement="right-start" closeOnMouseLeave>
          <ProjectsMenu
            isLoading={!hasAnyProject}
            projects={activeProjects}
            pastProjects={pastProjects}
            LinkComponent={CustomLink}
            isProjectLaunchEnabled={isProjectLaunchEnabled}
            selectedProjectToken={selectedProjectToken}
            onLaunchProjectClick={onLaunchProjectClick}
            defaultOpened={true}
          />
        </S.Popover>
      )}
    </div>
  );
};
