import React, { FC } from "react";
import { Button, Modal, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import { MODAL_TITLE, MODAL_MESSAGE, CONFIRM, BACK, MODAL_WIDTH } from "./constants";

const DataTestIds = {
  confirmButton: "exit-modal-confirm-button",
  cancelButton: "exit-modal-cancel-button",
};

type DocUploadExitModalProps = {
  onClickConfirm: () => void;
  onClickCancel: () => void;
};

const DocUploadExitModal: FC<DocUploadExitModalProps> = ({ onClickConfirm, onClickCancel }) => (
  <Modal
    title={MODAL_TITLE}
    size="small"
    open
    slotWidth={MODAL_WIDTH}
    slotHeight="auto"
    transition="opacity 0.3s ease-out"
    shouldShowFooter={true}
    onClose={onClickCancel}
    primaryButton={<ConfirmButton onClick={onClickConfirm} />}
    secondaryButton={<BackButton onClick={onClickCancel} />}
  >
    <Typography variant="body-small">{MODAL_MESSAGE}</Typography>
  </Modal>
);

type ModalButtonProps = {
  onClick: () => void;
};

const ConfirmButton: FC<ModalButtonProps> = ({ onClick }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={onClick}
      ml={spacing.inner.base04}
      dataAttributes={{ "data-testid": DataTestIds.confirmButton }}
    >
      {CONFIRM}
    </Button>
  );
};

const BackButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick} dataAttributes={{ "data-testid": DataTestIds.cancelButton }}>
    {BACK}
  </Button>
);

export { DocUploadExitModal as default, DataTestIds };
