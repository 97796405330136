import React from "react";
import { IconButton, Pill, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { ChevronLeft, ChevronRight } from "@alphasights/alphadesign-icons";
import { HitAction } from "@alphasights/portal-api-client";
import { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { question } from "views/ProjectSynthesisView/synthesisTypeGuards";

export const RevisionToggler = ({ ...props }) => {
  const {
    revision,
    selectedModule,
    selectedRevisionIdx,
    prevRevision,
    nextRevision,
    synthesisLogHit,
  } = useProjectSynthesisContext();

  const onClickPrev = () => {
    synthesisLogHit({
      action: HitAction.projectSynthesisRevisionTogglerClicked,
      details: {
        revision: revision?.revision,
        direction: "prev",
        moduleType: selectedModule?.contentType,
        ...(selectedModule?.contentType === "QUESTION" && { question: question(revision?.contents)?.question }),
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    prevRevision();
  };

  const onClickNext = () => {
    synthesisLogHit({
      action: HitAction.projectSynthesisRevisionTogglerClicked,
      details: {
        revision: revision?.revision,
        direction: "next",
        moduleType: selectedModule?.contentType,
        ...(selectedModule?.contentType === "QUESTION" && { question: question(revision?.contents)?.question }),
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    nextRevision();
  };

  if (!selectedModule?.revisions || selectedModule.revisions.length <= 1) return null;

  return (
    <x.div {...props}>
      <Pill
        data-testid="revision-toggler"
        leftAccessories={
          <Tooltip title="Switch to previous revision">
            <IconButton size="x-small" onClick={onClickPrev} variant="basic" testId="prev-revision">
              <ChevronLeft />
            </IconButton>
          </Tooltip>
        }
        rightAccessories={
          <Tooltip title="Switch to next revision">
            <IconButton size="x-small" onClick={onClickNext} variant="basic" testId="next-revision">
              <ChevronRight />
            </IconButton>
          </Tooltip>
        }
        isInteractive={false}
        variant="outline"
        size="small"
      >
        <x.span w="30px" display="flex" justifyContent="center">
          <Typography component="span" variant="body-small">
            {selectedRevisionIdx + 1}/{selectedModule.revisions.length}
          </Typography>
        </x.span>
      </Pill>
    </x.div>
  );
};
