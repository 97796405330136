import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AudioPlayer } from "components/AdvisorCard/AudioPlayer";
import {
  Alert,
  Button,
  Checkbox,
  Icon,
  IconButton,
  ListItem,
  Option,
  Popover,
  Select,
  SelectItem,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";
import { Download, InsertLink, PlayArrow, Search, Show } from "@alphasights/alphadesign-icons";
import { useInteractioUpgradesStyles } from "./InteractionUpgrades.styles";
import { getLanguageFromCode } from "helpers/displayHelpers";
import ExpiringComponent from "components/ExpiringComponent";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { NavLink } from "router-utils";
import { useRememberSearchParams } from "providers/RememberSearchParamsProvider";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { compact, flatten } from "lodash";
import { UpgradeType } from "./types";

const typeUIRef = {
  ai: {
    product: "AI Transcript",
    icon: "transcripts-ai",
    select: (req: TranscriptRequest) => req.transcriptType === "ai",
    reject: (req: TranscriptRequest) => req.transcriptType !== "ai",
  },
  regular: {
    product: "Transcript",
    icon: "transcripts",
    select: (req: TranscriptRequest) => req.transcriptType === "regular",
    reject: (req: TranscriptRequest) => req.transcriptType !== "regular",
  },
  for: (transcriptType: TranscriptType) => typeUIRef[transcriptType],
};

interface UpgradeItemProps extends UpgradeType {
  postInteraction: boolean;
  interaction: Interaction;
  transcript?: TranscriptRequest;
  transcriptRequests?: TranscriptRequest[];
  onAddUpgrade: (type: string, language?: string) => void;
  onRemoveUpgrade: (type: string) => void;
  recordingExpired?: boolean;
  selectedUpgrades?: string[];
  pendingUpgrades?: string[];
  setPendingUpgrades?: (pendingUpgrades: string[]) => void;
  deliverablesViewEnabled?: boolean;
  projectToken: string;
}

export const UpgradeItem = ({
  name,
  price,
  language,
  description,
  deliveryTime,
  availableMessage,
  expiredMessage,
  icon,
  selected: selectedInput,
  disabled,
  disabledInPost,
  postInteraction,
  available,
  expired,
  downloadable,
  interaction,
  transcript,
  transcriptRequests,
  onAddUpgrade,
  onRemoveUpgrade,
  recording,
  recordingExpired,
  type,
  banner,
  innerBanner,
  requireLanguageSelection,
  selectedLanguage,
  requireRemovalConfirmation,
  removalConfirmationText,
  languageNotAvailableMessage,
  sourceLanguage,
  languages,
  shareLink,
  notAvailableOnPlatformMessage,
  dependsOn,
  selectedUpgrades = [],
  pendingUpgrades = [],
  setPendingUpgrades = () => {},
  indeterminateStateHint,
  deliverablesViewEnabled,
  projectToken,
  transcriptRequestType,
}: UpgradeItemProps) => {
  const { id: interactionId } = interaction;
  const [confirmedLanguage, setConfirmedLanguage] = useState<string | undefined>(undefined);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selected, setSelected] = useState(selectedInput);
  const selectedOrDisabledInPost = selectedInput || disabledInPost;
  const isNotSelectable = recordingExpired || (selectedOrDisabledInPost && postInteraction);
  const indeterminate =
    showLanguageSelection || showAddConfirmation || showRemovalConfirmation || pendingUpgrades.includes(type);

  const {
    upgradeListItemStyle,
    upgradeDisabledListItemStyle,
    upgradeIconStyle,
    disabledItemTitleStyle,
    upgradeActionsStyle,
  } = useInteractioUpgradesStyles({
    selected: selected || indeterminate,
    disabled,
  });

  const languageName = getLanguageFromCode(language) ?? language;
  const secondaryText = [
    price,
    indeterminate && indeterminateStateHint ? indeterminateStateHint : languageName,
    description,
    deliveryTime,
  ]
    .filter(Boolean)
    .join(" | ");

  const secondaryTextAvailable = [languageName, availableMessage].join(" | ");

  const secondaryTextExpired = [languageName, expiredMessage].join(" | ");

  const onChange = (checked: boolean) => {
    setSelected(checked);
    checked ? onAddUpgrade(type, confirmedLanguage) : onRemoveUpgrade(type);
    setConfirmedLanguage(undefined);
  };

  const dependenciesMet = useMemo(() => !dependsOn || [...selectedUpgrades, ...pendingUpgrades].includes(dependsOn), [
    dependsOn,
    pendingUpgrades,
    selectedUpgrades,
  ]);

  const onCancelAddition = useCallback(
    (removePending = true) => {
      setShowAddConfirmation(false);
      setShowLanguageSelection(false);
      setConfirmedLanguage(undefined);
      removePending && setPendingUpgrades(pendingUpgrades.filter((p) => p !== dependsOn && p !== type));
    },
    [dependsOn, pendingUpgrades, setPendingUpgrades, type]
  );

  const onCancelRemoval = useCallback(() => {
    setShowRemovalConfirmation(false);
  }, []);

  useEffect(() => {
    setSelected(selectedInput);
  }, [selectedInput]);

  useEffect(() => {
    !dependenciesMet && onCancelAddition(false);
  }, [dependenciesMet, onCancelAddition]);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (indeterminate) {
      event.preventDefault();
      !selected ? onCancelAddition() : onCancelRemoval();
      return;
    }

    if (selected && requireRemovalConfirmation) {
      event.preventDefault();
      setShowRemovalConfirmation(true);
      return;
    }

    const shouldAskForLanguageBeforeAdding = !selected && requireLanguageSelection && !confirmedLanguage;
    const shouldConfirmBeforeAdding = !selected && postInteraction;
    if (shouldAskForLanguageBeforeAdding || shouldConfirmBeforeAdding) {
      event.preventDefault();
      !dependenciesMet && setPendingUpgrades(compact([...pendingUpgrades, dependsOn]));
      shouldAskForLanguageBeforeAdding ? setShowLanguageSelection(true) : setShowAddConfirmation(true);
      return;
    }
  };

  const onTogglePlayRecording = () => {
    setIsPlaying(!isPlaying);
  };

  return isNotSelectable ? (
    <ListItem variant="compact" spacing="sm" data-testid={`${type}-upgrade`}>
      {!isPlaying ? (
        <x.div {...upgradeDisabledListItemStyle}>
          <Icon {...upgradeIconStyle}>{icon}</Icon>
          <x.div {...disabledItemTitleStyle}>
            <Typography>{name}</Typography>
            <Typography variant="body-small" color="secondary">
              {expired ? secondaryTextExpired : available ? secondaryTextAvailable : secondaryText}
            </Typography>
          </x.div>
          {available && !expired && (
            <x.div {...upgradeActionsStyle}>
              {recording && !downloadable && (
                <PlayRecordingButton
                  type={type}
                  interaction={interaction}
                  recording={recording}
                  deliverablesViewEnabled={deliverablesViewEnabled}
                  projectToken={projectToken}
                  transcriptRequests={transcriptRequests}
                  onPlayRecording={onTogglePlayRecording}
                  accessControl={{
                    allowedPermissions: ["access_transcripts_and_recordings"],
                  }}
                />
              )}
              {downloadable && !notAvailableOnPlatformMessage && (
                <>
                  {deliverablesViewEnabled ? (
                    <ViewInDeliverablesTabButton
                      projectToken={projectToken}
                      interaction={interaction}
                      transcriptRequestType={transcriptRequestType}
                    />
                  ) : (
                    <>
                      {transcript && (
                        <DownloadTranscriptButton
                          interaction={interaction}
                          transcript={transcript}
                          accessControl={{
                            allowedPermissions: ["access_transcripts_and_recordings"],
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {shareLink && (
                <ShareLinkButton
                  deliverablesViewEnabled={deliverablesViewEnabled}
                  projectToken={projectToken}
                  interactionId={interaction.id}
                  transcriptRequestType={transcriptRequestType}
                />
              )}
              {notAvailableOnPlatformMessage && (
                <ViewTranslatedTranscriptButton
                  message={notAvailableOnPlatformMessage}
                  transcriptRequests={transcriptRequests}
                />
              )}
            </x.div>
          )}
        </x.div>
      ) : (
        <AudioPlayer
          recording={recording}
          onClose={onTogglePlayRecording}
          interactionId={interactionId}
          projectToken={interaction.projectToken}
          origin={HitOrigin.flyout}
          language={language}
        />
      )}
    </ListItem>
  ) : (
    <ListItem variant="compact" spacing="sm" data-testid={`${type}-upgrade`}>
      <x.div {...upgradeListItemStyle}>
        <x.div onClick={disabled ? undefined : onClick} flexGrow={1}>
          <Checkbox
            variant="boxed"
            disabled={disabled}
            indeterminate={indeterminate}
            checked={!indeterminate && selected}
            onChange={({ currentTarget: { checked } }) => onChange(checked)}
            icon={icon}
            secondaryText={secondaryText}
            data-testid={`upgrade-checkbox-${type}`}
          >
            {name}
          </Checkbox>
        </x.div>

        {showLanguageSelection && (
          <>
            <LanguageSelection
              selectedLanguage={selectedLanguage}
              setConfirmedLanguage={setConfirmedLanguage}
              onConfirmLanguage={() => {
                if (!confirmedLanguage) return;
                setShowLanguageSelection(false);
                postInteraction ? setShowAddConfirmation(true) : onChange(true);
              }}
              languageNotAvailableMessage={languageNotAvailableMessage}
              sourceLanguage={sourceLanguage}
              languages={languages}
            />
            {innerBanner}
          </>
        )}
        {showRemovalConfirmation && (
          <Alert
            variant="warning"
            buttonPrimaryProps={{
              children: "Cancel",
              variant: "ghost",
              onClick: () => setShowRemovalConfirmation(false),
            }}
            buttonSecondaryProps={{
              children: "Confirm",
              variant: "secondary",
              onClick: () => {
                setShowRemovalConfirmation(false);
                onChange(false);
              },
            }}
          >
            {removalConfirmationText ?? "Confirm upgrade removal?"}
          </Alert>
        )}

        {showAddConfirmation && (
          <Alert
            variant="info"
            buttonPrimaryProps={{
              children: "Cancel",
              variant: "ghost",
              onClick: () => onCancelAddition(),
            }}
            buttonSecondaryProps={{
              children: "Confirm",
              variant: "secondary",
              onClick: () => {
                setShowAddConfirmation(false);
                onChange(true);
              },
            }}
          >
            Upgrades added after the call is completed cannot be removed.
          </Alert>
        )}
        {!showAddConfirmation && !showRemovalConfirmation && banner}
      </x.div>
    </ListItem>
  );
};

const SelectLanguage = styled(Select)(({ isMobile }: { isMobile: boolean }) => {
  return isMobile ? "width: unset;" : "";
});

const LanguageSelection = ({
  selectedLanguage,
  setConfirmedLanguage,
  onConfirmLanguage,
  languageNotAvailableMessage,
  sourceLanguage,
  languages = [],
}: {
  selectedLanguage?: string;
  setConfirmedLanguage: (language?: string) => void;
  onConfirmLanguage: () => void;
  languageNotAvailableMessage?: string;
  sourceLanguage?: string;
  languages?: { locale: string; language: string }[];
}) => {
  const { isMobile } = useCheckScreen();
  const {
    languageSelectionStyle,
    languageSelectionButtonStyle,
    languageSelectionInputStyle,
    languageSelectionLanguageTextStyle,
  } = useInteractioUpgradesStyles({ isMobile });

  useEffect(() => {
    setConfirmedLanguage(selectedLanguage);
  }, [setConfirmedLanguage, selectedLanguage]);

  const renderLabel = (language: string, sourceLanguage?: string) => {
    return sourceLanguage ? `${sourceLanguage} to ${language}` : language;
  };

  const renderSelectedValue = (selectedItem: SelectItem | SelectItem[]) => {
    const item = flatten([selectedItem]).at(0);
    const languageName = getLanguageFromCode(item?.value);
    return languageName ? renderLabel(languageName, sourceLanguage) : "";
  };

  return (
    <x.div {...languageSelectionStyle} data-testid="language-selection">
      <SelectLanguage
        input
        isMobile={isMobile}
        placeholder="Select language"
        rightAccessory={
          <Icon color="secondary">
            <Search />
          </Icon>
        }
        allowRightAccessoryRotation={false}
        value={selectedLanguage}
        onChange={(value) => {
          setConfirmedLanguage(value as string);
        }}
        noResultsMessage={languageNotAvailableMessage}
        {...languageSelectionInputStyle}
        renderSelectedValue={renderSelectedValue}
        data-testid="language-select"
      >
        {languages.map(({ locale, language }) => (
          <Option value={locale} label={language} key={locale}>
            <x.div display="flex" justifyContent="space-between" w="100%" data-testid={`language-select-${locale}`}>
              <x.div>
                <Typography {...languageSelectionLanguageTextStyle}>{renderLabel(language, sourceLanguage)}</Typography>
              </x.div>
            </x.div>
          </Option>
        ))}
      </SelectLanguage>
      <Button variant="secondary" onClick={onConfirmLanguage} {...languageSelectionButtonStyle}>
        <Typography variant="body-em">Confirm Language</Typography>
      </Button>
    </x.div>
  );
};

const ShareLinkButton = ({
  projectToken,
  deliverablesViewEnabled,
  interactionId,
  transcriptRequestType,
}: {
  projectToken: string;
  deliverablesViewEnabled?: boolean;
  interactionId: string;
  transcriptRequestType?: TranscriptType;
}) => {
  const { linkCopiedStyle } = useInteractioUpgradesStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const ref = React.useRef(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    const deliverablesTabRef = `${window.location.protocol}//${
      window.location.host
    }/${projectToken}/experts/deliverables-view/?selectedInteraction=${interactionId}${
      transcriptRequestType ? `&transcriptType=${transcriptRequestType}` : ""
    }`;
    navigator.clipboard
      .writeText(deliverablesViewEnabled ? deliverablesTabRef : window.location.href)
      .then(() => setAnchorEl(target));
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        variant="ghost"
        color="secondary"
        size="small"
        onClick={handleClick}
        dataAttributes={{ "data-testid": "upgrade-share-button" }}
      >
        <InsertLink />
      </IconButton>
      {open && (
        <ExpiringComponent delay={3000} onExpire={handleClose}>
          <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClose} placement="bottom-end">
            <x.div {...linkCopiedStyle}>
              <Icon color="brandSecondary">
                <InsertLink />
              </Icon>
              <Typography variant="body-em">Link copied!</Typography>
            </x.div>
          </Popover>
        </ExpiringComponent>
      )}
    </>
  );
};

const DownloadTranscriptButton = withAccessControl(
  ({
    interaction,
    transcript,
    disabled,
  }: {
    interaction: Interaction;
    transcript: TranscriptRequest;
    disabled?: boolean;
  }) => {
    const { logHit } = useTrackUserAction();
    const formatedCallTime = interaction.scheduledCallTime ? ` - ${interaction.scheduledCallTime.slice(0, 10)}` : "";
    const transcriptFileName = `${interaction.projectTitle} - ${interaction.advisorName} ${formatedCallTime}.docx`.replace(
      /[[\],]/g,
      ""
    );
    const transcriptFileDownload = () => {
      logHit({
        origin: HitOrigin.flyout,
        action: HitAction.downloadTranscript,
        advisorshipId: interaction.id,
        projectToken: interaction.projectToken,
        details: { transcriptType: transcript.transcriptType },
        references: { transcriptId: transcript.id },
      });

      return `/api/projects/${interaction.projectToken}/transcripts/${transcript.token}?filename=${encodeURIComponent(
        transcriptFileName
      )}`;
    };

    return (
      <IconButton
        variant="ghost"
        size="small"
        onClick={() => window.open(transcriptFileDownload(), "_self")}
        disabled={disabled}
        dataAttributes={{ "data-testid": "upgrade-download-button" }}
      >
        <Download />
      </IconButton>
    );
  }
);

const PlayRecordingButton = withAccessControl(
  ({
    type,
    interaction,
    recording,
    deliverablesViewEnabled,
    projectToken,
    transcriptRequests = [],
    onPlayRecording,
    disabled,
  }: {
    type: string;
    interaction: Interaction;
    recording: Recording;
    deliverablesViewEnabled?: boolean;
    projectToken: string;
    transcriptRequests?: TranscriptRequest[];
    onPlayRecording: () => void;
    disabled?: boolean;
  }) => {
    const readAlongCompatibleTranscript = transcriptRequests.find(typeUIRef.ai.select);

    if (!recording.visibleToClient && !readAlongCompatibleTranscript?.completed) {
      return null;
    }

    return (
      <>
        {deliverablesViewEnabled && readAlongCompatibleTranscript?.completed ? (
          <ViewInDeliverablesTabButton
            projectToken={projectToken}
            interaction={interaction}
            transcriptRequestType="ai"
          />
        ) : (
          <IconButton
            variant="ghost"
            size="small"
            disabled={disabled}
            onClick={onPlayRecording}
            dataAttributes={{ "data-testid": "upgrade-play-button" }}
          >
            {type === "transcript_ai" ? <Show /> : <PlayArrow />}
          </IconButton>
        )}
      </>
    );
  }
);

const ViewTranslatedTranscriptButton = ({
  message,
  transcriptRequests = [],
}: {
  message: string;
  transcriptRequests?: TranscriptRequest[];
}) => {
  const regularTranscript = transcriptRequests.find(typeUIRef.regular.select);

  if (!regularTranscript?.completed) return null;

  return (
    <Tooltip title={message} position="left">
      <IconButton
        variant="ghost"
        size="small"
        data-tip={message}
        dataAttributes={{ "data-testid": "upgrade-unavaible-view-button" }}
        disabled
      >
        <Show />
      </IconButton>
    </Tooltip>
  );
};

const ViewInDeliverablesTabButton = ({
  projectToken,
  interaction,
  transcriptRequestType,
}: {
  projectToken: string;
  interaction: { id: string };
  transcriptRequestType?: TranscriptType;
}) => {
  const { withSearchParams } = useRememberSearchParams();

  const deliverablesTabWithSearchParams = withSearchParams("/experts/deliverables-view");
  const deliverablesTabRef = `/${projectToken}${deliverablesTabWithSearchParams}${
    deliverablesTabWithSearchParams.includes("?") ? "&" : "?"
  }selectedInteraction=${interaction.id}${transcriptRequestType ? `&transcriptType=${transcriptRequestType}` : ""}`;

  return (
    <NavLink to={deliverablesTabRef} style={{ textDecoration: "none" }}>
      <IconButton
        variant="ghost"
        size="small"
        dataAttributes={{ "data-testid": "upgrade-view-in-deliverables-tab-button" }}
      >
        <Show />
      </IconButton>
    </NavLink>
  );
};
