import React, { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useHover } from "../../hooks/useHover";
import * as S from "./Popover.styled";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const Popover = ({
  children,
  body,
  placement,
  isActive,
  disableHover = false,
  disableArrow = false,
  textClassName = "",
}) => {
  const [referenceElement, isHovered] = useHover(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [0, 10] } },
      { name: "flip", options: { fallbackPlacements: [] } },
    ],
  });

  const { shape } = useThemeTokens();

  return (
    <>
      <span className={textClassName} ref={referenceElement}>
        {children}
      </span>
      {(isActive || (isHovered && !disableHover)) &&
        createPortal(
          <x.div
            py={2}
            px={4}
            boxShadow={shape.shadow.default}
            position={"relative"}
            ref={setPopperElement}
            style={{
              ...styles.popper,
              backgroundColor: "white",
              zIndex: 20,
            }}
            {...attributes.popper}
            data-testid="popover-wrapper"
          >
            {!disableArrow && <S.PopoverArrow ref={setArrowElement} />}
            {body}
          </x.div>,
          document.getElementById("portal-root")
        )}
    </>
  );
};
