import { apiClient } from "@alphasights/portal-api-client";
import { CustomerKnowledgeRecord } from "pages/CustomerExpertPage/helpers";
import React, { useCallback, useContext, useEffect, useState } from "react";

const defaultComplianceVideo = {
  description: "Watch our video explaining interaction expectations.",
  file: null,
  thumbUrl:
    "https://embed-ssl.wistia.com/deliveries/94eb9285a1fac59299672cd6abc1d4a9ccbb1fff.jpg?image_crop_resized=320x180",
  title: "AlphaSights Compliance",
  type: "video",
  videoId: "yw3qlh3dne",
};

type ComplianceResouce = typeof defaultComplianceVideo;

export interface CurrentProjectContextState {
  complianceResources?: ComplianceResouce[];
  project?: Project;
  isLoading?: boolean;
  initialized?: boolean;
  customerKnowledge: CustomerKnowledgeRecord[];
  getCustomerKnowledgeByInteraction?: (id: string) => CustomerKnowledge[];
  refresh?: () => Promise<void> | undefined;
}

export const CurrentProjectContext = React.createContext<CurrentProjectContextState>({
  customerKnowledge: [],
  complianceResources: [defaultComplianceVideo],
});

export const CurrentProjectProvider = ({
  project,
  isLoading,
  complianceResources,
  children,
  ...props
}: {
  project?: Project;
  isLoading?: boolean;
  complianceResources?: ComplianceResouce[];
  children: JSX.Element;
}) => {
  const [customerKnowledge, setCustomerKnowledge] = useState<CustomerKnowledgeRecord[]>([]);

  useEffect(
    function fetchCustomerKnowledge() {
      if (!project) return;

      const angles = (project as any).angles || [];

      if (!angles.some((a: Angle) => a.type?.name === "End Customers")) return;

      apiClient.get<CustomerKnowledgeRecord[]>(`/api/projects/${project.token}/customer-knowledge`).then((res) => {
        setCustomerKnowledge(res);
      });
    },
    [project]
  );

  const getCustomerKnowledgeByInteraction = useCallback(
    (id: string) => {
      return customerKnowledge.find((ck) => ck.id === id)?.customerKnowledges || [];
    },
    [customerKnowledge]
  );

  const value = {
    project,
    isLoading,
    customerKnowledge,
    complianceResources: project?.complianceResources ?? [defaultComplianceVideo],
    getCustomerKnowledgeByInteraction,
  };

  return <CurrentProjectContext.Provider value={value} children={children} {...props} />;
};

export const useCurrentProjectContext = () => {
  const ctx = useContext(CurrentProjectContext);
  // Lazy fetch
  !ctx.initialized && ctx.refresh && ctx.refresh();
  return ctx;
};
