import { Alert, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import React, { useState } from "react";
import { CallUnavailableAlert } from "./CallUnavailableAlert.js";
import { CallAvailabilityStatus } from "./constants";
import { TwilioClientConnection } from "./TwilioClientConnection";

export const WebConnect = ({
  isOpen,
  interactionId,
  projectToken,
  mainNumber,
  accessCode,
  callAvailabilityStatus,
  projectLead,
  isClosed,
  setIsWebConnectionActive,
  setSelectedTabIndex,
}) => {
  const [isConnectionDegraded, setIsConnectionDegraded] = useState(false);
  const [webConnectError, setWebConnectError] = useState();
  const [webConnectWarning, setWebConnectWarning] = useState();
  const [webConnectInfo, setWebConnectInfo] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const { color, spacing } = useThemeTokens();

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {callAvailabilityStatus &&
        callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE &&
        callAvailabilityStatus.availability !== CallAvailabilityStatus.FETCH_ERROR && (
          <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
        )}

      {isConnected && webConnectInfo && (
        <x.div marginTop={spacing.layout.base03}>
          <Alert variant="info" size="small">
            {webConnectInfo}
          </Alert>
        </x.div>
      )}
      {!isConnected && webConnectError && (
        <x.div marginTop={spacing.layout.base03}>
          <Alert variant="danger">{webConnectError}</Alert>
        </x.div>
      )}
      {isConnected && webConnectWarning && (
        <x.div marginTop={spacing.layout.base03}>
          <Alert variant="warning" size="small">
            {webConnectWarning}
          </Alert>
        </x.div>
      )}

      {isConnected && isConnectionDegraded && (
        <x.div marginTop={spacing.layout.base03}>
          <Alert variant="warning">Your connection is unstable. The audio quality might be affected.</Alert>
        </x.div>
      )}

      <Typography variant="body-small-spaced" marginTop={spacing.layout.base03} color={color.text.assistive}>
        Click on "Connect" to join your call directly from this browser. Enable microphone access if prompted and please
        ensure you are not connected over a cellular network.
      </Typography>

      <TwilioClientConnection
        mainNumber={mainNumber}
        accessCode={accessCode}
        interactionId={interactionId}
        projectToken={projectToken}
        isClosed={isClosed}
        setIsWebConnectionActive={setIsWebConnectionActive}
        setWebConnectError={setWebConnectError}
        webConnectError={webConnectError}
        setWebConnectWarning={setWebConnectWarning}
        webConnectWarning={webConnectWarning}
        setIsConnectionDegraded={setIsConnectionDegraded}
        webConnectInfo={webConnectInfo}
        setWebConnectInfo={setWebConnectInfo}
        setSelectedTabIndex={setSelectedTabIndex}
        setIsConnected={setIsConnected}
      />
    </div>
  );
};
