import { Pill, Typography, useThemeTokens, Icon } from "@alphasights/alphadesign-components";
import { AlphaCircle } from "@alphasights/alphadesign-icons";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { StatusChip } from "components/SurveysPage/surveysColumns/FollowUpCallStatusColumn";
import React, { useMemo } from "react";
import { FormattedDateTime } from "../../providers/TimezoneProvider";
import { x } from "@xstyled/styled-components";
import { ExpertTableRow } from "models/ExpertTable";
import { uniq } from "lodash";

const statusColumnPrefix = "status-column-";
const statusChipPrefix = "status-chip-";
const statusCountChipPrefix = "status-count-chip-";
const statusCircleChipPrefix = "status-circle-chip-";

export const StatusColumn = ({ row }: { row: ExpertTableRow }) => {
  const { spacing } = useThemeTokens();
  const value = row.original;
  const [otherCountsKeys, otherCountsValues] = useMemo(
    () => [Object.keys(value.othersCounts || {}), Object.values(value.othersCounts || {})],
    [value.othersCounts]
  );

  if (
    (value.interactionType === "Work Product" || value.interactionType === "Work Request") &&
    otherCountsKeys.length === 0
  ) {
    value.state = "proposed";
  }

  const adjustedState = value.hidden ? "hidden" : value.state.replace(/proposed/, "available");

  const [totalOthers, showCredits] = useMemo(() => {
    return [
      otherCountsValues.reduce((acc, it) => acc + it, 0),
      value.state === "proposed" && otherCountsKeys.length === 0,
    ];
  }, [value, otherCountsValues, otherCountsKeys]);

  const showAlphaCircleMultipler = showCredits && value.alphaCircleMultiplier > 1.0;

  return (
    <x.div
      display="flex"
      alignItems="center"
      textTransform="capitalize"
      w="100%"
      h="100%"
      gap={spacing.inner.base02}
      data-testid={`${statusColumnPrefix}${value.id}`}
    >
      <TooltipStateDetail interaction={value} pendingRequest={value.pendingRequest}>
        <StatusChip
          data-testid={`${statusChipPrefix}${value.id}`}
          followUpState={adjustedState}
          chipContent={adjustedState}
          hidden={value.hidden}
        />
      </TooltipStateDetail>
      {!!totalOthers && (
        <StatusChip
          data-testid={`${statusCountChipPrefix}${value.id}`}
          hidden={value.hidden}
          chipContent={`${totalOthers}`}
          newChipCount={true}
          tooltipText={<OthersTooltip states={value.othersStates} />}
          followUpState={
            uniq(Object.keys(value.othersCounts)).length === 1 ? Object.keys(value.othersCounts).at(0) : undefined
          }
        />
      )}
      {showAlphaCircleMultipler && (
        <AlphaCircleMultiplierChip
          data-testid={`${statusCircleChipPrefix}${value.id}`}
          content={`${value.alphaCircleMultiplier}`}
          IconComponent={AlphaCircle}
        />
      )}
    </x.div>
  );
};

const OthersTooltip = ({ states }: { states: { description: string; period?: string }[] }) => {
  const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  const validStates = states || [];
  const validSize = validStates.length <= 3;
  const top = validSize ? validStates : validStates.slice(0, 2);
  const othersCount = validSize ? 0 : validStates.length - 2;

  return (
    <ul>
      {top.map((state, idx) => (
        <li key={idx}>
          {capitalize(state.description)}
          {" • "}
          <FormattedDateTime date={state.period} format={"E d MMM, h:mmaaa"} />
        </li>
      ))}
      {othersCount > 0 && <li>+ {othersCount} more that can be viewed in the expert flyout</li>}
    </ul>
  );
};

const AlphaCircleMultiplierChip = ({
  "data-testid": dataTestid,
  IconComponent,
  content,
}: {
  "data-testid": string;
  IconComponent: React.FC;
  content: string;
}) => {
  const {
    color: { background, text },
    spacing: { inner },
  } = useThemeTokens();

  const chipColor = {
    backgroundColor: `${background.neutral.default} !important`,
    color: text.strong._,
    border: "none !important",
  };

  const chipStyle = {
    padding: `${inner.base} ${inner.base02}`,
    borderRadius: inner.base02,
  };

  return (
    <Pill
      id={dataTestid}
      data-testid={dataTestid}
      size="small"
      leftAccessories={
        <Icon size="medium">
          <IconComponent />
        </Icon>
      }
      style={chipStyle}
      {...chipColor}
      isInteractive={false}
    >
      <Typography variant="body-small">{content}</Typography>
    </Pill>
  );
};
