import {
  Avatar,
  Button,
  ContentCard,
  Divider,
  EllipsisText,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { ChevronUp, Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import React from "react";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { SurveyInsight, TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";
import { RotatedIcon } from "views/SurveysResultsView/components/DataVisualization/components/ExpandToggle";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

type ExpertCardProps = {
  index: number;
  element: SurveyInsight;
  isCardOpen: boolean;
  handleOnCardClick: (index: number) => void;
};

const MAX_VISIBLE_VENDORS_LOGOS = 5;

const VendorsLogos = ({ vendors }: { vendors: string[] }) => {
  const { color, icons } = useThemeTokens();

  // TODO: Get the survey id from the url
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { companyDetails } = surveyMetadata as TransformedSurveyMetadata;

  const vendorsLength = vendors.length;
  const hasVendors = vendorsLength > 0;
  const hasVendorsOverflow = vendorsLength > MAX_VISIBLE_VENDORS_LOGOS;

  if (!hasVendors) {
    return (
      <Typography variant="body-small" color={color.text.strong._}>
        -
      </Typography>
    );
  }

  const displayedVendors = hasVendorsOverflow ? vendors.slice(0, MAX_VISIBLE_VENDORS_LOGOS - 1) : vendors;
  return (
    <>
      {displayedVendors.map((vendor) => {
        const logoUrl = companyDetails.find((company) => company.name === vendor)?.logoUrl;

        return <PrimerCompanyLogo key={vendor} size={icons.size.sm} src={logoUrl} />;
      })}
      {hasVendorsOverflow && (
        <Typography variant="body-small" color={color.text.strong._}>
          +{vendorsLength - MAX_VISIBLE_VENDORS_LOGOS - 1}
        </Typography>
      )}
    </>
  );
};

const ExpertCard = ({ index, element, isCardOpen, handleOnCardClick }: ExpertCardProps) => {
  const { spacing, color, shape } = useThemeTokens();
  const { advisorData, summary, results } = element;

  const handleOnEquestExpert = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // TODO: implement logic to request an expert.
    event.stopPropagation();
  };

  return (
    <ContentCard
      cursor="pointer"
      display="flex"
      selected={isCardOpen}
      flexDirection="column"
      gap={spacing.inner.base03}
      onClick={() => handleOnCardClick(index)}
    >
      <x.div data-testid="card-collapsed-container" display="flex" alignItems="center" gap={spacing.inner.base02}>
        <Avatar color="base02">
          <Expert />
        </Avatar>

        <x.div display="flex" flexDirection="column" gap={spacing.inner.base} flex="1 1 auto">
          <x.div data-testid="card-first-line" display="flex" alignItems="center" w="100%" gap={spacing.inner.base}>
            <x.div display="flex" w="70px" alignItems="center">
              <EllipsisText
                typographyProps={{
                  variant: "body-small-em",
                  component: "p",
                  color: color.text.strong._,
                }}
              >
                {advisorData.name}
              </EllipsisText>
            </x.div>

            <Divider
              vertical
              h={spacing.inner.base04}
              borderLeft={`${shape.border.width.sm} solid ${color.text.secondary}`}
            />
            <Typography variant="body-small" color={color.text.strong._}>
              Uses:
            </Typography>
            <x.div display="flex" gap={spacing.inner.base} alignItems="center" justifyContent="center">
              <VendorsLogos vendors={summary.vendors} />
            </x.div>
          </x.div>
          <x.div data-testid="card-second-line" display="flex" alignItems="center" w="100%">
            <Typography variant="body-small" color={color.text.strong._}>
              {summary.spend}
            </Typography>
          </x.div>
        </x.div>

        <RotatedIcon color={color.icon.secondary} up={isCardOpen ? 1 : 0} size="small">
          <ChevronUp />
        </RotatedIcon>
      </x.div>

      {isCardOpen && (
        <>
          <Divider w="100%" color={color.border.neutral.default} />
          <x.div
            data-testid="card-expanded-container"
            w="100%"
            display="flex"
            flexDirection="column"
            gap={spacing.inner.base04}
          >
            {results.map(({ tab, insights }) => (
              <x.div key={tab} display="flex" flexDirection="column" gap={spacing.inner.base}>
                <Typography variant="body-small" color={color.text.secondary}>
                  {tab}
                </Typography>
                <x.ul listStyleType="initial" paddingLeft={spacing.inner.base05}>
                  {insights.map(({ id, text }) => (
                    <li key={id}>
                      <Typography variant="body-small" color={color.text.strong._}>
                        {text}
                      </Typography>
                    </li>
                  ))}
                </x.ul>
              </x.div>
            ))}
          </x.div>
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="end"
            data-testid="card-footer"
            paddingTop={spacing.inner.base}
          >
            <Button
              size="small"
              whiteSpace="nowrap"
              variant="secondary"
              onClick={(event) => handleOnEquestExpert(event)}
            >
              <Typography variant="body-small-em" color={color.text.inverse}>
                Request Expert
              </Typography>
            </Button>
          </x.div>
        </>
      )}
    </ContentCard>
  );
};

export default ExpertCard;
