import React, { useEffect, useRef } from "react";
import { Icon, Typography, TypographyVariation, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { OptionalOptionProps, SearchOption } from "components/Search/types";
import { ElementUtil } from "utils/element";
import { emphasiseLabel } from "components/Search/components/Option/utils";

const DataTestIds = {
  SearchOption: "search-option",
  EmphasisedText: "emphasised-text",
  NonEmphasisedText: "non-emphasised-text",
  StartIcon: "option-start-icon",
  EndIcon: "option-end-icon",
  Avatar: "option-avatar",
};

export type OptionProps = {
  data: SearchOption;
  onSelect: () => void;
  onHighlight: () => void;
  highlight: boolean;

  size?: SearchSizeVariant;
} & OptionalOptionProps;

const Option = ({
  data,
  onSelect,
  onHighlight,
  size = SearchSizeVariant.Medium,
  highlight = false,
  ...props
}: OptionProps) => {
  const { color, spacing } = useThemeTokens();

  const ref = useRef<HTMLDivElement>(null);
  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const {
    height: minHeight,
    typographyVariant,
    icon: { size: iconSize },
    avatar: { size: avatarSize },
    searchOption: { xStyledProps: searchOptionStyles },
  } = styles;

  const { label, value, secondaryLabel, inputValue = "", StartIcon, EndIcon, avatar } = data;
  const { inputEmphasis, anywhereEmphasis } = props;

  const shouldEmphasiseInputTextMatch = !!inputEmphasis?.(data, inputValue);
  const labelParts = emphasiseLabel(
    label ?? value,
    inputValue,
    shouldEmphasiseInputTextMatch,
    !!anywhereEmphasis?.(data)
  );

  useEffect(() => {
    if (ref?.current && highlight && ElementUtil.isElementXPercentInViewport(ref.current, 100)) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [highlight]);

  return (
    <x.div
      data-testid={DataTestIds.SearchOption}
      ref={ref}
      display="flex"
      alignItems="center"
      minH={minHeight}
      pl={searchOptionStyles.pl}
      background={{
        _: highlight ? color.background.surface.page : color.background.none,
        hover: color.background.input.hover,
      }}
      borderRadius={spacing.inner.base02}
      cursor="pointer"
      onClick={onSelect}
      onMouseEnter={onHighlight}
    >
      <x.div display="flex" alignItems="center" spaceX={spacing.inner.base02}>
        {StartIcon && (
          <Icon color={color.icon.secondary} data-testid={DataTestIds.StartIcon} size={iconSize}>
            <StartIcon />
          </Icon>
        )}
        {avatar?.src && (
          <x.img w={avatarSize} h={avatarSize} src={avatar.src} objectFit="contain" data-testId={DataTestIds.Avatar} />
        )}
        <x.span alignItems="baseline" spaceX={spacing.inner.base02}>
          <span>
            {labelParts.map(({ text, isEmphasised }, index) => (
              <Typography
                key={index}
                component="span"
                variant={(isEmphasised ? `${typographyVariant}-em` : typographyVariant) as TypographyVariation}
                data-testid={isEmphasised ? DataTestIds.EmphasisedText : DataTestIds.NonEmphasisedText}
              >
                {text}
              </Typography>
            ))}
          </span>
          {secondaryLabel && (
            <Typography
              component="span"
              variant={typographyVariant as TypographyVariation}
              color={color.text.secondary}
            >
              {secondaryLabel}
            </Typography>
          )}
        </x.span>
      </x.div>
      {EndIcon && (
        <x.div position="absolute" right="0" mr={spacing.inner.base02} data-testid={DataTestIds.EndIcon}>
          <Icon color={color.icon.secondary}>
            <EndIcon />
          </Icon>
        </x.div>
      )}
    </x.div>
  );
};

export { Option as default, DataTestIds };
