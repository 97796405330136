import React from "react";
import useStyles from "./styles";
import { x } from "@xstyled/styled-components";
import { Button, Icon, Tooltip } from "@alphasights/alphadesign-components";
import { DefaultView } from "components/DefaultView";
import { LANDING_PAGE } from "./text";

const mainContentTestId = "deliverables-main-content-message";

const MainContentTranscriptProcessing = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.processing.title}
    subtitle={LANDING_PAGE.processing.subtitle}
  />
);
const MainContentAudioOnly = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.noTranscript.title}
    subtitle={LANDING_PAGE.noTranscript.subtitle}
  />
);
const MainContentEmpty = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.selectContent.title}
    subtitle={LANDING_PAGE.selectContent.subtitle}
  />
);
const MainContentNoDeliverable = () => (
  <DefaultView
    data-testid={mainContentTestId}
    title={LANDING_PAGE.noContent.title}
    subtitle={LANDING_PAGE.noContent.subtitle}
  />
);

const TooltipedButton = ({
  tooltip,
  icon,
  active = null,
  variant = "ghost",
  style = null,
  children = null,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Tooltip title={tooltip}>
      <Button variant={variant} {...(style || styles.topBarDeliverableButton)} {...props}>
        <x.div display="flex">
          <Icon active={active}>{icon}</Icon>
          {children ? children : null}
        </x.div>
      </Button>
    </Tooltip>
  );
};

export {
  MainContentEmpty,
  MainContentNoDeliverable,
  TooltipedButton,
  MainContentTranscriptProcessing,
  MainContentAudioOnly,
};
