import React from "react";

export const HideableCell = ({
  hidden,
  value,
  children,
}: {
  hidden: boolean;
  value?: string;
  children?: React.ReactNode;
}) => {
  const className = hidden ? "aui-text-grey-3 aui-pointer-events-none" : "aui-dark-1";

  return (
    <div style={{ display: "contents" }} className={className}>
      {children || value}
    </div>
  );
};
