import React, { useCallback, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  AlphaDesignTheme,
  AlphaDesignThemeContextProvider,
  Option,
  Select,
  Icon,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { ChevronDown } from "@alphasights/alphadesign-icons";
import { Datepicker } from "components/DatePicker";
import { getHours, getMinutes, set } from "date-fns";

const RightAccessory = () => {
  const { shape } = useThemeTokens();
  return (
    <x.div pt={shape.border.width.sm} pb={shape.border.width.sm}>
      <Icon color="secondary">
        <ChevronDown />
      </Icon>
    </x.div>
  );
};

export const WorkRequestDeadline = ({
  value,
  label = "Deadline",
  onChange = (value) => {},
  placeholder = "Select date",
  labelWithRequired = false,
  errorMessage = undefined,
  minDate = new Date().setUTCHours(0, 0, 0, 0),
  disabled = false,
  mobileVariant = false,
}: {
  value?: Date;
  label?: string;
  onChange?: (value: Date) => void;
  placeholder?: string;
  labelWithRequired?: boolean;
  errorMessage?: string;
  minDate?: Date | number;
  disabled?: boolean;
  mobileVariant?: boolean;
}) => {
  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const minDateWithoutTime = set(minDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const validatedMinDate = minDateWithoutTime.valueOf() < today.valueOf() ? today : minDateWithoutTime;

  const [selectedHour, setSelectedHour] = useState<number>();
  const [selectedMinutes, setSelectedMinutes] = useState<number>();
  const [selectedDayOption, setSelectedDayOption] = useState<Date>();
  const [deadlineErrors, setDeadlineErrors] = useState<string>();

  useEffect(() => {
    const initialDate = value
      ? set(new Date(value.getTime()), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      : undefined;

    const initialHour = value ? getHours(value) : undefined;
    const initialMinute = value ? getMinutes(value) : undefined;

    setSelectedHour(initialHour === 0 ? -1 : initialHour);
    setSelectedMinutes(initialMinute === 0 ? -1 : initialMinute);
    setSelectedDayOption(initialDate);
  }, [value]);

  useEffect(() => {
    if (errorMessage) {
      setDeadlineErrors(" ");
    } else {
      setDeadlineErrors(undefined);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (selectedDayOption && selectedHour && selectedMinutes) {
      onChange(
        set(selectedDayOption, {
          hours: selectedHour === -1 ? 0 : selectedHour,
          minutes: selectedMinutes === -1 ? 0 : selectedMinutes,
          seconds: 0,
          milliseconds: 0,
        })
      );
    }
  }, [selectedDayOption, selectedHour, selectedMinutes, onChange]);

  const onSelectDay = useCallback(
    (date: Date) => {
      setSelectedDayOption(date);
      if (selectedHour === undefined && selectedMinutes === undefined) {
        setSelectedHour(23);
        setSelectedMinutes(45);
      }
    },
    [selectedHour, selectedMinutes]
  );

  const hoursValues = Array.from({ length: 24 }, (x, i) => ({
    value: i === 0 ? -1 : i,
    label: `0${i}`.slice(-2),
  }));

  const minutesValues = Array.from({ length: 4 }, (x, i) => ({
    value: i === 0 ? -1 : i * 15,
    label: `0${i * 15}`.slice(-2),
  }));

  const { spacing, color } = useThemeTokens();

  const style = {
    borderColor: errorMessage ? color.border.danger : color.border.neutral.default,
    cursor: disabled ? "not-allowed" : "pointer",
  };

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      {label && (
        <x.div pb={spacing.inner.base}>
          <Typography color="assistive" component="span">
            {label}
          </Typography>
          {labelWithRequired && (
            <Typography color="danger" component="span">
              {" *"}
            </Typography>
          )}
        </x.div>
      )}

      <Typography
        data-testid="work-request-deadline"
        component="div"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        gap={spacing.inner.base02}
      >
        <Datepicker
          dateFormat={"dd MMM yyyy"}
          date={selectedDayOption}
          setDate={onSelectDay}
          minDate={validatedMinDate}
          placeholder={placeholder}
          styles={{
            flex: 1,
            width: "100%",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "medium",
            borderColor: deadlineErrors ? color.border.danger : color.border.neutral.default,
          }}
          disabled={disabled}
        />
        <Select
          input={mobileVariant ? false : true}
          data-testid="work-request-deadline-select-hour"
          style={style}
          value={selectedHour}
          placeholder="12"
          onChange={(value) => setSelectedHour(value as number)}
          minWidth="72px"
          maxWidth="72px"
          rightAccessory={<RightAccessory />}
          disabled={disabled}
          size={mobileVariant ? "small" : "medium"}
        >
          {hoursValues.map((option) => (
            <x.div key={option.value} data-testid={`work-request-deadline-select-hour-option-${option.value}`}>
              <Option key={option.value} {...option}>
                {option.label}
              </Option>
            </x.div>
          ))}
        </Select>
        <Select
          input={mobileVariant ? false : true}
          data-testid="work-request-deadline-select-minute"
          style={style}
          value={selectedMinutes}
          placeholder="00"
          onChange={(value) => setSelectedMinutes(value as number)}
          minWidth="72px"
          maxWidth="72px"
          rightAccessory={<RightAccessory />}
          disabled={disabled}
          size={mobileVariant ? "small" : "medium"}
        >
          {minutesValues.map((option) => (
            <x.div key={option.value} data-testid={`work-request-deadline-select-minute-option-${option.value}`}>
              <Option key={option.value} {...option}>
                {option.label}
              </Option>
            </x.div>
          ))}
        </Select>
      </Typography>
      {errorMessage && (
        <Typography component="span" variant="body-small" color="danger">
          {errorMessage}
        </Typography>
      )}
    </AlphaDesignThemeContextProvider>
  );
};
