import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { flatExpertsWithRequests } from "../../components/InteractionsPage/helpers/Interaction";
import { useBuildColumnDefinitions, filterColumnDefinitions } from "./TableColumnDefinitions";
import { usePreference } from "../../hooks/usePreference";
import { Button, useAlphaToast } from "@alphasights/alphadesign-components";
import { ENABLE_PORTAL_COMMENTS, ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { AlphaTable, AlphaTableTypes, useAlphaTable } from "@alphasights/alphadesign-table";
import { x } from "@xstyled/styled-components";
import _, { isEqual } from "lodash";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { selectExperts } from "components/InteractionsPage/reducer";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import { ActionBar } from "./ActionBar/ActionBar";
import {
  buildColumnsOptions,
  columnIds,
  columnPreferenceExists,
  getAddedColumnName,
  getNewColumnOrder,
  hasOrderChanged,
  hasPinningChange,
  hasVisibilityChanged,
} from "../../helpers/portalTablePreferencesHelper";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useLabelsContext } from "providers/LabelsProvider";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import pluralize from "pluralize";
import { useMemoizedValue } from "hooks/useMemoizedValue";
import { Preference, PreferenceType } from "providers/types";
import * as S from "./AdvisorsTableView.styled";
import { AdvisorsTableSkeleton, AdvisorsTableViewSkeleton } from "./AdvisorsTableViewSkeleton";
import { useEnv } from "@alphasights/client-portal-shared";
import { useProjectsLoaded } from "hooks/useProjectsLoaded";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { Env } from "@alphasights/portal-api-client";
import { ExpertTableInteractionType, ExpertTableRow } from "models/ExpertTable";

type SharedTableProps = {
  areaHeight?: number;
  columnPreference?: Preference;
  hasClientPortalMessages: boolean;
  interactions: Interaction[];
  isMobile: boolean;
  isPCCEnabledClient: boolean;
  isProjectCidEnabled: boolean;
  newMessageOrigin: NewMessageOrigin;
  onDownloadCompaniesCid: (selectedInteraction: Interaction[]) => void;
  onSelectCard: (interaction: Interaction) => void;
  preferencesEnabled: boolean;
  project: Project;
  scrollToCardId: string;
  selectedCardId: string;
  selectedExpertsIds: string[];
  updateColumnPreference: (partialAttributes: { [key: string]: unknown }) => Promise<void>;
  userCidEnabled: boolean;
};

type TableViewProps = SharedTableProps & {
  appliedFilters: { [key: string]: string[] };
  clientRequests: InteractionRequest[];
  experts: Expert[];
  loading: boolean;
};

type TableProps = SharedTableProps & {
  rowData: ExpertTableInteractionType[];
  showHiddenExperts: boolean;
};

type TableViewWithPreferencesProps = Omit<TableViewProps, "columnPreference" | "updateColumnPreference"> & {
  token: string;
};

export const AdvisorsTableView = React.memo(
  ({
    loading,
    isFilterOptionsLoading,
    ...props
  }: TableViewWithPreferencesProps & {
    loading: boolean;
    isFilterOptionsLoading: boolean;
  }) => {
    return <TableViewWithPreferences {...props} loading={loading || isFilterOptionsLoading} />;
  }
);

const TableViewWithPreferences = React.memo(({ token, ...props }: TableViewWithPreferencesProps) => {
  const {
    preference: columnPreference,
    updatePreference: updateColumnPreference,
    isLoading,
  } = usePreference(PreferenceType.TABLE_VIEW_COLUMNS, { token });

  return (
    <>
      {isLoading ? (
        <AdvisorsTableViewSkeleton />
      ) : (
        <TableView columnPreference={columnPreference} updateColumnPreference={updateColumnPreference} {...props} />
      )}
    </>
  );
});

const TableView = React.memo(
  ({
    clientRequests,
    columnPreference,
    updateColumnPreference,
    experts = [],
    isMobile,
    isProjectCidEnabled,
    onDownloadCompaniesCid,
    onSelectCard,
    preferencesEnabled,
    scrollToCardId,
    selectedCardId,
    userCidEnabled,
    project,
    loading: loadingInput,
    isPCCEnabledClient,
    hasClientPortalMessages,
    selectedExpertsIds: selectedExpertsIdsInput,
    newMessageOrigin,
    interactions: interactionsInput,
    appliedFilters,
    areaHeight,
  }: TableViewProps) => {
    const [showHiddenExperts, setShowHiddenExperts] = useState(false);
    const projectsLoaded = useProjectsLoaded();
    const { isLoading: projectLoading } = useCurrentProjectContext();
    const loading = loadingInput || !projectsLoaded || projectLoading;

    useEffect(
      function changeHiddenExpertsVisibilityOnFilterChanged() {
        setShowHiddenExperts(appliedFilters.profile_activity && appliedFilters.profile_activity.includes("Hidden"));
      },
      [appliedFilters]
    );

    const rowData = useMemoizedValue(
      _.sortBy(flatExpertsWithRequests(experts, clientRequests), [(o) => o.hidden]).flat()
    );

    const hiddenExperts = useMemo(() => rowData.filter((expert) => expert.hidden), [rowData]);
    const selectedExpertsIds = useMemo(() => selectedExpertsIdsInput ?? [], [selectedExpertsIdsInput]);
    const interactions = useMemo(() => interactionsInput ?? [], [interactionsInput]);

    return (
      <x.div display="flex" flexDirection="column" flexGrow="1" mr="0">
        {loading ? (
          <AdvisorsTableSkeleton />
        ) : (
          <x.div
            className="AdvisorsTableView"
            position="relative"
            flexDirection="column"
            flexGrow="1"
            display={loading ? "none" : "flex"}
          >
            <Table
              columnPreference={columnPreference}
              updateColumnPreference={updateColumnPreference}
              isMobile={isMobile}
              isProjectCidEnabled={isProjectCidEnabled}
              onSelectCard={onSelectCard}
              scrollToCardId={scrollToCardId}
              selectedCardId={selectedCardId}
              project={project}
              selectedExpertsIds={selectedExpertsIds}
              newMessageOrigin={newMessageOrigin}
              preferencesEnabled={preferencesEnabled}
              hasClientPortalMessages={hasClientPortalMessages}
              userCidEnabled={userCidEnabled}
              showHiddenExperts={showHiddenExperts}
              interactions={interactions}
              onDownloadCompaniesCid={onDownloadCompaniesCid}
              isPCCEnabledClient={isPCCEnabledClient}
              rowData={rowData}
              areaHeight={areaHeight}
            />
            <ShowHideExpertsButton
              onClick={() => setShowHiddenExperts((prev) => !prev)}
              showHiddenExperts={showHiddenExperts}
              hiddenExperts={hiddenExperts}
            />
          </x.div>
        )}
      </x.div>
    );
  }
);

const ShowHideExpertsButton = ({
  onClick,
  showHiddenExperts,
  hiddenExperts,
}: {
  onClick: () => void;
  showHiddenExperts: boolean;
  hiddenExperts: Interaction[];
}) => {
  const hasHiddenExperts = hiddenExperts.length > 0;

  if (!hasHiddenExperts) return null;

  return (
    <S.ShowHideExpertsButtonWrapper>
      <Button variant="outline" size="small" onClick={onClick}>
        {showHiddenExperts ? "Remove" : "Show"} Hidden {pluralize("Expert", hiddenExperts.length)}
      </Button>
    </S.ShowHideExpertsButtonWrapper>
  );
};

const Table = ({
  columnPreference,
  updateColumnPreference,
  isMobile,
  isProjectCidEnabled,
  onSelectCard,
  scrollToCardId,
  selectedCardId,
  project,
  selectedExpertsIds,
  newMessageOrigin,
  interactions,
  preferencesEnabled,
  hasClientPortalMessages,
  userCidEnabled,
  onDownloadCompaniesCid,
  showHiddenExperts,
  isPCCEnabledClient,
  rowData,
  areaHeight,
}: TableProps) => {
  const { toast } = useAlphaToast();
  const [previousColumnVisibility, setPreviousColumnVisibility] = useState<AlphaTableTypes.VisibilityState>({});
  const [hideHeader, setHideHeader] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedRows, setSelectedRows] = useState<Interaction[]>([]);
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects() ?? [];
  const { hasProjectBadge } = useProjectBadgeContext();
  const { enableLabels } = useLabelsContext();
  const poralMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);
  const hasActionButtons = poralMessagesEnabled || isProjectCidEnabled || enableLabels;
  const { threadHeaders } = useMessageThreadContext();
  const env = useEnv() as
    | (Env & {
        enableTableviewVirtualization: string;
        tableviewVirtualizationThreshold: number;
      })
    | null;
  const { enableTableviewVirtualization: isVirtualizationEnabledInEnv, tableviewVirtualizationThreshold } = env ?? {
    enableTableviewVirtualization: "false",
    tableviewVirtualizationThreshold: 0,
  };

  const showComments =
    !!(!currentUser || allProjects.find(({ token }) => token === project.token)) &&
    hasProjectBadge(ENABLE_PORTAL_COMMENTS) &&
    project.active;

  useEffect(() => {
    const element = document.getElementById(`advisor-row-${scrollToCardId}`);
    if (element) {
      element.scrollIntoView();
    }
  }, [scrollToCardId]);

  const initialColumns = useBuildColumnDefinitions({
    onSelectCard,
    project,
    interactions,
    preferencesEnabled,
    userCidEnabled,
  });

  const saveColumnPreferences = () => {
    const attributes = buildColumnPreferences(columnIds(columns));
    updateColumnPreference(attributes);
  };

  useEffect(() => {
    if (!columnPreferenceExists(columnPreference)) {
      saveColumnPreferences();
    }
  }, [columnPreference?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = filterColumnDefinitions({
    columns: initialColumns,
    project,
    showComments,
    isProjectCidEnabled,
    isMobile,
    threadHeaders,
    interactions: rowData,
  });

  const dispatch = useContext(DispatchContext);

  const onRowSelectionChange = (selection: Record<string, boolean>) => {
    const selectedRows = rowData.filter((item, index) => index in selection && selection[index]);
    setSelectedRows(selectedRows);
    setHideHeader(hasActionButtons && Object.keys(selectedRows).length > 0);
    const expertsIds = selectedRows.map((interaction) => interaction.advisorId);
    if (newMessageOrigin === NewMessageOrigin.TableView && !isEqual(expertsIds, selectedExpertsIds) && !firstLoad) {
      dispatch(selectExperts(expertsIds));
    }
  };

  const onRowClicked = useCallback(
    (row: ExpertTableRow) => {
      const { original: interaction } = row;

      if (!interaction.hidden) {
        onSelectCard({
          ...interaction,
          showChainList: false,
          scrollToCardId: interaction.id,
        });
      }
    },
    [onSelectCard]
  );

  const rowClassRules = [
    {
      className: "row-selected",
      ruleFunction: (row: ExpertTableRow) => row.original.id === selectedCardId,
    },
    {
      className: "row-hidden",
      ruleFunction: (row: ExpertTableRow) => row.original.hidden,
    },
  ];

  const options = buildColumnsOptions({
    columnPreference,
    columns,
    hideSelectColumn: !currentUser || !hasActionButtons,
    onRowClicked,
    rowClassRules,
    hideHeader: hideHeader,
    enableRowSelection: ({ original: interaction }) => !interaction.hidden,
    onRowSelectionChange,
    onColumnOrderChange: (updatedColumnOrders) => {
      if (hasOrderChanged(updatedColumnOrders, columnPreference)) {
        saveColumnPreferences();
      }
    },
    onColumnVisibilityChange: (updatedColumnVisibilities) => {
      const addedColumnName = getAddedColumnName(columns, previousColumnVisibility, updatedColumnVisibilities);
      addedColumnName && toast.success({ message: `${addedColumnName} column added` });
      const newColumnOrder = getNewColumnOrder(columns, table.getState().columnOrder, updatedColumnVisibilities);
      table.setColumnOrder(newColumnOrder);
      setPreviousColumnVisibility(updatedColumnVisibilities);
      if (hasVisibilityChanged(columns, updatedColumnVisibilities, columnPreference)) {
        saveColumnPreferences();
      }
    },
    onColumnPinningChange: (updatedColumnPinning) => {
      if (hasPinningChange(updatedColumnPinning, columnPreference)) {
        saveColumnPreferences();
      }
    },
  });

  const filteredData = useMemo(() => rowData.filter((expert) => showHiddenExperts || !expert.hidden), [
    rowData,
    showHiddenExperts,
  ]);
  const enableVirtualization =
    String(isVirtualizationEnabledInEnv) === "true" && filteredData.length > tableviewVirtualizationThreshold;
  const table = useAlphaTable(filteredData, columns, options);

  useEffect(
    function updateColumnOrderOnNewColumnsAdded() {
      options.state?.columnOrder && table.setColumnOrder(options.state.columnOrder);
    },
    [columns.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (rowData.length && selectedExpertsIds && newMessageOrigin === NewMessageOrigin.TableView) {
      const selectedRows = rowData.reduce(
        (acc: { [key: string]: boolean }, row, idx) => ({
          ...acc,
          [idx]: selectedExpertsIds.includes(row.advisorId),
        }),
        {}
      );
      table.setRowSelection(selectedRows);
    }
  }, [selectedExpertsIds, table, rowData, newMessageOrigin]);

  const downloadCidCompaniesCsv = () => {
    onDownloadCompaniesCid(selectedRows);
    table.resetRowSelection(true);
  };

  const buildColumnPreferences = useCallback(
    (columns: string[]) => {
      const columnIds = table.getState().columnOrder.length > 0 ? table.getState().columnOrder : columns;
      return columnIds.reduce((obj, column, index) => {
        const isColumnVisible = _.get(table.getState(), `columnVisibility.${column}`, true);
        const isColumnHiddenInPreferences = _.get(columnPreference, `attributes.${column}.hide`, false);
        return {
          ...obj,
          [column]: {
            position: index,
            hide: columns.includes(column) ? !isColumnVisible : isColumnHiddenInPreferences,
            pinned: table.getState().columnPinning.left?.includes(column),
          },
        };
      }, {});
    },
    [table, columnPreference]
  );

  const onSelectAll = () => {
    table.toggleAllRowsSelected(true);
  };

  const onUnselectAll = () => {
    table.resetRowSelection(true);
  };

  useEffect(() => setFirstLoad(false), []);

  return (
    <S.AlphaTableWrapper
      hasVirtualization={enableVirtualization}
      areaHeight={enableVirtualization ? areaHeight : undefined}
    >
      {hideHeader && (
        <ActionBar
          interactions={rowData}
          selectedInteractions={selectedRows}
          onDownloadCid={downloadCidCompaniesCsv}
          pcc={isPCCEnabledClient}
          isActiveProject={project.active}
          hasClientPortalMessages={hasClientPortalMessages}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          isProjectCidEnabled={isProjectCidEnabled}
        />
      )}
      <AlphaTable
        data-testid="alpha-table"
        table={table}
        virtualizationOptions={{
          enabled: enableVirtualization,
          estimateSize: () => 56,
          overscan: 5,
        }}
      />
    </S.AlphaTableWrapper>
  );
};
