import React, { useEffect, useMemo, useState } from "react";
import { generatePath, Navigate, Route, Routes, useLocation, useParams, NavigateProps } from "router-utils";
import { useAccessControl } from "hooks/useAccessControl";
import { AdvisorFlyoutRoute, InteractionsPage, NewMessageRoute } from "components/InteractionsPage";
import ThirdPartyPage from "components/ThirdPartyPage";
import { UnauthorizedPage } from "components/UnauthorizedPage";
import { DismissClientRequest } from "components/DismissClientRequest";
import { CUSTOMER_KNOWLEDGE, useProjectBadgeContext, useUserBadgeContext } from "providers/BadgeProvider";
import {
  useCurrentUser,
  useIsAuthenticated,
  useMyProjects,
  useProjectsIsReady,
  useUserAuthIsReady,
} from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { MobileComplianceResourcesPage } from "views/MobileComplianceResoucesPage";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "providers/types";
import { currentProjectView } from "helpers/currentView";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { Badge } from "models/Badge";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const INVALID_MOBILE_VIEWS = ["calendar-view", "table-view", "customer-view", "survey-view"];

const NavigateWithParams = ({ to, ...props }: { to: string } & NavigateProps) => {
  const params = useParams();
  const path = generatePath(to, params);

  return <Navigate {...props} to={path} />;
};

const NavigateToDefaultExpertsView = () => {
  const { workstreamId, token } = useParams();
  const root = workstreamId ? `/${token}/workstream/${workstreamId}/experts` : `/${token}/experts`;
  return <Navigate to={root} replace />;
};

interface PropsWithWorkstream {
  project: Project;
  enablePortalWorkstreams: boolean;
  isSurveyWorkstream: boolean;
}
export const ProjectRoutes = ({ project }: { project: Project }) => {
  const isLoggedIn = useIsAuthenticated();
  const { isLoading } = useCurrentProjectContext();
  const { pathname } = useLocation();
  const { enablePortalWorkstreams, workstreams } = project;

  const isMyAlphaSights = pathname.includes(myAlphaSightsBaseUrl);
  const workstreamId = enablePortalWorkstreams
    ? /\/workstream\/(?<workstreamId>\w+)\//.exec(pathname)?.groups?.workstreamId ?? workstreams?.at(0)?.id
    : undefined;

  const authHasBeenReady = useAuthHasBeenReady();
  const isSurveyWorkstream = useMemo(() => {
    const workstream = workstreams?.find((w) => w.id === workstreamId);
    return enablePortalWorkstreams && workstream?.workstreamType === "survey" && workstream?.clientSurveys?.length > 0;
  }, [enablePortalWorkstreams, workstreamId, workstreams]);

  if (!authHasBeenReady) {
    return null;
  }

  const propsWithWorkstream: PropsWithWorkstream = {
    project,
    enablePortalWorkstreams,
    isSurveyWorkstream,
  };

  if (isMyAlphaSights) {
    return <MyAlphasightsElement {...propsWithWorkstream} />;
  }

  if (isLoggedIn && isLoading) {
    // Show the table view page loading when a logged-in user
    // navigates to a different project and it's still loading
    // @ts-ignore
    return <InteractionsPage project={project} currentView="table-view" />;
  }

  return (
    <>
      <DeprecatedRouteRedirects {...propsWithWorkstream} />
      <OtherProjectRoutes {...propsWithWorkstream} />
      <SignedInRoutes {...propsWithWorkstream} />
      <OpenRoutes {...propsWithWorkstream} />
    </>
  );
};

export const DeprecatedRouteRedirects = (allProps: PropsWithWorkstream) => {
  const { project, isSurveyWorkstream, enablePortalWorkstreams } = allProps;
  const { preference } = usePreference(PreferenceType.DEFAULT_ADVISOR_VIEW, { token: project.token });

  const defaultView = preference?.attributes?.defaultView ?? project.defaultView;
  const userDefaultView = isSurveyWorkstream ? "survey-view" : defaultView;
  const currentView = currentProjectView(useLocation().pathname);

  return (
    <Routes>
      <Route path={`/services/*`} element={<RedirectReplacing replaceOld={"services"} withNew={"experts"} />} />

      <Route
        path={`/experts/calendar-view/*`}
        element={<RedirectReplacing replaceOld={"experts/calendar-view"} withNew={"calendar-view"} />}
      />

      <Route
        path={`/industry-view/*`}
        element={<RedirectReplacing replaceOld={"industry-view"} withNew={"experts"} />}
      />

      <Route path={`/advisors/*`} element={<RedirectReplacing replaceOld={"advisors"} withNew={"experts"} />} />

      <Route
        path={`/experts`}
        element={<RedirectReplacing replaceOld={"/experts"} withNew={`/experts/${userDefaultView || "table-view"}`} />}
      />
      {!currentView && enablePortalWorkstreams && (
        <Route
          path={`/workstream/:workstreamId/experts/:expertId?`}
          element={
            <RedirectReplacing replaceOld={"/experts"} withNew={`/experts/${userDefaultView || "table-view"}`} />
          }
        />
      )}
      {/* InteractionId without a view specified may be passed */}
      {!currentView && (
        <Route
          path={`/experts/:expertId/*`}
          element={
            <RedirectReplacing replaceOld={"/experts"} withNew={`/experts/${userDefaultView || "table-view"}`} />
          }
        />
      )}
      {/* Redirect the url of the old message thread to a new messenger */}
      <Route path={`/messenger/*)`} element={<NavigateWithParams to="/:token/experts/messages-view" replace />} />
      <Route path={`/messages/*)`} element={<NavigateWithParams to="/:token/experts/messages-view" replace />} />
    </Routes>
  );
};

export const OpenRoutes = React.memo((allProps: PropsWithWorkstream) => {
  const { project, isSurveyWorkstream, enablePortalWorkstreams } = allProps;
  const params = useParams();

  const currentView = currentProjectView(useLocation().pathname) ?? "";
  const { isMobile } = useCheckScreen();
  const currentUser = useCurrentUser();

  const { hasProjectBadge } = useProjectBadgeContext();

  const customerViewEnabled = currentUser?.portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);

  const projectViews = [
    !isSurveyWorkstream && "table-view",
    !isSurveyWorkstream && "list-view",
    isSurveyWorkstream && "survey-view",
    customerViewEnabled && "customer-view",
  ]
    .filter((view): view is string => !!view)
    .filter((view) => !isMobile || !INVALID_MOBILE_VIEWS.includes(view));

  const disabledViews = ["list-view", "table-view", "survey-view", "customer-view", "invalid"].filter(
    (view) => !projectViews.includes(view)
  );

  return (
    <Routes>
      {enablePortalWorkstreams ? (
        <>
          {projectViews.includes(currentView) && (
            <>
              <Route
                path={`/experts/:currentView/:advisor?/*`}
                element={
                  <RedirectReplacing
                    replaceOld={"/experts"}
                    withNew={`/workstream/${project.workstreams[0].id}/experts`}
                  />
                }
              />
              <Route
                path={`/workstream/:workstreamId/experts/:currentView`}
                element={<InteractionsPage {...allProps} />}
              >
                <Route path="new-message" element={<NewMessageRoute />} />
                <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
              </Route>
            </>
          )}
          {disabledViews.includes(currentView) && (
            <Route
              path={`/workstream/:workstreamId/experts/:currentView/*`}
              element={<NavigateToDefaultExpertsView />}
            />
          )}
        </>
      ) : (
        <>
          {projectViews.includes(currentView) && (
            <Route path={`/experts/:currentView`} element={<InteractionsPage {...allProps} />}>
              <Route path="new-message" element={<NewMessageRoute />} />
              <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
            </Route>
          )}
          <Route path={`/workstream/*`} element={<Navigate to={`/${params.token}/experts`} replace />} />
        </>
      )}
    </Routes>
  );
});

const MyAlphasightsElement = (allProps: PropsWithWorkstream) => {
  return (
    <RestrictedInteractionsPage
      otherProps={{
        ...allProps,
        currentView: "my-alphasights-view",
      }}
      viewEnabled
    />
  );
};

export const SignedInRoutes = (allProps: PropsWithWorkstream) => {
  const { project } = allProps;
  const { enableComparisonView } = useExpertCompareContext();
  const { enableAngleQuestions } = useAngleQuestionsContext();

  const enableProjectAlphaGpt = project.projectAlphaGPTEnabled;

  // TODO [REDIS2-502]: Remove Surveys Results Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasSurveysResultsPageBadge = hasUserBadge(Badge.surveysResults);

  return (
    <>
      <MobileRoutes />
      <Routes>
        <Route
          path={`/experts/deliverables-view`}
          element={
            <RestrictedInteractionsPage otherProps={{ ...allProps, currentView: "deliverables-view" }} viewEnabled />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/experts/comparison-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "comparison-view" }}
              viewEnabled={enableComparisonView}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/workstream/:workstreamId/experts/comparison-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "comparison-view" }}
              viewEnabled={enableComparisonView}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/experts/alphagpt-view/*`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "alphagpt-view" }}
              viewEnabled={enableProjectAlphaGpt}
            />
          }
        />
        <Route
          path={`/calendar-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "calendar-view" }}
              viewEnabled={project.active}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/workstream/:workstreamId/calendar-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "calendar-view" }}
              viewEnabled={project.active}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/experts/messages-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "messages-view" }}
              viewEnabled={project.active && project.enablePortalMessages}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/workstream/:workstreamId/experts/messages-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "messages-view" }}
              viewEnabled={project.active && project.enablePortalMessages}
            />
          }
        >
          <Route path=":id/*" element={<AdvisorFlyoutRoute />} />
        </Route>
        <Route
          path={`/synthesis/*`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "synthesis" }}
              viewEnabled={project.active && project.synthesisEnabled}
            />
          }
        />
        <Route
          path={`/workstream/:workstreamId/surveys-results-view`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "surveys-results-view" }}
              viewEnabled={project.active && hasSurveysResultsPageBadge}
            />
          }
        />
        <Route
          path={`/questions/*`}
          element={
            <RestrictedInteractionsPage
              otherProps={{ ...allProps, currentView: "questions" }}
              viewEnabled={enableAngleQuestions}
            />
          }
        ></Route>
      </Routes>
    </>
  );
};

export const MobileRoutes = () => {
  const { isMobile } = useCheckScreen();
  return isMobile ? (
    <Routes>
      <Route path={`/compliance-resources/*`} element={<MobileComplianceResourcesPage />} />
      <Route path={`/synthesis/*`} element={<MobileComplianceResourcesPage />} />
      {INVALID_MOBILE_VIEWS.map((view) => (
        <Route path={`/experts/${view}/*`} element={<NavigateWithParams to="/:token/experts/list-view" replace />} />
      ))}
    </Routes>
  ) : null;
};

export const OtherProjectRoutes = ({ project }: PropsWithWorkstream) => {
  return (
    <Routes>
      <Route path={`/third-party/*`} element={<ThirdPartyPage project={project} />} />
      <Route path={`/unauthorized`} element={<UnauthorizedPage />} />
      {/* TODO: remove this ignore */}
      {/* @ts-ignore */}
      <Route path={`/client-requests/:id/*`} element={<DismissClientRequest />} />
    </Routes>
  );
};

const RedirectReplacing = ({ replaceOld, withNew }: { replaceOld: string; withNew: string }) => {
  const location = useLocation();
  const newPath = {
    ...location,
    pathname: location.pathname.replace(replaceOld, withNew),
  };

  return <Navigate to={newPath} state={location.state} replace />;
};

interface RestrictedInteractionsPageProps {
  viewEnabled?: boolean;
  otherProps: PropsWithWorkstream & { currentView: string };
}
const RestrictedInteractionsPage = ({ viewEnabled, otherProps }: RestrictedInteractionsPageProps) => {
  const { project } = otherProps;
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const allProjects = useMyProjects();
  const isLoggedIn = useIsAuthenticated();
  const userBelongsToProject = !!allProjects?.find((p) => p.token === project.token);
  const isProjectsReady = useProjectsIsReady();
  const location = useLocation();
  const params = useParams();

  if (!isLoggedIn) {
    const searchParams = new URLSearchParams({
      next: `${location.pathname}${location.search || ""}`,
    });
    return <Navigate to={`/sign-in?${searchParams}`} />;
  }

  if (!isProjectsReady || !hasProjectsPermission) {
    return null;
  }

  if (params && params.token && (!viewEnabled || !userBelongsToProject)) {
    const expertsView = {
      ...location,
      pathname: `/${params.token}/experts`,
    };

    return <Navigate to={expertsView} replace />;
  }

  // @ts-ignore
  return <InteractionsPage {...otherProps} />;
};

// Check whether auth has been ready at least once,
// so we can offer the correct routes to the user.
const useAuthHasBeenReady = () => {
  const isAuthReady = useUserAuthIsReady();
  const [authHasBeenReady, setAuthHasBeenReady] = useState(false);
  useEffect(
    function checkAuthHasBeenReady() {
      if (!authHasBeenReady) {
        isAuthReady && setAuthHasBeenReady(true);
      }
    },
    [isAuthReady] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return authHasBeenReady;
};
