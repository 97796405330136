import { flatExpertsWithRequests } from "components/InteractionsPage/helpers/Interaction";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { Hit, HitAction, HitOrigin } from "@alphasights/portal-api-client";
import {
  CustomerKnowledgeFilters,
  filterFunctions,
} from "pages/CustomerExpertPage/CustomerKnowledgeFilters/ckFilterService";
import useCustomerKnowledgeURL from "pages/CustomerExpertPage/useCustomerKnowledgeURL";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomerKnowledgeRecord } from "pages/CustomerExpertPage/helpers";

export const CustomerKnowledgeContext = React.createContext<CustomerKnowledgeProviderState | undefined>(undefined);

interface CustomerKnowledgeProviderProps {
  experts: any;
  clientRequests: any[];
  token: string;
  customerKnowledge: CustomerKnowledgeRecord[];
}

export interface CustomerKnowledgeProviderState {
  interactions: Interaction[];
  filteredInteractions: Interaction[];
  applyFilters: (filters: Partial<CustomerKnowledgeFilters>) => any;
  clearFilters: () => any;
  appliedFilters: CustomerKnowledgeFilters;
  filterInteractions: (interactions: Interaction[], filters: CustomerKnowledgeFilters) => Interaction[];
  logCKHit: (payload: Partial<Hit> & Pick<Hit, "action">) => void;
  hasAppliedFilters: boolean;
}

export const CustomerKnowledgeProvider = ({
  experts,
  customerKnowledge,
  clientRequests,
  ...props
}: CustomerKnowledgeProviderProps) => {
  const [appliedFilters, setAppliedFilters] = useState<CustomerKnowledgeFilters>({});
  const { logHit } = useTrackUserAction();

  const logCKHit = useCallback(
    (payload: Partial<Hit> & Pick<Hit, "action">) => {
      logHit({
        projectToken: props.token,
        origin: HitOrigin.customerView,
        ...payload,
      });
    },
    [logHit, props.token]
  );

  const url = useCustomerKnowledgeURL();

  const filterOptions = (props as any).filterOptions;

  useEffect(() => {
    if (Object.keys(filterOptions || {}).length === 0) return;

    setAppliedFilters(url.restore(filterOptions) || {});
  }, [filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const interactions = useMemo(() => {
    const ckExperts = experts.filter((expert: any) => {
      return expert.angleTypeName === "End Customers";
    });

    return flatExpertsWithRequests(ckExperts, clientRequests).map((interaction: Interaction) => ({
      ...interaction,
      customerKnowledges: customerKnowledge.find((ck) => ck.id === interaction.id)?.customerKnowledges || [],
    }));
  }, [experts, clientRequests, customerKnowledge]);

  const filterInteractions = useCallback(
    (interactions: Interaction[], filters: CustomerKnowledgeFilters): Interaction[] => {
      return Object.entries(filters).reduce((filtering, [filterName, values]) => {
        if (Array.isArray(values) && values.length === 0) return filtering;

        return filtering.filter((i) => {
          // @ts-ignore
          return filterFunctions[filterName](i, values);
        });
      }, interactions);
    },
    []
  );

  const filteredInteractions: Interaction[] = useMemo(() => {
    return filterInteractions(interactions, appliedFilters);
  }, [appliedFilters, interactions, filterInteractions]);

  const applyFilters = useCallback(
    (appliedFilter: CustomerKnowledgeFilters) => {
      const newFilters = {
        ...appliedFilters,
        ...appliedFilter,
      };

      setAppliedFilters(newFilters);
      url.apply(newFilters);

      logCKHit({
        action: HitAction.customerKnowledgeSearch,
        details: newFilters,
      });
    },
    [appliedFilters, url, logCKHit]
  );

  const clearFilters = useCallback(() => {
    setAppliedFilters({});
    url.apply({});

    logCKHit({
      action: HitAction.customerKnowledgeSearch,
    });
  }, [url, logCKHit]);

  const hasAppliedFilters = useMemo(() => {
    return !!(
      Object.values(appliedFilters).find((f) => f?.length > 0) ||
      Object.values(appliedFilters?.vendors ?? {}).find((v) => v?.length > 0)
    );
  }, [appliedFilters]);

  const context: CustomerKnowledgeProviderState = useMemo(
    () => ({
      interactions,
      filteredInteractions,
      applyFilters,
      clearFilters,
      appliedFilters,
      filterInteractions,
      logCKHit,
      hasAppliedFilters,
    }),
    [
      interactions,
      filteredInteractions,
      applyFilters,
      appliedFilters,
      filterInteractions,
      clearFilters,
      logCKHit,
      hasAppliedFilters,
    ]
  );

  return <CustomerKnowledgeContext.Provider value={context} {...props} />;
};

export const useCustomerKnowledgeContext = () => {
  const context = useContext(CustomerKnowledgeContext);

  if (!context) throw new Error("CustomerKnowledgeContext should only be used within the CustomerKnowledgeProvider");

  return context;
};
