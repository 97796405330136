// TODO: Populate with types from the backend and remove all `any` types

import { InteractionOrigin } from "views/DeliverablesView/constants";

export interface Expert {
  id: string;
  value: any;
  label: string;
  name: string;
  noWrittenWork: any;
  relevantCompany: any;
  relevantPosition: any;
  alphaCircleMultiplier: any;
  advisorLockState: any;
  hostedInteraction: any;
  hasCompletedOrScheduled: boolean;
  hasOnlyCompletedOrScheduled: boolean;
  canSendFollowUpMessage: any;
  canSendMessage: boolean;
  group: any;
  interactions: Interaction[];
  interaction: Interaction;
  interactionCount: number;
}

export interface InteractionsFetchResponse {
  edges?: {
    node: Interaction;
  }[];
}

export interface ExpertMessageSummaryItem {
  advisorId: string;
  lastMessageAt: number;
  hasRepliedMessages: 1 | 0;
}

export enum ThreadType {
  Messenger = "messenger",
  CallGuide = "call_guide",
}

export enum RequestType {
  CallGuide = "CALL_GUIDE",
  WorkRequest = "WORK_REQUEST",
  Clarification = "CLARIFICATION",
}

export enum MessageType {
  CallGuide = "Call Guide",
  Clarification = "Clarification",
  WorkRequest = "Work Request",
}

export enum ResponseStatusType {
  Creating = "Creating",
  Replied = "Replied",
  AwaitingResponse = "Awaiting Response",
  Sent = "Sent",
  AwaitingApproval = "Awaiting Approval",
  Closed = "Closed",
  Declined = "Declined",
  Cancelled = "Cancelled",
  ExpiredAwaitingApproval = "Expired Awaiting Approval",
  Expired = "Expired",
  PendingUpdate = "Pending Update",
}

export interface ThreadStateResponse {
  code: string;
  description: ResponseStatusType;
}

export interface WorkRequestHeaderResponse {
  id: string;
  maximumTimeAllowed: number;
  deadline: Date;
}

export interface MessageThreadHeaderResponse {
  id: string;
  state: ThreadStateResponse;
  createdAt: string;
  threadType: ThreadType;
  workRequest: WorkRequestHeaderResponse;
  groupId: string;
  advisor: MessageThreadHeaderParticipantResponse;
  hasUnreadMessagesOrPendingApproval: boolean;
  mostRecentMessageVersion?: MessageThreadHeaderMessageVersionResponse;
}

export interface MessageThreadHeaderParticipantResponse {
  id: string;
  systemId: number;
  name: string;
}

export interface MessageThreadHeaderMessageResponse {
  id: number;
  direction: string;
  status: string;
  isRead: boolean;
  createdAt: string;
  systemId?: number;
  name?: string;
  messageVersions: MessageThreadHeaderMessageVersionResponse[];
  obfuscated: Boolean;
}

export interface MessageThreadHeaderMessageVersionResponse {
  id: number;
  complianceReviewStatus?: ComplianceReviewStatus;
  content: string;
  createdAt: string;
}

export interface ParticipantResponse {
  id: string;
  name?: string;
  role: ParticipantRole;
  avatarColor?: string;
}

export interface AttachmentResponse {
  id: string;
  filename: string;
  type?: string | null;
  fileSize?: string | null;
}

export enum ComplianceReviewStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REJECTED_WITH_SUGGESTION = "REJECTED_WITH_SUGGESTION",
  SUGGESTION_PENDING = "SUGGESTION_PENDING",
  SUGGESTION_ACCEPTED = "SUGGESTION_ACCEPTED",
  SUGGESTION_DECLINED = "SUGGESTION_DECLINED",
  DO_NOT_REVIEW = "DO_NOT_REVIEW",
}

export interface MessageVersionResponse {
  id: number;
  complianceReviewStatus?: ComplianceReviewStatus;
  content: string;
  sanitizedContent: string;
  createdAt: string;
}

export interface MessageResponse {
  id: string;
  sender: ParticipantResponse;
  sentAt: string;
  attachments: AttachmentResponse[];
  content: string;
  sanitizedContent: string;
  contentId?: number;
  versionId?: number;
  status: string;
  createdAt: string;
  obfuscated: boolean;
  isRead: boolean;
  complianceReviewStatus?: ComplianceReviewStatus;
  originalContentOfSuggestion?: string;
  contentUpdateHistory?: MessageVersionResponse[];
}

export interface MessageMarkAsReadResponse {
  messageId: string;
}

export interface ThreadResponse {
  id: string;
  token: string;
  messages: MessageResponse[];
  requestType: RequestType;
  workRequest?: WorkRequestResponse;
  projectId: number;
  advisor: ParticipantResponse;
  status: ResponseStatusType;
  createdAt: string;
}

export interface WorkRequestResponse {
  id: string;
  maximumTimeAllowed: number;
  deadline: string;
  threadState?: ThreadStateResponse;
  threadStatus?: ResponseStatusType;
}

export interface InboxResponse {
  groupId: string;
  requestType: RequestType;
  mostRecentMessage: MessageResponse;
  mostRecentAdvisor: ParticipantResponse;
  threads: ThreadResponse[];
}

export enum ParticipantRole {
  Client = "client",
  Advisor = "advisor",
  Associate = "associate",
}

export interface Attachment {
  size: number;
  name: string;
  type: string;
  valid: boolean;
  file: File;
}

export interface ValidationResponse {
  isValid: boolean;
}

export enum ThirdPartyDocumentType {
  transcript = "transcript",
  unknown = "unknown",
}

export type ThirdPartyDocument = {
  id: string;
  originalFilename: string;
  fileType: string;
  documentType: string | null;
  processingStatus: string;
  statusMessage: string | null;
  attribute: {
    documentDate?: string;
    title?: string;
    attributes: {
      angle?: string;
      expertPosition?: string;
      expertCompanyName?: string;
      expertCompanyCdsAlphaCompanyId?: number;
    };
  };
  createdAt: string;
  redactedFilename?: string;
};

export type RequestQuestionsResponse = {
  id: string;
};

export type ThirdPartyInteraction = {
  scheduledCallTime: string;
  origin: InteractionOrigin.thirdParty;
  fileExtension: string;
} & ThirdPartyDocument;
