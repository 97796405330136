import React from "react";
import { Textile } from "components/Textile";
import ReactMarkdownHTML from "react-markdown/with-html";
import { AccordionItem, Button, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import "./index.css";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { x } from "@xstyled/styled-components";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useLocation, useNavigate } from "router-utils";
import { getHighlightedText } from "components/AdvisorCard/HighlightText";

export interface RelevantExperienceProps {
  title?: string;
  relevanceStatement?: string;
  relevantStatementType?: "MARKDOWN" | "TEXTILE";
  isVisible?: boolean;
  expanded?: boolean;
  showBorderTop?: boolean;
  showCompareExpertsButton?: boolean;
  interaction?: Interaction;
}

export const RelevantExperience = React.forwardRef(
  (
    {
      title = "Relevant Experience",
      relevanceStatement,
      relevantStatementType = "MARKDOWN",
      isVisible = true,
      expanded,
      showBorderTop = true,
      showCompareExpertsButton = false,
      interaction,
    }: RelevantExperienceProps,
    ref: React.Ref<HTMLLIElement>
  ) => {
    const { color } = useThemeTokens();
    const { isExpandedExpertProfile } = useFlyoutContext() ?? {};
    const { container } = useRevelantExperienceStyles();

    if (!isVisible) return null;
    if (!relevanceStatement) return null;

    const highlightedText = getHighlightedText({
      highlights: interaction?.highlights as never[],
      fieldNames: [
        "advisorships.relevance_statement",
        "advisorships.relevance_statement.concat",
        "relevanceStatement",
        "relevanceStatement.concat",
      ],
      text: relevanceStatement || "",
    });

    return (
      <AccordionItem
        data-testid="relevant-experience-accordion"
        titleContent={<Title title={title} />}
        open={isExpandedExpertProfile || expanded}
        ref={ref}
        {...(!showBorderTop && {
          borderTop: "none",
        })}
      >
        <x.div {...container}>
          <Typography color={color.text.strong._} component="div">
            {relevantStatementType === "MARKDOWN" ? (
              <ReactMarkdownHTML
                className="Advisor-RelevantStatement aui-break-words"
                escapeHtml={false}
                source={highlightedText}
              />
            ) : (
              <Textile className="Advisor-RelevantStatement">{highlightedText}</Textile>
            )}
          </Typography>
          {showCompareExpertsButton && interaction && <CompareExpertsButton interaction={interaction} />}
        </x.div>
      </AccordionItem>
    );
  }
);

const CompareExpertsButton = ({ interaction }: { interaction: Interaction }) => {
  const { isViewAccessible, isViewBeingGenerated, setReferrerOrigin, enableComparisonView } = useExpertCompareContext();
  const {
    currentView,
    state: { project },
  } = useProjectInteractionsContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!enableComparisonView || currentView === "comparison-view") return null;

  const angle = interaction.angles?.at(0);
  const angleId = angle?.parent?.id ?? angle?.id;
  const tooltip = isViewBeingGenerated
    ? "Comparison view is being generated."
    : "Not enough experts to compare at this time.";

  return (
    <div>
      <Tooltip title={tooltip} disabled={isViewAccessible}>
        <ButtonWithLoginWall
          variant="outline"
          size="small"
          disabled={!isViewAccessible}
          onClick={() => {
            setReferrerOrigin(`${currentView}-flyout`);
            const searchParams = new URLSearchParams(location.search);
            angleId && searchParams.set("selectedAngleId", angleId);
            navigate(`/${project.token}/experts/comparison-view${searchParams.toString() ? `?${searchParams}` : ""}`);
          }}
        >
          Compare Experts
        </ButtonWithLoginWall>
      </Tooltip>
    </div>
  );
};

const Title = ({ title }: { title: string }) => <Typography variant="body-large-em">{title}</Typography>;

const useRevelantExperienceStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base06,
    },
  };
};

const ButtonWithLoginWall = withLoginWall(Button);
