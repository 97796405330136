import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { fetch } from "../../hooks/useApi";
import { ResetPasswordForm, ResetPasswordRequested, AccountDeactivated, SignInFooter, Help } from "./index";
import { BackgroundPage, ModalForm } from "./BackgroundPage";
import { FormBody, FormContainer, FormFooter, FormHeader } from "./Form";
import { useParams, Navigate } from "router-utils";
import { NotFoundPage, useNotifications } from "@alphasights/client-portal-shared";
import { Button, Loading, Typography } from "@alphasights/alphadesign-components";
import { useLoginStyles } from "./index.styles";

export const PasswordResetRequestInvalid = ({
  state,
  error,
  isLoading,
  onClickResendResetPassword,
  onClickSignIn,
  onClickHelp,
}) => {
  const { returnSignInButton, modalTitle, modalDescription, buttonLink } = useLoginStyles();

  const title = {
    USED: "This reset link has already been used",
    EXPIRED: "The link has expired",
    CANCELED: "This reset link is no longer valid",
  }[state];

  const description = {
    EXPIRED: "The password reset link you are trying to use has expired.",
  }[state];

  return (
    <FormContainer isLoading={isLoading}>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            {title}
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            {description} To reset your password&nbsp;
            <Button variant="link" onClick={onClickResendResetPassword} {...buttonLink}>
              <Typography variant="body-em">click here.</Typography>
            </Button>
          </Typography>
        }
        error={error}
      />
      <FormBody>
        <Button variant="outline" onClick={onClickSignIn} {...returnSignInButton}>
          <Typography variant="body-em">Return to Sign In</Typography>
        </Button>
      </FormBody>
      <FormFooter>
        <SignInFooter showSignUp={false} onClickHelp={onClickHelp} />
      </FormFooter>
    </FormContainer>
  );
};

export const ResetPasswordPage = () => {
  const { token } = useParams();
  const [loadingError, setLoadingError] = useState(null);
  const [error, setError] = useState(null);
  const [resetRequest, setResetRequest] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [step, setStepInternal] = useState("loading");
  const [previousStep, setPreviousStep] = useState();
  const { showSuccessBanner } = useNotifications();
  const { loadingWrapper } = useLoginStyles();

  const setStep = (newstep) => {
    setPreviousStep(step);
    setStepInternal(newstep);
  };

  useEffect(() => {
    fetch({ url: `/api/auth/password-reset-requests/${token}` })
      .then((res) => res.json())
      .then((resetRequest) => {
        setResetRequest(resetRequest);
        setStep(resetRequest.state === "PENDING" ? "reset-password-form" : "invalid-state");
      })
      .catch((err) => {
        setLoadingError(err);
      });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickResetPassword = async ({ resend = false }) => {
    try {
      setLoading(true);
      await fetch({
        url: "/api/auth/password-reset-requests",
        method: "POST",
        body: JSON.stringify({ email: resetRequest.email }),
        skipAlert: true,
      });

      setError(null);
      setStep("reset-password-requested");
      if (resend) {
        showSuccessBanner("Password reset email resent.", {
          position: "bottom",
          useAlphaUI: false,
        });
      }
    } catch (err) {
      const { message } = await err.json();
      if (message === "User is disabled") {
        setStep("deactivated");
      } else {
        setError(message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onResetPassword = async ({ password }, { setErrors }) => {
    try {
      setError(null);
      setLoading(true);
      await fetch({
        url: `/api/auth/password-reset-requests/${token}/reset`,
        method: "POST",
        body: JSON.stringify({ newPassword: password }),
        skipAlert: true,
      });

      showSuccessBanner("Password successfully reset", {
        position: "bottom",
        useAlphaUI: false,
      });
      setStep("sign-in");
    } catch (err) {
      const { message, request } = await err.json();
      if (request?.state === "EXPIRED") {
        setResetRequest(request);
        setStep("invalid-state");
      } else if (message === "Password reset request cannot be executed because the user state is deactivated") {
        setStep("deactivated");
      } else if (message.includes("new password must be different")) {
        setErrors({
          password: "Must differ from previous password.",
          passwordConfirmation: message,
        });
      } else {
        setError(message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSignIn = () => setStep("sign-in");

  const redirectToHelp = () => setStep("help");

  const onClickReturn = () => setStep(previousStep);

  if (loadingError) {
    return <NotFoundPage />;
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={step}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames="LoginTransition"
      >
        <>
          {step === "loading" && (
            <BackgroundPage>
              <div style={loadingWrapper}>
                <Loading size="sm" />
              </div>
            </BackgroundPage>
          )}
          {step === "invalid-state" && (
            <ModalForm>
              <PasswordResetRequestInvalid
                error={error}
                state={resetRequest.state}
                isLoading={isLoading}
                onClickResendResetPassword={onClickResetPassword}
                onClickSignIn={onSignIn}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "reset-password-form" && (
            <ModalForm slotHeight="501px">
              <ResetPasswordForm
                error={error}
                email={resetRequest.email}
                onSubmit={onResetPassword}
                isLoading={isLoading}
                onClickResendResetPassword={onClickResetPassword}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "reset-password-requested" && (
            <ModalForm>
              <ResetPasswordRequested
                isLoading={isLoading}
                error={error}
                email={resetRequest.email}
                onClickResetPassword={onClickResetPassword}
                onClickReturnSignIn={onSignIn}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "deactivated" && (
            <ModalForm>
              <AccountDeactivated
                email={resetRequest.email}
                onClickEmail={() => setStep("sign-in")}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "sign-in" && (
            <Navigate
              to={{
                pathname: "/sign-in",
                search: resetRequest?.email ? `?email=${encodeURIComponent(resetRequest.email)}` : "",
              }}
            />
          )}
          {step === "help" && (
            <ModalForm>
              <Help onClickReturn={onClickReturn} />
            </ModalForm>
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};
