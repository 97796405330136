import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { hasOnlySurveyWorkstreams } from "components/InteractionsPage/helpers/Workstreams";
import { AngleQuestionCount, AngleQuestionTheme } from "models/AngleQuestions";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { angleQuestionsService } from "services/angleQuestionsService";

interface AngleQuestionsContextState {
  enableAngleQuestions: boolean;
  getAngleQuestionThemes: (angleId: string) => Promise<AngleQuestionTheme[]>;
  selectedAngleId?: string;
  setSelectedAngleId: (angleId: string) => void;
  angleQuestionsCount: AngleQuestionCount[];
  angleQuestionsCountLoading: boolean;
}

export interface AngleQuestionsContextProps {
  service?: typeof angleQuestionsService;
  project?: Project;
  children: ReactNode;
}

const AngleQuestionsContext = React.createContext<undefined | AngleQuestionsContextState>(undefined);

export const AngleQuestionsProvider = ({
  service = angleQuestionsService,
  project,
  children,
}: AngleQuestionsContextProps) => {
  const { isMobile } = useCheckScreen();
  const currentUser = useCurrentUser() as (User & { questionsViewEnabled: boolean }) | undefined;
  const isSurveyOnlyProject = useMemo(() => project && hasOnlySurveyWorkstreams(project), [project]);
  const projectToken = project?.token;
  const enableAngleQuestions = !!(currentUser?.questionsViewEnabled && !isSurveyOnlyProject && !isMobile);
  const [angleQuestionsCount, setAngleQuestionsCount] = useState<AngleQuestionCount[]>([]);
  const [angleQuestionsCountLoading, setAngleQuestionsCountLoading] = useState<boolean>(true);

  const [selectedAngleId, setSelectedAngleId] = React.useState<string | undefined>();

  const getAngleQuestionThemes = useCallback(
    (angleId: string) => {
      return projectToken ? service.findAngleQuestionThemes(projectToken, angleId) : Promise.resolve([]);
    },
    [projectToken, service]
  );

  useEffect(
    function getAngleQuestionsCount() {
      if (currentUser && projectToken) {
        service.getAngleQuestionsCount(projectToken).then((counts) => {
          setAngleQuestionsCount(counts);
          setAngleQuestionsCountLoading(false);
        });
      }
    },
    [currentUser, projectToken, service]
  );

  const context = {
    enableAngleQuestions,
    getAngleQuestionThemes,
    selectedAngleId,
    setSelectedAngleId,
    angleQuestionsCount,
    angleQuestionsCountLoading,
  };

  return <AngleQuestionsContext.Provider value={context} children={children} />;
};

export const useAngleQuestionsContext = () => {
  const context = useContext(AngleQuestionsContext);

  if (!context) throw new Error("AngleQuestionsContext should only be used within AngleQuestionsProvider");

  return context;
};
