import React, { useEffect, useMemo, useRef, useState } from "react";
import { validateEmail } from "../../pages/Login/validators";
import ReactTooltip from "react-tooltip";
import { CreatableSelect } from "../../components/Select";
import { IconButton, Icon, Typography, useThemeTokens, Tooltip } from "@alphasights/alphadesign-components";
import { Advisor, Close, Warning } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { emailValidatorService } from "services/emailValidatorService";
import * as S from "./AttendeesSection.styled";

export const AttendeesList = ({ attendees, removeAttendee, toggleOptionalAttendee }) => {
  useEffect(ReactTooltip.rebuild, []);
  const currentUser = useCurrentUser();

  return (
    <>
      {!currentUser && <Typography>Include your own email to receive an invite</Typography>}
      <div style={{ maxHeight: 150, overflow: "auto" }}>
        {attendees.map((attendee) => (
          <Attendee
            key={attendee.emailAddress}
            label={attendee.displayName || attendee.emailAddress}
            tooltip={attendee.emailAddress}
            optional={attendee.optional}
            onRemove={(attendees.length > 1 && removeAttendee(attendee)) || null}
            onToggleOptional={toggleOptionalAttendee(attendee)}
          />
        ))}
      </div>
    </>
  );
};

const RoundInitials = ({ name }) => {
  const { color } = useThemeTokens();
  const label = name
    ?.split(" ")
    .map((word) => word.substring(0, 1))
    .join("");
  return (
    <S.RoundInitialsContainer data-testid="round-initials">
      <Typography color={color.text.secondary} variant="pre-title" component="span">
        {label && label.substring(0, 2)}
      </Typography>
    </S.RoundInitialsContainer>
  );
};

export const AttendeesSelect = ({ options, currentAttendees, setCurrentAttendees }) => {
  const [invalid, setInvalid] = useState(false);
  const [input, setInput] = useState("");
  const ref = useRef(null);

  const loadOptions = (input, updateOptions) => {
    updateOptions(options.filter((o) => o.displayName.toLowerCase().includes(input.toLowerCase())));
  };

  const notSelectedOptions = useMemo(() => {
    return options.filter((opt) => !currentAttendees.map((e) => e.emailAddress).includes(opt.emailAddress));
  }, [options, currentAttendees]);

  const onCreateOption = (input) => {
    emailValidatorService.validateCalendarInvitationEmails(input.emailAddress || input).then((response) => {
      if (response.isValid) {
        setInvalid(false);
        setInput("");
        addAttendees(input);
      } else {
        setInvalid(true);
        ref.current.select.select.select.inputRef.focus();
        ref.current.select.select.select.inputRef.blur();
      }
    });
  };

  const addAttendees = (input) => {
    if (typeof input === "object" && !input.emailAddress.includes(",")) {
      setCurrentAttendees([...currentAttendees, { ...input, optional: false }]);
    } else {
      const emails = (input.emailAddress || input)
        .split(",")
        .map((entry) => entry.trim())
        .filter((entry) => entry);
      setCurrentAttendees([...currentAttendees, ...emails.map((email) => ({ emailAddress: email, optional: false }))]);
    }
  };

  const onChange = (option, { action }) => {
    return action === "select-option" && onCreateOption(option);
  };

  const validateInput = (input) => !validateEmail(input);

  const onInputChange = (value, action) => {
    if (action.action === "input-change") setInput(value);
  };

  return (
    <>
      <CreatableSelect
        ref={ref}
        getOptionValue={(option) => option.emailAddress}
        getOptionLabel={(option) => option.displayName || option.emailAddress}
        defaultOptions={notSelectedOptions}
        noOptionsMessage={() => "Enter valid emails separated by comma"}
        getNewOptionData={(emailAddress) => {
          const emailCount = emailAddress.split(",").filter((val) => val).length;
          return emailCount > 1
            ? { emailAddress, displayName: `Add ${emailCount} emails...` }
            : { emailAddress, displayName: emailAddress };
        }}
        isValidNewOption={(input) =>
          input &&
          !input
            .split(",")
            .map((entry) => entry.trim())
            .filter((entry) => entry)
            .find((entry) => !validateInput(entry))
        }
        placeholderMenuOpen="Add colleagues"
        placeholderMenuClosed="Add colleagues"
        onChange={onChange}
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        inputValue={input}
        onInputChange={onInputChange}
      />
      {invalid && (
        <Typography color="danger" variant="body-small" mt="8px">
          Invalid email address
        </Typography>
      )}
    </>
  );
};

export const LoadingBar = () => (
  <S.StyledContent viewBox="0 0 360 30" data-testid="attendees-section-placeholder">
    <rect x="17" y="10" width="250" height="22" />
  </S.StyledContent>
);

const Attendee = ({ label, optional, tooltip, onClick, onRemove, onToggleOptional }) => {
  const { color } = useThemeTokens();
  const isAlphasightsEmail = (label || "").toLowerCase().endsWith("@alphasights.com");
  return (
    <S.AttendeeContainer onClick={onClick} data-testid={`attendee-${label}`}>
      <S.AttendeeInfo title={tooltip}>
        <RoundInitials name={label} />
        <S.LabelWrapper isAlphasightsEmail={isAlphasightsEmail}>
          {isAlphasightsEmail && (
            <Tooltip
              data-testid="tooltip-warning"
              title="Alphasights emails will be saved even though they won't show here once you close this popup"
            >
              <Icon>
                <Warning data-testid="warning-icon" />
              </Icon>
            </Tooltip>
          )}
          <Typography component="span">{label}</Typography>
        </S.LabelWrapper>
      </S.AttendeeInfo>

      <S.ActionContainer>
        <Tooltip dark title={optional ? "Mark Required" : "Mark Optional"}>
          <S.OptionalToggle>
            <IconButton
              variant="ghost"
              onClick={onToggleOptional}
              color={optional ? color.icon.assistive : color.icon.strong}
            >
              <Advisor data-testid="toggle-optional" />
            </IconButton>
          </S.OptionalToggle>
        </Tooltip>

        <Tooltip dark title={onRemove ? "Remove" : "Can't remove last attendee"}>
          <S.HiddenIconButton
            color={color.icon.assistive}
            variant="ghost"
            onClick={onRemove}
            disabled={!onRemove}
            className="hover-icon"
          >
            <Close />
          </S.HiddenIconButton>
        </Tooltip>
      </S.ActionContainer>
    </S.AttendeeContainer>
  );
};
