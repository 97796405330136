import { useQueryClient } from "query-utils";
import { isEmpty } from "lodash";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { useNewNavigation } from "hooks/useNewNavigationUnbadge";
import { useCurrentUser } from "@alphasights/portal-auth-react";

import { Badge } from "models/Badge";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { contentService, projectService } from "services/content";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { MatchedOnField, MatchedOnType } from "models/companies/search/constants";
import useContentClientSettings from "hooks/useContentClientSettings";
import {
  COLLEAGUE_SEARCH_ID,
  COMPANY_SEARCH_ID,
  MARKET_SEARCH_ID,
} from "pages/AlphaNowPage/components/AlphaNowSearch/constants";
import {
  getNumCompaniesVisible,
  hasSearchFailure,
  processSearchResults,
} from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import {
  SearchSuggestion,
  SearchPromise,
  KeywordSuggestion,
  CompanySuggestion,
  CompanyKeywordSuggestion,
  ColleagueSuggestion,
  MarketSuggestion,
  ProjectSuggestion,
  ProjectSearchResult,
} from "pages/AlphaNowPage/components/AlphaNowSearch/types";
import {
  alphaNowSearchErrorMessage,
  MAX_NUMBER_COMPANIES_DEFAULT,
  MAX_NUMBER_COMPANIES_NEW_NAV,
  MAX_NUMBER_MARKETS,
} from "./constants";
import { SEARCH_SECTIONS } from "constants/AlphaNow";

class AlphaNowSearchError extends Error {
  constructor(msg: string) {
    super(`${alphaNowSearchErrorMessage} ${msg}`);
  }
}

const useAlphaNowSearch = () => {
  const currentUser = useCurrentUser();
  const isAlphaNowEnabled = currentUser?.alphaNowEnabled;
  const queryClient = useQueryClient();
  const { isMobile } = useCheckScreen();
  const { hasUserBadge } = useUserBadgeContext();
  const {
    query: { searchQuery },
  } = useAppSearchContext();
  const { clientSettings } = useContentClientSettings();

  const hasRelevantColleaguesBadge = hasUserBadge(Badge.privateContributor);
  const hasRelevantColleaguesEnabled = hasRelevantColleaguesBadge && !!clientSettings?.showRelevantColleagues;
  const hasMarketsEnabled = !isMobile;
  const newNavigationEnabled = useNewNavigation();
  const hasProjectsEnabled = newNavigationEnabled && searchQuery.length === 0;
  //TODO [RD1-209]: Remove when new Navigation enabled
  const MAX_NUMBER_COMPANIES = newNavigationEnabled ? MAX_NUMBER_COMPANIES_NEW_NAV : MAX_NUMBER_COMPANIES_DEFAULT;

  const fetchResearchLibrarySearchSuggestions: (inputValue: string) => SearchPromise[] = (inputValue) => {
    const fetchPromises = [
      {
        id: COMPANY_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-companies", inputValue], () =>
          contentService.searchCompanies(inputValue, MAX_NUMBER_COMPANIES)
        ),
      },
    ];

    if (hasRelevantColleaguesEnabled) {
      fetchPromises.push({
        id: COLLEAGUE_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-colleagues", inputValue], () =>
          contentService.searchColleagues(inputValue)
        ),
      });
    }

    if (hasMarketsEnabled) {
      fetchPromises.push({
        id: MARKET_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-markets", inputValue], () =>
          contentService.searchMarkets(inputValue, MAX_NUMBER_MARKETS)
        ),
      });
    }

    return fetchPromises;
  };

  const fetchProjectSearchResults = async (inputValue: string) => {
    try {
      const projectSearchResults = await queryClient.fetchQuery(["search-projects", inputValue], () =>
        projectService.searchProjects(inputValue)
      );
      return projectSearchResults;
    } catch (error) {
      throw error;
    }
  };

  const getProjectSearchOptions = async (inputValue: string) => {
    try {
      const projects = (await fetchProjectSearchResults(inputValue)) as ProjectSearchResult[];
      return projects.map(
        ({ title, id, token, targetCompanies }) =>
          ({
            label: title,
            value: title,
            token,
            inputValue,
            id,
            targetCompanies,
            type: SEARCH_SUGGESTION_TYPES.Project,
            section: SEARCH_SECTIONS.Project,
          } as ProjectSuggestion)
      );
    } catch (error) {
      handleSearchFailure();
    }
  };

  const getResearchLibrarySearchOptions = async (inputValue: string) => {
    try {
      const researchLibraryFetches = fetchResearchLibrarySearchSuggestions(inputValue);
      const researchLibrarySearchResults = researchLibraryFetches.map((fetchObj) => fetchObj.promise);
      const results = await Promise.allSettled(researchLibrarySearchResults);
      const { companies, colleagues, markets } = processSearchResults(results, researchLibraryFetches);

      if (hasSearchFailure(results)) {
        handleSearchFailure();
      }
      const primaryNameExactMatches = companies.filter(
        ({ matchedOn: { field, type } }) => field === MatchedOnField.PrimaryName && type === MatchedOnType.Exact
      );

      const options: SearchSuggestion[] = [];
      if (isEmpty(primaryNameExactMatches)) {
        options.push({
          label: inputValue[0].toUpperCase() + inputValue.substring(1),
          value: inputValue,
          type: SEARCH_SUGGESTION_TYPES.Keyword,
          section: SEARCH_SECTIONS.ResearchLibrary,
        } as KeywordSuggestion);
      } else {
        primaryNameExactMatches.forEach(({ cdsAlphaCompanyId, primaryName }) => {
          options.push({
            label: inputValue[0].toUpperCase() + inputValue.substring(1),
            value: primaryName,
            id: cdsAlphaCompanyId,
            type: SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch,
            section: SEARCH_SECTIONS.ResearchLibrary,
          } as CompanyKeywordSuggestion);
        });
      }
      //TODO [RD1-209]: Remove when new Navigation enabled
      if (newNavigationEnabled) {
        const numCompaniesVisible = getNumCompaniesVisible(
          inputValue,
          markets.length,
          colleagues.length,
          MAX_NUMBER_COMPANIES
        );
        companies.slice(0, numCompaniesVisible).forEach(({ cdsAlphaCompanyId, primaryName, logo }) => {
          options.push({
            label: primaryName,
            value: primaryName,
            inputValue,
            id: cdsAlphaCompanyId,
            type: SEARCH_SUGGESTION_TYPES.Company,
            section: SEARCH_SECTIONS.ResearchLibrary,
            logo,
          } as CompanySuggestion);
        });
      }

      colleagues.forEach(({ id, name }) => {
        options.push({
          label: name,
          value: name,
          inputValue,
          id,
          type: SEARCH_SUGGESTION_TYPES.Colleague,
          section: SEARCH_SECTIONS.ResearchLibrary,
        } as ColleagueSuggestion);
      });

      markets.forEach(({ id, name }) => {
        options.push({
          label: name,
          value: name,
          inputValue,
          id,
          type: SEARCH_SUGGESTION_TYPES.Market,
          section: SEARCH_SECTIONS.ResearchLibrary,
        } as MarketSuggestion);
      });

      //TODO [RD1-209]: Remove when new Navigation enabled
      if (!newNavigationEnabled) {
        companies.forEach(({ cdsAlphaCompanyId, primaryName, logo }) => {
          options.push({
            label: primaryName,
            value: primaryName,
            inputValue,
            id: cdsAlphaCompanyId,
            type: SEARCH_SUGGESTION_TYPES.Company,
            logo,
            section: SEARCH_SECTIONS.ResearchLibrary,
          } as CompanySuggestion);
        });
      }
      //TODO [RD1-209]: Remove when new Navigation enabled
      return options;
    } catch {
      handleSearchFailure();
    }
  };

  const handleSearchFailure = () => {
    throw new AlphaNowSearchError("Search failed");
  };

  const loadSearchSuggestions = async (inputValue: string) => {
    // User should not be able to search if they are not logged in
    if (!currentUser) return [];

    if (inputValue.length <= 1) return [];

    //TODO [RD1-209]: Remove when new Navigation enabled
    if (!newNavigationEnabled) {
      const [researchLibraryOptions, projectOptions] = await Promise.all([
        isAlphaNowEnabled ? getResearchLibrarySearchOptions(inputValue) : Promise.resolve([]),
        hasProjectsEnabled ? getProjectSearchOptions(inputValue) : Promise.resolve([]),
      ]);

      return [...(researchLibraryOptions as SearchSuggestion[]), ...(projectOptions as SearchSuggestion[])];
    }

    const [researchLibraryOptions, projectOptions] = await Promise.allSettled([
      isAlphaNowEnabled ? getResearchLibrarySearchOptions(inputValue) : Promise.resolve([]),
      hasProjectsEnabled ? getProjectSearchOptions(inputValue) : Promise.resolve([]),
    ]);

    return [
      { ...researchLibraryOptions, section: SEARCH_SECTIONS.ResearchLibrary },
      { ...projectOptions, section: SEARCH_SECTIONS.Project },
    ];
  };

  return {
    loadSearchSuggestions,
  };
};

export { useAlphaNowSearch as default, AlphaNowSearchError };
