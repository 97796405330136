import React, { forwardRef } from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TransformedTab } from "views/SurveysResultsView/api/types";
import SubTab from "views/SurveysResultsView/components/DataVisualization/components/SubTab";

type TabProps = { tab: TransformedTab };

const Tab = forwardRef<HTMLDivElement, TabProps>(({ tab }, ref) => {
  const { color, spacing, shape } = useThemeTokens();
  const { name, display, subTabs } = tab;

  return (
    <x.div
      ref={ref}
      display="flex"
      flexDirection="column"
      data-testid={`tab-section-${name}`}
      backgroundColor={color.background.surface.page.default}
      borderRadius={shape.border.radius[8]}
      border={`1px solid ${color.border.neutral.default}`}
    >
      <x.div
        display="flex"
        data-testid="tab-title"
        alignItems="center"
        padding={`${spacing.layout.base03} ${spacing.layout.base05}`}
      >
        <Typography variant="body-large-em">{display}</Typography>
      </x.div>
      {subTabs.map((subTab) => (
        <SubTab key={subTab.display} subTab={subTab} />
      ))}
    </x.div>
  );
});

export default Tab;
