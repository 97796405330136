import React from "react";

import { ActionColumn } from "./ActionColumn";
import { AdvisorColumn } from "./AdvisorColumn";
import { StarColumn } from "./StarColumn";
import { HideColumn } from "./HideColumn";
import { StatusColumn } from "./StatusColumn";
import { HideableCell } from "./HideableCell";
import { LabelColumn } from "./LabelColumn";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { Typography, IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { CommentIcon } from "components/CommentThread/CommentIcon";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { CidBadge } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { getRolePeriodDuration } from "helpers/displayHelpers";
import { MessageStatusColumn } from "./MessageStatusColumn";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { useLabelsContext } from "providers/LabelsProvider";
import { priority } from "providers/MessengerProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Advisor } from "@alphasights/alphadesign-icons";
import { MessageThreadHeaderResponse } from "types";
import { ExpertTableColumnDefinition, ExpertTableRow } from "models/ExpertTable";

const statusOrder = ["available", "requested", "scheduled", "completed"];

const useBuildColumnDefinitions = ({
  interactions,
  onSelectCard,
  preferencesEnabled,
  project,
  userCidEnabled,
  origin,
}: {
  interactions: Interaction[];
  onSelectCard: (interaction: Interaction) => void;
  preferencesEnabled: boolean;
  project: Project;
  userCidEnabled: boolean;
  origin?: string;
}): { [key: string]: ExpertTableColumnDefinition } => {
  preferencesEnabled = preferencesEnabled && project.active;
  const { getWorkRequestStatus } = useMessageThreadContext();
  const { getLabelledExpert } = useLabelsContext();
  const { isMobile } = useCheckScreen();

  const groupOf = (interaction: Interaction) => {
    const { group } = interaction;
    return group.parent ? group.parent.name : group.name;
  };

  const subGroupOf = (interaction: Interaction) => {
    const { group } = interaction;
    return group.parent ? group.name : null;
  };

  const sortTenure = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const period1 = getRolePeriodDuration(row1.original.rolePeriod);
    const period2 = getRolePeriodDuration(row2.original.rolePeriod);

    return period1 > period2 ? 1 : -1;
  };

  const sortLabels = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const expertId1 = row1.original.advisorId;
    const angle1 = row1.original.angles?.at(0);
    const angleId1 = angle1 && angle1?.id;
    const label1 = angleId1 ? getLabelledExpert(expertId1, angleId1) : undefined;
    const label1Text = label1?.label.text ?? "";

    const expertId2 = row2.original.advisorId;
    const angle2 = row2.original.angles?.at(0);
    const angleId2 = angle2 && angle2?.id;
    const label2 = angleId2 ? getLabelledExpert(expertId2, angleId2) : undefined;
    const label2Text = label2?.label.text ?? "";

    return label1Text.localeCompare(label2Text, undefined, { sensitivity: "accent" });
  };

  const sortStatus = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    return statusOrder.indexOf(row1.original.state) > statusOrder.indexOf(row2.original.state) ? 1 : -1;
  };

  const sortMessageStatus = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const row1Status = getWorkRequestStatus({
      expertId: row1.original.advisorId,
    });
    const row2Status = getWorkRequestStatus({
      expertId: row2.original.advisorId,
    });

    return priority.indexOf(row2Status[0]?.state.description) > priority.indexOf(row1Status[0]?.state.description)
      ? 1
      : -1;
  };

  const defaultColumnConfig = {
    enablePin: preferencesEnabled,
    enableDragDrop: preferencesEnabled,
    sortAscMeta: {
      label: "Sort A-Z",
    },
    sortDescMeta: {
      label: "Sort Z-A",
    },
  };

  return {
    actionColumn: {
      id: "actionColumn",
      header: "",
      cell: ({ row: { original: interaction } }) => (
        <div className="action-column" data-testid={`action-column-${interaction.id}`}>
          <ActionColumn origin={origin} interaction={interaction} isMobile={isMobile} />
        </div>
      ),
      meta: {
        enableColumnActions: false,
        enableDragDrop: false,
        enableResizing: false,
      },
      size: 170,
    },

    groupColumn: {
      id: "groupColumn",
      header: "Angle",
      accessorFn: (row) => groupOf(row),
      sortingFn: "alphanumeric",
      size: 180,
      minSize: 94,
      cell: ({ row: { original: interaction } }) => {
        const groupText = groupOf(interaction);

        return (
          <HideableCell hidden={interaction.hidden}>
            <Tooltip title={groupText.length > 1 ? groupText : ""} enterDelay={10} leaveDelay={10}>
              <Typography
                variant="body-small"
                component="span"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
                data-testid={`advisor-row-group-${interaction.id}`}
              >
                {groupText}
              </Typography>
            </Tooltip>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    subGroupColumn: {
      id: "subGroupColumn",
      header: "Sub-Angle",
      size: 180,
      minSize: 125,
      accessorFn: (row) => subGroupOf(row),
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => {
        const subGroupText = subGroupOf(interaction);

        return (
          <HideableCell hidden={interaction.hidden}>
            <Tooltip title={(subGroupText?.length ?? 0) > 1 ? subGroupText : ""} enterDelay={10} leaveDelay={10}>
              <Typography
                variant="body-small"
                component="span"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
                data-testid={`advisor-row-subgroup-${interaction.id}`}
              >
                {subGroupText ?? ""}
              </Typography>
            </Tooltip>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    expertColumn: {
      id: "advisorColumn",
      header: "Expert",
      minSize: 94,
      cell: AdvisorColumn,
      accessorKey: "advisorName",
      sortingFn: "alphanumeric",
      meta: { ...defaultColumnConfig },
    },

    cidStatusColumn: {
      id: "cidColumn",
      header: "CID Status",
      accessorFn: (row) => row.externalCidStatus,
      sortingFn: "alphanumeric",
      minSize: 125,
      size: 125,
      cell: ({ row: { original: interaction } }) => {
        return (
          <HideableCell hidden={false}>
            <CidBadge
              userCidEnabled={userCidEnabled}
              externalCidStatus={interaction.externalCidStatus}
              useFullWidth
            ></CidBadge>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    companyColumn: {
      id: "companyColumn",
      header: "Relevant Company",
      accessorKey: "advisorCompany",
      sortingFn: "alphanumeric",
      minSize: 176,
      cell: ({
        row: {
          original: { id, highlights, advisorCompany, hidden, angles, advisorId, doubleBlinded },
        },
      }) => (
        <HideableCell hidden={hidden}>
          <HighlightTextTypography
            data-testid={`advisor-row-company-${id}`}
            variant="body-small"
            component="span"
            highlights={highlights}
            fieldNames={[
              "advisorships.relevant_company_name",
              "advisorships.relevant_company_name.concat",
              "relevantPrimaryJob.companyName",
              "relevantPrimaryJob.companyName.concat",
            ]}
            text={doubleBlinded ? "Company name withheld" : advisorCompany}
          />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    relevantPositionColumn: {
      id: "positionColumn",
      header: "Relevant Position",
      accessorKey: "role",
      sortingFn: "alphanumeric",
      minSize: 184,
      cell: ({
        row: {
          original: { id, role, hidden, highlights, angles, advisorId },
        },
      }) => (
        <HideableCell hidden={hidden}>
          <HighlightTextTypography
            data-testid={`advisor-row-role-${id}`}
            variant="body-small"
            component="span"
            highlights={highlights}
            fieldNames={[
              "advisorships.relevant_job_title",
              "advisorships.relevant_job_title.concat",
              "relevantPrimaryJob.jobTitle",
              "relevantPrimaryJob.jobTitle.concat",
            ]}
            text={role}
          />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    tenureColumn: {
      id: "tenureColumn",
      header: "Tenure",
      accessorKey: "rolePeriod",
      sortingFn: sortTenure,
      minSize: 150,
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={interaction.hidden}>
          <Typography variant="body-small" component="span">
            {interaction.rolePeriod}
          </Typography>
        </HideableCell>
      ),
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Shortest-Longest",
        },
        sortDescMeta: {
          label: "Sort Longest-Shortest",
        },
      },
    },

    geographicKnowledgeColumn: {
      id: "geographicKnowledgeColumn",
      header: "Geographic Knowledge",
      hideIf: ({ hasColumnPreferences }) => !hasColumnPreferences || !interactions.some((i) => i.geographies.length),
      minSize: 210,
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={interaction.hidden}>
          <Tooltip
            title={interaction.geographies.length > 1 ? interaction.geographies.join(", ") : ""}
            enterDelay={10}
            leaveDelay={10}
          >
            <Typography
              variant="body-small"
              component="span"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inline-block",
                maxWidth: "100%",
              }}
            >
              {interaction.geographies.join(", ")}
            </Typography>
          </Tooltip>
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    statusColumn: {
      id: "statusColumn",
      header: "Call Status",
      accessorKey: "state",
      sortingFn: sortStatus,
      minSize: 220,
      cell: StatusColumn,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    labelColumn: {
      id: "labelColumn",
      header: "Label",
      accessorKey: "label",
      sortingFn: sortLabels,
      minSize: 220,
      cell: LabelColumn,
      meta: {
        ...defaultColumnConfig,
        columnDescription: "Labels are visible to your colleagues and your AlphaSights team.",
        sortAscMeta: {
          label: "Sort A-Z",
        },
        sortDescMeta: {
          label: "Sort Z-A",
        },
      },
    },

    messageStatusColumn: {
      id: "messageStatusColumn",
      header: "Message Status",
      accessorKey: "state",
      sortingFn: sortMessageStatus,
      minSize: 260,
      cell: MessageStatusColumn,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    starColumn: {
      id: "starColumn",
      header: "",
      cell: StarColumn,
      size: 52,
      minSize: 52,
      enableResizing: false,
      columnName: "Star",
      meta: {
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    hideColumn: {
      id: "hideColumn",
      header: "",
      enableResizing: false,
      cell: HideColumn,
      size: 52,
      minSize: 52,
      columnName: "Hide",
      meta: {
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    showProfileColumn: {
      id: "showProfile",
      header: "",
      size: 52,
      minSize: 52,
      enableResizing: false,
      cell: ({ row: { original: data } }) => (
        <IconButton variant="ghost" onClick={() => onSelectCard(data)}>
          <Advisor />
        </IconButton>
      ),
      columnName: "Show Profile",
      meta: { ...defaultColumnConfig },
    },

    commentsColumn: {
      id: "commentsColumn",
      header: "",
      enableResizing: false,
      cell: ({ row: { original: data } }) => (
        <CommentIconWithLoginWall
          angleId={data.angles?.at(0)}
          expertId={data.advisorId}
          tooltip="Comment"
          interaction={data}
        />
      ),
      size: 52,
      minSize: 52,
      columnName: "Comments",
      meta: {
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    proposedAtColumn: {
      id: "proposedAtColumn",
      header: "Date Added",
      accessorKey: "proposedAt",
      minSize: 130,
      cell: ({ row: { original: data } }) => {
        return <FormattedDateTime date={data.proposedAt} format="d LLL, h:mmaaa" />;
      },
      size: 45,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Oldest to Newest",
        },
        sortDescMeta: {
          label: "Sort Newest to Oldest ",
        },
      },
    },
  };
};

const filterColumnDefinitions = ({
  columns,
  project,
  showComments,
  isProjectCidEnabled,
  isMobile,
  isCustomerKnowledgeEnabled,
  threadHeaders,
  interactions,
}: {
  columns: { [key: string]: ExpertTableColumnDefinition };
  project: Project;
  showComments: boolean;
  isProjectCidEnabled: boolean;
  isMobile: boolean;
  isCustomerKnowledgeEnabled?: boolean;
  threadHeaders: MessageThreadHeaderResponse[];
  interactions: Interaction[];
}): ExpertTableColumnDefinition[] => {
  const columnDefs = [];

  if (project.active) {
    columnDefs.push(columns.actionColumn);
  }

  if (project.enableLabels) {
    columnDefs.push(columns.labelColumn);
  }

  if (showComments) columnDefs.push(columns.commentsColumn);

  if (isCustomerKnowledgeEnabled) {
    columnDefs.push(columns.currentVendorColumn);
    columnDefs.push(columns.otherVendorsColumn);
    columnDefs.push(columns.roleColumn);
  }

  columnDefs.push(columns.groupColumn);

  if (interactions.some((i) => i.hasSubGroup)) {
    columnDefs.push(columns.subGroupColumn);
  }

  columnDefs.push(columns.expertColumn);

  if (project.active && isProjectCidEnabled) columnDefs.push(columns.cidStatusColumn);

  columnDefs.push(columns.companyColumn);
  columnDefs.push(columns.relevantPositionColumn);
  columnDefs.push(columns.tenureColumn);

  columnDefs.push(columns.statusColumn);

  columnDefs.push(columns.proposedAtColumn);

  if (project.enablePortalMessages && threadHeaders.length > 0) {
    columnDefs.push(columns.messageStatusColumn);
  }

  if (project.active) {
    columnDefs.push(columns.geographicKnowledgeColumn);
  }

  columnDefs.push(columns.starColumn);
  columnDefs.push(columns.hideColumn);

  if (isMobile) columnDefs.push(columns.showProfileColumn);

  return columnDefs;
};

const CommentIconWithLoginWall = withLoginWall(CommentIcon);

export { useBuildColumnDefinitions, filterColumnDefinitions };
