import React, { useRef } from "react";
import { FloatingActions, useHelperActions } from "components/FloatingActions";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { Typography } from "@alphasights/alphadesign-components";
import { OVERVIEW_TITLE } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentSummaryTab/consts";
import { OverviewContainer } from "./OverviewDisplay.styled";

const dataTestIds = {
  summaryOverview: "overview",
};

const SummaryOverview = ({ overview }: { overview: string | null }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { copyRefAction } = useHelperActions();

  return overview ? (
    <OverviewContainer>
      <FloatingActions highlightOnHover={false} actions={[copyRefAction(ref)]}>
        <div ref={ref} data-testid={dataTestIds.summaryOverview}>
          <EmphasisTypography>
            <Typography variant="body-em" component="span">
              {OVERVIEW_TITLE}{" "}
            </Typography>
            {overview}
          </EmphasisTypography>
        </div>
      </FloatingActions>
    </OverviewContainer>
  ) : null;
};

export { SummaryOverview as default, dataTestIds };
