import React from "react";
import { x } from "@xstyled/styled-components";
import { useProjectMembersContext } from "providers/ProjectMembersProvider";
import reactStringReplace from "react-string-replace";
import { Popover, Button, useThemeTokens, Avatar, Typography, Icon } from "@alphasights/alphadesign-components";
import { FormattedDateRelative, useTimezone } from "../../providers/TimezoneProvider";
import { MoreVert, Edit, Delete } from "@alphasights/alphadesign-icons";
import { CommentNotedBy } from "./CommentNotedBy";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { color, shape } = tokens;

export const CommentRow = ({
  comment,
  onCommentEdit,
  editingCommentId,
  editingValue,
  onCommentRemove,
  avatarBgColor,
  showMobileView,
}) => {
  const { clients = [], csts = [] } = useProjectMembersContext();
  const {
    containerStyles,
    headStyles,
    avatarStyles,
    headDataStyles,
    nameRowStyles,
    creatorNameTypography,
    newCommentMarkStyles,
    commentRowStyles,
    editedContentStyle,
  } = useCommentRowStyles(showMobileView);

  const tz = useTimezone();

  const { id, content, createdAt, creatorName, selfOwner, readAt, editedAt, editable, creatorAvatarUrl } = comment;

  const onClickEdit = () => {
    onCommentEdit(id);
  };

  const onClickDelete = () => {
    onCommentRemove(id);
  };

  return (
    <x.div data-comment-id={id} {...containerStyles}>
      <x.div {...headStyles}>
        <Avatar src={creatorAvatarUrl} text={creatorName} color={avatarBgColor} {...avatarStyles} />
        <x.div {...headDataStyles}>
          <x.div {...nameRowStyles}>
            <Typography {...creatorNameTypography}>{creatorName}</Typography>
            {!readAt && <x.div {...newCommentMarkStyles} />}
          </x.div>
          <Typography variant="body-small" title={tz.format(createdAt, "yyyy-MM-dd HH:mm:ss")} color="secondary">
            <FormattedDateRelative date={createdAt} />
          </Typography>
        </x.div>
        {selfOwner && !showMobileView && (
          <PopoverMenu onClickDelete={onClickDelete} onClickEdit={onClickEdit} editable={editable} />
        )}
      </x.div>
      {renderContent({
        content: editingCommentId === id ? editingValue : content,
        editedAt,
        members: [...clients, ...csts],
        commentRowStyles,
        editedContentStyle,
      })}
      {comment.notedBy && <CommentNotedBy comment={comment} />}
    </x.div>
  );
};

const renderContent = ({ content, editedAt, members, commentRowStyles, editedContentStyle }) => {
  const taggedMembers = members.filter(({ displayName }) => content.includes(`@${displayName}`));
  const makeBlue = (text) => (
    <Typography key={`blue-${text}`} component="span" data-testid="tagged-user" color="info" variant="body-small">
      {text}
    </Typography>
  );
  const highlightContent = taggedMembers.reduce(
    (acc, member) => reactStringReplace(acc, `@${member.displayName}`, makeBlue),
    content
  );
  const editedContent = () => {
    return editedAt && <span style={editedContentStyle}>(edited)</span>;
  };
  return (
    <Typography variant="body-small" {...commentRowStyles}>
      {highlightContent} {editedContent()}
    </Typography>
  );
};

export const useCommentRowStyles = (showMobileView) => {
  const {
    color: { text, background },
    spacing: { inner },
  } = useThemeTokens();

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
  };

  const headStyles = {
    display: "flex",
    alignItems: "center",
    pr: inner.base,
  };

  const avatarStyles = {
    size: showMobileView ? "small" : "medium",
  };

  const headDataStyles = {
    display: "flex",
    flexDirection: "column",
    pl: inner.base02,
    ...(showMobileView && {
      flexDirection: "row",
      justifyContent: "space-between",
      flexGrow: 1,
    }),
  };

  const nameRowStyles = {
    display: "flex",
    alignItems: "center",
  };

  const creatorNameTypography = {
    variant: showMobileView ? "body-em" : "body-small-em",
  };

  const newCommentMarkStyles = {
    w: "4px",
    h: "4px",
    borderRadius: "4px",
    ml: inner.base02,
    backgroundColor: background.selected.strong.default,
  };

  const commentRowStyles = {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    mt: inner.base02,
  };

  const editedContentStyle = {
    color: text.disabled,
  };

  return {
    containerStyles,
    headStyles,
    avatarStyles,
    headDataStyles,
    nameRowStyles,
    creatorNameTypography,
    newCommentMarkStyles,
    commentRowStyles,
    editedContentStyle,
  };
};

const PopoverMenu = ({ onClickEdit, onClickDelete, editable }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef(null);

  const onClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeBeforeHandle = (cb) => () => {
    handleClose();
    cb();
  };

  const open = Boolean(anchorEl);
  const {
    popoverStyle,
    popoverInnerStyle,
    popoverButtonStyle,
    popoverItemTextStyle,
    popoverIconStyle,
    popoverTextStyle,
  } = usePopoverStyles();

  return (
    <x.div ml="auto">
      <Icon
        onClick={onClick}
        active={anchorEl != null}
        size="small"
        color="secondary"
        data-testid="comment-menu-handle"
      >
        <MoreVert />
      </Icon>
      <Popover
        p={2}
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom-end"
        portalEl={document.querySelector("#comment-popover")}
        {...popoverStyle}
      >
        <x.div {...popoverInnerStyle}>
          {editable && (
            <Button onClick={closeBeforeHandle(onClickEdit)} {...popoverButtonStyle}>
              <Typography {...popoverItemTextStyle} component="div">
                <Icon {...popoverIconStyle}>
                  <Edit />
                </Icon>
                <x.div {...popoverTextStyle}>Edit</x.div>
              </Typography>
            </Button>
          )}
          <Button onClick={closeBeforeHandle(onClickDelete)} {...popoverButtonStyle}>
            <Typography {...popoverItemTextStyle} component="div">
              <Icon {...popoverIconStyle}>
                <Delete />
              </Icon>
              <x.div {...popoverTextStyle}>Delete</x.div>
            </Typography>
          </Button>
        </x.div>
      </Popover>
    </x.div>
  );
};

export const usePopoverStyles = () => {
  const popoverStyle = {
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    borderRadius: `${shape.border.radius.medium}`,
  };
  const popoverInnerStyle = {
    display: "flex",
    flexDirection: "column",
  };
  const popoverButtonStyle = {
    justifyContent: "flex-start",
    textAlign: "center",
    variant: "ghost",
    p: 2,
    h: 1,
    lineHeight: 1,
    bg: { hover: color.background.surface.page.hover },
  };
  const popoverItemTextStyle = {
    alignItems: "center",
    variant: "body",
    color: color.text.strong._,
    display: "inline-flex",
  };
  const popoverIconStyle = {
    size: "small",
    style: {
      marginRight: "12px",
      color: color.icon.secondary,
    },
  };
  const popoverTextStyle = {
    style: {
      marginRight: "12px",
    },
  };

  return {
    popoverStyle,
    popoverInnerStyle,
    popoverButtonStyle,
    popoverItemTextStyle,
    popoverIconStyle,
    popoverTextStyle,
  };
};
