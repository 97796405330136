import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import Title from "./components/Title";
import Filters from "./components/Filters";
import Experts from "./components/Experts/Experts";

const sidebarStyling = {
  w: 357,
};

const Sidebar = React.memo(() => {
  const { color } = useThemeTokens();

  return (
    <x.div
      display="flex"
      flexDirection="column"
      h="100%"
      backgroundColor={color.background.surface.page.default}
      flex={`0 0 ${sidebarStyling.w}px`}
      borderLeft={`1px solid ${color.border.neutral.default}`}
    >
      <Title />
      <Filters />
      <Experts />
    </x.div>
  );
});

export default Sidebar;
