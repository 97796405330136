import React, { useState } from "react";
import { Field, Formik, Form, useFormikContext } from "formik";
import { validateEmail, validatePassword, buildValidationRule } from "./validators";
import { LoadingOverlay } from "@alphasights/client-portal-shared";
import { Tick } from "@alphasights/alphadesign-icons";
import { TextField, Typography, Icon as IconAds } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useFormStyles } from "./Form.styles";
import { Hide, Show } from "@alphasights/alphadesign-icons";

export const FormField = ({
  className = "",
  type,
  name,
  placeholder,
  size = "medium",
  disabled = false,
  readOnly,
  dataTestId,
  autoFocus,
  endAdornment,
  showErrorText = true,
  ...props
}) => {
  return (
    <Field name={name} {...props}>
      {({ field, meta: { touched, error } }) => {
        return (
          <TextField
            width="100%"
            placeholder={placeholder}
            type={type}
            size={size}
            {...field}
            error={touched && error}
            helperText={touched && showErrorText ? error : ""}
            disabled={disabled}
            data-testid={dataTestId}
            autoFocus={autoFocus}
            readOnly={readOnly}
            endAdornment={endAdornment}
          />
        );
      }}
    </Field>
  );
};

export const EmailField = ({ customValidation }) => {
  const validate = (value) => {
    const error = validateEmail(value);
    if (!error && customValidation) {
      return customValidation(value);
    }
    return error;
  };

  return <FormField type="email" name="email" placeholder="Email" validate={validate} />;
};

export const PasswordField = ({ validationRules, validate, size = "medium", isMobile = false, ...props }) => {
  const { showPasswordButton } = useFormStyles(!isMobile);

  const [showPassword, setShowPassword] = useState(false);

  const callbackValidatePassword = (value) => {
    return validatePassword(value, validationRules);
  };

  const ShowPasswordButton = ({ showPassword, onClick }) => (
    <x.div {...showPasswordButton}>
      <IconAds
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onClick={onClick}
      >
        {showPassword ? <Hide /> : <Show />}
      </IconAds>
    </x.div>
  );

  return (
    <x.div position="relative">
      <FormField
        name="password"
        placeholder="Password"
        size={size}
        validate={validate || callbackValidatePassword}
        {...props}
        type={showPassword ? "text" : "password"}
        endAdornment={<ShowPasswordButton showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />}
      />
    </x.div>
  );
};

export const PasswordConfirmationField = (props) => {
  return <PasswordField name="passwordConfirmation" placeholder="Confirm password" {...props} />;
};

export const PasswordRulesList = ({ validationRules }) => {
  const { passwordRuleIcon, passwordRule, passwordRuleSuccess } = useFormStyles();

  const {
    values: { password },
  } = useFormikContext();

  return (
    <ul>
      {validationRules.map((rule) => {
        return (
          <x.li {...(!buildValidationRule(password, rule) ? passwordRuleSuccess : passwordRule)}>
            <x.div {...passwordRuleIcon}>
              <Tick />
            </x.div>{" "}
            <Typography component="span">{rule.successMessage}</Typography>
          </x.li>
        );
      })}
    </ul>
  );
};

export const FormHeader = ({ title, description, error }) => {
  const { formHeader } = useFormStyles();

  return (
    <x.header {...formHeader}>
      <div>{title}</div>
      <div>
        <span>{description}</span>
        {error && (
          <>
            <br />
            <Typography>{error}</Typography>
          </>
        )}
      </div>
    </x.header>
  );
};

export const FormBody = ({ ...props }) => {
  const { formBodyDiv } = useFormStyles();

  return <x.div {...formBodyDiv} {...props}></x.div>;
};

export const FormContent = ({ children, ...props }) => {
  const { formContentDiv } = useFormStyles();

  return (
    <Form {...props}>
      <x.div {...formContentDiv}>{children}</x.div>
    </Form>
  );
};

export const FormFooter = ({ children, ...props }) => {
  const { formFooterDiv, separator } = useFormStyles();

  return (
    <x.div {...formFooterDiv} {...props}>
      <x.div {...separator}></x.div>
      {children}
    </x.div>
  );
};

export const FormContainer = ({ className = "", isLoading, children, ...props }) => {
  const { formContainerDiv } = useFormStyles();

  return (
    <x.div {...formContainerDiv}>
      <Formik validateOnBlur={false} {...props}>
        {(renderProps) => <>{children instanceof Function ? children(renderProps) : children}</>}
      </Formik>
      {isLoading && <LoadingOverlay />}
    </x.div>
  );
};
