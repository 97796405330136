import React from "react";
import { useNavigate } from "router-utils";
import { TabItem, useThemeTokens } from "@alphasights/alphadesign-components";
import { ContactInfoDropdown } from "components/ContactInfoDropdown/ContactInfoDropdown";
import { x } from "@xstyled/styled-components";
import { useRememberSearchParams } from "providers/RememberSearchParamsProvider";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { Lead } from "@alphasights/portal-api-client";
import * as S from "./Toolbar.styled";
import { ProjectMoreOptions } from "components/ProjectMoreOptions/ProjectMoreOptions";
import { ProjectTitle } from "components/InteractionsPage/ProjectTitle";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { TopBarPortal } from "components/NavigationContainer/TopBar";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";

export interface ToolbarProps {
  token: string;
  currentView: string;
  isCalendarViewEnabled: boolean;
  isDeliverablesViewEnabled: boolean;
  isMessagesViewEnabled: boolean;
  isSurveysResultsViewEnabled: boolean;
  isMobile: boolean;
  lead: Lead;
  projectTitle: string;
  workstreamId?: string;
  isSurveyOnlyProject: boolean;
  isActiveProject: boolean;
}

interface LayoutToggleProps {
  mode: string;
  isMobile: boolean;
  isCalendarViewEnabled: boolean;
  isDeliverablesViewEnabled: boolean;
  isMessagesViewEnabled: boolean;
  isSurveysResultsViewEnabled: boolean;
  token: string;
  workstreamId?: string;
  isSurveyOnlyProject: boolean;
  isActiveProject: boolean;
  projectTitle: string;
}

const LayoutToggle = ({
  mode,
  isMobile,
  isCalendarViewEnabled,
  isDeliverablesViewEnabled,
  isMessagesViewEnabled,
  isSurveysResultsViewEnabled,
  token,
  projectTitle,
  workstreamId,
  isSurveyOnlyProject,
  isActiveProject,
}: LayoutToggleProps) => {
  const { openLoginModal } = useClientPortalOperations();
  const isLoggedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const { project } = useCurrentProjectContext();

  const isProjectAlphaGptEnabled = project?.projectAlphaGPTEnabled && isActiveProject && !isSurveyOnlyProject;
  const isSynthesisEnabled = isLoggedIn && project?.synthesisEnabled && isActiveProject && !isSurveyOnlyProject;
  const { enableAngleQuestions } = useAngleQuestionsContext();

  const isInactive = !isActiveProject;
  const { withSearchParams } = useRememberSearchParams();

  const views = [
    {
      mode: ["my-alphasights-view"],
      label: <ProjectTitle projectTitle={projectTitle} />,
      enabled: true,
      onClick: !isInactive
        ? () => {
            navigate(`/${myAlphaSightsBaseUrl}/projects/${token}`);
          }
        : undefined,
    },
    {
      mode: ["questions"],
      label: "Questions",
      enabled: enableAngleQuestions,
      onClick: () => navigate(`/${token}/questions`),
    },
    {
      mode: ["table-view", "list-view", "survey-view", "customer-view", "comparison-view"],
      label: "Experts",
      enabled: true,
      onClick: () => {
        navigate(`/${baseUrlFor(token, workstreamId)}/experts`);
      },
    },
    {
      mode: ["calendar-view"],
      label: "Calendar",
      enabled: isCalendarViewEnabled && (!isMobile || mode === "calendar-view"),
      onClick: () => {
        if (isLoggedIn) navigate(`/${baseUrlFor(token, workstreamId)}` + withSearchParams("/calendar-view"));
        else {
          openLoginModal({
            allowAnonymousContinue: false,
            message: "You must sign in to view this page.",
          });
          navigate({
            search: `next=/${baseUrlFor(token, workstreamId)}/calendar-view/`,
          });
        }
      },
    },
    {
      mode: ["surveys-results-view"],
      label: "Surveys",
      enabled: isSurveysResultsViewEnabled,
      onClick: () => {
        if (isLoggedIn) navigate(`/${baseUrlFor(token, workstreamId)}` + withSearchParams("/surveys-results-view"));
        else {
          openLoginModal({
            allowAnonymousContinue: false,
            message: "You must sign in to view this page.",
          });
          navigate({
            search: `next=/${baseUrlFor(token, workstreamId)}/surveys-results-view/`,
          });
        }
      },
    },
    {
      mode: ["messages-view"],
      label: "Messages",
      enabled: isMessagesViewEnabled && !isSurveyOnlyProject,
      onClick: () => {
        if (isLoggedIn) navigate(`/${token}/experts/messages-view`);
        else {
          openLoginModal({
            allowAnonymousContinue: false,
            message: "You must sign in to view this page.",
          });
          navigate({ search: `next=/${token}/experts/messages-view/` });
        }
      },
    },
    {
      mode: ["deliverables-view"],
      label: "Deliverables",
      enabled: isDeliverablesViewEnabled,
      onClick: () => {
        if (isLoggedIn) navigate(`/${token}` + withSearchParams("/experts/deliverables-view"));
        else {
          openLoginModal({
            allowAnonymousContinue: false,
            message: "You must sign in to view this page.",
          });
          navigate({ search: `next=/${token}/experts/deliverables-view/` });
        }
      },
    },
    {
      mode: ["alphagpt-view"],
      label: "Search",
      enabled: isProjectAlphaGptEnabled,
      onClick: () => {
        if (isLoggedIn) navigate(`/${token}` + withSearchParams("/experts/alphagpt-view"));
        else {
          openLoginModal({
            allowAnonymousContinue: false,
            message: "You must sign in to view this page.",
          });
          navigate({ search: `next=/${token}/experts/alphagpt-view/` });
        }
      },
    },
    {
      mode: ["synthesis"],
      label: "Synthesis",
      enabled: isSynthesisEnabled,
      onClick: () => {
        navigate(`/${token}` + withSearchParams("/synthesis"));
      },
    },
  ];

  if (views.some((i) => i.enabled === null)) return null;

  const availableViews = views.filter((view) => view.enabled);
  const selectedIndex = availableViews.findIndex((view) => view.mode.includes(mode));

  if (selectedIndex < 0) {
    navigate(`/${baseUrlFor(token, workstreamId)}/experts/`, { replace: true });
    return null;
  }

  return (
    <x.div display="flex" alignSelf="stretch" flexGrow={1} minWidth="0">
      <S.Tabs
        data-testid="layout-toggle"
        variant="container"
        isInactive={isInactive}
        index={selectedIndex}
        onChange={(index) => availableViews[index]?.onClick?.()}
      >
        {availableViews.map((view) => (
          <TabItem data-testid={`layout-toggle-${view.mode}`} key={`layout-toggle-${view.mode}`} label={view.label} />
        ))}
      </S.Tabs>
    </x.div>
  );
};

export const Toolbar = ({
  token,
  currentView,
  isCalendarViewEnabled,
  isDeliverablesViewEnabled,
  isMessagesViewEnabled,
  isSurveysResultsViewEnabled,
  isMobile,
  lead,
  projectTitle,
  workstreamId,
  isSurveyOnlyProject,
  isActiveProject,
}: ToolbarProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const layoutProps = {
    mode: currentView,
    isMobile,
    isCalendarViewEnabled,
    isDeliverablesViewEnabled,
    isMessagesViewEnabled,
    isSurveysResultsViewEnabled,
    token,
    workstreamId,
    isSurveyOnlyProject,
    isActiveProject,
    projectTitle,
  };

  return (
    <TopBarPortal>
      <S.ToolbarWrapper>
        <LayoutToggle {...layoutProps} />
        <x.div display="flex" alignItems="center" gap={inner.base04}>
          <ContactInfoDropdown lead={lead} />
          <ProjectMoreOptions />
        </x.div>
      </S.ToolbarWrapper>
    </TopBarPortal>
  );
};

const baseUrlFor = (token: string, workstreamId?: string) => {
  return workstreamId ? `${token}/workstream/${workstreamId}` : token;
};
