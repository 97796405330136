import React from "react";
import { Alert } from "@alphasights/alphadesign-components";
import { Blinded, ConsultantLed, VideoCall, PresentToAll, Interpreted } from "@alphasights/alphadesign-icons";
import { getFirstName } from "pages/InteractionPage/utils";
import { getLanguageFromCode, languages } from "helpers/displayHelpers";
import pluralize from "pluralize";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useInteractionUpgradesContext } from "./InteractionUpgradesProvider";
import { ChargeUnit } from "models/Interaction";
import { isAtLeastHoursBeforeInteraction } from "./helpers";
import { UpgradeType } from "./types";

export const useComputeEnhancements = (): UpgradeType[] => {
  const {
    accessPlan,
    upgrades,
    terms,
    selectedUpgrades: selected,
    projectLead,
    interaction,
    videoCallAvailable,
    blindedUpgradeAvailable,
  } = useInteractionUpgradesContext();

  const termsAccepted = !!terms?.acceptedAt;

  const { project } = useCurrentProjectContext();

  const atLeastThreeHoursBeforeInteraction = isAtLeastHoursBeforeInteraction({
    scheduledCallTime: interaction.scheduledCallTime,
    hoursBefore: 3,
  });

  const enhancementTypes: UpgradeType[] = [
    {
      type: "outsourced",
      name: "Consultant-led",
      description: `Further information available from project lead ${getFirstName(projectLead.name)}`,
      icon: <ConsultantLed />,
      disabled: true,
      visible: selected?.includes("outsourced"),
      available: true,
    },
    {
      type: "video_and_screen_share",
      name: "Video Call",
      price: "Free",
      icon: <VideoCall />,
      visible: videoCallAvailable,
      available: videoCallAvailable,
    },
    {
      type: "screen_sharing",
      name: "Screen Sharing",
      price: "Free",
      icon: <PresentToAll />,
      visible: project?.screenSharingAvailable,
      available: project?.screenSharingAvailable,
    },
    {
      type: "hosted",
      name: "Blinded",
      price: `+${accessPlan?.hostedCallMultiplier} ${pluralize("credit", accessPlan?.hostedCallMultiplier)}${
        accessPlan?.hostedCallChargeUnit === ChargeUnit.hour ? "/h" : ""
      }`,
      description: "Identity not disclosed to expert",
      icon: <Blinded />,
      disabled: termsAccepted || upgrades.find((u) => u.value === "hosted")?.actionerType === "cst",
      visible: (!termsAccepted || selected?.includes("hosted")) && interaction.newspeakProvider !== "teams",
      available: blindedUpgradeAvailable,
    },
    {
      type: "interpreted",
      name: "Interpreted",
      price: `+${accessPlan?.interpretedCallMultiplier} ${pluralize("credit", accessPlan?.interpretedCallMultiplier)}${
        accessPlan?.interpretedCallChargeUnit === ChargeUnit.hour ? "/h" : ""
      }`,
      language: interaction.needsInterpreter,
      icon: <Interpreted />,
      visible: isAtLeastHoursBeforeInteraction({
        scheduledCallTime: interaction.scheduledCallTime,
        hoursBefore: 48,
      }),
      disabled: !atLeastThreeHoursBeforeInteraction,
      requireLanguageSelection: true,
      selectedLanguage: interaction.needsInterpreter,
      requireRemovalConfirmation: true,
      languages: languages,
      removalConfirmationText: `By removing the interpreter upgrade, you are confirming that you or a colleague can conduct the call in ${
        getLanguageFromCode(interaction.needsInterpreter) ?? "the expert's language"
      }.`,
      banner: atLeastThreeHoursBeforeInteraction ? (
        interaction.needsInterpreter ? (
          <Alert variant="info">
            {getLanguageFromCode(interaction.language)} - {getLanguageFromCode(interaction.needsInterpreter)}{" "}
            interpreter required for this expert.
          </Alert>
        ) : undefined
      ) : (
        <Alert variant="info">
          You can no longer change this interpreter option - contact your project lead {getFirstName(projectLead.name)}{" "}
          for any further queries.
        </Alert>
      ),
      available: true,
    },
  ];

  return enhancementTypes
    .map((d) => {
      return { ...d, selected: selected?.includes(d.type) };
    })
    .filter((d) => d.available && (d.selected || d.visible));
};
