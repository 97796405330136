import React, { FC, useEffect, useMemo, useState } from "react";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";

import { SelectedThirdPartyFileToUpload } from "views/DeliverablesView/ThirdPartyDocUploadModal/types";
import { ANGLE_INFO_TOOLTIP_TEXT } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import {
  TitleCell,
  StatusCell,
  DeleteCell,
  AngleCell,
  EditCell,
  EditCellProps,
  AngleCellProps,
  DeleteCellProps,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/cells";
import { FileRow } from "./types";
import { ColumnIds, COLLAPSED_ANGLE_COLUMN_SIZE, COLUMN_NAMES, COLUMN_METADATA, TABLE_OPTIONS } from "./constants";

import * as ThirdPartyDocUploadModalStyles from "views/DeliverablesView/ThirdPartyDocUploadModal/ThirdPartyDocUploadModal.styled";
import * as FileTableStyles from "./FileTable.styled";
import _ from "lodash";

const S = { ...ThirdPartyDocUploadModalStyles, ...FileTableStyles };

type FileTableProps = {
  files: SelectedThirdPartyFileToUpload[];
  onSelectAngle: (id: number, value: string) => void;
  onClickDelete: (id: number) => void;
  onClickEdit: (id: number) => void;
  editMode?: boolean;
};

const FileTable: FC<FileTableProps> = ({ files, onSelectAngle, onClickDelete, onClickEdit, editMode = false }) => {
  const [selectedFiles, setSelectedFiles] = useState<FileRow[]>([]);
  const [angleColumnWidth, setAngleColumnWidth] = useState<number | undefined>(COLLAPSED_ANGLE_COLUMN_SIZE);

  useEffect(() => {
    const fileRows: FileRow[] = [];

    let isAnyAngleSelected = false;
    let maxStatusNumChars = COLUMN_NAMES[ColumnIds.status].length;
    let maxAngleNumChars = COLUMN_NAMES[ColumnIds.angle].length;

    files.forEach(({ id, documentTitle, status, angle }: SelectedThirdPartyFileToUpload) => {
      fileRows.push({
        id,
        documentTitle,
        status,
        angle,
      });
      if (angle) {
        isAnyAngleSelected = true;
      }
      if (angle && angle?.length > maxAngleNumChars) {
        maxAngleNumChars = angle.length;
      }
      if (status.length > maxStatusNumChars) {
        maxStatusNumChars = status.length;
      }
    });

    setSelectedFiles(fileRows);

    // set column widths based on content
    if (isAnyAngleSelected) {
      setAngleColumnWidth(undefined);
    } else {
      setAngleColumnWidth(COLLAPSED_ANGLE_COLUMN_SIZE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, setSelectedFiles, setAngleColumnWidth]);

  const columnDefs = useMemo(
    () =>
      _.compact([
        {
          id: ColumnIds.title,
          accessorKey: "title",
          header: COLUMN_NAMES[ColumnIds.title],
          cell: TitleCell,
          size: 450,
          maxSize: 450,
          meta: COLUMN_METADATA,
        },
        {
          id: ColumnIds.angle,
          accessorKey: "angle",
          header: AngleHeader,
          cell: (props: AngleCellProps) => <AngleCell {...props} onSelect={onSelectAngle} />,
          ...(angleColumnWidth && { size: angleColumnWidth, minSize: angleColumnWidth }),
          meta: COLUMN_METADATA,
        },
        {
          id: ColumnIds.status,
          accessorKey: "status",
          header: COLUMN_NAMES[ColumnIds.status],
          cell: StatusCell,
          meta: COLUMN_METADATA,
        },
        {
          id: ColumnIds.edit,
          cell: (props: EditCellProps) => <EditCell {...props} onClickEdit={onClickEdit} />,
          size: editMode ? 68 : 48,
          minSize: editMode ? 68 : 42,
          meta: COLUMN_METADATA,
        },
        !editMode && {
          id: ColumnIds.delete,
          cell: (props: DeleteCellProps) => <DeleteCell {...props} onClick={onClickDelete} />,
          size: 68,
          minSize: 68,
          meta: COLUMN_METADATA,
        },
      ]),
    [angleColumnWidth, editMode, onSelectAngle, onClickEdit, onClickDelete]
  );

  const table = useAlphaTable(selectedFiles, columnDefs, TABLE_OPTIONS);

  return (
    <S.TableWrapper>
      <AlphaTable table={table} enableHeaderActions={false} enableStickyHeader />
    </S.TableWrapper>
  );
};

const AngleHeader = () => {
  const { color } = useThemeTokens();
  return (
    <S.TextAndIconWrapper>
      <Typography variant="body-small-em" color={color.text.secondary}>
        {COLUMN_NAMES[ColumnIds.angle]}
      </Typography>
      <Tooltip variant="dark" size="small" title={ANGLE_INFO_TOOLTIP_TEXT}>
        <S.StyledInteractiveIcon color={color.icon.strong._}>
          <Info />
        </S.StyledInteractiveIcon>
      </Tooltip>
    </S.TextAndIconWrapper>
  );
};

export default FileTable;
