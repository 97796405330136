import React, { useCallback, useEffect, useState, useContext, useMemo, memo, useRef } from "react";
import { getStateColor } from "./stateFunctions";
import { getHighlightedText } from "./HighlightText";
import { Textile } from "../Textile";
import { useDimensions } from "../../hooks/useDimensions";
import { DispatchContext } from "../InteractionsPage/DispatchContext";
import { markAsViewed } from "../InteractionsPage/reducer";
import {
  groupByInteractionGroups,
  groupedRepresentationForExperts,
  isCallAvailable,
} from "../InteractionsPage/helpers/Interaction";
import ReactMarkdownHTML from "react-markdown/with-html";
import { useTimezone } from "../../providers/TimezoneProvider";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useProjectBadgeContext, ENABLE_PORTAL_MESSAGES } from "providers/BadgeProvider";
import { Link as LinkAds, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useAdvisorCardStyles } from "./AdvisorCard.styles";
import { InteractionHeader } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import {
  enrichInteraction as enrichInteractionHelper,
  isAvailabilityVisible,
  LockedExpertBanner,
  useFlyoutWidth,
} from "pages/InteractionPage";
import { ChevronDown, Request } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import {
  ClientAvailabilityButton,
  InstantScheduleButton,
  RequestButton,
  ScheduleButton,
  FollowUpButton,
  UpgradeInteractionButton,
  CtaButton,
  MessageButton,
} from "pages/InteractionPage/sections/Topbar/Topbar";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useAvailabilitySlots } from "pages/InteractionPage/sections/Availability/AvailabilitySelector";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { FLYOUT_SECTIONS } from "providers/FlyoutProvider";
import { CallMeButton } from "components/CallMe";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";

export { ContentLoader } from "./ContentLoader";

const AdvisorActions = ({
  allInteractions,
  backgroundSyncRunning,
  clientAvailability,
  currentView,
  hasClientPortalMessages,
  interaction,
  isMobileLayout,
  onCancelPendingRequest,
  onOpenFlyout,
  onRequest,
  onRequestFollowUp,
  onSchedule,
  onLeaveAvailability,
  onSendNewMessage,
  pendingRequest,
  token,
}) => {
  const { availabilitiesText } = useAdvisorCardStyles({ isMobileLayout });
  const { isMobile } = useCheckScreen();
  const tz = useTimezone();
  const { hasProjectBadge } = useProjectBadgeContext();
  const portalMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES) && !interaction.isSurvey;

  const isCallAboutToStartOrHappening = useMemo(() => isCallAvailable(interaction), [interaction]);

  const [isCallMeModalOpen, setIsCallMeModalOpen] = useState(false);

  const showAvaibilitySlots =
    ["proposed", "requested"].includes(interaction.state) &&
    interaction.advisorAvailability?.length > 0 &&
    isAvailabilityVisible(interaction.isActiveProject, interaction);

  const { availabilities, mutualAvailabilites } = useAvailabilitySlots({
    allInteractions: allInteractions,
    clientAvailability,
    advisorAvailability: interaction.advisorAvailability,
    interactionId: interaction.id,
    timezone: tz,
  });

  const allAvailabilitiesSize = useMemo(() => {
    return mutualAvailabilites.length + availabilities.length;
  }, [mutualAvailabilites, availabilities]);

  const lastItemOrder = 10;

  const numOthersInteractions = Object.values(interaction.othersCounts || {}).reduce((acc, val) => acc + val, 0);

  return (
    <>
      {showAvaibilitySlots && (
        <Typography
          variant={isMobile ? "body-large" : "body"}
          order={isMobileLayout ? lastItemOrder + 1 : undefined}
          onClick={() => onOpenFlyout({ showAvailabilities: true })}
          {...availabilitiesText}
        >
          {allAvailabilitiesSize} availability slot
          {allAvailabilitiesSize > 1 && "s"}
        </Typography>
      )}
      {portalMessagesEnabled && interaction.showMessage && (
        <MessageButton
          variant={
            (interaction.state === "requested" && !interaction.hasAdvisorAvailability) ||
            (interaction.state === "scheduled" && !isCallAboutToStartOrHappening)
              ? "secondary"
              : "outline"
          }
          order={
            (interaction.state === "requested" && !interaction.hasAdvisorAvailability) ||
            interaction.state === "scheduled"
              ? lastItemOrder
              : undefined
          }
          interactions={[interaction]}
          onSendNewMessage={onSendNewMessage}
        />
      )}
      {interaction.showLeaveAvailability && (
        <ClientAvailabilityButton
          disabled={backgroundSyncRunning}
          onClick={(e) => {
            e.stopPropagation();
            return onLeaveAvailability(interaction);
          }}
          interaction={interaction}
        />
      )}
      {interaction.showRequest && (
        <RequestButton
          disabled={backgroundSyncRunning}
          onClick={(e) => {
            e.stopPropagation();
            return onRequest(interaction.id, `${currentView}-regular`);
          }}
          interaction={interaction}
          loading={interaction.runningAction === "request"}
        />
      )}
      {interaction.showInstantSchedule && (
        <InstantScheduleButton
          disabled={backgroundSyncRunning}
          onClick={(e) => {
            e.stopPropagation();
            return onRequest(interaction.id, `${currentView}-instant`);
          }}
          interaction={interaction}
          loading={interaction.runningAction === "request"}
        />
      )}
      {interaction.showSchedule && (
        <ScheduleButton
          disabled={backgroundSyncRunning}
          onClick={(e) => {
            e.stopPropagation();
            return onSchedule(interaction);
          }}
          interaction={interaction}
          loading={interaction.runningAction === "schedule"}
        />
      )}
      {(!portalMessagesEnabled || !isCallAboutToStartOrHappening) && (
        <FollowUpButton
          interaction={interaction}
          hasClientPortalMessages={hasClientPortalMessages}
          token={token}
          onRequestFollowUp={(e) => {
            e.stopPropagation();
            return onRequestFollowUp({
              id: interaction.id,
              origin: HitOrigin.listView,
            });
          }}
          backgroundSyncRunning={backgroundSyncRunning}
          label={interaction.state === "completed" ? "Follow-up" : "Request Follow-up"}
          variant={interaction.state === "completed" ? "primary" : "outline"}
          startIcon={interaction.state === "completed" ? undefined : <Request />}
          order={interaction.state === "completed" ? lastItemOrder : undefined}
          singleButton={interaction.isSurvey || (interaction.state === "scheduled" && numOthersInteractions === 0)}
          onSendNewMessage={onSendNewMessage}
          showMessagingOptions={portalMessagesEnabled && interaction.state === "completed"}
        />
      )}
      {interaction.showJoinCall && (
        <CallMeButton
          callMeUrl={interaction.callMeUrl}
          provider={interaction.newspeakProvider}
          isModalOpen={isCallMeModalOpen}
          setIsModalOpen={setIsCallMeModalOpen}
          projectToken={token}
          size="small"
          variant={isCallAboutToStartOrHappening ? "primary" : "outline"}
          order={isCallAboutToStartOrHappening ? lastItemOrder : undefined}
          interaction={interaction}
        />
      )}
      {interaction.showRetractReschedulingRequest && (
        <CtaButtonWithLoginWall
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            return onCancelPendingRequest({
              interactionId: interaction.id,
              requestId: pendingRequest.requestId,
            });
          }}
        >
          Retract Rescheduling Request
        </CtaButtonWithLoginWall>
      )}
      {interaction.showRetractCancellationRequest && (
        <CtaButtonWithLoginWall
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            return onCancelPendingRequest({
              interactionId: interaction.id,
              requestId: pendingRequest.requestId,
            });
          }}
        >
          Retract Cancellation Request
        </CtaButtonWithLoginWall>
      )}
      {interaction.showUpgradeInteraction &&
        (!portalMessagesEnabled || isCallAboutToStartOrHappening || interaction.state !== "scheduled") && (
          <UpgradeInteractionButton
            variant={
              (interaction.showJoinCall && isCallAboutToStartOrHappening) || interaction.state !== "scheduled"
                ? "outline"
                : "secondary"
            }
            onClick={() => onOpenFlyout({ flyoutAction: FLYOUT_SECTIONS.expandUpgrades })}
          />
        )}
    </>
  );
};

const CardFooter = ({ isMobileLayout, locked, isExpertHidden, children, ...props }) => {
  const ref = useRef();
  const { cardFooter } = useAdvisorCardStyles({
    isMobileLayout,
    locked,
    ctasCount: ref.current?.childElementCount,
  });

  if (isExpertHidden) return null;

  return (
    <x.footer ref={ref} data-testid="advisor-card-footer" {...cardFooter} {...props}>
      {children}
    </x.footer>
  );
};

const CardContainer = ({ interaction, isExpertHidden, children, selected, locked, onClick }) => {
  const { cardContainer } = useAdvisorCardStyles({
    isExpertHidden,
    selected,
    locked,
  });

  return (
    <x.div id={`advisor-card-${interaction.id}`} data-testid="advisor-card" onClick={onClick} {...cardContainer}>
      {children}
    </x.div>
  );
};

//
// UI
//

/**
 * On AdvisorCard, we only display the first 500 characters of the relevance
 * statement. This causes an issue when there is bold text around the breaking
 * point. For instance, if the text is "this *exp|ert*", after splitting, we
 * end up with "this *exp...". This causes the asterisk to show up and text not
 * to appear in bold.
 *
 * This function counts how many "boldBegin" tokens the text has (i.e.
 * asterisks followed by a letter) and subtracts the amount of "boldEnd"
 * tokens present (letter followed by an asterisk). If the total is greater
 * than 0, we have an unbalanced pair of asterisks and need to manually
 * add another asterisk to close it.
 */
const formatRelevanceStatement = (text, characterLimit = 500) => {
  if (text.length <= characterLimit) {
    return text;
  }

  const slicedText = text.slice(0, characterLimit);

  const unbalanced = (slicedText.match(/\*\w/g) || []).length - (slicedText.match(/\w\*/g) || []).length > 0;

  if (unbalanced) {
    return slicedText + "*...";
  } else {
    return slicedText + "...";
  }
};

const AdvisorRelevantStatementNew = ({ interaction, relevantStatementType, characterLimit = 1500 }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
    color: { text, border },
    shape,
  } = useThemeTokens();

  if (!interaction.relevanceStatement) {
    return null;
  }

  const highlightedText = getHighlightedText({
    highlights: interaction.highlights,
    fieldNames: [
      "advisorships.relevance_statement",
      "advisorships.relevance_statement.concat",
      "relevanceStatement",
      "relevanceStatement.concat",
    ],
    text: interaction.relevanceStatement || "",
  });

  const relevanceStatement = isExpanded ? highlightedText : formatRelevanceStatement(highlightedText, characterLimit);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const endIcon = (
    <x.div
      w="20px"
      h="20px"
      fontSize="20px"
      transition="transform 0.2s ease-in-out"
      style={{
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
      }}
    >
      <ChevronDown />
    </x.div>
  );

  return (
    <x.div
      borderTopWidth={shape.border.width.sm}
      borderTopColor={border.neutral.default}
      py={inner.base06}
      data-testid="relevant-statement"
    >
      <Typography variant="body-large-em" component="span" color={text.strong._}>
        Relevant Experience{" "}
      </Typography>
      <Typography variant="body-large" component="span" color={text.strong._}>
        - {interaction.advisorName}
      </Typography>
      <Typography component="div" variant={isMobile ? "body-large" : "body"} pt={inner.base02} color={text.strong._}>
        {relevantStatementType === "MARKDOWN" ? (
          <ReactMarkdownHTML
            className="Advisor-RelevantStatement AdvisorCard-RelevantStatement"
            escapeHtml={false}
            source={relevanceStatement}
          />
        ) : (
          <Textile
            className="Advisor-RelevantStatement AdvisorCard-RelevantStatement"
            onClick={toggleExpanded}
            children={relevanceStatement}
          />
        )}
      </Typography>
      {highlightedText.length > characterLimit && (
        <LinkAds
          component="button"
          endIcon={endIcon}
          onClick={toggleExpanded}
          style={{ outline: "none" }}
          pt={inner.base02}
        >
          <Typography variant={isMobile ? "body-large-em" : "body-em"}>See {isExpanded ? "Less" : "More"}</Typography>
        </LinkAds>
      )}
    </x.div>
  );
};

export const AdvisorCard = memo(
  ({ interaction, ...args }) => {
    const { color } = useThemeTokens();
    const props = {
      ...args,
      color: getStateColor(args.state, color),
      hasAvailability: args.advisorAvailability?.length > 0,
    };

    const {
      isProjectCidEnabled,
      pendingRequest,
      pcc: clientPccFlag,
      relevantStatementType,
      flyoutMode,
      isFlyoutOpen,
      projectLead,
    } = props;

    const {
      spacing: { inner },
    } = useThemeTokens();
    const { isMobile } = useCheckScreen();
    const flyoutWidth = useFlyoutWidth(flyoutMode);
    const { isMobile: isMobileLayout } = useCheckScreen({
      offset: isFlyoutOpen ? flyoutWidth : 0,
    });
    const userCidEnabled = useUserCidEnabled();
    const [timeout, saveTimeout] = useState(null);
    const currentUser = useCurrentUser();

    const { locked } = useLockedExpert(interaction);

    const onOpenFlyout = useCallback(
      (props) => {
        args.onOpenFlyout({
          ...interaction,
          ...props,
        });
      },
      [args, interaction]
    );

    const dispatch = useContext(DispatchContext);

    const onCardVisibilityChanged = (isVisible) => {
      if (isVisible && interaction.newlyAdded) {
        saveTimeout(setTimeout(() => dispatch(markAsViewed(interaction, currentUser)), 2000));
      } else if (interaction.newlyAdded && timeout) {
        clearTimeout(timeout);
      }
    };
    const selected = args.selectedCardId === interaction.id;

    const richInteraction = enrichInteractionListView({
      interaction,
      clientPccFlag,
      pendingRequest,
      isMobile,
    });

    return (
      <ReactVisibilitySensor onChange={onCardVisibilityChanged} offset={{ top: args.headerHeight }}>
        <CardContainer
          interaction={richInteraction}
          selected={selected}
          locked={locked}
          onClick={locked ? undefined : onOpenFlyout}
        >
          <x.div px={inner.base06} pt={inner.base06}>
            <InteractionHeader
              {...props}
              interaction={richInteraction}
              userCidEnabled={userCidEnabled}
              isProjectCidEnabled={isProjectCidEnabled}
              onClick={locked ? undefined : onOpenFlyout}
              showActionIcons={!locked}
              showNewlyAddedMark
              origin={HitOrigin.listView}
              isMobileLayout={isMobileLayout}
              locked={locked}
            />
            {!locked && (
              <AdvisorRelevantStatementNew
                isExpertHidden={richInteraction.hidden}
                relevanceStatement={richInteraction.relevanceStatement}
                relevantStatementType={relevantStatementType}
                interaction={richInteraction}
              />
            )}
          </x.div>
          <CardFooter isMobileLayout={isMobileLayout} locked={locked} isExpertHidden={richInteraction.hidden}>
            {locked ? (
              <LockedExpertBanner projectLead={projectLead} />
            ) : (
              <AdvisorActions
                {...props}
                interaction={richInteraction}
                isMobileLayout={isMobileLayout}
                onOpenFlyout={onOpenFlyout}
              />
            )}
          </CardFooter>
        </CardContainer>
      </ReactVisibilitySensor>
    );
  },
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

export const AdvisorCardList = ({
  experts = [],
  token,
  transcriptEnabled,
  relevantStatementType,
  showAngleTypes,
  onRequest,
  onRequestFollowUp,
  onCancelRequest,
  onCancelPendingRequest,
  onRequestTranscript,
  onRequestRescheduleInteraction,
  onCancelRequestTranscript,
  onUpdateFollowUp,
  onLeaveAvailability,
  onSchedule,
  onSelectCard,
  backgroundSyncRunning,
  showCalendarView,
  clientRequests,
  hasClientPortalMessages,
  onRequestTranscriptPostCall,
  onOpenComments,
  flyoutMode,
  isFlyoutOpen,
  headerHeight,
  selectedCardId,
  isProjectCidEnabled,
  pcc,
  pccOptOutWindow,
  chainIdSelected,
  allInteractions,
  clientAvailability,
  projectLead,
  onSendNewMessage,
}) => {
  const [ref, { width }] = useDimensions();
  const { isMobile } = useCheckScreen();

  const onOpenFlyout = useCallback(
    (card) => {
      const { width, ...props } = card;
      onSelectCard(props);
    },
    [onSelectCard]
  );

  const { cardList, cardGroupList, textColorStrong } = useAdvisorCardStyles();

  const numCards = 10;
  const [count, setCount] = useState({ prev: 0, next: numCards });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(experts.slice(count.prev, count.next));
  const getMoreData = useCallback(() => {
    if (current.length === experts.length) {
      setHasMore(false);
      return;
    }

    setCurrent(current.concat(experts.slice(count.prev + numCards, count.next + numCards)));
    setCount((prevState) => ({
      prev: prevState.prev + numCards,
      next: prevState.next + numCards,
    }));
  }, [experts, current, count]);

  useEffect(() => {
    if (hasMore) {
      const timer = setTimeout(() => getMoreData(), 50);
      return () => clearTimeout(timer);
    }
  }, [hasMore, getMoreData]);

  useEffect(() => {
    setCurrent([...experts]);
  }, [experts]);

  const groups = useMemo(() => {
    const representations = groupedRepresentationForExperts(current, chainIdSelected);

    return groupByInteractionGroups(representations);
  }, [current, chainIdSelected]);

  return (
    <x.div data-testid="list-view-cards" {...cardGroupList} ref={ref}>
      {groups.map(({ name: groupName, type, interactions: cards }) => (
        <div key={`cards-angle-${groupName}`}>
          <div key={`cards-angle-${type}-${groupName}`}>
            {showAngleTypes && (
              <Typography variant={isMobile ? "body-large" : "body"} color="secondary">
                {type}
              </Typography>
            )}
            <Typography variant={isMobile ? "h2" : "h3"} style={{ wordBreak: "break-word" }} {...textColorStrong}>
              {groupName}
            </Typography>
            <x.div {...cardList}>
              {cards.map((props, i) => (
                <AdvisorCard
                  key={`card-${props.id}-${i}`}
                  token={token}
                  relevantStatementType={relevantStatementType}
                  transcriptEnabled={transcriptEnabled}
                  flyoutMode={flyoutMode}
                  isFlyoutOpen={isFlyoutOpen}
                  interaction={props}
                  width={width}
                  onOpenFlyout={onOpenFlyout}
                  onRequest={onRequest}
                  onRequestFollowUp={onRequestFollowUp}
                  onCancelRequest={onCancelRequest}
                  onRequestTranscript={onRequestTranscript}
                  onCancelRequestTranscript={onCancelRequestTranscript}
                  onUpdateFollowUp={onUpdateFollowUp}
                  onLeaveAvailability={onLeaveAvailability}
                  onSchedule={onSchedule}
                  backgroundSyncRunning={backgroundSyncRunning}
                  currentView="list-view"
                  showCalendarView={showCalendarView}
                  pendingRequest={clientRequests.find((req) => req.interactionId === props.id)}
                  hasClientPortalMessages={hasClientPortalMessages}
                  onRequestTranscriptPostCall={onRequestTranscriptPostCall}
                  onOpenComments={onOpenComments}
                  newlyAdded={props.newlyAdded}
                  headerHeight={headerHeight}
                  selectedCardId={selectedCardId}
                  isProjectCidEnabled={isProjectCidEnabled}
                  pcc={pcc}
                  pccOptOutWindow={pccOptOutWindow}
                  onRequestRescheduleInteraction={onRequestRescheduleInteraction}
                  onCancelPendingRequest={onCancelPendingRequest}
                  allInteractions={allInteractions}
                  clientAvailability={clientAvailability}
                  projectLead={projectLead}
                  onSendNewMessage={onSendNewMessage}
                />
              ))}
            </x.div>
          </div>
        </div>
      ))}
    </x.div>
  );
};

const enrichInteractionListView = ({ interaction, clientPccFlag, pendingRequest, isMobile }) => {
  const richInteraction = enrichInteractionHelper({
    interaction,
    clientPccFlag,
    isActiveProject: interaction.isActiveProject,
    pendingRequest,
    isMobile,
  });

  const showFollowUp =
    ["scheduled", "completed"].includes(richInteraction.state) &&
    richInteraction.isActiveProject &&
    !richInteraction.followUpId &&
    !pendingRequest;

  const cancelInteractionRequest = pendingRequest?.type === "CANCEL_REQUEST" ? pendingRequest : null;

  return {
    ...richInteraction,
    showRescheduleMenu: richInteraction.state === "scheduled" && !pendingRequest,
    showCancelInteractionMenu: richInteraction.state === "scheduled" && pendingRequest?.type !== "CANCEL_REQUEST",
    showFollowUpMessageButton:
      ["scheduled", "completed"].includes(richInteraction.state) &&
      richInteraction.isActiveProject &&
      !pendingRequest &&
      (richInteraction.showEmailButton || richInteraction.canSendFollowUpMessage),
    showFollowUp: showFollowUp,
    showJoinCall: richInteraction.state === "scheduled" && !pendingRequest,
    showRetractReschedulingRequest:
      richInteraction.state === "scheduled" && pendingRequest?.type === "RESCHEDULE_REQUEST",
    showRetractCancellationRequest: richInteraction.state === "scheduled" && pendingRequest?.type === "CANCEL_REQUEST",
    showMessage: richInteraction.state !== "completed" && !cancelInteractionRequest,
    showUpgradeInteraction:
      richInteraction.showUpgradeInteraction ||
      (richInteraction.isActiveProject && richInteraction.state === "completed"),
  };
};

const CtaButtonWithLoginWall = withLoginWall(CtaButton);
