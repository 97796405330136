import React, { useState } from "react";
import { useDeliverableContext } from "providers/DeliverableProvider";
import {
  ERROR_SUBMITTING_QUESTION_MESSAGE,
  MINIUM_INPUT_CHAR_LENGTH,
  REPHRASE_QUESTION_MESSAGE,
  SUBMIT_QUESTION_MESSAGE,
} from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/consts";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { AiAskQuestionInput } from "components/AiAskQuestionInput";
import { Alert, Typography } from "@alphasights/alphadesign-components";
import { NewQuestionContainer } from "./NewQuestion.styled";
import { useMutation, useQueryClient } from "query-utils";
import { DOCUMENT_QUESTIONS_KEY } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/consts";

const DataTestIds = {
  submitQuestionsSection: "ask-question-section",
  submitQuestionInput: "question-add",
};

const NewQuestion = ({ documentId }: { documentId: string }) => {
  const [currentQuestion, setCurrentQuestion] = useState<string>("");

  const queryClient = useQueryClient();
  const { submitDocumentQuestion } = useDeliverableContext();

  const submitQuestionMutation = useMutation((question: string) => submitDocumentQuestion(documentId, question), {
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_QUESTIONS_KEY, documentId]);
    },
    onError: (error) => {
      console.error("Error submitting question:", error);
    },
  });

  const onSubmitQuestion = async (questionText: string): Promise<void> => {
    if (questionText.trim().length < MINIUM_INPUT_CHAR_LENGTH) {
      throw new Error(REPHRASE_QUESTION_MESSAGE);
    }

    submitQuestionMutation.mutate(questionText);
  };

  const handleSubmitQuestion = (questionText: string): Promise<void> => {
    setCurrentQuestion(questionText);
    return onSubmitQuestion(questionText) as Promise<void>;
  };

  return (
    <NewQuestionContainer data-testid={DataTestIds.submitQuestionsSection}>
      <EmphasisTypography component="span" variant="body-large-em">
        {SUBMIT_QUESTION_MESSAGE}
      </EmphasisTypography>
      <AiAskQuestionInput
        text={""}
        onSubmit={(question: string) => handleSubmitQuestion(question)}
        onBlur={() => {}}
        isNewQuestion={true}
        isLoading={submitQuestionMutation.isLoading}
        data-testid={DataTestIds.submitQuestionInput}
      />
      {submitQuestionMutation.isError && (
        <Alert
          w="100%"
          size="small"
          variant="danger"
          buttonsPosition="right"
          buttonPrimaryProps={{
            children: "Generate",
            variant: "outline",
            size: "small",
            onClick: () => onSubmitQuestion(currentQuestion),
          }}
        >
          <Typography>{ERROR_SUBMITTING_QUESTION_MESSAGE}</Typography>
        </Alert>
      )}
    </NewQuestionContainer>
  );
};

export { NewQuestion as default, DataTestIds };
