import React from "react";
import { AngleQuestionTheme } from "models/AngleQuestions";
import { QuestionThemeCardLoading } from "./QuestionThemeCard";
import * as S from "./MainContent.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";

export const MainContent = ({ themes, loading }: { themes: AngleQuestionTheme[]; loading: boolean }) => {
  const { selectedAngleId } = useAngleQuestionsContext();
  return (
    <S.Wrapper>
      {loading ? (
        <>
          <QuestionThemeCardLoading />
          <QuestionThemeCardLoading />
          <QuestionThemeCardLoading />
        </>
      ) : (
        <>
          <h1>TODO: Main Content</h1>
          <h2>selected angle id: {selectedAngleId}</h2>
        </>
      )}
    </S.Wrapper>
  );
};
