import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { parseISO } from "date-fns";
import { x } from "@xstyled/styled-components";
import { Icon, Link, Typography } from "@alphasights/alphadesign-components";
import { ArrowRight, Close, Error, Expert, Info } from "@alphasights/alphadesign-icons";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { useQuery } from "query-utils";
import useQueryParams from "hooks/useQueryParams";
import useStyles from "./styles";
import { PROJECT_SUMMARY_ID, RECOMMENDED_CONTENT_ID, CardsSidebar } from "./Cards";
import { MainContentEmpty, MainContentNoDeliverable } from "./MainContentViews";
import {
  deliverablesInteractions,
  filterSuggestions,
  SORT_KEYS,
  suggestionsInfo,
  shouldHideUploadedDocs,
  createThirdPartyInteraction,
} from "./helpers";
import { useAlertMessengerContext } from "components/InteractionsPage/AlertContextProvider";
import { useDeliverableContext } from "../../providers/DeliverableProvider";
import { CallbackProvider } from "providers/CallbackProvider";
import deliverablesService from "./deliverablesService";
import { SuggestionMainContent } from "./SuggestionMainContent";
import { useTrackUserAction, LimitedLinesTypography, AlphaNowContentType } from "@alphasights/client-portal-shared";
import { ExpertsInfoView } from "components/Experts/ExpertsInfoView";
import { CitationProvider, useCitationContext } from "components/CitationContext/CitationContext";
import { DeliverableNotFoundMessage, ContentNotFoundMessage, DeliverableNotReady } from "./DeliverablesMessages";
import { ENABLE_PROJECT_SUMMARIZATION, useProjectBadgeContext } from "providers/BadgeProvider";
import { SummaryType } from "./AiSummaries/AiSummaries.types";
import { useDeliverablesViewStyles } from "./DeliverablesView.styles";
import { RecommendedContentTable } from "./RecommendedContentTable/RecommendedContentTable";
import { Sidebar } from "./Sidebar/Sidebar";
import { default as useLocationQuery } from "hooks/useQuery";
import { ProjectSummary } from "./ProjectSummaries/ProjectSummary";
import { ProjectSummariesProvider, useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import { ProjectSummaryStatus } from "./ProjectSummaries/ProjectSummaries.types";
import { useTimezone } from "providers/TimezoneProvider";
import { DeliverablesPage } from "./DeliverablesPage/DeliverablesPage";
import { CardsSidebarControlProvider } from "./CardsSidebarControlProvider/CardsSidebarControlProvider";
import { ExpandSidebarButton } from "./ExpandSidebarButton/ExpandSidebarButton";
import { InteractionOrigin } from "./constants";
import ThirdPartyDocumentPage from "./ThirdPartyDocumentPage";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";

export const PARAM_CONTENT_SELECTED = "selectedContentId";
const PARAM_INTERACTION_SELECTED = "selectedInteraction";
const PARAM_TRANSCRIPT_SELECTED = "selectedTranscript";
const PARAM_AI_SUMMARY_TYPE = "aiSummaryType";
const PARAM_THIRD_PARTY_DOC_SELECTED = "selectedUploadedDocument";

const DeliverablesView = ({
  project,
  service = deliverablesService,
  onSelectCard,
  onRequestFollowUp,
  selectedCardId,
  chainIdSelected,
  ...props
}) => {
  return (
    <CallbackProvider
      callbacks={{
        onSelectCard,
        onRequestFollowUp,
      }}
    >
      <ProjectSummariesProvider
        projectToken={project.token}
        interactions={props.allInteractions}
        filterOptions={props.filterOptions}
      >
        <InternalDeliverablesView
          isFlyoutOpen={Boolean(selectedCardId)}
          chainIdSelected={chainIdSelected}
          project={project}
          {...props}
        />
      </ProjectSummariesProvider>
    </CallbackProvider>
  );
};

const InternalDeliverablesView = ({
  appliedFilters,
  filterOptions: generalFilterOptions,
  interactions,
  token,
  isFlyoutOpen,
  onSubmitFilters,
  onCloseFlyout,
  onTextSearch,
  onResetAllFilters,
  loading,
  chainIdSelected,
  project,
}) => {
  const {
    bookmarkedInteractionIds,
    fetchAlphaNowContent,
    fetchAlphaNowContentById,
    fetchDocuments,
  } = useDeliverableContext();
  const currentUser = useCurrentUser();
  const queryParams = useQueryParams();
  const { isMobile } = useCheckScreen();
  const { logHit } = useTrackUserAction();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { hasUserBadge } = useUserBadgeContext();

  const [isInitialSelection, setIsInitialSelection] = useState(true);
  const [contentLoading, setContentLoading] = useState(false);
  const { addPermanentMessage } = useAlertMessengerContext();
  const [queryKeywords, setQueryKeywords] = useState(
    queryParams
      .get("keywords")
      ?.split(",")
      ?.filter((s) => s.length > 0) || []
  );
  const [alphaNowFilterOptions, setAlphaNowFilterOptions] = useState({});
  const [alphaNowContent, setAlphaNowContent] = useState({});
  const [sortCriteria, setSortCriteria] = useState(SORT_KEYS.callDateDesc);
  const [currentInteractionId, setCurrentInteractionId] = useState(queryParams.get(PARAM_INTERACTION_SELECTED));
  const [currentSuggestionId, setCurrentSuggestionId] = useState(queryParams.get(PARAM_CONTENT_SELECTED));
  const [currentThirdPartyDocId, setCurrentThirdPartyDocId] = useState(queryParams.get(PARAM_THIRD_PARTY_DOC_SELECTED));
  const [currentUtilityCardId, setCurrentUtilityCardId] = useState(null);
  const [initialAiSummarizationType, setInitialAiSummarizationType] = useState(
    SummaryType[queryParams.get(PARAM_AI_SUMMARY_TYPE)]
  );
  const [landingAddressed, setLandingAddressed] = useState(false);
  const [thirdPartyDocuments, setThirdPartyDocuments] = useState([]);

  const hasThirdPartyDocumentsBadge = hasUserBadge(Badge.thirdPartyDocUpload);
  const hasThirdPartyDocumentsSetting = currentUser?.documentUploadsEnabled;
  const shouldHideThirdPartyDocuments =
    !hasThirdPartyDocumentsBadge ||
    !hasThirdPartyDocumentsSetting ||
    isMobile ||
    // TODO (Fran): revisit keywords condition once search endpoint is available
    shouldHideUploadedDocs(queryKeywords, appliedFilters);

  const fetchThirdPartyDocuments = async () => {
    let docs = [];
    try {
      const res = await fetchDocuments();
      // enhance the documents
      docs = res.map(createThirdPartyInteraction);
    } catch (error) {
      console.error("Error fetching third party documents", error);
    }
    setThirdPartyDocuments(docs);
    return docs;
  };

  const { refetch: refetchThirdPartyDocuments, isFetching: isFetchingUploadedDocs = false } = useQuery(
    ["fetchThirdPartyDocuments"],
    fetchThirdPartyDocuments,
    {
      enabled: !shouldHideThirdPartyDocuments,
    }
  );
  const isLoading = loading || contentLoading || isFetchingUploadedDocs;

  const filteredInteractions = useMemo(() => {
    return deliverablesInteractions(interactions, appliedFilters, sortCriteria, bookmarkedInteractionIds).filter(
      (i) => !currentUser?.aiSynthesisOnly || i.language === "en"
    );
  }, [appliedFilters, bookmarkedInteractionIds, currentUser.aiSynthesisOnly, interactions, sortCriteria]);

  const currentSelection = useMemo(() => {
    const id = currentInteractionId ?? currentSuggestionId ?? currentUtilityCardId ?? currentThirdPartyDocId;

    if (id && [RECOMMENDED_CONTENT_ID, currentUtilityCardId].includes(id)) {
      return {
        id,
      };
    }

    return [
      ...filteredInteractions,
      ...(alphaNowContent?.suggestedContent || []),
      ...(alphaNowContent?.privateContent || []),
      ...(alphaNowContent?.pitchedContent || []),
      ...thirdPartyDocuments,
    ].find((s) => s.id === id);
  }, [
    currentInteractionId,
    currentSuggestionId,
    currentUtilityCardId,
    currentThirdPartyDocId,
    filteredInteractions,
    alphaNowContent,
    thirdPartyDocuments,
  ]);

  useEffect(() => {
    const selectedTranscriptId = queryParams.get(PARAM_TRANSCRIPT_SELECTED);
    if (!interactions.length || !selectedTranscriptId) {
      return;
    }
    const interactionToSelect = interactions.find((i) =>
      i.recordings.find((r) => r.transcriptRequests.find((tr) => tr.id === selectedTranscriptId))
    );
    interactionToSelect && setCurrentInteractionId(interactionToSelect.id);
    queryParams.delete(PARAM_TRANSCRIPT_SELECTED, true);
  }, [interactions, queryParams]);

  useEffect(
    function pushToUrl() {
      currentInteractionId
        ? queryParams.set(PARAM_INTERACTION_SELECTED, currentInteractionId, true)
        : queryParams.delete(PARAM_INTERACTION_SELECTED, true);
    },
    [currentInteractionId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function pushToUrl() {
      currentSuggestionId
        ? queryParams.set(PARAM_CONTENT_SELECTED, currentSuggestionId, true)
        : queryParams.delete(PARAM_CONTENT_SELECTED, true);
    },
    [currentSuggestionId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function pushToUrl() {
      currentThirdPartyDocId
        ? queryParams.set(PARAM_THIRD_PARTY_DOC_SELECTED, currentThirdPartyDocId)
        : queryParams.delete(PARAM_THIRD_PARTY_DOC_SELECTED);
    },
    [currentThirdPartyDocId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function fixInvalidId() {
      if (currentInteractionId && filteredInteractions.length) {
        if (!filteredInteractions.find((i) => i.id === currentInteractionId || i.followUpId === currentInteractionId)) {
          setCurrentInteractionId(null);
          if (!chainIdSelected) {
            const hasTranscriptRequested = ["ai", "regular"].includes(
              interactions.find((i) => i.id === currentInteractionId)?.transcriptOption
            );
            const messageProps = hasTranscriptRequested
              ? {
                  icon: <Info />,
                  variant: "info",
                  message: <DeliverableNotReady token={token} />,
                }
              : {
                  icon: <Error />,
                  variant: "danger",
                  message: <DeliverableNotFoundMessage token={token} />,
                };
            addPermanentMessage({
              ...messageProps,
              marginLeft: {
                md: "375px",
                xl: "475px",
              },
            });
          }
        }
      }
    },
    [interactions, filteredInteractions, currentInteractionId, chainIdSelected, addPermanentMessage, token]
  );

  const updateDeliverableSuggestion = useCallback(
    (contentId, changes) => {
      const copyUpdating = (content) =>
        content.id === contentId
          ? suggestionsInfo({
              ...content,
              ...changes,
              // this is because the market primer details endpoint does not return the company logos and keywordHits props
              companyLogos: content.companyLogos ? content.companyLogos : [],
              keywordHits: content.keywordHits ? content.keywordHits : 0,
            })
          : content;

      setAlphaNowContent((prevVal) => ({
        ...prevVal,
        suggestedContent: prevVal?.suggestedContent?.map(copyUpdating),
        privateContent: prevVal?.privateContent?.map(copyUpdating),
      }));
    },
    [setAlphaNowContent]
  );

  const showContentNotFoundMessage = () => {
    addPermanentMessage({
      message: <ContentNotFoundMessage />,
      icon: <Error />,
      variant: "danger",
      marginLeft: {
        md: "375px",
        xl: "475px",
      },
    });
  };

  const fetchSuggestions = useCallback(
    () => {
      const linkedFirst = (linked, content) => {
        const others = content.filter(({ id }) => id !== currentSuggestionId);
        return [linked, ...others];
      };
      setContentLoading(true);
      return fetchAlphaNowContent(queryKeywords)
        .then((response) => {
          if (!currentSuggestionId || currentSuggestionId === RECOMMENDED_CONTENT_ID) {
            setAlphaNowContent(response);
            return;
          }

          const linked = [...response.pitchedContent, ...response.suggestedContent, ...response.privateContent].find(
            ({ id }) => id === currentSuggestionId
          );

          if (linked?.privateMode === true) {
            setAlphaNowContent(response);
            return;
          }

          if (linked || !project?.active) {
            const contentToBeOrdered = linked?.recommendationId ? "pitchedContent" : "suggestedContent";
            setAlphaNowContent({
              ...response,
              [contentToBeOrdered]: linked
                ? linkedFirst(linked, response[contentToBeOrdered])
                : response[contentToBeOrdered],
            });
            return;
          }

          fetchAlphaNowContentById({ id: currentSuggestionId })
            .then((content) => {
              setAlphaNowContent({
                ...response,
                suggestedContent: [content, ...response.suggestedContent],
              });
            })
            .catch(() => {
              showContentNotFoundMessage();
              setAlphaNowContent(response);
            });
        })
        .finally(() => {
          setContentLoading(false);
        });
    },
    [fetchAlphaNowContent, queryKeywords, setAlphaNowContent, project] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const filterOptions = useMemo(() => {
    return { ...generalFilterOptions, ...alphaNowFilterOptions };
  }, [generalFilterOptions, alphaNowFilterOptions]);

  useEffect(() => {
    fetchSuggestions();
  }, [queryKeywords]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldHideThirdPartyDocuments) {
      setThirdPartyDocuments([]);
    } else {
      refetchThirdPartyDocuments();
    }
  }, [shouldHideThirdPartyDocuments, setThirdPartyDocuments, refetchThirdPartyDocuments]);

  useEffect(() => {
    fetchAlphaNowContent([]).then((res) => {
      const types = [...res.suggestedContent, ...res.privateContent, ...res.pitchedContent].map((c) => {
        return c.isPrimer
          ? "primer"
          : c.contentType === AlphaNowContentType.alphaview
          ? "moderated-content"
          : "investor-led-content";
      });

      setAlphaNowFilterOptions({
        alphaNowTypesSet: [...new Set(types)],
      });
    });
  }, [project, fetchAlphaNowContent]);

  useEffect(
    function flyoutInteractionUpdated() {
      if (!chainIdSelected || currentUtilityCardId) return;
      setCurrentInteractionId(chainIdSelected);
    },
    [chainIdSelected] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onTextSearchWrapped = (args) => {
    setCurrentInteractionId(null);
    setCurrentSuggestionId(null);
    setCurrentUtilityCardId(null);
    setCurrentThirdPartyDocId(null);
    setQueryKeywords(args);
    return onTextSearch(args);
  };

  const filterCallback = (filters) => {
    setCurrentInteractionId(null);
    setCurrentSuggestionId(null);
    setCurrentUtilityCardId(null);
    setCurrentThirdPartyDocId(null);
    onSubmitFilters(filters);
  };

  const onCardSelect = (selection) => {
    let suggestionId = null;
    let interactionId = null;
    let utilityCardId = null;
    let thirdPartyDocId = null;

    if (selection.origin === InteractionOrigin.thirdParty) {
      thirdPartyDocId = selection.id;
    } else if (selection.id === PROJECT_SUMMARY_ID) {
      utilityCardId = selection.id;
    } else if (selection.contentType || selection.id === RECOMMENDED_CONTENT_ID) {
      suggestionId = selection.id;
    } else {
      interactionId = selection.id;
    }

    setInitialAiSummarizationType(undefined);
    onCloseFlyout();
    setIsInitialSelection(false);

    setCurrentInteractionId(interactionId);
    setCurrentSuggestionId(suggestionId);
    setCurrentUtilityCardId(utilityCardId);
    setCurrentThirdPartyDocId(thirdPartyDocId);
  };

  const onSelectRecommendedContent = (content) => {
    setCurrentSuggestionId(content.id);
  };

  const onCloseContentSidebar = () => {
    setCurrentSuggestionId(RECOMMENDED_CONTENT_ID);
  };

  const onClearFilters = () => {
    onResetAllFilters();
    setQueryKeywords([]);
  };

  const tz = useTimezone();

  const alphaNowContentFiltered = filterSuggestions({
    appliedFilters,
    alphaNowContent,
    tz,
  });

  const notAccessibleSuggestedContent = useMemo(
    () => (alphaNowContentFiltered?.suggestedContent ?? []).filter((suggestion) => !suggestion.isAccessible),
    [alphaNowContentFiltered]
  );

  const accessibleContent = useMemo(
    () => (alphaNowContentFiltered?.suggestedContent ?? []).filter((suggestion) => suggestion.isAccessible),
    [alphaNowContentFiltered]
  );

  const projectSummariesContext = useProjectSummariesContext();

  useEffect(
    function landing() {
      if (landingAddressed) return;
      if (Object.keys(alphaNowContent).length === 0) return;

      if (queryKeywords.length > 0) {
        onTextSearch(queryKeywords);
      }

      if (currentSelection) {
        setLandingAddressed(true);
        return;
      }

      if (hasProjectBadge(ENABLE_PROJECT_SUMMARIZATION) && queryKeywords.length === 0) {
        if (projectSummariesContext.isSummaryStatusLoading) return;

        const { uniqSummariesStatusByAngle, onSelectSummary } = projectSummariesContext;

        const projectSummary = uniqSummariesStatusByAngle.find((el) => !el.angleId);

        if (projectSummary?.status === ProjectSummaryStatus.Complete) {
          setCurrentUtilityCardId(PROJECT_SUMMARY_ID);
          onSelectSummary(projectSummary.id);
          setLandingAddressed(true);
          return;
        }
      }

      if (notAccessibleSuggestedContent.length > 0) {
        setCurrentSuggestionId(RECOMMENDED_CONTENT_ID);
        setLandingAddressed(true);
        return;
      }

      if ((alphaNowContent.privateContent || []).length > 0) {
        setCurrentSuggestionId(alphaNowContent.privateContent[0].id);
        setLandingAddressed(true);
        return;
      }

      if (accessibleContent.length > 0) {
        setCurrentSuggestionId(accessibleContent[0].id);
        setLandingAddressed(true);
        return;
      }

      if (filteredInteractions.length > 0) {
        const latestDeliverable = _.maxBy(filteredInteractions, (i) => {
          return _.maxBy(
            [
              ...i.recordings.map((r) => r.createdAt),
              ...i.recordings.flatMap((r) => {
                return r.transcriptRequests
                  .filter((tr) => tr.completed && !tr.purgedAt)
                  .map((tr) => tr.visibleToClientUpdatedAt);
              }),
            ].map((string) => parseISO(string).valueOf())
          );
        });
        setCurrentInteractionId(latestDeliverable.id);
        setLandingAddressed(true);
        return;
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      alphaNowContent,
      currentSelection,
      filteredInteractions,
      hasProjectBadge,
      projectSummariesContext,
      landingAddressed,
      notAccessibleSuggestedContent,
      accessibleContent,
      queryKeywords,
    ]
  );

  const onErrorLoadingContent = () => {
    setCurrentInteractionId(null);
    setCurrentSuggestionId(null);
    setCurrentUtilityCardId(null);
    setCurrentThirdPartyDocId(null);
  };

  useEffect(() => {
    if (!currentSelection || currentSelection.id === RECOMMENDED_CONTENT_ID) return;

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.interactionSelected,
      projectToken: project.token,
      advisorshipId: currentSelection.id === currentInteractionId ? currentInteractionId : null,
      details: {
        suggestionId: currentSuggestionId,
      },
      references: {
        advisorshipId: currentInteractionId,
      },
    });
  }, [currentSelection?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CitationProvider>
      <FullDeliverablesView
        appliedFilters={appliedFilters}
        appliedKeywords={queryKeywords}
        filterOptions={filterOptions}
        interactions={filteredInteractions}
        thirdPartyDocuments={thirdPartyDocuments}
        alphaNowContent={alphaNowContentFiltered}
        sortCriteria={sortCriteria}
        isFlyoutOpen={isFlyoutOpen}
        onSelect={onCardSelect}
        onFilter={filterCallback}
        onSort={setSortCriteria}
        onCloseFlyout={onCloseFlyout}
        currentSelection={currentSelection}
        onTextSearch={onTextSearchWrapped}
        onClearFilters={onClearFilters}
        loading={isLoading}
        initialAiSummarizationType={initialAiSummarizationType}
        chainIdSelected={chainIdSelected}
        onErrorLoadingContent={onErrorLoadingContent}
        updateDeliverableSuggestion={updateDeliverableSuggestion}
        notAccessibleSuggestedContent={notAccessibleSuggestedContent}
        onSelectRecommendedContent={onSelectRecommendedContent}
        onCloseContentSidebar={onCloseContentSidebar}
        fetchSuggestions={fetchSuggestions}
        isInitialSelection={isInitialSelection}
      />
    </CitationProvider>
  );
};

const FullDeliverablesView = (props) => {
  const {
    appliedKeywords,
    alphaNowContent,
    currentSelection,
    isFlyoutOpen,
    chainIdSelected,
    onErrorLoadingContent,
    updateDeliverableSuggestion,
    onCloseFlyout,
    notAccessibleSuggestedContent,
    onSelectRecommendedContent: onSelectRecommendedContentInput,
    onCloseContentSidebar: onCloseContentSidebarInput,
    fetchSuggestions,
    filterOptions,
    onSelect: onCardSelect,
    isInitialSelection,
  } = props;
  const { selectedInsight, onSelectInsight } = useProjectSummariesContext();
  const { isMobile, isTablet, isUltraWide } = useCheckScreen();
  const [showCards, setShowCards] = useState(true);
  const isCardsSidebarCollapsed = !isUltraWide && (!showCards || isFlyoutOpen || selectedInsight);

  const styles = useStyles({
    currentSelection,
    isCardsSidebarCollapsed,
  });

  const onExpandSidebar = () => {
    onCloseFlyout();
    onSelectInsight(undefined);
    currentSelection?.paymentRequired && onCloseContentSidebarInput();
    setShowCards(true);
  };

  const onSelectRecommendedContent = (content) => {
    onSelectRecommendedContentInput(content);
    !isMobile && setShowCards(false);
  };

  useEffect(() => {
    if (currentSelection?.id === PROJECT_SUMMARY_ID) {
      setShowCards(!isTablet);
    } else {
      if (!currentSelection?.contentType) return;
      if (currentSelection.paymentRequired) return;
      if (isInitialSelection) return;

      setShowCards(false);
    }
  }, [currentSelection?.id, currentSelection?.paymentRequired, isTablet]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelect = useCallback(
    (selection) => {
      if (selection.id === PROJECT_SUMMARY_ID && isTablet) {
        setShowCards(false);
      }
      onCardSelect(selection);
    },
    [isTablet, onCardSelect]
  );

  const showExpertsInfoView = useMemo(() => {
    const isFullScreenPrimer = !showCards && currentSelection?.isPrimer;
    return isFullScreenPrimer && currentSelection?.isAccessible;
  }, [currentSelection, showCards]);

  const { pitchedContent } = alphaNowContent;
  const recommendations = [...(Array.isArray(pitchedContent) ? pitchedContent : []), ...notAccessibleSuggestedContent];

  const { selectedCitation } = useCitationContext();

  useEffect(() => {
    if (!selectedCitation) return;

    setShowCards(false);
  }, [selectedCitation]);

  return (
    <Wrapper isCardsSidebarCollapsed={isCardsSidebarCollapsed} onExpandSidebar={onExpandSidebar} styles={styles}>
      <x.div data-testid="deliverables-sidebar" {...styles.sidebar}>
        <CardsSidebar
          {...props}
          isCardsSidebarCollapsed={isCardsSidebarCollapsed}
          onExpandSidebar={onExpandSidebar}
          onSelect={onSelect}
        />
      </x.div>
      <x.div {...styles.mainContentWrapper}>
        {currentSelection && (
          <MainContent
            key={currentSelection.id}
            componentKey={currentSelection.id}
            currentSelection={currentSelection}
            appliedKeywords={appliedKeywords}
            onErrorLoadingContent={() => {
              setShowCards(true);
              onErrorLoadingContent();
            }}
            updateDeliverableSuggestion={updateDeliverableSuggestion}
            freeSamples={alphaNowContent.freeSamples}
            onSelectRecommendedContent={onSelectRecommendedContent}
            onCloseContentSidebar={onExpandSidebar}
            recommendations={recommendations}
            fetchSuggestions={fetchSuggestions}
            filterOptions={filterOptions}
          />
        )}
        {!currentSelection && chainIdSelected && <MainContentNoDeliverable />}
        {!currentSelection && !chainIdSelected && <MainContentEmpty />}
        <x.div maxW={"350px"}>
          {showExpertsInfoView && (
            <ExpertsInfoView
              contentId={currentSelection.id}
              speakers={currentSelection.speakers}
              isPaymentRequired={currentSelection?.paymentRequired}
            />
          )}
        </x.div>
      </x.div>
      {selectedInsight && <InsightReferences selectedInsight={selectedInsight} />}
    </Wrapper>
  );
};

const Wrapper = ({ isCardsSidebarCollapsed, onExpandSidebar, children, styles }) => {
  return (
    <CardsSidebarControlProvider isCollapsed={isCardsSidebarCollapsed} onExpand={onExpandSidebar}>
      <x.div {...styles.view}>{children}</x.div>
    </CardsSidebarControlProvider>
  );
};

const limitText = (text, limit) => {
  if (text.length <= limit) return text;
  return `${text.substring(0, limit - 3)}...`;
};
const InsightReferences = ({ selectedInsight }) => {
  const { insightsReferences } = useDeliverablesViewStyles();
  const { onSelectInsight, projectSummaryLogHit } = useProjectSummariesContext();
  const expertLabel = `${selectedInsight.interaction.advisorName} - ${selectedInsight.interaction.role} - ${selectedInsight.interaction.companyName}`;
  const link = (index) =>
    `/${selectedInsight.interaction.projectToken}/experts/deliverables-view/?selectedInteraction=${selectedInsight.interaction.id}&part=${index}`;
  const key = (source) => `insight-${source.transcriptId}-${source.index}`;

  const logHitViewTranscript = useCallback(
    (source) => {
      projectSummaryLogHit({
        action: HitAction.projectSummaryViewInTranscript,
        extraDetails: { type: selectedInsight.insight.type, text: selectedInsight.insight.text, source },
        extraReferences: { interactionId: selectedInsight.interaction.id },
      });
    },
    [projectSummaryLogHit, selectedInsight]
  );

  return (
    <x.div {...insightsReferences.wrapper}>
      <x.div {...insightsReferences.close}>
        <Icon
          color="secondary"
          onClick={() => onSelectInsight(undefined)}
          size="medium"
          data-testid="close-insights-references"
        >
          <Close />
        </Icon>
        <Typography variant="pre-title">Linked Transcript Citations</Typography>
      </x.div>
      <x.div {...insightsReferences.cardsWrap}>
        {selectedInsight.insight.sources.map((source) => (
          <x.div {...insightsReferences.cardWrap} data-testid={key(source)} key={key(source)}>
            <x.div {...insightsReferences.cardTitle}>
              <x.div {...insightsReferences.expertIcon}>
                <Icon>
                  <Expert />
                </Icon>
              </x.div>
              <LimitedLinesTypography lines={2} tooltip={expertLabel} variant="body-small-em" color="secondary">
                {expertLabel}
              </LimitedLinesTypography>
            </x.div>
            <Typography variant="body">{limitText(source.text, 450)}</Typography>
            <x.div>
              <Link
                size="small"
                variant="nav"
                href={link(source.index)}
                endIcon={<ArrowRight />}
                data-testid="view-in-transcript"
                target="_blank"
                onClick={() => logHitViewTranscript(source)}
              >
                <Typography variant="body-small-em">View in transcript</Typography>
              </Link>
            </x.div>
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

const MainContent = ({
  componentKey: key,
  currentSelection,
  appliedKeywords,
  onErrorLoadingContent,
  updateDeliverableSuggestion,
  freeSamples,
  onSelectRecommendedContent,
  onCloseContentSidebar,
  recommendations,
  fetchSuggestions,
  filterOptions,
}) => {
  const query = useLocationQuery();
  const {
    recommendedContentWrapper,
    recommendedContentDiv,
    recommendedContentTableDiv,
    recommendedContentTitle,
  } = useDeliverablesViewStyles();
  const [fragmentIds, setFragmentIds] = useState([]);

  const isSuggestedContent = currentSelection.contentType || currentSelection.id === RECOMMENDED_CONTENT_ID;
  const isProjectSummary = currentSelection.id === PROJECT_SUMMARY_ID;

  useEffect(() => {
    const fragmentIds = query.get("fragmentIds")?.split(",");
    setFragmentIds(fragmentIds);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isProjectSummary) {
    return <ProjectSummary filterOptions={filterOptions} />;
  }

  if (isSuggestedContent) {
    return (
      <>
        {!currentSelection.isAccessible ? (
          <x.div {...recommendedContentWrapper}>
            {currentSelection.privateMode ? (
              <SuggestionMainContent
                key={key}
                suggestion={currentSelection}
                updateDeliverableSuggestion={updateDeliverableSuggestion}
                appliedKeywords={appliedKeywords}
                freeSamples={freeSamples}
                onErrorLoading={onErrorLoadingContent}
                fragmentIds={fragmentIds}
              />
            ) : (
              <>
                <x.div {...recommendedContentDiv}>
                  <x.div {...recommendedContentTitle}>
                    <ExpandSidebarButton />
                    <Typography variant="h3" shouldCapitalizeHeadline={false}>
                      Recommended Research
                    </Typography>
                  </x.div>
                  <x.div {...recommendedContentTableDiv}>
                    <RecommendedContentTable
                      suggestedContent={recommendations}
                      onSelectRecommendedContent={onSelectRecommendedContent}
                      selectedRecomendedContent={
                        currentSelection.id !== RECOMMENDED_CONTENT_ID ? currentSelection : undefined
                      }
                    />
                  </x.div>
                </x.div>
                {currentSelection.contentType && (
                  <Sidebar
                    isOpen
                    content={currentSelection}
                    onClose={onCloseContentSidebar}
                    updateDeliverableSuggestion={updateDeliverableSuggestion}
                    freeSamples={freeSamples}
                    fetchSuggestions={fetchSuggestions}
                    referrer="deliverables"
                  />
                )}
              </>
            )}
          </x.div>
        ) : (
          <SuggestionMainContent
            key={key}
            suggestion={currentSelection}
            updateDeliverableSuggestion={updateDeliverableSuggestion}
            appliedKeywords={appliedKeywords}
            freeSamples={freeSamples}
            onErrorLoading={onErrorLoadingContent}
            fragmentIds={fragmentIds}
          />
        )}
      </>
    );
  }
  if (currentSelection.origin === InteractionOrigin.thirdParty) {
    return <ThirdPartyDocumentPage interaction={currentSelection} />;
  }

  return <DeliverablesPage key={key} interaction={currentSelection} appliedKeywords={appliedKeywords} />;
};

export { DeliverablesView };
