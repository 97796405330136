import { ThirdPartyInteraction } from "types";
import { useMutation, useQueryClient } from "query-utils";
import thirdPartyDocumentsService, { DocumentAttributes } from "services/thirdPartyDocumentsService";
import { useCallback } from "react";

interface UpdateDocumentAttributesProps {
  documentId: string;
  attributes: Partial<DocumentAttributes>;
}

export const useUpdateDocumentAttributes = ({
  interaction,
  projectToken,
  service = thirdPartyDocumentsService,
}: {
  interaction?: ThirdPartyInteraction;
  projectToken: string;
  service?: typeof thirdPartyDocumentsService;
}) => {
  const queryClient = useQueryClient();

  const updateDocumentAttributes = useCallback(
    (documentId: string, attributes: Partial<DocumentAttributes>) => {
      return service.updateDocumentAttributes(projectToken, documentId, attributes);
    },
    [service, projectToken]
  );

  const refetchDocuments = () => queryClient.invalidateQueries(["fetchThirdPartyDocuments"]);

  const updateDocumentAttributesMutation = useMutation(
    ({ documentId, attributes }: UpdateDocumentAttributesProps) =>
      updateDocumentAttributes(documentId, {
        ...interaction?.attribute.attributes,
        documentTitle: attributes.documentTitle,
        ...attributes,
        date: interaction?.scheduledCallTime,
      }),
    {
      onSuccess: () => refetchDocuments(),
    }
  );

  return {
    onSaveEdits: (props: UpdateDocumentAttributesProps) => updateDocumentAttributesMutation.mutateAsync(props),
  };
};
