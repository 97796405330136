import React, { useCallback, useMemo } from "react";
import { Icon, useThemeTokens, Tooltip } from "@alphasights/alphadesign-components";
import { Loading, StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useLockedExpert } from "hooks/useLockedExpert";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useStarExpertContext } from "providers/StarExpertProvider";

export const StarColumn = ({ row: { original: data } }) => {
  const { font } = useThemeTokens();
  const { locked } = useLockedExpert(data);
  const { onToggleStar } = useStarExpertContext();

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      return onToggleStar(data, HitOrigin.tableView);
    },
    [onToggleStar, data]
  );

  const loading = useMemo(() => {
    return data.runningAction === "toggleStar";
  }, [data.runningAction]);

  return (
    <div className="aui-space-x-4 aui-inline-flex aui-items-center">
      {!data.hidden && (
        <StarButton
          starred={data.starred}
          onClick={handleClick}
          loading={loading}
          fontSize={font.size["06"]}
          disabled={locked}
        />
      )}
    </div>
  );
};

const StarButton = ({ starred, loading, disabled, onClick, ...props }) => {
  const icon = useMemo(() => (starred ? <StarSelected /> : <StarUnselected />), [starred]);
  const tooltip = useMemo(() => (starred ? "Unstar expert" : "Star expert"), [starred]);

  return loading ? (
    <x.div {...props}>
      <Loading style={{ animation: "x-spin 1s linear infinite" }} />
    </x.div>
  ) : (
    <x.div cursor={disabled ? "not-allowed" : "auto"} {...props}>
      <Tooltip title={tooltip} disabled={disabled} dark>
        <Icon color="secondary" data-testid="table-star-button" onClick={disabled ? undefined : onClick}>
          {icon}
        </Icon>
      </Tooltip>
    </x.div>
  );
};
