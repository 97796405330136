import React, { useEffect, useState } from "react";
import { Sidebar } from "./Sidebar";
import { Topbar } from "./Topbar";
import { MainContent } from "./MainContent";
import * as S from "./AngleQuestionsView.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { AngleQuestionTheme } from "models/AngleQuestions";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

const AngleQuestionsView = () => {
  const { getAngleQuestionThemes } = useAngleQuestionsContext();
  const angles = useAnglesFromFilters();
  const anglesLoading = angles.length === 0;
  const [themesLoading, setThemesLoading] = useState(true);
  const [themes, setThemes] = useState<AngleQuestionTheme[]>([]);

  useEffect(
    function getFirstAngleQuestionThemes() {
      if (!anglesLoading) {
        getAngleQuestionThemes(angles.at(0)!.value)
          .then(setThemes)
          .finally(() => setThemesLoading(false));
      }
    },
    [angles, anglesLoading, getAngleQuestionThemes]
  );

  return (
    <S.Wrapper>
      <Sidebar loading={anglesLoading} />
      <S.RightSide>
        <Topbar loading={themesLoading} />
        <MainContent themes={themes} loading={themesLoading} />
      </S.RightSide>
    </S.Wrapper>
  );
};

const useAnglesFromFilters = () => {
  const {
    state: { filterOptions },
  } = useProjectInteractionsContext();

  return (filterOptions?.groups ?? []) as FilterOption[];
};

export { AngleQuestionsView };
