import React, { useEffect, useReducer, useRef, useState } from "react";
import { omit } from "lodash";
import { x } from "@xstyled/styled-components";
import {
  AlphaNowContentType,
  useTrackUserAction,
  SEARCH_SUGGESTION_TYPES,
  PromiseStatus,
} from "@alphasights/client-portal-shared";
import { useNewNavigation } from "hooks/useNewNavigationUnbadge";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens, Alert } from "@alphasights/alphadesign-components";

import { ACTION_TYPES, SEARCH_SUGGESTION_TYPE_NAMES, SEARCH_SUGGESTION_TYPE_ICONS } from "constants/AlphaNow";
import { searchForExpertInsights } from "content/AlphaNow";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { contentService } from "services/content";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";

import Search, { SearchSizeVariant, SearchVariant, SearchError } from "components/Search";
import { getDefaultComponents } from "components/Search/components";
import AlphaNowFilters from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters";
import SearchResults from "pages/AlphaNowPage/components/AlphaNowSearch/SearchResults";

import { REQUEST_PRIMER } from "content/AlphaNow/constants";
import { BooleanExpressionError, isBooleanSearch } from "components/Search/utils";
import { processSearchQuery } from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import Navigation from "components/NavigationContainer/Navigation";
import { useAppSearchContext } from "providers/AppSearchProvider";
import {
  getMarketValues,
  getMarketCompetitorCompanyIds,
  getContentFiltersPayload,
  extractSearchItemsFromQuery,
} from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { hasAnyPrimer } from "content/AlphaNow/utils";
import {
  resultsPerPage,
  pageLimit,
  noContentErrorMessage,
  noRelevantMarketsErrorMessage,
  invalidBooleanSearchErrorMessage,
  invalidCharacterNumberErrorMessage,
  noSearchMatchesErrorMessage,
} from "pages/AlphaNowPage/components/AlphaNowSearch/constants";
import useAlphaNowSearch, { AlphaNowSearchError } from "pages/AlphaNowPage/hooks/useAlphaNowSearch";
import { useAlphaNowPageStore } from "pages/AlphaNowPage/store/useAlphaNowPageStore";

import {
  SearchBarWrapper,
  SearchToolsContainer,
  SearchContainer,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowSearch.styled";
import "./index.css";

const WarningBanner = ({ text }) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div mt={spacing.inner.base02}>
      <Alert variant="warning">{text}</Alert>
    </x.div>
  );
};

const enhanceOption = (option) => ({
  ...option,
  ...(option.logo ? { avatar: { src: option.logo } } : { StartIcon: SEARCH_SUGGESTION_TYPE_ICONS[option.type] }),
  secondaryLabel: SEARCH_SUGGESTION_TYPE_NAMES[option.type],
});

const SearchItem = ({ data, ...props }) => {
  const enhancedData = enhanceOption(data);
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  return <BaseSearchItem data={enhancedData} {...props} />;
};

const Option = ({ data, ...props }) => {
  const { Option: BaseOption } = getDefaultComponents();
  const enhancedOption = enhanceOption(data);
  return <BaseOption data={enhancedOption} {...props} />;
};

const initialPageState = {
  hasMoreSearchResults: false,
  numberOfResults: undefined,
  searchIsLoading: true,
  searchResults: [],
  searchResultsPage: 1,
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.updateSearchResults:
      return { ...state, ...action.payload };
    case ACTION_TYPES.updateSearchIsLoading:
      return { ...state, searchIsLoading: action.payload };
    default:
      throw new Error(
        `Action { type: ${action.type}, payload: ${action.payload} } must be one of the defined ACTION_TYPES.`
      );
  }
};

const cleanUpQuery = (query) =>
  query?.map((item) => omit(item, ["secondaryLabel", "StartIcon", "inputValue", "logo"])) ?? [];

const AlphaNowSearch = ({
  isRequestPrimer = false,
  isSidebarExpanded,
  purchasedContentChanged = [],
  bookmarksChanged = [],
  accessDenied,
  setAccessDenied,
  activeSearchBar,
  setActiveSearchBar,
  selectedCompanyId,
  setSelectedCompanyId,
  setServicePlanExpired,
}) => {
  const currentUser = useCurrentUser();
  const userId = currentUser?.id;
  const { hasUserBadge } = useUserBadgeContext();
  const { logHit } = useTrackUserAction();
  const { isMobile } = useCheckScreen();

  const firstUpdate = useRef(true);

  const [pageState, pageDispatch] = useReducer(pageReducer, initialPageState);

  const {
    query: { filters, searchQuery, selectedContentId },
    updateQuery,
    searchErrorMessage,
    setSearchErrorMessage,
    isNavigating,
  } = useAppSearchContext();
  const { loadSearchSuggestions } = useAlphaNowSearch();
  const newNavigationEnabled = useNewNavigation();
  const { contentTypeMapping } = useAlphaNowContentAccessLevelContext();

  const [searchSelectedCompanies, setSearchSelectedCompanies] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const [marketCompetitors, setMarketCompetitors] = useState([]);

  const { hasMoreSearchResults, numberOfResults, searchIsLoading, searchResults, searchResultsPage } = pageState;

  const displayRequestPrimerCard =
    !selectedCompanyId && searchSelectedCompanies?.length > 0 && hasAnyPrimer(contentTypeMapping);

  // TODO [CON-3152]: Remove Company Page Badge
  const hasCompanyPageBadge = hasUserBadge(Badge.companyPage);
  const hasCompanyPageEnabled = hasCompanyPageBadge && !isMobile;
  const showSearchBar = !newNavigationEnabled || isMobile;
  const showWarningBanner = [
    noSearchMatchesErrorMessage,
    invalidBooleanSearchErrorMessage,
    invalidCharacterNumberErrorMessage,
  ].includes(searchErrorMessage);
  const searchResultsErrorMessage = [noContentErrorMessage, noRelevantMarketsErrorMessage].includes(searchErrorMessage)
    ? searchErrorMessage
    : "";

  const setIsResearchEntryLoading = useAlphaNowPageStore(({ setIsResearchEntryLoading }) => setIsResearchEntryLoading);

  const handleSearchLoading = (isLoading) => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchIsLoading,
      payload: isLoading,
    });
  };

  const handleSearchError = (errorType) => {
    if (errorType === SearchError.InvalidCharacterNumber) {
      setSearchErrorMessage(invalidCharacterNumberErrorMessage);
    } else {
      resetSearchErrorMessage([invalidCharacterNumberErrorMessage]);
    }
  };

  const resetSearchErrorMessage = (errorMessagesToReset = []) => {
    if (searchErrorMessage && errorMessagesToReset.includes(searchErrorMessage)) {
      setSearchErrorMessage("");
    }
  };

  const loadSearchOptions = async (inputValue) => {
    resetSearchErrorMessage([noSearchMatchesErrorMessage]);
    let options = [];
    try {
      options = await loadSearchSuggestions(inputValue);
    } catch (e) {
      if (e instanceof AlphaNowSearchError) {
        setSearchErrorMessage(noSearchMatchesErrorMessage);
      }
    }
    return options;
  };

  const validateQuery = (query) => {
    if (isBooleanSearch(query)) {
      try {
        const booleanExpressionTree = processSearchQuery(query);
        resetSearchErrorMessage([invalidBooleanSearchErrorMessage]);
        return booleanExpressionTree;
      } catch (e) {
        if (e instanceof BooleanExpressionError) {
          setSearchErrorMessage(invalidBooleanSearchErrorMessage);
          handleSearchLoading(false);
          return;
        } else {
          throw e;
        }
      }
    }
    resetSearchErrorMessage([invalidBooleanSearchErrorMessage]);
    return query;
  };

  const onSearchChange = (searchQuery) => {
    const query = cleanUpQuery(searchQuery ?? []);

    if (!validateQuery(query)) return;

    setActiveSearchBar(false);
    updateQuery({
      searchQuery: query,
      selectedContentId: undefined,
    });
    // prevents the useEffect from clearing the search query until the user dismisses the warning banner
    if (accessDenied) setAccessDenied(false);

    if (searchErrorMessage) resetSearchErrorMessage([noContentErrorMessage, noRelevantMarketsErrorMessage]);
  };

  const fetchSearchResults = (searchPayload) => {
    const fetchPromises = [contentService.fetchContent(searchPayload)];
    const hasMarketCompetitorsEnabled = hasCompanyPageEnabled && searchPayload.markets.length > 0;
    if (hasMarketCompetitorsEnabled) {
      fetchPromises.push(contentService.fetchRelevantCompanies(searchPayload));
    }

    return fetchPromises;
  };

  const handleResetSearchResults = () => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchResults,
      payload: {
        searchResults: [],
        hasMoreSearchResults: false,
        numberOfResults: 0,
        searchResultsPage: 0,
      },
    });
  };

  const handleRejectedContentSearchPromise = async (contentSearchResults) => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchResults,
      payload: {
        searchResults: null,
        searchIsLoading: false,
      },
    });
    setSearchErrorMessage(noContentErrorMessage);

    const error = contentSearchResults.reason;
    if (error?.status === 404) {
      const response = await error.json();

      setServicePlanExpired(response?.type === "PricingDetailException");
    }
  };

  const selectDefaultCompanyPage = async (marketCompetitorResults, selectedCompanies) => {
    let marketCompetitorCompanyId;

    if (marketCompetitorResults?.status === PromiseStatus.Fulfilled) {
      const marketCompetitors = marketCompetitorResults.value;
      if (!!marketCompetitors) {
        marketCompetitorCompanyId = marketCompetitors[0]?.company?.cdsAlphaCompanyId;
        setSelectedCompanyId(marketCompetitorCompanyId);
        setMarketCompetitors(marketCompetitors);
      }
    } else if (marketCompetitorResults?.status === PromiseStatus.Rejected) {
      setSearchErrorMessage(noRelevantMarketsErrorMessage);
    }

    if (!marketCompetitorCompanyId && selectedCompanies.length > 0) {
      const [{ id, name }] = selectedCompanies;
      try {
        const companyPageSettings = await contentService.fetchCompanyPageSettings(id, name);
        companyPageSettings.hasCompanyPage && setSelectedCompanyId(id);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // newSearch is true when getItems is run on first load or with new parameters, it is false in infinite scroll
  const getItems = async ({ newSearch = false, initialSearch = false, contentId }) => {
    // prevent multiple requests from being made when navigating
    if (isNavigating) return;

    const isBooleanQuery = isBooleanSearch(searchQuery);
    let booleanSearchConditions = [];

    if (isBooleanQuery) {
      const validatedQuery = validateQuery(searchQuery);
      if (!validatedQuery) return;
      booleanSearchConditions.push(validatedQuery);
    }

    handleSearchLoading(true);

    if (newSearch) handleResetSearchResults();

    const {
      keywords: selectedKeywords,
      companies: selectedCompanies,
      colleagues: selectedColleagues,
      markets: selectedMarkets,
    } = extractSearchItemsFromQuery(searchQuery, isBooleanQuery);

    const newPage = newSearch ? 1 : searchResultsPage + 1;

    Promise.allSettled(
      fetchSearchResults({
        companies: selectedCompanies,
        keywords: selectedKeywords,
        colleagues: selectedColleagues,
        markets: selectedMarkets,
        page: newPage,
        filters: getContentFiltersPayload(filters),
        booleanSearchConditions,
        pageSize: resultsPerPage,
      })
    ).then(async (results) => {
      const [contentSearchResults, marketCompetitorResults] = results;
      if (contentSearchResults.status === PromiseStatus.Rejected) {
        await handleRejectedContentSearchPromise(contentSearchResults);
      } else {
        resetSearchErrorMessage();
        if (hasCompanyPageEnabled) await selectDefaultCompanyPage(marketCompetitorResults, selectedCompanies);

        const { requestId, results: contentResults, total, hasNextPage } = contentSearchResults.value;

        setPageNumber(newPage);
        setSearchSelectedCompanies(selectedCompanies);

        if (searchQuery.length > 0 && !initialSearch) {
          logHit({
            origin: HitOrigin.alphaNow,
            action: HitAction.alphaNowSearch,
            details: {
              companies: selectedCompanies,
              keywords: selectedKeywords,
              markets: getMarketValues(searchQuery, isBooleanQuery),
              marketCompetitorCompanyIds: getMarketCompetitorCompanyIds(marketCompetitorResults?.value),
              advanced_search_conditions: booleanSearchConditions,
              requestId,
            },
          });
        }

        if (contentResults) {
          let newResults = newSearch ? contentResults : [...searchResults, ...contentResults];
          if (contentId) {
            try {
              // temporary workaround until AlphaNow vs Transcript search relationship is reevaluated
              // and transcript search can support boolean search
              const allKeywords = selectedKeywords ?? [
                ...new Set(
                  searchQuery
                    .filter(({ type }) => parseInt(type) === SEARCH_SUGGESTION_TYPES.Keyword)
                    .map(({ value }) => value.replace(/["]+/g, ""))
                ),
              ];
              const sharedContent = await contentService.fetchContentById(contentId);
              if (allKeywords.length) {
                const sharedContentMentions = await contentService.fetchTranscriptMentions(contentId, allKeywords);
                sharedContent.keywordHits = sharedContentMentions?.data["transcript_search"].total;
              }
              newResults = [sharedContent, ...newResults.filter((x) => x.id !== contentId)];
              setAccessDenied(false);
            } catch (err) {
              setAccessDenied(true);
            }
          }
          pageDispatch({
            type: ACTION_TYPES.updateSearchResults,
            payload: {
              searchResults: newResults,
              hasMoreSearchResults: hasNextPage,
              numberOfResults: total,
              searchResultsPage: newPage,
              searchIsLoading: false,
            },
          });
        }
      }
    });
  };

  const selectContent = (id, index = null, contentType = null) => {
    const logHitAction =
      contentType === AlphaNowContentType.srm ? HitAction.alphaNowViewCompanyPrimer : HitAction.alphaNowViewTranscript;

    if (id) {
      setIsResearchEntryLoading(true);

      const newQuery = {};
      newQuery.selectedContentId = id;

      if (id !== REQUEST_PRIMER) {
        logHit({
          origin: HitOrigin.alphaNow,
          action: logHitAction,
          details: { contentId: id, rank: index + 1, userId },
        });
        newQuery.fragmentIds = [];
      }
      updateQuery(newQuery);
    }
  };

  const activateSearchBar = () => {
    setActiveSearchBar(true);
  };

  const deactivateSearchBar = () => {
    setActiveSearchBar(false);
  };

  useDeepCompareEffect(() => {
    // reset selected content and search when filters / searchQuery change
    // on first update, get shared contentId from state and get items with shared content
    if (firstUpdate.current && selectedContentId) {
      updateQuery({ selectedContentId });
      getItems({ newSearch: true, contentId: selectedContentId });
      firstUpdate.current = false;
      return;
    }
    getItems({ newSearch: true, initialSearch: firstUpdate.current });
    // reset selected content when a new search is performed
    selectContent(undefined);

    // reset searched company page cards when a new search is performed
    setSelectedCompanyId();
    setMarketCompetitors([]);

    firstUpdate.current = false;
  }, [filters, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //handles selecting content in the company page which does not exist within the search results
    const isNewSearchCompanyPage =
      selectedCompanyId && selectedContentId && !searchResults.map(({ id }) => id).includes(selectedContentId);

    isNewSearchCompanyPage && getItems({ newSearch: true, contentId: selectedContentId });
  }, [selectedContentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accessDenied && searchQuery.length) {
      updateQuery({ searchQuery: [] });
      handleSearchLoading(false);
    }
  }, [accessDenied, pageDispatch, updateQuery, searchQuery]);

  useEffect(() => {
    resetSearchErrorMessage();
    return () => resetSearchErrorMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SearchContainer>
        <SearchToolsContainer showSearchBar={showSearchBar}>
          {showSearchBar && (
            <SearchBarWrapper activeSearchBar={activeSearchBar} id="alphanow-search-container">
              <Navigation />
              <Search
                variant={SearchVariant.Complex}
                size={isMobile ? SearchSizeVariant.Large : SearchSizeVariant.Medium}
                query={searchQuery}
                placeholder={searchForExpertInsights}
                debounceSearch={true}
                loadOptions={loadSearchOptions}
                onChange={onSearchChange}
                onFocus={activateSearchBar}
                onBlur={deactivateSearchBar}
                onError={handleSearchError}
                components={{
                  SearchItem,
                  Option,
                }}
                optionProps={{
                  inputEmphasis: ({ type }) =>
                    [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.Colleague].includes(type),
                  anywhereEmphasis: ({ type }) => type === SEARCH_SUGGESTION_TYPES.Colleague,
                }}
                allowSingleCharSearch={false}
                autoHideSearchIcon={isMobile}
                allowBooleanOperators
                isMultiLine
                style={{ flex: 1 }}
              />
            </SearchBarWrapper>
          )}
          {showWarningBanner && <WarningBanner text={searchErrorMessage} />}
          <AlphaNowFilters numberOfResults={numberOfResults} canOpenPopovers={isSidebarExpanded} />
        </SearchToolsContainer>
        <SearchResults
          isPageLimit={pageNumber === pageLimit}
          key={searchQuery}
          isRequestPrimer={isRequestPrimer}
          displayRequestPrimerCard={displayRequestPrimerCard}
          isLoading={searchIsLoading}
          hasMoreContent={hasMoreSearchResults}
          onSelect={selectContent}
          searchResults={searchResults}
          bookmarksChanged={bookmarksChanged}
          purchasedContentChanged={purchasedContentChanged}
          getItems={getItems}
          selectedCompanies={searchSelectedCompanies}
          marketCompetitors={marketCompetitors}
          setSelectedCompanyId={setSelectedCompanyId}
          selectedCompanyId={selectedCompanyId}
          errorMessage={searchResultsErrorMessage}
          numberOfResults={numberOfResults}
        />
      </SearchContainer>
    </>
  );
};

export default AlphaNowSearch;
