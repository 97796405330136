import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

type ChartTooltipProps = {
  children: React.ReactNode | React.ReactNode[] | string;
};

const ChartTooltip = ({ children }: ChartTooltipProps) => {
  const {
    typography,
    spacing: { inner },
    shape,
    color: { background, text },
  } = useThemeTokens();
  const tooltipProps = {
    p: `${inner.base03} ${inner.base04}`,
    boxShadow: shape.shadow[500],
    borderTop: "none",
    maxW: 280,
    overflow: "hidden",
    background: background.dark,
    borderRadius: shape.border.radius.small,
    color: text.inverse,
    padding: `${inner.base} ${inner.base02}`,
    ...typography.body.regular,
    border: "0",
    role: "tooltip",
    maxWidth: 480,
  };

  return <x.div {...tooltipProps}>{children}</x.div>;
};

export default ChartTooltip;
