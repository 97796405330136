import React, { FC, useState } from "react";
import { Divider } from "@alphasights/alphadesign-components";

import { ThirdPartyInteraction } from "types";
import DocumentSummaryTab from "./DocumentSummaryTab";
import DocumentQuestionTab from "./DocumentQuestionsTab";
import PageHeader from "./PageHeader";
import DocumentViewer from "./DocumentViewer";
import { ViewType } from "./consts";

import * as S from "./ThirdPartyDocumentPage.styled";

type ThirdPartyDocumentPageProps = {
  interaction: ThirdPartyInteraction;
};

const DataTestIds = {
  tabContent: "tab-content-wrapper",
};

const ThirdPartyDocumentPage: FC<ThirdPartyDocumentPageProps> = ({ interaction }) => {
  const [selectedView, setSelectedView] = useState(ViewType.Transcript);
  const [searchTargetEl, setSearchTargetEl] = useState<HTMLElement | null>(null);

  return (
    <S.Wrapper>
      <PageHeader
        interaction={interaction}
        searchTargetEl={searchTargetEl}
        selectedView={selectedView}
        onChangeView={setSelectedView}
      />
      <Divider />
      <S.MainContentContainer data-testid={DataTestIds.tabContent}>
        {selectedView === ViewType.Summary && (
          <S.TabContainer data-summary-scrollable-area>
            <DocumentSummaryTab setSearchTargetEl={setSearchTargetEl} interaction={interaction} />
          </S.TabContainer>
        )}
        {selectedView === ViewType.Questions && (
          <S.TabContainer data-questions-scrollable-area>
            <DocumentQuestionTab setSearchTargetEl={setSearchTargetEl} interaction={interaction} />
          </S.TabContainer>
        )}
        {selectedView === ViewType.Transcript && (
          <S.TabContainer data-transcript-scrollable-area>
            <DocumentViewer key={interaction.id} interaction={interaction} />
          </S.TabContainer>
        )}
      </S.MainContentContainer>
    </S.Wrapper>
  );
};

export default ThirdPartyDocumentPage;
