import { useCallback } from "react";
import { useInView } from "react-intersection-observer";

type ReferenceLinkableSectionProps = {
  sectionRef: React.MutableRefObject<HTMLDivElement | null>;
  root?: React.MutableRefObject<HTMLDivElement | null>;
  triggerOnce?: boolean;
};

export default ({ sectionRef, root, triggerOnce }: ReferenceLinkableSectionProps) => {
  const [ref, inView] = useInView({
    root: root ? root.current : null,
    rootMargin: "-25%",
    triggerOnce,
  });

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      // Ref's from useRef needs to have the node assigned to `current`
      sectionRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      ref(node);
    },
    [ref, sectionRef]
  );

  return { setRefs, inView };
};
