import { create } from "zustand";

type storeState = {
  surveyId: string;
  selectedSectionIndex: number | null;
  navigateToSectionIndex: number | null;
  setSelectedSectionIndex: (newIndex: number) => void;
  setNavigateToSectionIndex: (newIndex: number | null) => void;
};

export const useSurveysResultsStore = create<storeState>((set) => ({
  surveyId: "25fa79f4-c5f6-4418-8f0e-6949c9e644c5",
  selectedSectionIndex: null,
  navigateToSectionIndex: null,
  setSelectedSectionIndex: (newIndex: number) => set({ selectedSectionIndex: newIndex }),
  setNavigateToSectionIndex: (newIndex: number | null) => set({ navigateToSectionIndex: newIndex }),
}));
