import _, { difference, sortBy } from "lodash";
import { AlphaTableTypes, RowState } from "@alphasights/alphadesign-table";
import { Preference } from "providers/types";
import {
  ExpertTableColumnDefinition,
  ExpertTableColumnPreference,
  ExpertTableInteractionType,
  ExpertTableRow,
} from "models/ExpertTable";

export const columnPreferenceExists = (columnPreference?: ExpertTableColumnPreference) => {
  return columnPreference && columnPreference.id && Object.keys(columnPreference.attributes).length > 0;
};

export const hasOrderChanged = (updatedColumnOrders: string[], columnPreference?: ExpertTableColumnPreference) => {
  return columnPreference?.id && JSON.stringify(columnOrder(columnPreference)) !== JSON.stringify(updatedColumnOrders);
};

export const hasVisibilityChanged = (
  columns: ExpertTableColumnDefinition[],
  updatedColumnVisibilities: AlphaTableTypes.VisibilityState,
  columnPreference?: ExpertTableColumnPreference
) => {
  return (
    columnPreference?.id &&
    updatedColumnVisibilities &&
    Object.keys(updatedColumnVisibilities).length > 0 &&
    !_.isEqual(columnVisibility(columns, columnPreference), updatedColumnVisibilities)
  );
};

export const getAddedColumnName = (
  columns: ExpertTableColumnDefinition[],
  previousColumnVisibilities: AlphaTableTypes.VisibilityState,
  updatedColumnVisibilities: AlphaTableTypes.VisibilityState
) => {
  const previousVisibleColumns = columns.filter((column) => previousColumnVisibilities[column.id] !== false);
  const updatedVisibleColumns = columns.filter((column) => updatedColumnVisibilities[column.id] !== false);
  const addedColumns = difference(updatedVisibleColumns, previousVisibleColumns);
  return addedColumns.length === 1 ? (addedColumns.at(0)!.header as string) ?? addedColumns[0].columnName : undefined;
};

export const getNewColumnOrder = (
  columns: ExpertTableColumnDefinition[],
  currentColumnOrder: string[],
  columnVisibilities: AlphaTableTypes.VisibilityState
) => {
  const hiddenColumnsIds = columns
    .filter((column) => columnVisibilities[column.id] === false)
    .map((column) => column.id);
  return currentColumnOrder.filter((columnId) => !hiddenColumnsIds.includes(columnId)).concat(hiddenColumnsIds);
};

export const hasPinningChange = (
  updatedColumnPinning: AlphaTableTypes.ColumnPinningState,
  columnPreference?: ExpertTableColumnPreference
) => {
  return (
    columnPreference?.id &&
    (updatedColumnPinning?.left?.length ?? 0) > 0 &&
    !_.isEqual(["_selection", ...pinnedColumns(columnPreference)], updatedColumnPinning.left)
  );
};

export const columnIds = (columns: ExpertTableColumnDefinition[]) => {
  return columns.map((col) => col.id);
};

const pinnedColumns = (columnPreference?: ExpertTableColumnPreference) => {
  return columnPreference
    ? Object.keys(columnPreference.attributes).filter((k) => columnPreference.attributes[k].pinned)
    : [];
};

const columnOrder = (columnPreference?: ExpertTableColumnPreference) => {
  return columnPreference
    ? sortBy(Object.keys(columnPreference.attributes), (columnName) =>
        columnPreference.attributes[columnName].hide
          ? Object.keys(columnPreference.attributes).length
          : columnPreference.attributes[columnName].position
      )
    : [];
};

const columnVisibility = (
  columns: ExpertTableColumnDefinition[],
  columnPreference?: ExpertTableColumnPreference
): AlphaTableTypes.VisibilityState => {
  const hasColumnPreferences = !!columnPreference;
  const columnIdsInPreferences = hasColumnPreferences ? Object.keys(columnPreference.attributes) : [];
  return columns
    .filter(
      (column) =>
        (columnIdsInPreferences.includes(column.id) && columnPreference?.attributes[column.id].hide) ||
        (column.hideIf && column.hideIf({ hasColumnPreferences }))
    )
    .reduce((acc, column) => ({ ...acc, [column.id]: false }), {});
};

const newColumns = (columns: ExpertTableColumnDefinition[], columnPreference?: ExpertTableColumnPreference) => {
  return columnIds(columns).filter((column) => !columnOrder(columnPreference).includes(column));
};

const rowState = (row: ExpertTableRow) => {
  if (row.original.newlyAdded) return RowState.HIGHLIGHTED;
  return RowState.NONE;
};

export const buildColumnsOptions = ({
  columnPreference,
  columns,
  hideSelectColumn,
  onRowClicked,
  rowClassRules,
  hideHeader,
  enableRowSelection,
  onRowSelectionChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onColumnPinningChange,
}: {
  columnPreference?: Preference;
  columns: ExpertTableColumnDefinition[];
  hideSelectColumn: boolean;
  onRowClicked: (row: ExpertTableRow) => void;
  rowClassRules: {
    className: string;
    ruleFunction: (row: ExpertTableRow) => boolean;
  }[];
  hideHeader: boolean;
  enableRowSelection: (row: ExpertTableRow) => boolean;
  onRowSelectionChange: (selection: Record<string, boolean>) => void;
  onColumnOrderChange: (updatedColumnOrders: string[]) => void;
  onColumnVisibilityChange: (updatedColumnVisibilities: AlphaTableTypes.VisibilityState) => void;
  onColumnPinningChange: (updatedColumnPinning: AlphaTableTypes.ColumnPinningState) => void;
}): Partial<AlphaTableTypes.TableOptions<ExpertTableInteractionType>> => {
  return {
    meta: {
      hideSelectColumn,
      onRowClicked,
      rowClassRules,
      hideHeader,
      enableRowState: true,
      fullWidth: true,
      rowState,
    },
    state: {
      columnPinning: {
        left: [...new Set(["actionColumn", ...pinnedColumns(columnPreference)])],
        right: ["showProfile"],
      },
      columnOrder: buildColumnOrder(columns, columnPreference),
      columnVisibility: columnVisibility(columns, columnPreference),
      sorting: [],
    },
    enableRowSelection,
    onRowSelectionChange: (state) => {
      typeof state === "object" && onRowSelectionChange(state);
    },
    onColumnOrderChange: (state) => {
      Array.isArray(state) && onColumnOrderChange(state);
    },
    onColumnVisibilityChange: (state) => {
      typeof state === "object" && onColumnVisibilityChange(state);
    },
    onColumnPinningChange: (state) => {
      typeof state === "object" && onColumnPinningChange(state);
    },
  };
};

const buildColumnOrder = (columns: ExpertTableColumnDefinition[], columnPreference?: ExpertTableColumnPreference) => {
  // new columns should take some precedence to show up new features
  const order = [...newColumns(columns, columnPreference), ...columnOrder(columnPreference)];

  // always show messageStatusColumn after statusColumn
  if (order.includes("messageStatusColumn") && order.includes("statusColumn")) {
    order.splice(order.indexOf("messageStatusColumn"), 1);
    order.splice(order.indexOf("statusColumn") + 1, 0, "messageStatusColumn");
  }

  return order;
};
