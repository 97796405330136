import React, { ChangeEvent, useCallback, useState } from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Divider,
  Typography,
  Link,
  Tile,
  Pill,
  Icon,
  EllipsisText,
  Tooltip,
  Carousel,
  Skeleton,
  IconButton,
  SelectSearch,
  SelectOption,
  SelectValue,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Company, Delete, Quote, RightArrow, ThirdParty } from "@alphasights/alphadesign-icons";
import { useStyles } from "./VendorModuleContent.styles";
import { HitAction, TranscriptMention, VendorItem } from "@alphasights/portal-api-client";
import { vendors } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useNavigate } from "router-utils";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { EditableInput, RevisionToggler } from "../components";
import { ExternalAlphaCompany } from "../../../../models/ExternalAlphaCompany";
import { ExternalAlphaCompaniesService } from "services/externalAlphaCompaniesService";

import { debounce } from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";

const LOADING_LABEL = "Loading...";

export const VendorModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const styles = useStyles();

  const {
    selectedRevisionIdx,
    revision,
    mode,
    editOperations,
    synthesisLogHit,
    selectedModule,
  } = useProjectSynthesisContext();

  const content = vendors(revision?.contents);

  const user = useCurrentUser();

  const handleNewVendor = (item: VendorItem) => {
    editOperations.addVendorItem(selectedRevisionIdx, item);
    synthesisLogHit({
      action: HitAction.projectSynthesisEditModuleAddVendors,
      details: {
        revision: revision?.revision,
        moduleType: selectedModule?.contentType,
        vendor: item.companyName,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  return (
    <x.div {...styles.contentWrapper}>
      {mode === Mode.EDIT && user?.enableAiInteractivity && (
        <React.Fragment key={"add-vendor"}>
          <AddCard
            allVendors={[...content.featuredVendors, ...content.emergingVendors]}
            onAddVendor={handleNewVendor}
          />
          <Divider mx={"-24px"} />
        </React.Fragment>
      )}
      {mode === Mode.VIEW && (
        <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="24px" zIndex="1" />
      )}
      {content.featuredVendors.map((v) => {
        return (
          <React.Fragment key={v.companyName}>
            <VendorRender vendor={v} expanded />
            <Divider mx={"-24px"} />
          </React.Fragment>
        );
      })}

      {content.emergingVendors.length > 0 && (
        <>
          <Typography variant="body-large-em">Additional Vendors</Typography>
          <Divider mx={"-24px"} />
          {content.emergingVendors.map((v) => {
            return (
              <React.Fragment key={v.companyName}>
                <VendorRender vendor={v} />
                <Divider mx={"-24px"} />
              </React.Fragment>
            );
          })}
        </>
      )}
    </x.div>
  );
};

const VendorRender = ({ vendor, expanded = false }: { vendor: VendorItem; expanded?: boolean }) => {
  const styles = useStyles();

  const navigate = useNavigate();

  const { project } = useCurrentProjectContext();

  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onClickAllMentions = useCallback(() => {
    const search = new URLSearchParams({
      keywords: vendor.companyName,
      transcriptType: "Transcript",
    });

    navigate(`/${project!.token}/experts/deliverables-view/?${search}`);
  }, [project, vendor, navigate]);

  const onDelete = useCallback(() => {
    editOperations.deleteVendor(vendor.companyName, selectedRevisionIdx);
  }, [vendor, editOperations, selectedRevisionIdx]);

  return (
    <x.div data-testid="vendor-card" {...styles.vendorWrapper}>
      <x.div>
        {vendor.companyLogo ? (
          <Tile variant="image" size="medium" image={vendor.companyLogo} />
        ) : (
          <Tile variant="icon" size="medium" icon={<Company />} />
        )}
      </x.div>
      <x.div {...styles.vendorDataColumn}>
        <x.div display="flex">
          <x.div display="flex" flexDirection="column" gap="4px">
            <Typography variant="body-large-em" data-testid="vendor-title">
              {vendor.companyName}
            </Typography>
            <x.div {...styles.vendorMentionsTotal}>
              {vendor.mentions && vendor.summary !== LOADING_LABEL ? (
                <>
                  <Typography variant="body-small-em" color="secondary">
                    Mentioned by {vendor.mentions.length} experts
                  </Typography>
                  {mode === Mode.VIEW && (
                    <Link
                      endIcon={<RightArrow />}
                      size="small"
                      onClick={onClickAllMentions}
                      fontWeight="600 !important"
                      data-testid="link-view-all-mentions"
                    >
                      View all mentions
                    </Link>
                  )}
                </>
              ) : (
                <Skeleton variant="noMargin" width="300px" height="16px" />
              )}
            </x.div>
          </x.div>
          {mode === Mode.EDIT && (
            <Tooltip title="Delete vendor" variant="dark">
              <IconButton
                variant="ghost"
                size="small"
                onClick={onDelete}
                xStyledProps={{ ml: "auto" }}
                dataAttributes={{ "data-testid": `delete-vendor-${vendor.companyName}` }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </x.div>
        {expanded && (
          <>
            <VendorSummary vendor={vendor} />
            {mode === Mode.VIEW && vendor.summary !== LOADING_LABEL && (
              <Carousel shouldScrollOnFocus={false} {...styles.carousel}>
                {vendor.mentions
                  ? vendor.mentions.map((m) => {
                      return <ExpertCard key={m.interactionId} mention={m} companyName={vendor.companyName} />;
                    })
                  : [1, 2, 3].map((i) => {
                      return <Skeleton key={i} variant="noMargin" width="340px" height="46px" />;
                    })}
              </Carousel>
            )}
          </>
        )}
      </x.div>
    </x.div>
  );
};

const VendorSummary = ({ vendor }: { vendor: VendorItem }) => {
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onEditSummary = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      editOperations.updateVendorSummary(e.target.value, vendor.companyName, selectedRevisionIdx);
    },
    [editOperations, selectedRevisionIdx, vendor.companyName]
  );

  if (vendor.summary === LOADING_LABEL)
    return (
      <>
        {[1, 2, 3].map((i) => (
          <Skeleton variant="noMargin" width="100%" height="16px" />
        ))}
      </>
    );

  if (mode === Mode.VIEW) return <Typography data-testid="vendor-summary">{vendor.summary}</Typography>;

  return <EditableInput input={vendor.summary} onChange={onEditSummary} />;
};

const ExpertCard = ({ companyName, mention }: { companyName: string; mention: TranscriptMention }) => {
  const styles = useStyles();

  const navigate = useNavigate();

  const { project } = useCurrentProjectContext();

  const onClick = useCallback(() => {
    const search = new URLSearchParams({
      selectedInteraction: mention.interactionId ?? "",
      keywords: companyName,
      transcriptType: "Transcript",
    });

    navigate(`/${project!.token}/experts/deliverables-view/?${search}`);
  }, [mention.interactionId, companyName, navigate, project]);

  return (
    <Tooltip title={`View ${mention.count} mentions in transcript`} variant="dark">
      <x.div {...styles.expertCardWrapper} onClick={onClick} data-testid={`expert-mention-${mention.interactionId}`}>
        <Tile variant="icon" size={"x-small"} color="tertiary" icon={<ThirdParty />} />
        <x.div {...styles.interactionContent}>
          <EllipsisText variant="body-small" disableTooltip>
            {mention.advisorCompany} - {mention.role}
          </EllipsisText>
        </x.div>
        <Pill
          leftAccessories={
            <Icon color="secondary">
              <Quote />
            </Icon>
          }
          size="x-small"
        >
          {mention.count}
        </Pill>
      </x.div>
    </Tooltip>
  );
};

const AddCard = ({
  allVendors,
  onAddVendor,
}: {
  allVendors: VendorItem[];
  onAddVendor: (item: VendorItem) => void;
}) => {
  const styles = useStyles();

  const existingVendors = new Set(allVendors.map(({ companyName }) => companyName));

  const [options, setOptions] = useState<ExternalAlphaCompany[]>([]);

  const searchCompaniesFunction = ExternalAlphaCompaniesService.search;

  const searchCompanies = debounce(async (value: string) => {
    setOptions([]);
    const data = value ? await searchCompaniesFunction(value) : [];
    setOptions(data.filter((it) => !existingVendors.has(it.name)));
  }, 200);

  const handleSelectedItem = useCallback(
    (value: SelectValue | SelectValue[]) => {
      const selectedCompany = options.find(({ name }) => name === value);
      selectedCompany &&
        onAddVendor({
          cdsAlphaCompanyId: `${selectedCompany.id}`,
          companyLogo: selectedCompany.logo!!,
          companyName: selectedCompany.name!!,
          summary: LOADING_LABEL,
          mentions: [],
        });
    },
    [options, onAddVendor]
  );

  const handleItemRender = () => "";

  return (
    <x.div {...styles.vendorWrapper}>
      <x.div>
        <Tile variant="icon" size="medium" icon={<Company />} />
      </x.div>
      <x.div {...styles.vendorDataColumn} w="100%">
        <Typography variant="body-em" data-testid="vendor-title" {...styles.vendorAddTitle}>
          Add a vendor
        </Typography>
        <x.div {...styles.vendorMentionsTotal}>
          <>
            <Typography variant="body-small" {...styles.vendorAddInfo}>
              Search for a vendor to add it to your synthesis
            </Typography>
          </>
        </x.div>
        <SelectSearch
          placeholder="Search for a vendor"
          allowMultiple={false}
          onChange={handleSelectedItem}
          onInputValueChange={(value) => searchCompanies(value.trim())}
          hideResultsIfSearchEmpty={true}
          isClearButtonEnabled={false}
          customSelectedItemRendererInInput={handleItemRender}
          dataAttributes={{ "data-testid": "search-vendor" }}
          mt="2px"
        >
          {options.length > 0
            ? options.map(({ name, logo }) => (
                <SelectOption key={name} value={name}>
                  <x.div display="flex" gap="8px" justifyContent="center">
                    <x.div {...styles.vendorLogo}>
                      {logo ? (
                        <x.img src={logo} maxW="100%" maxH="100%" />
                      ) : (
                        <Icon size="small" color="secondary">
                          <Company />
                        </Icon>
                      )}
                    </x.div>
                    <Typography variant="body">{name}</Typography>
                  </x.div>
                </SelectOption>
              ))
            : null}
        </SelectSearch>
      </x.div>
    </x.div>
  );
};
