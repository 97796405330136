import React, { useEffect, useState } from "react";
import { Login } from ".";
import useQuery from "../../hooks/useQuery";
import { ModalForm } from "./BackgroundPage";
import { useLogger } from "logging/Logger";
import { useLocation } from "router-utils";

export const LoginPage = () => {
  const location = useLocation();
  const query = useQuery();
  const [step, setStep] = useState();
  const attemptedRoute = query.get("next");
  const initialEmail = decodeURIComponent(query.get("email") ?? "");
  const isSignUp = location.pathname === "/sign-up";
  const loginByLink = query.has("link");
  const canContinueWithoutLogin = attemptedRoute?.includes("/calendar-view");
  const logger = useLogger();

  const redirectToBaseView = () => {
    const interactionId = new URLSearchParams(attemptedRoute).get("interactionId");
    const viewReplacer = canContinueWithoutLogin ? "/experts" : "";
    window.location.href = `${attemptedRoute.replace(/\/\w*?-view/, viewReplacer).split("?")[0]}/${
      interactionId || ""
    }`;
  };

  const onSetStep = (step) => {
    setStep(step);
  };

  useEffect(
    function onInitialize() {
      logger.log("login launched");
    },
    [logger]
  );

  return (
    <ModalForm style={{ overflow: "auto" }} step={step}>
      <Login
        allowAnonymousContinue={canContinueWithoutLogin}
        onContinue={canContinueWithoutLogin && ((args) => args?.skipSignIn && redirectToBaseView(attemptedRoute))}
        onSetStep={onSetStep}
        initialEmail={initialEmail}
        loginByLink={loginByLink}
        initialStep={isSignUp ? "sign-up" : null}
      />
    </ModalForm>
  );
};
