import React, { useRef } from "react";
import { Tooltip, Typography, TypographyVariation } from "@alphasights/alphadesign-components";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const HighlightTextTypography = ({
  variant = "body",
  component,
  className = "",
  color,
  highlights,
  fieldNames,
  text,
}: {
  variant?: TypographyVariation;
  component?: string;
  className?: string;
  color?: string;
  highlights?: SearchHighlight[];
  fieldNames: string[];
  text?: string;
}) => {
  const tooltipTitle = getHighlightedText({
    highlights: highlights,
    fieldNames: fieldNames,
    text: text ?? "",
  });
  const textRef = useRef(null);
  const isOverflow = useIsOverflow(textRef);

  return (
    <Tooltip data-testid="highlited-tooltip" title={tooltipTitle} disabled={!isOverflow}>
      <Typography
        ref={textRef}
        maxWidth="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="inline-block"
        cursor="pointer"
        variant={variant}
        component={component}
        className={className}
        color={color}
        dangerouslySetInnerHTML={{ __html: tooltipTitle }}
      />
    </Tooltip>
  );
};

export const getHighlightedText = ({
  highlights = [],
  fieldNames,
  text,
}: {
  highlights?: SearchHighlight[];
  fieldNames: string[];
  text: string;
}) => {
  const highlight = highlights.find((highlight) => fieldNames.includes(highlight.fieldName));
  return highlight && highlight.terms.length ? highlight.terms[0] : text;
};
