import React, { useState } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { LabeledIcon, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import ChartLegend from "pages/AlphaNowPage/primers/MarketPrimer/components/ChartLegend/ChartLegend";
import { Info } from "@alphasights/alphadesign-icons";
import SegmentChart from "./SegmentChart";
import SegmentationTabs from "./SegmentationTabs";
import { Section } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";

const SegmentSection = ({ segmentSection, sectionIndex }: { segmentSection: Section; sectionIndex: number }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { sectionTitle, segments, type, year, quarter } = segmentSection;
  const { isAccessible } = usePrimersStore(({ isAccessible }) => ({ isAccessible }));
  const [selectedChartSegment, setSelectedChartSegment] = useState(0);
  const { getChartColors } = useGetChartColors({
    numberOfBars: segments.length,
  });
  const segmentsWithSplitPercentage = segments.filter((segment) => segment.split.min.value !== null);
  const segmentsWithoutSplitPercentage = segments.filter((segment) => segment.split.min.value === null);

  const getSecondColumnLabel = () => {
    if (quarter && year) {
      return `Split (Q${quarter}, ${year})`;
    }

    if (quarter) {
      return `Split (Q${quarter})`;
    }

    if (year) {
      return `Split (${year})`;
    }

    return "Split";
  };

  const onHighlightChartSegment = (index: number): void => {
    // we only render in the chart segments that have split %,
    // so we do this to avoid selecting elements that are not in the chart
    if (index <= segmentsWithSplitPercentage.length - 1) {
      console.log("selected on the chart");
      setSelectedChartSegment(index);
      ApexCharts.exec(`segmentationChart${sectionIndex}`, "toggleDataPointSelection", index);
    }
  };

  return (
    <x.div px={inner.base10}>
      <x.div
        display="flex"
        flexDirection="column"
        gap={inner.base03}
        pb={segmentsWithSplitPercentage.length > 0 ? inner.base05 : 0}
      >
        <PrimerFieldTitle text={sectionTitle && sectionTitle.length > 0 ? sectionTitle : type} />
        {segmentsWithSplitPercentage.length > 0 && (
          <LabeledIcon labelText={`Click a chart section to view the ${sectionTitle} segment`} iconItem={<Info />} />
        )}
      </x.div>
      {segmentsWithSplitPercentage.length > 0 && (
        <SensitiveDataContainer isSensitive={!isAccessible}>
          <x.div display="flex" gap={inner.base12}>
            {/* We check if it has split min value as we know that it has max it is mandatory to have min also */}
            <SegmentChart
              sectionIndex={sectionIndex}
              segments={segmentsWithSplitPercentage}
              selectedSegment={selectedChartSegment}
              setSelectedSegment={setSelectedChartSegment}
            />
            <x.div display="flex" flex="1 1 auto" justifyContent="space-between" alignItems="center">
              <ChartLegend
                firstColumnLabel={type === "Regions" ? "Regions" : "Segments"}
                secondColumnLabel={getSecondColumnLabel()}
                segmentInfo={[
                  ...segmentsWithSplitPercentage.map((entry, index) => ({
                    leader: entry.other ? { ...entry.title, value: "Other" } : entry.title,
                    share: entry.split ? `${entry.split.min.value}-${entry.split.max.value}` : undefined,
                    color: getChartColors(null)[index],
                  })),
                ]}
                onClick={onHighlightChartSegment}
              />
            </x.div>
          </x.div>
        </SensitiveDataContainer>
      )}
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <x.div>
          <SegmentationTabs
            segments={[...segmentsWithSplitPercentage, ...segmentsWithoutSplitPercentage]}
            onClick={onHighlightChartSegment}
            selectedChartSegment={selectedChartSegment}
          />
        </x.div>
      </SensitiveDataContainer>
    </x.div>
  );
};

export default SegmentSection;
