import { Avatar, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import React from "react";
import { useSurveyInsights } from "views/SurveysResultsView/api/useSurveysAPI";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

const Filters = () => {
  const { color, spacing } = useThemeTokens();

  // TODO: Get the survey id from the url
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyInsights } = useSurveyInsights(surveyId);

  return (
    <x.div py={spacing.inner.base04} px={spacing.inner.base06}>
      <x.div display="flex" alignItems="center" gap={spacing.inner.base02}>
        <Avatar color="base02">
          <Expert />
        </Avatar>
        <Typography variant="body-large" color={color.text.strong._}>
          {surveyInsights?.length || "-"}
        </Typography>
      </x.div>
    </x.div>
  );
};

export default Filters;
