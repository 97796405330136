import React from "react";
import { Icon, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TickOutline } from "@alphasights/alphadesign-icons";
import { format } from "date-fns";
import { x } from "@xstyled/styled-components";

export const CommentNotedBy = ({ comment }) => {
  const { notedBy, notedAt } = comment;
  const { notedDiv } = useCommentNotedByStyles();

  const notedTooltipTitle = `Noted by ${notedBy} ${
    notedAt ? `at ${format(new Date(notedAt), "h:mmaaaaa'm', dd MMM")}` : ""
  }`;

  return (
    <Tooltip title={notedTooltipTitle} position="bottom" leaveDelay={200}>
      <x.div data-testid="comment-noted-by" {...notedDiv}>
        <Icon color="info">
          <TickOutline />
        </Icon>
        <Typography variant="body-small">Noted</Typography>
      </x.div>
    </Tooltip>
  );
};

export const useCommentNotedByStyles = () => {
  const {
    color: { text },
  } = useThemeTokens();

  const notedDiv = {
    display: "flex",
    color: text.info,
    marginTop: "10px",
    gap: "6px",
    alignItems: "center",
  };

  return {
    notedDiv,
  };
};
