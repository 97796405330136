import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TransformedSubTab } from "views/SurveysResultsView/api/types";
import Question from "views/SurveysResultsView/components/DataVisualization/components/Question";

type SubTabProps = { subTab: TransformedSubTab };

const SubTab = ({ subTab }: SubTabProps) => {
  const { color, spacing } = useThemeTokens();
  const { display, questions } = subTab;

  return (
    <x.div display="flex" flexDirection="column" data-testid="sub-tab-section">
      <x.div
        display="flex"
        flexDirection="column"
        padding={`${spacing.layout.base03} ${spacing.layout.base05}`}
        borderTop={`1px solid ${color.border.neutral.default}`}
      >
        <Typography variant="pre-title" textTransform="uppercase">
          {display}
        </Typography>
      </x.div>
      {questions.map((question) => (
        <Question key={question.id} question={question} />
      ))}
    </x.div>
  );
};

export default SubTab;
