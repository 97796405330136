import { Alert, Button, Divider, Icon, Loading, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ArrowRight, GenerativeAi } from "@alphasights/alphadesign-icons";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useIsInternalUser } from "@alphasights/portal-auth-react";
import styled, { x } from "@xstyled/styled-components";
import { FloatingAction, FloatingActions, useHelperActions } from "components/FloatingActions";
import _ from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { HighlightAiSummaryPart, SummaryStatus, ViewType } from "../../AiSummaries/AiSummaries.types";
import { GenericMessagePage } from "../GenericMessagePage";
import { useStyles } from "./SummaryTab.styles";
import FeedbackButtons from "components/FeedbackButtons/FeedbackButtons";
import { CopyButton } from "components/CopyButton/CopyButton";
import { differenceInMinutes, parseISO } from "date-fns";
import { useHideDeliverablesContent } from "../useHideDeliverablesContent";
import { GenerativeAiDisclaimer } from "components/GenerativeAiDisclaimer";

type Theme = {
  title: string;
  points: HighlightAiSummaryPart[];
  quotes: HighlightAiSummaryPart[];
};

export const SummaryTab = () => {
  const { contentStyle } = useHideDeliverablesContent();
  const styles = useStyles();
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const { logHit } = useTrackUserAction();
  const isInternal = useIsInternalUser();
  const {
    isAiSummariesLoading,
    summaryComprehensive,
    interaction,
    currentTranscript,
    currentRecording,
    refreshSummaries,
    keywordSearchTargetEl,
    onUpdateSummaryFeedback,
    selectedView,
    logHitDuration,
    hasTranscripts,
  } = useInteractionDeliverablesContext()!!;

  const summaryRef = useRef<HTMLDivElement>(null);

  const { generateAiSummary } = useDeliverableContext();

  const { project } = useCurrentProjectContext();

  const { deleteAiSummaries } = useDeliverableContext();

  const maxScrollReached = useRef(0);
  const openedAt = useRef(Date.now());
  useEffect(() => {
    if (selectedView !== ViewType.Summary) return;
    if (summaryComprehensive?.status !== SummaryStatus.Generated) return;
    openedAt.current = Date.now();
    const scrollableArea = document.querySelector("[data-summary-scrollable-area]");

    const computeMaxScrollReached = () => {
      if (!scrollableArea) return;
      const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;
      maxScrollReached.current = _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]) ?? 0;
    };

    scrollableArea?.addEventListener("scroll", computeMaxScrollReached);

    window.addEventListener("beforeunload", () =>
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.aiSummaryRead)
    );

    return () => {
      window.removeEventListener("beforeunload", () =>
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.aiSummaryRead)
      );
      scrollableArea?.removeEventListener("scroll", computeMaxScrollReached);
      logHitDuration(openedAt.current, maxScrollReached.current, HitAction.aiSummaryRead);
    };
  }, [selectedView, summaryComprehensive]); // eslint-disable-line react-hooks/exhaustive-deps

  const { overview, themes } = useMemo(() => {
    const parts = summaryComprehensive?.content?.parts ?? [];

    const highlightParts = parts as HighlightAiSummaryPart[];

    const overview = highlightParts.find(({ type }) => type === "overview")?.text || null;

    const themes = highlightParts
      .filter(({ type }) => type !== "overview")
      .reduce((acc: Theme[], part: HighlightAiSummaryPart) => {
        if (part.type === "theme") {
          return [...acc, { title: part.text, quotes: [], points: [] } as Theme];
        } else {
          const theme = _.last(acc) as Theme;
          const quotes = part.type === "quote" ? [part] : [];
          const points = part.type === "point" ? [part] : [];

          const updated = {
            ...theme,
            quotes: [...theme.quotes, ...quotes],
            points: [...theme.points, ...points],
          };

          return [..._.initial(acc), updated];
        }
      }, []) as Theme[];

    return { overview, themes };
  }, [summaryComprehensive]);

  const onDeleteSummary = useCallback(() => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.aiSummaryDelete,
      projectToken: interaction.projectToken,
      advisorshipId: interaction.id,
      references: { transcriptId: currentTranscript?.id },
    });
    deleteAiSummaries(interaction.id, currentRecording?.id).then(() => {
      refreshSummaries();
    });
  }, [interaction, currentTranscript, currentRecording, deleteAiSummaries, logHit, refreshSummaries]);

  const onGenerateSummary = useCallback(
    (automatic: boolean = false) => {
      if (!currentTranscript) return;
      if (!currentRecording) return;

      setIsGenerateLoading(true);
      logHit({
        origin: HitOrigin.deliverablesView,
        action: automatic ? HitAction.aiSummaryAutoGenerate : HitAction.aiSummaryGenerate,
        projectToken: project?.token,
        advisorshipId: interaction.id,
        references: { transcriptId: currentTranscript?.id },
        details: { newDeliverablesUx: true },
      });
      generateAiSummary(interaction.id, currentRecording?.id).finally(() => {
        refreshSummaries();
        setIsGenerateLoading(false);
      });
    },
    [currentTranscript, currentRecording, project, interaction, generateAiSummary, logHit, refreshSummaries]
  );

  const canDelete = useMemo(() => isInternal && summaryComprehensive?.status !== SummaryStatus.NotRequested, [
    isInternal,
    summaryComprehensive,
  ]);

  // auto ai summary enabled for all, need to clean up later
  const isGenerating = useMemo(
    () =>
      !currentTranscript?.completed ||
      !summaryComprehensive ||
      summaryComprehensive?.status === SummaryStatus.Generating ||
      summaryComprehensive?.status === SummaryStatus.NotRequested,
    [currentTranscript, summaryComprehensive]
  );

  useEffect(() => {
    if (isGenerating && selectedView === ViewType.Summary) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewProcessing,
        details: { tab: "Summary", message: "Summary is generating" },
      });
    }
  }, [isGenerating, selectedView, logHit]);

  const currentSummaryStatus = summaryComprehensive?.status;

  useEffect(() => {
    if (currentSummaryStatus === SummaryStatus.FailedToGenerate && selectedView === ViewType.Summary) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.deliverablesViewError,
        details: { tab: "Summary", message: "Summary failed to generate" },
      });
    }
  }, [currentSummaryStatus, selectedView, logHit]);

  const shouldHaveCompletedAlready = useMemo(
    () =>
      currentTranscript?.completed &&
      currentTranscript?.visibleToClientUpdatedAt &&
      differenceInMinutes(new Date(), parseISO(currentTranscript?.visibleToClientUpdatedAt)) > 90,
    [currentTranscript]
  );

  const canGenerate = useMemo(
    () =>
      currentTranscript?.completed &&
      (summaryComprehensive?.status === SummaryStatus.FailedToGenerate ||
        ((isInternal || shouldHaveCompletedAlready) && themes.length < 1)),
    [summaryComprehensive, currentTranscript, isInternal, shouldHaveCompletedAlready, themes]
  );

  if (!hasTranscripts) {
    return null;
  }

  if (isAiSummariesLoading && !summaryComprehensive) {
    return (
      <GenericMessagePage
        title={"Summary view is loading"}
        message={"Loading data..."}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  const copyContentFormatter = (ev: React.ClipboardEvent<HTMLDivElement>) => {
    ev.preventDefault();

    const selectedText = window.getSelection();
    if (ev.clipboardData && selectedText) {
      const clonedSelection = selectedText.getRangeAt(0).cloneContents();
      const removeElements = (query: string, callback?: (el: Element) => void) =>
        clonedSelection.querySelectorAll(query).forEach((el) => {
          callback && callback(el);
          el.remove();
        });

      // remove tabs
      [
        "div[data-transcript-scrollable-area]",
        "div[data-questions-scrollable-area]",
        "div[data-mentions-area]",
      ].forEach((q) => removeElements(q));
      // remove undesired components
      ["div[role='dialog']", "div[data-testid='bottom-buttons']"].forEach((q) => removeElements(q));
      // replace divider
      removeElements("hr", (el) => el.parentElement?.appendChild(document.createElement("br")));

      const div = document.createElement("div");
      div.appendChild(clonedSelection);

      ev.clipboardData.setData("text/html", div.innerHTML);
      ev.clipboardData.setData("text/plain", div.innerText);
    }
  };

  return (
    <x.div
      display="flex"
      flexDirection="column"
      gap="8px"
      alignItems="center"
      ref={selectedView === ViewType.Summary ? keywordSearchTargetEl : undefined}
      data-testid="rendered-summary-view"
    >
      {canDelete && (
        <>
          <Button
            variant="outline"
            onClick={onDeleteSummary}
            dataAttributes={{ "data-testid": "delete-summary" }}
            {...styles.deleteSummaryBtn}
          >
            Remove Summary
          </Button>
          <x.div {...styles.divider}>
            <Divider />
          </x.div>
        </>
      )}
      {summaryComprehensive?.status === SummaryStatus.FailedToGenerate && (
        <Alert variant="danger" data-testid="fail-alert" w="auto">
          {summaryComprehensive?.fatalError || "Generation was unsuccessful. Please try again."}
        </Alert>
      )}
      {isGenerating && (
        <GenericMessagePage
          title={"Summary view is generating"}
          message={"Check back in a few minutes..."}
          pre={
            <Icon>
              <Loading />
            </Icon>
          }
        />
      )}
      {canGenerate &&
        (!summaryComprehensive?.fatalError ? (
          <>
            <Typography data-testid="generate-summary" variant="body">
              Click the button below to create a free summary of your transcript.
            </Typography>
            <Button
              variant="outline"
              onClick={() => onGenerateSummary(false)}
              loading={isGenerateLoading}
              dataAttributes={{ "data-testid": "summary-generate-btn" }}
            >
              Generate
            </Button>
            <x.div {...styles.divider}>
              <Divider />
            </x.div>
          </>
        ) : null)}
      {themes.length > 0 && (
        <>
          <GenerativeAiDisclaimer {...styles.disclaimerWrapper} />
          <x.div
            data-testid="copiable-content"
            onCopy={copyContentFormatter}
            display="flex"
            flexDirection="column"
            w="100%"
            {...contentStyle}
          >
            <x.div ref={summaryRef} {...styles.wrapper}>
              <OverviewDisplay overview={overview} />
              {themes.map((t, idx) => {
                return (
                  <React.Fragment key={t.title}>
                    <ThemeDisplay theme={t} ix={idx} />
                    {idx !== themes.length - 1 && (
                      <x.div {...styles.divider}>
                        <Divider />
                      </x.div>
                    )}
                  </React.Fragment>
                );
              })}
            </x.div>
          </x.div>
          <x.div data-testid="bottom-buttons" {...styles.actionsWrapper}>
            <FeedbackButtons
              interactionId={interaction.id}
              transcriptId={currentTranscript?.id}
              recordingId={currentRecording?.id}
              summaryType={summaryComprehensive?.type}
              feedbackType={summaryComprehensive?.feedbackType?.toLowerCase() ?? ""}
              onFeedbackUpdated={(feedbackType: string) => {
                onUpdateSummaryFeedback(summaryComprehensive!!.type, feedbackType);
              }}
            />
            <CopyButton
              messageRef={summaryRef}
              onClick={() => {
                logHit({
                  origin: HitOrigin.deliverablesView,
                  action: HitAction.aiSummaryCopy,
                  projectToken: interaction.projectToken,
                  advisorshipId: interaction.id,
                  details: {
                    aiSummaryType: summaryComprehensive?.type,
                  },
                  references: { transcriptId: currentTranscript?.id },
                });
              }}
            />
          </x.div>
        </>
      )}
    </x.div>
  );
};

const OverviewDisplay = ({ overview }: { overview: string | null }) => {
  const styles = useStyles();
  const { logHit } = useTrackUserAction();
  const { interaction, currentTranscript } = useInteractionDeliverablesContext()!;
  const ref = useRef<HTMLDivElement>(null);

  const { copyRefAction } = useHelperActions();

  return overview ? (
    <x.div {...styles.overviewFloatingActionWrapper}>
      <FloatingActions
        highlightOnHover={false}
        actions={[
          copyRefAction(ref, "Copy section", () => {
            logHit({
              origin: HitOrigin.deliverablesView,
              action: HitAction.aiSummaryCopy,
              advisorshipId: interaction.id,
              projectToken: interaction.projectToken,
              details: { type: "overview" },
              references: {
                transcriptId: currentTranscript?.id,
              },
            });
          }),
        ]}
      >
        <x.div ref={ref} data-testid={`overview`}>
          <EmphasisTypography>
            <Typography variant="body-em" component="span">
              Overview:{" "}
            </Typography>
            {overview}
          </EmphasisTypography>
        </x.div>
      </FloatingActions>
    </x.div>
  ) : null;
};

const ThemeDisplay = ({ theme, ix }: { theme: Theme; ix: number }) => {
  const styles = useStyles();
  const { logHit } = useTrackUserAction();
  const { interaction, currentTranscript } = useInteractionDeliverablesContext()!;
  const author = `${interaction.role || ""}, ${interaction.advisorCompany || ""}`;
  const ref = useRef<HTMLDivElement>(null);

  const { copyRefAction, copyQuoteAction } = useHelperActions();
  return (
    <x.div {...styles.themeFloatingActionWrapper}>
      <FloatingActions
        highlightOnHover={false}
        actions={[
          copyRefAction(ref, "Copy section", () => {
            logHit({
              origin: HitOrigin.deliverablesView,
              action: HitAction.aiSummaryCopy,
              advisorshipId: interaction.id,
              projectToken: interaction.projectToken,
              details: { type: "theme" },
              references: {
                transcriptId: currentTranscript?.id,
              },
            });
          }),
        ]}
      >
        <x.div ref={ref} {...styles.themeWrapper} data-testid={`theme-${ix}`}>
          <x.div {...styles.themeName}>
            <EmphasisTypography component="strong" data-testid={`theme-title-${ix}`} variant="body-em">
              {theme.title}
            </EmphasisTypography>
          </x.div>
          <x.div {...styles.themeContentWrapper}>
            <Icon size="large" color="ai">
              <GenerativeAi />
            </Icon>
            <x.div {...styles.themeTextWrapper}>
              <x.div>
                {theme.points.map((p, idx) => {
                  return (
                    <ActionablePart key={`part-${idx}`} part={p}>
                      <PointsUL>
                        <SummaryCopyablePoint position={idx} part={p} themeIx={ix} />
                      </PointsUL>
                    </ActionablePart>
                  );
                })}
              </x.div>
              <x.div {...styles.quotesWrapper}>
                {theme.quotes.map((p, idx) => {
                  return (
                    <ActionablePart
                      key={`part-${idx}`}
                      part={p}
                      copy={copyQuoteAction(p.text, author, "Copy quote", () => {
                        logHit({
                          origin: HitOrigin.deliverablesView,
                          action: HitAction.aiSummaryCopy,
                          advisorshipId: interaction.id,
                          projectToken: interaction.projectToken,
                          details: { type: p.type },
                          references: {
                            transcriptId: currentTranscript?.id,
                          },
                        });
                      })}
                    >
                      <SummaryVerbatim position={idx} part={p} themeIx={ix} />
                    </ActionablePart>
                  );
                })}
              </x.div>
            </x.div>
          </x.div>
        </x.div>
      </FloatingActions>
    </x.div>
  );
};

const PointsUL = styled.ul`
  list-style-type: "•";
  margin-left: 8px;
  li {
    padding-left: 10px;
  }
`;

type ItemRendererProps = {
  part: HighlightAiSummaryPart;
  position: number;
  themeIx: number;
};

const ActionablePart = ({
  part,
  children,
  copy,
}: {
  part: HighlightAiSummaryPart;
  copy?: FloatingAction;
} & PropsWithChildren) => {
  const { logHit } = useTrackUserAction();
  const {
    interaction,
    currentTranscript,
    focusOnTranscriptIndex,
    setSelectedView,
    transcriptFeaturesEnabled,
  } = useInteractionDeliverablesContext()!;
  const actions = [copy].flatMap((it) => (it ? [it] : []));

  const canViewInTranscript = transcriptFeaturesEnabled && (part.references?.length || 0) > 0;

  if (canViewInTranscript) {
    actions.push({
      icon: <ArrowRight />,
      tooltip: "View in transcript",
      testid: "nav",
      onClick: () => {
        focusOnTranscriptIndex({ transcriptIx: part.references!![0].transcriptIndex, text: part.text });
        setSelectedView(ViewType.Transcript);
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.aiSummaryViewInTranscript,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: { type: part.type },
          references: {
            transcriptId: currentTranscript?.id,
          },
        });
      },
    });
  }

  return (
    <FloatingActions highlightOnHover={true} actions={actions}>
      {children}
    </FloatingActions>
  );
};

const SummaryCopyablePoint = ({ part, position, themeIx }: ItemRendererProps) => {
  return (
    <EmphasisTypography component="li" data-testid={`point-${themeIx}-${position}`}>
      {part.keyword ? (
        <>
          <Typography component="strong" variant={"body-small-em"}>
            {part.keyword}:{" "}
          </Typography>
          {part.text}
        </>
      ) : (
        <>{part.text}</>
      )}
    </EmphasisTypography>
  );
};

const SummaryVerbatim = ({ position, part, themeIx }: ItemRendererProps) => {
  const tokens = useThemeTokens();

  return (
    <x.div data-testid={`quote-${themeIx}-${position}`} display="flex" gap="8px">
      <x.div w="2px" backgroundColor={tokens.color.border.successSubtle} />
      <EmphasisTypography component="i">"{part.text}"</EmphasisTypography>
    </x.div>
  );
};
