import React, { useState } from "react";
import { ActionsWrapper, MessengerSuggestionWrapper } from "./MessengerSuggestion.styles";
import { Alert, Button, Tooltip, Typography } from "@alphasights/alphadesign-components";

export type MessengerSuggestionProps = {
  content?: string;
  messageTypeName: string;
  onDeclineSuggestion?: () => Promise<void>;
  onAcceptSuggestion?: () => Promise<void>;
};

export const MessengerSuggestion = ({
  content,
  messageTypeName,
  onDeclineSuggestion,
  onAcceptSuggestion,
}: MessengerSuggestionProps) => {
  const [loading, setLoading] = useState(false);
  const [pressedButton, setPressedButton] = useState(undefined as "Decline" | "Accept" | undefined);

  const handleSuggestionClick = (origin: "Decline" | "Accept", callback?: () => Promise<void>) => {
    if (loading || !callback) return;

    setLoading(true);
    setPressedButton(origin);

    callback().finally(() => {
      setLoading(false);
      setPressedButton(undefined);
    });
  };

  return (
    <MessengerSuggestionWrapper>
      <Alert variant="warning" size="small" w="100%">
        AlphaSights has reviewed your {messageTypeName} and has provided suggestions to ensure your message is
        compliant. Please accept this suggestion in order for your message to be sent to the expert.
      </Alert>

      <Typography variant="body-em">AlphaSights Suggestion</Typography>

      <Typography dangerouslySetInnerHTML={{ __html: content ?? "" }} />

      <ActionsWrapper>
        <Tooltip title="Your AlphaSights point of contact will be in touch if this suggestion is declined">
          <Button
            onClick={() => handleSuggestionClick("Decline", onDeclineSuggestion)}
            size="small"
            variant="ghost"
            loading={pressedButton === "Decline"}
            disabled={loading}
          >
            Decline Suggestion
          </Button>
        </Tooltip>
        <Button
          onClick={() => handleSuggestionClick("Accept", onAcceptSuggestion)}
          size="small"
          variant="secondary"
          loading={pressedButton === "Accept"}
          disabled={loading}
        >
          Accept Suggestion
        </Button>
      </ActionsWrapper>
    </MessengerSuggestionWrapper>
  );
};
