import React, { useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { useAdvisorsPageStyles } from "./AdvisorsPage.styles";
import { ContentLoader, AdvisorCardList } from "../../components/AdvisorCard";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const AdvisorsPage = ({
  allInteractions,
  appliedFilters = {},
  backgroundSyncRunning,
  clientAvailability,
  clientRequests,
  experts = [],
  followUpModalProps,
  hasClientPortalMessages,
  interactions = [],
  isFlyoutOpen,
  loading,
  onCancelFollowUp,
  onCancelRequest,
  onCancelRequestTranscript,
  onCancelPendingRequest,
  onCloseFlyout,
  onCloseFollowUpModal,
  onLeaveAvailability,
  onRequest,
  onRequestFollowUp,
  onRequestRescheduleInteraction,
  onRequestTranscript,
  onRequestTranscriptPostCall,
  onSelectCard,
  onStartSchedule,
  onTrackViewedProfile,
  onUpdateFollowUp,
  pcc,
  pccOptOutWindow,
  project,
  relevantStatementType,
  scrollToCardId,
  selectedCardId,
  showAngleTypes,
  showCalendarView,
  token,
  transcriptEnabled,
  onOpenComments,
  headerHeight,
  isProjectCidEnabled,
  chainIdSelected,
  onSendNewMessage,
  flyoutMode,
}) => {
  const { list } = useAdvisorsPageStyles();
  const { spacing } = useThemeTokens();

  useEffect(() => {
    const element = document.getElementById(`advisor-card-${scrollToCardId}`);
    if (element) {
      element.scrollIntoView();
    }
  }, [scrollToCardId, loading]);

  return (
    <Container isFlyoutOpen={isFlyoutOpen} onOverlayClick={onCloseFlyout}>
      <div data-testid="before-header-div">
        <x.div maxWidth="80%" margin="0 auto" width="100%" px={spacing.inner.base08} {...list}>
          {loading ? (
            <x.div marginTop={spacing.layout.base06}>
              <ContentLoader />
              <ContentLoader />
              <ContentLoader />
              <ContentLoader />
            </x.div>
          ) : (
            <AdvisorCardList
              cards={interactions}
              experts={experts}
              token={token}
              transcriptEnabled={transcriptEnabled}
              relevantStatementType={relevantStatementType}
              showAngleTypes={showAngleTypes}
              appliedFilters={appliedFilters}
              onRequest={onRequest}
              onRequestFollowUp={onRequestFollowUp}
              onCancelRequest={onCancelRequest}
              onRequestTranscript={onRequestTranscript}
              onCancelRequestTranscript={onCancelRequestTranscript}
              onUpdateFollowUp={onUpdateFollowUp}
              onCancelFollowUp={onCancelFollowUp}
              onCloseFollowUpModal={onCloseFollowUpModal}
              onSchedule={onStartSchedule}
              onSelectCard={onSelectCard}
              onLeaveAvailability={onLeaveAvailability}
              onTrackViewedProfile={onTrackViewedProfile}
              followUpModalProps={followUpModalProps}
              backgroundSyncRunning={backgroundSyncRunning}
              showCalendarView={showCalendarView}
              clientRequests={clientRequests}
              hasClientPortalMessages={hasClientPortalMessages}
              onRequestTranscriptPostCall={onRequestTranscriptPostCall}
              onOpenComments={onOpenComments}
              isFlyoutOpen={isFlyoutOpen}
              headerHeight={headerHeight}
              selectedCardId={selectedCardId}
              isProjectCidEnabled={isProjectCidEnabled}
              pcc={pcc}
              pccOptOutWindow={pccOptOutWindow}
              chainIdSelected={chainIdSelected}
              onRequestRescheduleInteraction={onRequestRescheduleInteraction}
              onCancelPendingRequest={onCancelPendingRequest}
              allInteractions={allInteractions}
              clientAvailability={clientAvailability}
              projectLead={project.lead}
              onSendNewMessage={onSendNewMessage}
              flyoutMode={flyoutMode}
            />
          )}
        </x.div>
      </div>
    </Container>
  );
};

const Container = ({ children }) => {
  return (
    <x.div display="flex" flexDirection="column" flexGrow={1}>
      {children}
    </x.div>
  );
};
