import { filterOtherInteractions } from "components/InteractionsPage/helpers/Interaction";
import { Expert } from "types";

export const SURVEY_WARNING_THRESHOLD = 5;

const getMostRecent = (interaction: Interaction, currentInteraction: Interaction) => {
  if (interaction.requestedAt == null && currentInteraction.requestedAt == null) return interaction;
  else if (interaction.requestedAt == null && currentInteraction.requestedAt != null) return currentInteraction;
  else if (interaction.requestedAt != null && currentInteraction.requestedAt == null) return interaction;
  else if (new Date(currentInteraction.requestedAt) > new Date(interaction.requestedAt)) return currentInteraction;
  else return interaction;
};

export const interactionsToExperts = (interactions: Interaction[], portalMessagesEnabled: boolean = false) => {
  const chainInteractions = filterOtherInteractions(interactions);

  const experts: Expert[] = chainInteractions.reduce((experts: Expert[], currentInteraction: Interaction) => {
    let expert = experts.find((expert) => expert.id === currentInteraction.advisorId);

    if (!expert) {
      let _expert = expertDataFromInteraction(currentInteraction);
      _expert.interactions = [currentInteraction];
      _expert.interaction = currentInteraction;
      _expert.interactionCount = 1;
      experts.push(_expert);
    } else {
      expert.hostedInteraction = expert.hostedInteraction || currentInteraction.hostedInteraction;
      expert.hasCompletedOrScheduled = expert.hasCompletedOrScheduled || hasCompletedOrScheduled(currentInteraction);
      expert.hasOnlyCompletedOrScheduled =
        expert.hasOnlyCompletedOrScheduled && hasCompletedOrScheduled(currentInteraction);
      expert.canSendFollowUpMessage = expert.canSendFollowUpMessage || currentInteraction.canSendFollowUpMessage;
      expert.interaction = getMostRecent(expert.interaction, currentInteraction);
      expert.alphaCircleMultiplier = expert.interaction.alphaCircleMultiplier;
      expert.interactions.push(currentInteraction);
      expert.interactionCount++;
    }

    return experts;
  }, []);

  return portalMessagesEnabled ? experts : experts.filter((expert) => expert.canSendFollowUpMessage);
};

const expertDataFromInteraction = (interaction: Interaction): Expert => {
  return {
    id: interaction.advisorId,
    value: interaction.advisorId,
    label: interaction.advisorName,
    name: interaction.advisorName,
    noWrittenWork: interaction.noWrittenWork,
    relevantCompany: interaction.advisorCompany,
    relevantPosition: interaction.role,
    alphaCircleMultiplier: interaction.alphaCircleMultiplier,
    advisorLockState: interaction.advisorLockState,
    hostedInteraction: interaction.hostedInteraction,
    hasCompletedOrScheduled: hasCompletedOrScheduled(interaction),
    hasOnlyCompletedOrScheduled: hasCompletedOrScheduled(interaction),
    canSendFollowUpMessage: interaction.canSendFollowUpMessage,
    canSendMessage: interaction.canSendMessage,
    group: interaction.group,
    interactions: [interaction],
    interaction,
    interactionCount: 1,
  };
};

const hasCompletedOrScheduled = (interaction: Interaction) =>
  interaction.state === "completed" ||
  (interaction.state === "scheduled" && new Date(interaction.scheduledCallTime) < new Date());

export const convertCreditsToString = (credits: number) => {
  return credits === 0
    ? "No charge"
    : credits === 1
    ? "1 credit"
    : `${credits.toFixed(2).replace(/\.?0+$/, "")} credits`;
};

export const calculateTotalCredit = (selectedExperts: Expert[], selectedTaskDuration: number) => {
  if (selectedExperts != null && selectedTaskDuration != null) {
    let credits = 0.0;

    selectedExperts.forEach((expert) => {
      credits += calculateCredit(expert.alphaCircleMultiplier, selectedTaskDuration);
    });

    return credits;
  } else {
    return 0;
  }
};

export const calculateCredit = (multiplier = 1.0, selectedTaskDuration: number) => {
  return ((selectedTaskDuration ? selectedTaskDuration : 0) / 60) * multiplier;
};

export const getThreadStateColorFlyout = (state: any, colorTokens: any) => {
  if (!state) {
    return;
  }

  const colors = [
    { states: ["WAITING_RESPONSE"], color: colorTokens.text.danger },
    { states: ["REPLIED"], color: colorTokens.text.success },
    {
      states: ["PENDING_UPDATE", "AWAITING_APPROVAL"],
      color: colorTokens.text.processing,
    },
  ];

  return colors.find((color) => color.states.includes(state.code))?.color ?? colorTokens.text.secondary;
};
