import { useTheme } from "@alphasights/alphadesign-components";

const stepHeightMap = {
  lou: "589px",
  locked: "367px",
  "change-password-initial": "auto",
  "change-password-success": "240px",
  password: "466px",
  email: "445px",
};

export const useLoginStyles = (step) => {
  const {
    tokens: { color, spacing },
  } = useTheme();

  const modalTitle = {
    color: color.text.strong._,
    textTransform: "none",
  };

  const modalDescription = {
    color: color.text.strong._,
    marginTop: spacing.inner.base02,
  };

  const nextButton = {
    marginTop: spacing.inner.base06,
  };

  const skipSignInButton = {
    borderColor: color.border.neutral.default,
    marginTop: spacing.inner.base04,
  };

  const signInFooterDiv = {
    textAlign: "center",
    my: spacing.inner.base,
  };

  const helpButton = {
    padding: 0,
    h: spacing.inner.base02,
  };

  const textStyle = {
    color: color.text.secondary,
  };

  const limitationsOfUseButton = {
    padding: 0,
    h: spacing.inner.base02,
  };

  const checkboxDiv = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: spacing.inner.base04,
  };

  const checkbox = {
    color: color.text.strong._,
  };

  const checkboxLabel = {
    color: color.text.strong._,
  };

  const signInButton = {
    marginTop: spacing.inner.base06,
  };

  const forgotPasswordLink = {
    cursor: "pointer",
  };

  const sendSignInLinkButton = {
    borderColor: color.border.neutral.default,
    marginTop: spacing.inner.base04,
  };

  const emailHeaderDiv = {
    marginTop: spacing.inner.base02,
  };

  const emailLabel = {
    color: color.text.strong._,
  };

  const editEmailButton = {
    display: "inline-block",
    verticalAlign: "middle",
    ml: spacing.inner.base,
  };

  const emailInput = {
    display: "none",
  };

  const loginModal = {
    w: "442px",
    h: step && stepHeightMap[step] ? stepHeightMap[step] : "466px",
  };

  const validationMessage = {
    color: color.text.danger,
    marginTop: spacing.inner.base02,
  };

  const passwordField = {
    marginTop: spacing.inner.base,
  };

  const createPasswordButton = {
    marginTop: spacing.inner.base06,
  };

  const cancelButton = {
    marginBottom: spacing.layout.base04,
  };

  const deactivatedAccountMessage = {
    pt: spacing.inner.base04,
  };

  const accountLeadContainer = {
    display: "flex",
    gap: spacing.inner.base04,
    pt: spacing.inner.base02,
    justifyContent: "flex-start",
  };

  const accountLeadInfo = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.inner.base02,
    minWidth: 0,
    marginLeft: spacing.layout.base02,
  };

  const accountLeadInfoIcon = {
    display: "flex",
    alignItems: "center",
    gap: spacing.inner.base02,
    textDecoration: "none",
    color: color.text.strong._,
    wordBreak: "break-all",
  };

  const returnSignInButton = {
    borderColor: color.border.neutral.default,
  };

  const backButton = {
    borderColor: color.border.neutral.default,
  };

  const reactivateAccountText = {
    mt: spacing.inner.base04,
  };

  const buttonLink = {
    h: spacing.inner.base05,
    color: color.text.link._,
  };

  const emailLinkText = {
    color: color.text.strong._,
    textDecoration: "none",
  };

  const loadingWrapper = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "80%",
  };

  const loadingCreatePasswordWrapper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  return {
    modalTitle,
    modalDescription,
    nextButton,
    skipSignInButton,
    signInFooterDiv,
    helpButton,
    textStyle,
    limitationsOfUseButton,
    checkbox,
    checkboxLabel,
    signInButton,
    forgotPasswordLink,
    sendSignInLinkButton,
    emailHeaderDiv,
    emailLabel,
    editEmailButton,
    checkboxDiv,
    loginModal,
    emailInput,
    validationMessage,
    passwordField,
    createPasswordButton,
    deactivatedAccountMessage,
    accountLeadContainer,
    accountLeadInfo,
    accountLeadInfoIcon,
    returnSignInButton,
    buttonLink,
    backButton,
    reactivateAccountText,
    emailLinkText,
    cancelButton,
    loadingWrapper,
    loadingCreatePasswordWrapper,
  };
};
