import React, { ForwardedRef } from "react";
import { ProjectLeadAvatar } from "@alphasights/client-portal-shared";
import { Typography, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { th, x } from "@xstyled/styled-components";
import { Call, Mail } from "@alphasights/alphadesign-icons";
import { Lead } from "@alphasights/portal-api-client";
export interface ProjectLeadCardProps {
  lead: Lead;
  hideMyProjectLeadLabel?: boolean;
  isMobile?: boolean;
}

export const ProjectLeadCard = React.forwardRef(
  (
    { lead, hideMyProjectLeadLabel = false, isMobile = false }: ProjectLeadCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { wrapper, contact, phones } = useProjectLeadCardStyles({
      isMobile,
    });

    return (
      <x.div ref={ref} {...wrapper} data-testid="project-lead-card">
        <CustomProjectLeadAvatar lead={lead} isMobile={isMobile} />
        <div>
          <div>
            <Typography variant="body-large-em" color="strong">
              {lead.name}
            </Typography>
            {!hideMyProjectLeadLabel && (
              <Typography color="strong">
                {lead.role === "PROJECT_LEAD" ? "My Project Lead" : "Account Lead"}
              </Typography>
            )}
          </div>
          {lead.email && (
            <x.div {...contact}>
              <Icon color="secondary">
                <Mail />
              </Icon>
              <Link href={`mailto:${lead.email}`}>
                <Typography>{lead.email}</Typography>
              </Link>
            </x.div>
          )}
          {(lead.desktopNumber || lead.mobileNumber) && (
            <x.div {...contact}>
              <Icon color="secondary">
                <Call />
              </Icon>
              <x.div {...phones}>
                {lead.desktopNumber && (
                  <Link href={`tel:${lead.desktopNumber}`}>
                    <Typography>{lead.desktopNumber}</Typography>
                  </Link>
                )}
                {lead.mobileNumber && (
                  <Link href={`tel:${lead.mobileNumber}`}>
                    <Typography>{lead.mobileNumber}</Typography>
                  </Link>
                )}
              </x.div>
            </x.div>
          )}
        </div>
      </x.div>
    );
  }
);

const Link = ({ children, href }: { href: string; children: JSX.Element }) => {
  const { link } = useProjectLeadCardStyles();
  return (
    <x.a href={href} {...link}>
      {children}
    </x.a>
  );
};

const useProjectLeadCardStyles = ({
  isMobile = false,
}: {
  isMobile?: boolean;
} = {}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    alignItems: "start",
    gap: inner.base06,
    ...(isMobile && {
      py: inner.base06,
    }),
  };

  const contact = {
    display: "flex",
    gap: inner.base,
    alignItems: "flex-start",
    mt: inner.base02,
  };

  const link = {
    color: text.secondary,
    wordBreak: "break-all",
    textDecoration: "none",
  };

  const phones = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base,
  };

  return {
    wrapper,
    contact,
    link,
    phones,
  };
};

const CustomProjectLeadAvatar = styled(ProjectLeadAvatar)<{ isMobile: boolean }>`
  height: ${({ isMobile }) => (isMobile ? th.space("layout-base03") : th.space("layout-base05"))};
  width: ${({ isMobile }) => (isMobile ? th.space("layout-base03") : th.space("layout-base05"))};
`;
