import React from "react";
import styled, { x } from "@xstyled/styled-components";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";

export const Wrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: auto;
    gap: ${spacing.inner.base03};
    padding: ${spacing.layout.base03};
    padding-bottom: 0;
  `;
});

export const SidebarCardLoadingWrapper = styled(x.div)(() => {
  const { spacing, color, shape } = useThemeTokens();

  return `
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    align-items: center;
    padding: ${spacing.inner.base04};
    gap: ${spacing.inner.base04};
    border: 1px solid ${color.border.divider};
    border-radius: ${shape.border.radius.medium};
  `;
});

export const AngleQuestionCountLoading = () => {
  const { spacing } = useThemeTokens();

  return (
    <x.div w="100px">
      <Skeleton variant="noMargin" width="100%" height={spacing.inner.base05} />
    </x.div>
  );
};
