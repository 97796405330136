import React, { useMemo, useRef } from "react";
import { RadioButton, RadioGroup, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { DAY_FORMAT, TIME_FORMAT } from "helpers/interactionHelpers";
import styled, { x } from "@xstyled/styled-components";
import { ComplianceReviewStatus, MessageThreadHeaderResponse, ThreadType } from "types";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { getThreadStateColorFlyout } from "pages/InteractionPage/utils";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const MessageThreadList = ({ ...props }) => {
  const { messageThreads, selectedMessageThread, onSelectMessageThread } = useFlyoutContext();

  if (!messageThreads || messageThreads.length === 0) return null;

  return (
    <RadioGroup
      data-testid="message-thread-list-radio"
      name="message-thread-list-radio"
      value={selectedMessageThread?.id}
      maxHeight={"400px"}
      overflowY={"auto"}
      {...props}
    >
      {messageThreads.map((thread: MessageThreadHeaderResponse) => {
        return (
          <RadioButton
            key={thread.id}
            value={thread.id}
            onClick={() => {
              thread.hasUnreadMessagesOrPendingApproval = false;
              onSelectMessageThread(thread);
            }}
            data-testid={`thread-link-${thread.id}`}
          >
            <MessageThreadInfo thread={thread} />
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
};

const UnreadDot = styled(x.div)`
  width: ${tokens.spacing.inner.base};
  height: ${tokens.spacing.inner.base};
  border-radius: ${tokens.spacing.inner.base};
  background-color: ${tokens.color.icon.info};
  margin-bottom: ${tokens.spacing.inner.base};
  margin-right: ${tokens.spacing.inner.base02};
  display: inline-flex;
`;

const MessageThreadState = ({ thread }: { thread: MessageThreadHeaderResponse }) => {
  const { color } = useThemeTokens();

  const complianceReviewStatus = thread.mostRecentMessageVersion?.complianceReviewStatus;

  const statusMap = {
    [ComplianceReviewStatus.SUGGESTION_PENDING]: {
      color: "processing",
      text: "Awaiting Approval",
    },
    [ComplianceReviewStatus.PENDING]: {
      color: "processing",
      text: "Awaiting Approval",
    },
    [ComplianceReviewStatus.SUGGESTION_DECLINED]: {
      color: "danger",
      text: "Suggestion Declined",
    },
  };

  if (complianceReviewStatus && complianceReviewStatus in statusMap) {
    const complianceReviewStatusKey = complianceReviewStatus as keyof typeof statusMap;

    return (
      <Typography component="span" color={statusMap[complianceReviewStatusKey].color}>
        {statusMap[complianceReviewStatusKey].text}
      </Typography>
    );
  }

  return (
    <Typography component="span" color={getThreadStateColorFlyout(thread.state, color)}>
      {thread.state.description}
    </Typography>
  );
};

const MessageThreadInfo = ({ thread }: { thread: MessageThreadHeaderResponse }) => {
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref);

  const threadType = useMemo(
    () =>
      thread.workRequest != null && thread.threadType === ThreadType.Messenger
        ? "Work Request"
        : thread.threadType === ThreadType.CallGuide
        ? "Call Guide"
        : "Clarification",
    [thread.threadType, thread.workRequest]
  );

  const content = (
    <>
      {thread.hasUnreadMessagesOrPendingApproval && <UnreadDot />}
      {threadType}
      {" • "}
      <MessageThreadState thread={thread} />
      {" • "}
      <FormattedDateTime date={thread.createdAt} format={`${DAY_FORMAT}, ${TIME_FORMAT}`} />
    </>
  );

  return (
    <Tooltip title={<Typography>{content}</Typography>} disabled={!isOverflow} maxWidth={"45ch" as any}>
      <Typography
        ref={ref}
        component="div"
        maxWidth="45ch"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
