import React, { useContext, useEffect, useMemo, useState } from "react";
import { addSeconds, differenceInMinutes, isEqual } from "date-fns";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import { FormattedDateTime, useTimezone } from "../../providers/TimezoneProvider";
import parseISO from "date-fns/parseISO";
import { useLockedExpert } from "hooks/useLockedExpert";
import { CalendarContext } from "./CalendarContext";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { CalendarPopup, ExpertData, CalendarPopupFooter, PopupMenus, SelectAttendees } from "./PopupRenderers/common";
import { SelectDateTime } from "./PopupRenderers/SelectDateTime";
import { Button, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useAlertMessengerContext } from "components/InteractionsPage/AlertContextProvider";
import { updateInitialAttendees } from "./reducer";
import { setFlyoutMode } from "components/InteractionsPage/reducer";
import { Info } from "@alphasights/alphadesign-icons";
import { ButtonLink } from "@alphasights/client-portal-shared";
import { OneHourMinimumAlert } from "components/OneHourMinimumAlert";
import { FlyoutMode } from "pages/InteractionPage/enums";

const calculateOptionsStart = ({ start, end, minDuration, tz, step = 15 }) => {
  const maxPossibleStart = addSeconds(end, -minDuration);

  const intervals = isEqual(start, maxPossibleStart)
    ? [start]
    : eachMinuteOfInterval(
        {
          start,
          end: maxPossibleStart,
        },
        { step }
      );

  return intervals.map((step) => ({
    label: tz.format(step, "h:mmaaa"),
    value: step,
  }));
};

export const interactionMinDuration = (interaction) => {
  return Math.min(
    interaction.allowNonHourAutoBook ? 1800 : 3600,
    interaction.previousAdvisorshipId || interaction.state === "completed" ? 900 : 3600
  );
};

export const calculateOptionsEnd = ({ selectedStart, end, minDuration, tz, currentDuration }) => {
  const baseOptions = [900, 1800, 2700, 3600, 4500, 5400, 6300, 7200].map((seconds) => ({
    label: tz.format(addSeconds(selectedStart, seconds), "h:mmaaa"),
    value: seconds,
  }));

  const calculatedOptions = baseOptions
    .filter(({ value }) => value >= minDuration)
    .filter(({ value }) => !end || addSeconds(selectedStart, value) <= end);

  const missingCurrentValue = currentDuration && !calculatedOptions.find((o) => o.value === currentDuration);

  const options = missingCurrentValue
    ? [
        ...calculatedOptions,
        {
          value: currentDuration,
          label: tz.format(addSeconds(selectedStart, currentDuration), "h:mmaaa"),
        },
      ]
    : calculatedOptions;

  return options.sort((a, b) => a.value - b.value);
};

export const AvailabilityPopup = ({
  event: { interaction, availability, projectToken, deps },
  selectedStart,
  setSelectedStart,
  duration,
  setDuration,
  onClose,
  onSchedule,
  onScheduleFollowUp,
  onRequestSchedule,
  onRequestScheduleFollowUp,
  isFlyout,
}) => {
  const tz = useTimezone();
  const [saving, setSaving] = useState(
    interaction.runningAction === "schedule" || interaction.runningAction === "request"
  );

  const isCompleted = interaction.state === "completed";
  const minDuration = interactionMinDuration(interaction);
  const availabilityStart = parseISO(availability.startsAt);
  const availabilityEnd = parseISO(availability.endsAt);

  const optionsStart = calculateOptionsStart({
    start: availabilityStart,
    end: availabilityEnd,
    minDuration,
    tz,
  });

  const optionsEnd = useMemo(
    () =>
      calculateOptionsEnd({
        selectedStart,
        end: availabilityEnd,
        minDuration,
        tz,
      }),
    [selectedStart, minDuration, availabilityEnd, tz]
  );

  useEffect(() => {
    if (optionsEnd.length > 0 && !optionsEnd.find(({ value }) => value === duration)) {
      setDuration(optionsEnd[0].value);
    }
  }, [optionsEnd, duration, setDuration]);

  const onOpenFlyout = () => {
    deps.onSelectCard(interaction);
    onClose();
  };

  const { canRequest, canSchedule, canFollowUp, tooltipText: lockedExpertTooltipText } = useLockedExpert(interaction);

  const { addMessage } = useAlertMessengerContext();

  const { state, dispatch: dispatchCalendar } = useContext(CalendarContext);

  const dispatch = useContext(DispatchContext);

  const tooSoon = differenceInMinutes(selectedStart, new Date()) < 60;
  const disableScheduleButton = isCompleted ? !canFollowUp : !canSchedule;
  const disableRequestButton = isCompleted ? !canFollowUp : !canRequest;

  return (
    <CalendarPopup data-testid={`interaction-slot-popup-${interaction.id}`}>
      <PopupMenus advisorName={interaction.advisorName} onClose={onClose} />
      <ExpertData interaction={interaction} onOpenFlyout={onOpenFlyout} />
      <SelectDateTime
        optionsStart={optionsStart}
        optionsEnd={optionsEnd}
        selectedStart={selectedStart}
        setSelectedStart={setSelectedStart}
        duration={duration}
        setDuration={setDuration}
      />
      <SelectAttendees interaction={interaction} />
      {duration < 3600 && interaction.oneHourMinimum && <OneHourMinimumAlert />}
      <CalendarPopupFooter>
        {interaction.autoBookEnabled && !state.popup.attendees.isLoading && (
          <Tooltip
            title={
              (disableScheduleButton && lockedExpertTooltipText) ||
              (tooSoon && "Please reach out to your AlphaSights project lead to schedule a call on short notice.") ||
              ""
            }
          >
            <Button
              variant="primary"
              size="small"
              data-testid="schedule-button"
              loading={saving}
              onClick={() => {
                setSaving(true);
                const scheduleFun = isCompleted ? onScheduleFollowUp : onSchedule;
                scheduleFun(
                  {
                    interaction,
                    start: selectedStart,
                    end: addSeconds(selectedStart, duration),
                    interactionId: interaction.id,
                    timezone: tz.currentTimezone,
                  },
                  () => {
                    deps.onAttendeesUpdated({
                      id: interaction.id,
                      attendees: state.popup.attendees.selected,
                    });
                    dispatchCalendar(updateInitialAttendees(state.popup.attendees.selected));
                    setSaving(false);
                    if (isFlyout) {
                      dispatch(setFlyoutMode(FlyoutMode.Interaction));
                      addMessage({
                        message: (
                          <ScheduleConfirmationMessage
                            selectedStart={selectedStart}
                            projectToken={projectToken}
                            interactionId={interaction.id}
                          />
                        ),
                        icon: <Info />,
                      });
                    }
                  },
                  () => {
                    setSaving(false);
                  }
                );
              }}
              disabled={
                tooSoon ||
                selectedStart < availabilityStart ||
                addSeconds(selectedStart, duration) > availabilityEnd ||
                disableScheduleButton
              }
            >
              {isCompleted ? "Schedule Follow-up" : "Schedule"}
            </Button>
          </Tooltip>
        )}
        {!interaction.autoBookEnabled && !state.popup.attendees.isLoading && (
          <span data-tip={disableRequestButton ? lockedExpertTooltipText : ""}>
            <Button
              size="small"
              data-testid="request-button"
              loading={saving}
              onClick={() => {
                setSaving(true);
                const requestFun = isCompleted ? onRequestScheduleFollowUp : onRequestSchedule;
                requestFun({
                  requestedTime: selectedStart,
                  duration: duration,
                }).then(() => setSaving(false));
              }}
              disabled={
                selectedStart < availabilityStart ||
                addSeconds(selectedStart, duration) > availabilityEnd ||
                disableRequestButton
              }
            >
              {isCompleted ? "Request Follow-up" : "Request"}
            </Button>
          </span>
        )}
      </CalendarPopupFooter>
    </CalendarPopup>
  );
};

const ScheduleConfirmationMessage = ({ selectedStart, projectToken, interactionId }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <>
      <Typography component="span">
        Interaction scheduled for <FormattedDateTime date={selectedStart} format="EEEE d MMM yyyy, h:mmaaa (O)." />
      </Typography>
      <ButtonLink href={`/api/projects/${projectToken}/interactions/${interactionId}/calendar-invitation`}>
        <Typography variant="body-em" component="span" marginLeft={inner.base02}>
          Add to Calendar
        </Typography>
      </ButtonLink>
    </>
  );
};
