import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, color } = useThemeTokens();

  return {
    wrapper: {
      w: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: spacing.inner.base10,
    },
    deleteSummaryBtn: {
      my: spacing.inner.base10,
    },
    divider: {
      w: "100%",
    },
    overviewFloatingActionWrapper: {
      maxW: "636px",
      w: "100%",
      display: "flex",
      flexDirection: "column",
      mx: spacing.inner.base10,
      mt: spacing.inner.base10,
      alignSelf: "center",
    },
    themeFloatingActionWrapper: {
      maxW: "636px",
      w: "100%",
      display: "flex",
      flexDirection: "column",
    },
    overviewWrapper: {
      mt: spacing.inner.base10,
      mx: spacing.inner.base10,
      alignSelf: "center",
      w: "100%",
    },
    themeName: {
      pb: spacing.inner.base06,
    },
    themeWrapper: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      w: "100%",
    },
    themeContentWrapper: {
      display: "flex",
      gap: spacing.inner.base03,
      w: "100%",
    },
    themeTextWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base06,
      w: "100%",
    },
    quotesWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base02,
      w: "100%",
    },
    actionsWrapper: {
      maxW: "636px",
      w: "100%",
      display: "flex",
      gap: spacing.inner.base02,
      pl: spacing.inner.base10,
      pb: spacing.inner.base10,
    },
    highlightStyle: {
      w: "fit-content",
      bg: {
        hover: color.background.highlightBase,
      },
    },
    extendedHighlightStyle: {
      bg: color.background.highlightBase,
    },
    disclaimerWrapper: {
      maxW: "852px",
      w: "100%",
      pt: spacing.inner.base10,
    },
  };
};
