import React, { ChangeEvent, useMemo, useState } from "react";
import { HitAction, KpcItem, SynthesisQuote } from "@alphasights/portal-api-client";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { useStyles } from "../SynthesisModuleContent.styles";
import { Button, IconButton, Skeleton, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { EditableInput, ExpertQuote, RevisionToggler } from "../components";
import { x } from "@xstyled/styled-components";
import { KpcChart } from "./KpcChart";
import { kpc } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { Delete } from "@alphasights/alphadesign-icons";
import { EditSynthesisModuleOperations } from "views/ProjectSynthesisView/hooks/useEditSynthesisModule";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { ScheduleCustomersBanner } from "../components/ScheduleCustomersBanner";

export const KpcModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <KpcModuleContentSwitch />;
};

const KpcModuleContentSwitch = () => {
  return <KpcCompleteModuleContent />;
};

const KpcCompleteModuleContent = () => {
  const { spacing } = useThemeTokens();
  const { borderedBox, kpcContentWrapper } = useStyles();
  const {
    selectedModule,
    revision,
    mode,
    synthesisLogHit,
    saveInProgress,
    editOperations,
    selectedRevisionIdx,
  } = useProjectSynthesisContext();

  const onNavigateToTranscript = (quote: SynthesisQuote) => {
    synthesisLogHit({
      action: HitAction.projectSynthesisViewInTranscript,
      details: {
        revision: revision?.revision,
        quote: quote.text,
        moduleType: selectedModule?.contentType,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  const onEditKpcQuote = (updatedQuote: SynthesisQuote, kpcItemIndex: number) => {
    editOperations.updateKpcQuote(updatedQuote, selectedRevisionIdx, kpcItemIndex);
  };

  const onDeleteQuote = (quote: SynthesisQuote, kpcItemIndex: number) => {
    editOperations.deleteKpcQuote(quote, selectedRevisionIdx, kpcItemIndex);
  };

  return (
    <x.div data-testid="kpc-module-content" mt={spacing.inner.base06}>
      <ScheduleCustomersBanner />
      <x.div {...kpcContentWrapper} position="relative">
        {mode === Mode.VIEW && (
          <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="32px" zIndex="1" />
        )}
        <KpcChart kpcItems={kpc(revision?.contents).kpcItems.filter((item) => !!item.id) ?? []} />
        {mode === Mode.EDIT && <AddKpcButton />}
        {kpc(revision?.contents).kpcItems.map((kpcItem, idx) => (
          <x.div key={kpcItem.id} {...borderedBox} data-testid={`kpc-item-${kpcItem.id}`}>
            <KpcItemTitle
              kpcItemIndex={idx}
              kpcItem={kpcItem}
              mode={mode}
              saveInProgress={saveInProgress}
              editOperations={editOperations}
              selectedRevisionIdx={selectedRevisionIdx}
            />
            <KpcItemSummary
              kpcItemIndex={idx}
              kpcItem={kpcItem}
              mode={mode}
              editOperations={editOperations}
              selectedRevisionIdx={selectedRevisionIdx}
            />
            {!kpcItem.id && mode !== Mode.EDIT && (
              <x.div display="flex" flexDirection="column" gap={spacing.inner.base03}>
                <Skeleton variant="noMargin" />
                <Skeleton variant="noMargin" />
              </x.div>
            )}
            {!!kpcItem.id &&
              kpcItem.quotes.map((quote) => (
                <ExpertQuote
                  key={quote.id}
                  quote={quote}
                  mode={mode}
                  onEditQuote={(updatedQuote) => onEditKpcQuote(updatedQuote, idx)}
                  onDeleteQuote={() => onDeleteQuote(quote, idx)}
                  onNavigateToTranscript={() => onNavigateToTranscript(quote)}
                  saveInProgress={saveInProgress}
                  showCompanyInfo={true}
                />
              ))}
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

const KpcItemTitle = ({
  kpcItemIndex,
  kpcItem,
  mode,
  saveInProgress,
  editOperations,
  selectedRevisionIdx,
}: {
  kpcItemIndex: number;
  kpcItem: KpcItem;
  mode: Mode;
  saveInProgress: boolean;
  editOperations: EditSynthesisModuleOperations;
  selectedRevisionIdx: number;
}) => {
  const user = useCurrentUser();
  const [hasEdited, setHasEdited] = useState(false);

  const hasError = useMemo(() => mode === Mode.EDIT && hasEdited && kpcItem.name.trim().length === 0, [
    hasEdited,
    kpcItem.name,
    mode,
  ]);

  const onEditKpcItemName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setHasEdited(true);
    editOperations.updateKpcItemName(e.target.value, selectedRevisionIdx, kpcItemIndex);
  };

  const onDeleteKpcItem = () => {
    editOperations.deleteKpcItem(kpcItemIndex, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT && user?.enableAiInteractivity) {
    return (
      <x.div display="flex" gap="8px" data-testid={`kpc-title-edit-${kpcItem.id}`}>
        <EditableInput
          input={kpcItem.name}
          onChange={onEditKpcItemName}
          errorMessage={hasError ? "Title is required" : undefined}
          placeholder="KPC title here..."
        />
        <x.div mt="8px">
          <Tooltip variant="dark" title="Delete KPC">
            <IconButton
              size="small"
              variant="ghost"
              onClick={onDeleteKpcItem}
              testId={`delete-kpc-${kpcItem.id}`}
              disabled={saveInProgress}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </x.div>
      </x.div>
    );
  }

  return <Typography variant="body-em">{kpcItem.name}</Typography>;
};

const KpcItemSummary = ({
  kpcItemIndex,
  kpcItem,
  mode,
  editOperations,
  selectedRevisionIdx,
}: {
  kpcItemIndex: number;
  kpcItem: KpcItem;
  mode: Mode;
  editOperations: EditSynthesisModuleOperations;
  selectedRevisionIdx: number;
}) => {
  const onEditKpcItemSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    editOperations.updateKpcItemSummary(e.target.value, selectedRevisionIdx, kpcItemIndex);
  };

  if (mode === Mode.EDIT) {
    return (
      <EditableInput input={kpcItem.summary} onChange={onEditKpcItemSummary} placeholder="KPC description here..." />
    );
  }

  return <Typography>{kpcItem.summary}</Typography>;
};

const AddKpcButton = () => {
  const { editOperations, selectedRevisionIdx } = useProjectSynthesisContext();
  const user = useCurrentUser();

  if (!user?.enableAiInteractivity) return null;

  const onAddKpcItem = () => {
    editOperations.addKpcItem(selectedRevisionIdx);
  };

  return (
    <x.div display="flex" justifyContent="center">
      <Button variant="outline" onClick={onAddKpcItem} w="100%" dataAttributes={{ "data-testid": "add-kpc-button" }}>
        + Add KPC
      </Button>
    </x.div>
  );
};
