import React, { FC } from "react";
import { Button, Modal, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import { MODAL_TITLE, MODAL_MESSAGE, MODAL_WIDTH, CONFIRM, BACK } from "./constants";

const DataTestIds = {
  CONFIRM_BUTTON: "confirm-remove-button",
  BACK_BUTTON: "cancel-remove-button",
};

type ConfirmRemoveDocModalProps = {
  onClickConfirm: () => void;
  onClickCancel: () => void;
};

const ConfirmRemoveDocModal: FC<ConfirmRemoveDocModalProps> = ({ onClickConfirm, onClickCancel }) => (
  <Modal
    title={MODAL_TITLE}
    size="small"
    open
    slotWidth={MODAL_WIDTH}
    slotHeight="auto"
    transition="opacity 0.3s ease-out"
    shouldShowFooter
    onClose={onClickCancel}
    primaryButton={<ConfirmButton onClick={onClickConfirm} />}
    secondaryButton={<BackButton onClick={onClickCancel} />}
  >
    <Typography variant="body-small">{MODAL_MESSAGE}</Typography>
  </Modal>
);

type ModalButtonProps = {
  onClick: () => void;
};

const ConfirmButton: FC<ModalButtonProps> = ({ onClick }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={onClick}
      ml={spacing.inner.base04}
      dataAttributes={{ "data-testid": DataTestIds.CONFIRM_BUTTON }}
    >
      {CONFIRM}
    </Button>
  );
};

const BackButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick} dataAttributes={{ "data-testid": DataTestIds.BACK_BUTTON }}>
    {BACK}
  </Button>
);

export { ConfirmRemoveDocModal as default, DataTestIds };
