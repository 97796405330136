import React, { FC, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useRef } from "react";
import { Divider, PopoverProps, useThemeTokens } from "@alphasights/alphadesign-components";

import { STYLE_CONFIG, SearchSizeVariant, SearchStyleVariant } from "components/Search/consts";
import { SearchOption, OptionalOptionProps, OptionsPopoverFooterProps, OptionSections } from "components/Search/types";
import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import Footer from "./Footer";
import { getOptionsPerSection } from "./utils";
import ErrorAlert from "./ErrorAlert";

import { MAX_NUMBER_RESULTS, NUM_OPTIONS_DEFAULT_POPOVER, NUM_OPTIONS_POPOVER_WITH_SECTIONS } from "./consts";

import * as S from "./OptionsPopover.styled";
import { x } from "@xstyled/styled-components";

const DataTestIds = {
  Popover: "advanced-search-popover",
  OptionSectionTitle: "option-section-title",
  OptionSection: "option-section",
};

export interface OptionsPopoverProps extends PopoverProps {
  onClose: () => void;
  options: SearchOption[];
  onSelect: () => void;
  onOptionHighlight: (index: number) => void;
  components: Record<"Option", Function>;
  allowBooleanExpressions: boolean;

  size?: SearchSizeVariant;
  styleVariant?: SearchStyleVariant;
  isOpen?: boolean;
  anchorEl?: HTMLElement;
  highlightedOptionIndex?: number;
  optionProps?: OptionalOptionProps;
  footerProps?: OptionsPopoverFooterProps;
  optionSections?: OptionSections[];
  searchFailureMessage?: string;
}

const OptionsPopover = forwardRef(
  (
    {
      onClose,
      options,
      onSelect,
      onOptionHighlight,
      anchorEl,
      allowBooleanExpressions,
      components: { Option },
      highlightedOptionIndex = -1,
      isOpen = false,
      optionProps = {},
      footerProps,
      size = SearchSizeVariant.Medium,
      styleVariant = SearchStyleVariant.V1,
      optionSections = [],
      searchFailureMessage,
      ...props
    }: OptionsPopoverProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { spacing } = useThemeTokens();

    const { current: styles } = useRef(STYLE_CONFIG[size]);

    const numSections = optionSections.length;
    const hasSections = numSections > 0;
    const paddingTop = hasSections ? spacing.inner.base02 : toPx(0);
    const sectionsHeight = hasSections ? numSections * fromPx(styles.sectionHeader.height) : 0;

    const numVisibleOptions = hasSections ? NUM_OPTIONS_POPOVER_WITH_SECTIONS : NUM_OPTIONS_DEFAULT_POPOVER;

    const maxHeight = useMemo(
      () => toPx(fromPx(styles.height) * numVisibleOptions + sectionsHeight + fromPx(paddingTop)),
      [styles, numVisibleOptions, sectionsHeight, paddingTop]
    );

    // Limiting the number of results to avoid slowness - see CON-3159
    const displayedOptions = options.slice(0, MAX_NUMBER_RESULTS).map((option, index) => ({
      ...option,
      index,
    }));

    const optionsPerSection = getOptionsPerSection(displayedOptions, optionSections);

    const renderOptions = useCallback(
      (optionList: SearchOption[] = displayedOptions) => {
        return optionList.map((option) => {
          const isHighlighted = option.index === highlightedOptionIndex;
          return (
            <Option
              key={option.index}
              size={size}
              data={option}
              highlight={isHighlighted}
              onSelect={onSelect}
              onHighlight={() => onOptionHighlight(option.index as number)}
              {...optionProps}
            />
          );
        });
      },
      [highlightedOptionIndex, onSelect, onOptionHighlight, optionProps, size, displayedOptions]
    );

    const isEmbeddedWrapper = styleVariant === SearchStyleVariant.V2;
    const Wrapper = useMemo(() => (isEmbeddedWrapper ? EmbeddedWrapper : PopoverWrapper), [isEmbeddedWrapper]);
    const wrapperProps = {
      isOpen,
      ...(!isEmbeddedWrapper && {
        onClose,
        anchorEl,
        ref,
        ...props,
      }),
    };

    return (
      <Wrapper {...wrapperProps}>
        <S.PopoverContentContainer maxHeight={maxHeight} paddingTop={paddingTop}>
          {searchFailureMessage ? (
            <ErrorAlert text={searchFailureMessage} />
          ) : (
            optionsPerSection.map(({ title, options, errorMessage }) => (
              <>
                <SectionWrapper title={title} height={styles.sectionHeader.height}>
                  {renderOptions(options)}
                </SectionWrapper>
                {errorMessage && <ErrorAlert text={errorMessage} />}
              </>
            ))
          )}
        </S.PopoverContentContainer>
        <Footer
          {...footerProps!}
          size={size}
          styleVariant={styleVariant}
          allowBooleanExpressions={allowBooleanExpressions}
        />
      </Wrapper>
    );
  }
);

type SectionWrapperProps = {
  children: ReactNode;
  title: string;
  height: string;
};

const SectionWrapper: FC<SectionWrapperProps> = ({ children, title, height }) => {
  return (
    <div key={title} data-testId={DataTestIds.OptionSection}>
      {title && (
        <S.StyledTypography variant="body-small-em" data-testId={DataTestIds.OptionSectionTitle} height={height}>
          {title}
        </S.StyledTypography>
      )}
      {children}
    </div>
  );
};

type EmbeddedWrapperProps = {
  children: ReactNode;
  isOpen: boolean;
};

const EmbeddedWrapper: FC<EmbeddedWrapperProps> = ({ children, isOpen = false }) => {
  const ref = useRef<HTMLDivElement>(null);

  if (isOpen) {
    return (
      <x.div ref={ref} data-testid={DataTestIds.Popover} padding="1px">
        <Divider />
        {children}
      </x.div>
    );
  }
  return null;
};

type PopoverWrapperProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  anchorEl?: HTMLElement;
};

const PopoverWrapper = forwardRef(
  (
    { children, isOpen = false, onClose, anchorEl, ...props }: PopoverWrapperProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const anchorWidth = toPx(anchorEl?.getBoundingClientRect().width);
    const anchorLeft = `${toPx(anchorEl?.getBoundingClientRect().left)} !important`;

    const handleFloatingData = () => ({ w: anchorWidth, left: anchorLeft });
    return (
      <S.StyledPopover
        {...props}
        ref={ref}
        data-testid={DataTestIds.Popover}
        className={DataTestIds.Popover}
        open={isOpen}
        closeOnClickOutside
        onClose={onClose}
        anchorEl={anchorEl}
        onFloatingData={handleFloatingData}
      >
        {children}
      </S.StyledPopover>
    );
  }
);

export { OptionsPopover as default, DataTestIds };
