import React, { FC, useCallback, useMemo } from "react";
import _ from "lodash";
import { Avatar } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";

import { TranscriptFragment } from "services/thirdPartyDocumentsService";
import { getSpeakerName } from "./utils";
import { AVATAR_COLORS } from "./constants";

import * as S from "./TranscriptViewer.styled";

export const DataTestIds = {
  TRANSCRIPT_VIEWER: "transcript-viewer",
};

type TranscriptViewerProps = {
  onError: () => void;
  fragments?: TranscriptFragment[];
  expertCompany?: string;
  expertPosition?: string;
};

const TranscriptViewer: FC<TranscriptViewerProps> = ({ fragments, onError, expertCompany, expertPosition }) => {
  const orderedUniqueSpeakers = useMemo(() => _.uniq(_.map(fragments, "sanitized_source")), [fragments]);

  const _getSpeakerName = useCallback(
    (speaker: string) => {
      // 2 speakers = Interviewer + Expert
      if (orderedUniqueSpeakers.length > 2) {
        return speaker;
      }
      return getSpeakerName(speaker, expertCompany, expertPosition);
    },
    [orderedUniqueSpeakers, expertCompany, expertPosition]
  );

  const render = () => {
    if (fragments?.length) {
      try {
        return (
          <S.TranscriptWrapper data-testid={DataTestIds.TRANSCRIPT_VIEWER}>
            {fragments.map((fragment) => {
              const { sanitized_source: speaker, content, index } = fragment;
              const speakerName = _getSpeakerName(speaker);
              const avatarColor = AVATAR_COLORS[speaker as keyof typeof AVATAR_COLORS];
              return <Fragment key={`fragment-${index}`} color={avatarColor} speaker={speakerName} content={content} />;
            })}
          </S.TranscriptWrapper>
        );
      } catch (error) {
        console.error("Error rendering transcript", error);
        onError();
      }
    }
    return null;
  };

  return render();
};

const Fragment = ({ color, speaker, content }: { color: any; speaker: string; content: string }) => (
  <S.FragmentWrapper>
    <S.SpeakerWrapper>
      <Avatar size="small" color={color}>
        <Expert />
      </Avatar>
      <S.SpeakerTypography>{speaker}</S.SpeakerTypography>
    </S.SpeakerWrapper>
    <S.FragmentTypography>{content}</S.FragmentTypography>
  </S.FragmentWrapper>
);

export default TranscriptViewer;
